import moment from "moment";
import { LESSON_SERVER_URL, UPLOAD_API_URL } from "../config/constants";
/**
 * Determine the mouse position
 *
 * @param event the canvas event
 * @returns *[] tuple of position x,y
 * @private
 */

export const pointerPosition = (event) => {
  event = event || window.event;
  var target = event.target || event.srcElement,
    style = target.currentStyle || window.getComputedStyle(target, null),
    borderLeftWidth = parseInt(style["borderLeftWidth"], 10),
    borderTopWidth = parseInt(style["borderTopWidth"], 10),
    rect = target.getBoundingClientRect(),
    _x = event.clientX - borderLeftWidth - rect.left,
    _y = event.clientY - borderTopWidth - rect.top,
    _touchX = event.changedTouches
      ? event.changedTouches[0].clientX - borderLeftWidth - rect.left
      : null,
    _touchY = event.changedTouches
      ? event.changedTouches[0].clientY - borderTopWidth - rect.top
      : null;
  return [_x || _touchX, _y || _touchY];
};

export const isObjectHoverable = (o) => {
  if (o.objectType && ((o.objectType === 'audio' || o.objectType === 'video' || o.objectType === 'pdfToView'))) {
    return true
  }
  else {
    return false
  }
};


/**
 * Calculate the distance of two x,y points
 *
 * @param point1 an object with x,y attributes representing the start point
 * @param point2 an object with x,y attributes representing the end point
 *
 * @returns {number}
 */
export const linearDistance = (point1, point2) => {
  let xs = point2.x - point1.x;
  let ys = point2.y - point1.y;
  return Math.sqrt(xs * xs + ys * ys);
};

/**
 * Return a random uuid of the form xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx
 * @returns {string}
 */
export const uuid4 = () => {
  let uuid = "",
    ii;
  for (ii = 0; ii < 32; ii += 1) {
    switch (ii) {
      case 8:
      case 20:
        uuid += "-";
        uuid += ((Math.random() * 16) | 0).toString(16);
        break;
      case 12:
        uuid += "-";
        uuid += "4";
        break;
      case 16:
        uuid += "-";
        uuid += ((Math.random() * 4) | 8).toString(16);
        break;
      default:
        uuid += ((Math.random() * 16) | 0).toString(16);
    }
  }
  return uuid;
};

/**
 *
 * @param hex is a color code in the form of hex.
 * Return a rgba equivalent of given hex.
 * @returns {string}
 */
export const hexToRgbA = (hex) => {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",0.4)";
  }
  throw new Error("Bad Hex");
};

/**
 *
 * @param color is a color name to convert it to hex
 * Return hex equivalent of given color name
 */
export const colorNameToHex = (color) => {
  var colors = {
    aliceblue: "#f0f8ff",
    antiquewhite: "#faebd7",
    aqua: "#00ffff",
    aquamarine: "#7fffd4",
    azure: "#f0ffff",
    beige: "#f5f5dc",
    bisque: "#ffe4c4",
    black: "#000000",
    blanchedalmond: "#ffebcd",
    blue: "#0000ff",
    blueviolet: "#8a2be2",
    brown: "#a52a2a",
    burlywood: "#deb887",
    cadetblue: "#5f9ea0",
    chartreuse: "#7fff00",
    chocolate: "#d2691e",
    coral: "#ff7f50",
    cornflowerblue: "#6495ed",
    cornsilk: "#fff8dc",
    crimson: "#dc143c",
    cyan: "#00ffff",
    darkblue: "#00008b",
    darkcyan: "#008b8b",
    darkgoldenrod: "#b8860b",
    darkgray: "#a9a9a9",
    darkgreen: "#006400",
    darkkhaki: "#bdb76b",
    darkmagenta: "#8b008b",
    darkolivegreen: "#556b2f",
    darkorange: "#ff8c00",
    darkorchid: "#9932cc",
    darkred: "#8b0000",
    darksalmon: "#e9967a",
    darkseagreen: "#8fbc8f",
    darkslateblue: "#483d8b",
    darkslategray: "#2f4f4f",
    darkturquoise: "#00ced1",
    darkviolet: "#9400d3",
    deeppink: "#ff1493",
    deepskyblue: "#00bfff",
    dimgray: "#696969",
    dodgerblue: "#1e90ff",
    firebrick: "#b22222",
    floralwhite: "#fffaf0",
    forestgreen: "#228b22",
    fuchsia: "#ff00ff",
    gainsboro: "#dcdcdc",
    ghostwhite: "#f8f8ff",
    gold: "#ffd700",
    goldenrod: "#daa520",
    gray: "#808080",
    green: "#008000",
    greenyellow: "#adff2f",
    honeydew: "#f0fff0",
    hotpink: "#ff69b4",
    "indianred ": "#cd5c5c",
    indigo: "#4b0082",
    ivory: "#fffff0",
    khaki: "#f0e68c",
    lavender: "#e6e6fa",
    lavenderblush: "#fff0f5",
    lawngreen: "#7cfc00",
    lemonchiffon: "#fffacd",
    lightblue: "#add8e6",
    lightcoral: "#f08080",
    lightcyan: "#e0ffff",
    lightgoldenrodyellow: "#fafad2",
    lightgrey: "#d3d3d3",
    lightgreen: "#90ee90",
    lightpink: "#ffb6c1",
    lightsalmon: "#ffa07a",
    lightseagreen: "#20b2aa",
    lightskyblue: "#87cefa",
    lightslategray: "#778899",
    lightsteelblue: "#b0c4de",
    lightyellow: "#ffffe0",
    lime: "#00ff00",
    limegreen: "#32cd32",
    linen: "#faf0e6",
    magenta: "#ff00ff",
    maroon: "#800000",
    mediumaquamarine: "#66cdaa",
    mediumblue: "#0000cd",
    mediumorchid: "#ba55d3",
    mediumpurple: "#9370d8",
    mediumseagreen: "#3cb371",
    mediumslateblue: "#7b68ee",
    mediumspringgreen: "#00fa9a",
    mediumturquoise: "#48d1cc",
    mediumvioletred: "#c71585",
    midnightblue: "#191970",
    mintcream: "#f5fffa",
    mistyrose: "#ffe4e1",
    moccasin: "#ffe4b5",
    navajowhite: "#ffdead",
    navy: "#000080",
    oldlace: "#fdf5e6",
    olive: "#808000",
    olivedrab: "#6b8e23",
    orange: "#ffa500",
    orangered: "#ff4500",
    orchid: "#da70d6",
    palegoldenrod: "#eee8aa",
    palegreen: "#98fb98",
    paleturquoise: "#afeeee",
    palevioletred: "#d87093",
    papayawhip: "#ffefd5",
    peachpuff: "#ffdab9",
    peru: "#cd853f",
    pink: "#ffc0cb",
    plum: "#dda0dd",
    powderblue: "#b0e0e6",
    purple: "#800080",
    rebeccapurple: "#663399",
    red: "#ff0000",
    rosybrown: "#bc8f8f",
    royalblue: "#4169e1",
    saddlebrown: "#8b4513",
    salmon: "#fa8072",
    sandybrown: "#f4a460",
    seagreen: "#2e8b57",
    seashell: "#fff5ee",
    sienna: "#a0522d",
    silver: "#c0c0c0",
    skyblue: "#87ceeb",
    slateblue: "#6a5acd",
    slategray: "#708090",
    snow: "#fffafa",
    springgreen: "#00ff7f",
    steelblue: "#4682b4",
    tan: "#d2b48c",
    teal: "#008080",
    thistle: "#d8bfd8",
    tomato: "#ff6347",
    turquoise: "#40e0d0",
    violet: "#ee82ee",
    wheat: "#f5deb3",
    white: "#ffffff",
    whitesmoke: "#f5f5f5",
    yellow: "#ffff00",
    yellowgreen: "#9acd32",
  };

  if (typeof colors[color.toLowerCase()] != "undefined")
    return colors[color.toLowerCase()];

  return false;
};

/**
 if multiple events with same objectID is present it will return the 
 event which is latest.
 **/

export function getLastUpdatedEventsInAscending(whiteboardEvents, startTime, endTime) {
  var lastUpdatedEvents = [];
  var alreadyFoundObjectIDs = [];
  const startTimeMoment = moment(startTime)
  const endTimeMoment = moment(endTime)
  for (var i = whiteboardEvents.length - 1; i >= 0; i--) {
    var eventData = JSON.parse(whiteboardEvents[i].eventData);
    var objectID = eventData.objectID
    var eventTimeMoment = moment(eventData.timestamp)
    if (eventTimeMoment.isSameOrAfter(startTimeMoment) && eventTimeMoment.isSameOrBefore(endTimeMoment)) {
      if (!alreadyFoundObjectIDs.includes(objectID) || !Boolean(objectID)) {
        alreadyFoundObjectIDs.push(objectID);
        lastUpdatedEvents.push(whiteboardEvents[i]);
      }
    }

  }
  return lastUpdatedEvents.reverse();
}

export function createIframeUsingJavascript(positionDimensionObj, objectID, mediaFileUrl, changeObjPosition, iframeType, clientID, roomName, accessToken) {
  // console.log(positionDimensionObj, objectID, mediaFileUrl)
  // console.log('roomname', roomName)
  if (document.getElementById(objectID)) {
    document.getElementById(objectID).remove()
  }
  let iframeDiv = document.createElement('div');
  iframeDiv.id = objectID;
  iframeDiv.style.position = 'fixed';
  iframeDiv.style.left = positionDimensionObj.left + 'px';
  iframeDiv.style.top = positionDimensionObj.top + 'px';
  iframeDiv.style.width = positionDimensionObj.width + 'px';
  iframeDiv.style.height = positionDimensionObj.height + 'px';
  iframeDiv.style.background = '#ffffff';
  iframeDiv.style.display = 'block';
  iframeDiv.style.zIndex = 5;
  iframeDiv.style.borderRadius = '10px';
  iframeDiv.style.boxShadow = '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)';
  let topBar = document.createElement('div')
  topBar.id = objectID + 'header'
  topBar.style.width = "100%";
  topBar.style.zIndex = 1;
  // topBar.style.marginLeft = "5%";
  topBar.style.height = "6.5%";
  topBar.style.position = 'relative';
  topBar.style.background = '#27292C';
  topBar.style.borderRadius = '10px 10px 0 0';
  topBar.style.cursor = 'move';
  let closeBtn = document.createElement("button");
  closeBtn.style.position = 'absolute';
  closeBtn.id = objectID + '-close';
  closeBtn.style.right = '5px';
  closeBtn.style.top = '5px';
  closeBtn.style.height = '15px'
  closeBtn.style.width = '25px'
  closeBtn.style.background = 'none';
  closeBtn.style.border = 'none';
  closeBtn.style.zIndex = 1000;
  closeBtn.style.cursor = 'pointer';
  // closeBtn.innerHTML = 'Close';
  let closeIcon = document.createElement('img')
  closeIcon.src = UPLOAD_API_URL + 'uploads/9eebcf13-7a44-4e2a-a2e3-ddf0c9a989f8/close_icon.png'
  closeIcon.style.height = '100%'
  closeIcon.style.width = '100%'
  closeBtn.appendChild(closeIcon)
  topBar.appendChild(closeBtn)
  iframeDiv.appendChild(topBar);
  let substack = document.createElement("iframe");
  if (iframeType) {
    if (iframeType === 'pdfViewer') {
      substack.src = LESSON_SERVER_URL + 'pdfViewer?roomName=' + roomName + '&clientID=' + clientID + '&objectID=' + objectID + '&pdfUrl=' + mediaFileUrl + '&accessToken=' + accessToken
      // console.log(substack.src)
    }
  }
  else {
    substack.src = mediaFileUrl;
  }
  substack.id = objectID + '-iframe'
  substack.loading = 'lazy'
  substack.width = "100%";
  substack.height = "92.5%";
  substack.style.border = 'none'
  substack.style.padding = '5px'
  substack.style.position = 'relative';
  iframeDiv.appendChild(substack);
  document.body.appendChild(iframeDiv);
  dragElement(iframeDiv, changeObjPosition);
  var resizer = document.createElement('div');
  resizer.id = objectID + '-resizer'
  resizer.style.width = "10px";
  resizer.style.height = '15px';
  resizer.style.background = 'transparent';
  resizer.style.position = 'absolute';
  resizer.style.right = 0;
  resizer.style.bottom = 0;
  resizer.style.zIndex = 7;

  // resizer.style.opacity = 0;
  resizer.style.cursor = 'se-resize';
  var resizeIcon = document.createElement('div');
  resizeIcon.style.width = '100%'
  resizeIcon.style.height = '100%'
  resizeIcon.style.transform = 'rotate(45deg)';
  resizeIcon.style.display = 'flex';
  // resizeIcon.style.background = 'black';
  var resizeIcon1 = document.createElement('div');
  resizeIcon1.style.width = '1px'
  resizeIcon1.style.height = '125%'
  // resizeIcon1.style.transform = 'rotate(45deg)';
  resizeIcon1.style.background = 'black';
  //Append Child to Element
  resizeIcon.appendChild(resizeIcon1)
  var resizeIcon2 = document.createElement('div');
  resizeIcon2.style.width = '1px'
  resizeIcon2.style.height = '75%'
  // resizeIcon2.style.transform = 'rotate(45deg)';
  resizeIcon2.style.background = 'black';
  resizeIcon2.style.marginLeft = '2.55px';
  resizeIcon2.style.marginTop = '40%';
  //Append Child to Element
  resizeIcon.appendChild(resizeIcon2)
  resizer.appendChild(resizeIcon)
  var resizerRight = document.createElement('div');
  resizerRight.id = objectID + '-resizer-right'
  resizerRight.style.width = "5px";
  resizerRight.style.height = '90%';
  resizerRight.style.background = 'transparent';
  resizerRight.style.position = 'absolute';
  resizerRight.style.right = 0;
  resizerRight.style.top = 0;
  resizerRight.style.zIndex = 6;
  resizerRight.style.opacity = 0;
  resizerRight.style.cursor = 'w-resize';
  iframeDiv.appendChild(resizerRight);
  var resizerLeft = document.createElement('div');
  resizerLeft.id = objectID + '-resizer-left'
  resizerLeft.style.width = "5px";
  resizerLeft.style.height = '100%';
  resizerLeft.style.background = 'transparent';
  resizerLeft.style.position = 'absolute';
  resizerLeft.style.left = 0;
  resizerLeft.style.top = 0;
  resizerLeft.style.zIndex = 6;
  resizerLeft.style.opacity = 0;
  resizerLeft.style.cursor = 'w-resize';
  iframeDiv.appendChild(resizerLeft);
  iframeDiv.appendChild(resizerRight);
  iframeDiv.appendChild(resizer);
  //box function onmousemove
  resizeElement(iframeDiv, changeObjPosition, iframeType);
  // iframeDiv.addEventListener('scroll', function (e) {
  //   e.preventDefault()
  //   console.log(e)
  // }, false)
  // // substack.contentDocument.onscroll = function (e) {
  // //   console.log(e)
  // // }
  // substack.contentDocument.addEventListener('scroll', function (event) {
  //   console.log(event);
  //   // console.log('hey')
  // }, false)
  //Window funtion mousemove & mouseup

}

function resizeElement(elmnt, changeObjPosition, iframeType) {
  var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;

  document.getElementById(elmnt.id + '-resizer').onmousedown = mouseDownResize;

  document.getElementById(elmnt.id + '-resizer-right').onmousedown = mouseDownResizeRight;

  document.getElementById(elmnt.id + '-resizer-left').onmousedown = mouseDownResizeLeft;

  function mouseDownResizeLeft(e) {
    e = e || window.event;
    e.preventDefault();
    document.getElementById(elmnt.id + '-iframe').style.zIndex = -1;
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    window.onmouseup = stopResizeElementLeft;
    // call a fuwindowhenever the cursor moves:
    window.onmousemove = elementResizeLeft;
  }

  function elementResizeLeft(e) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    elmnt.style.width = parseInt((elmnt.style.width).substring(0, (elmnt.style.width).length - 2)) + (pos3 - e.clientX) + "px"
    elmnt.style.left = parseInt((elmnt.style.left).substring(0, (elmnt.style.left).length - 2)) - (pos3 - e.clientX) + "px"
    // elmnt.style.height = parseInt((elmnt.style.height).substring(0, (elmnt.style.height).length - 2)) - (pos4 - e.clientY) + "px"
    if (iframeType) {
      if (iframeType === 'pdfViewer') {
        let iframe = document.getElementById(elmnt.id + '-iframe');
        // console.log(iframe.contentWindow)
        iframe.contentWindow.changePdfViewerDimension(parseInt((elmnt.style.height).substring(0, (elmnt.style.height).length - 2)), parseInt((elmnt.style.width).substring(0, (elmnt.style.width).length - 2)))
      }
    }
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    // console.log('chaning dimension')
    // console.log(parseInt((elmnt.style.width).substring(0, (elmnt.style.width).length - 2)))
    // console.log(e.clientX - elmnt.offsetLeft)

    // elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
    // elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
  }

  function stopResizeElementLeft(e) {
    // stop moving when mouse button is released:
    e.preventDefault()
    changeObjPosition(elmnt.id, elmnt.style.left, '', elmnt.style.height, elmnt.style.width)
    // console.log(elmnt.id)
    document.getElementById(elmnt.id + '-iframe').style.zIndex = 0;
    window.onmouseup = null;
    window.onmousemove = null;
    window.removeEventListener("mousemove", elementResizeLeft, false);
    window.removeEventListener("mouseup", stopResizeElementLeft, false);
    // document.removeEventListener("mousedown", mouseDownResize, false);
  }

  function mouseDownResizeRight(e) {
    e = e || window.event;
    e.preventDefault();
    document.getElementById(elmnt.id + '-iframe').style.zIndex = -1;
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    window.onmouseup = stopResizeElementRight;
    // call a fuwindowhenever the cursor moves:
    window.onmousemove = elementResizeRight;
  }

  function elementResizeRight(e) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    elmnt.style.width = parseInt((elmnt.style.width).substring(0, (elmnt.style.width).length - 2)) - (pos3 - e.clientX) + "px"
    // elmnt.style.height = parseInt((elmnt.style.height).substring(0, (elmnt.style.height).length - 2)) - (pos4 - e.clientY) + "px"
    if (iframeType) {
      if (iframeType === 'pdfViewer') {
        let iframe = document.getElementById(elmnt.id + '-iframe');
        // console.log(iframe.contentWindow)
        iframe.contentWindow.changePdfViewerDimension(parseInt((elmnt.style.height).substring(0, (elmnt.style.height).length - 2)), parseInt((elmnt.style.width).substring(0, (elmnt.style.width).length - 2)))
      }
    }
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    // console.log('chaning dimension')
    // console.log(parseInt((elmnt.style.width).substring(0, (elmnt.style.width).length - 2)))
    // console.log(e.clientX - elmnt.offsetLeft)

    // elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
    // elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
  }

  function stopResizeElementRight(e) {
    // stop moving when mouse button is released:
    e.preventDefault()
    changeObjPosition(elmnt.id, '', '', elmnt.style.height, elmnt.style.width)
    // console.log(elmnt.id)
    document.getElementById(elmnt.id + '-iframe').style.zIndex = 0;
    window.onmouseup = null;
    window.onmousemove = null;
    window.removeEventListener("mousemove", elementResizeRight, false);
    window.removeEventListener("mouseup", stopResizeElementRight, false);
    // document.removeEventListener("mousedown", mouseDownResize, false);
  }



  function mouseDownResize(e) {
    e = e || window.event;
    e.preventDefault();
    document.getElementById(elmnt.id + '-iframe').style.zIndex = -1;
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    window.onmouseup = stopResizeElement;
    // call a fuwindowhenever the cursor moves:
    window.onmousemove = elementResize;
  }

  function elementResize(e) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    elmnt.style.width = parseInt((elmnt.style.width).substring(0, (elmnt.style.width).length - 2)) - (pos3 - e.clientX) + "px"
    elmnt.style.height = parseInt((elmnt.style.height).substring(0, (elmnt.style.height).length - 2)) - (pos4 - e.clientY) + "px"
    if (iframeType) {
      if (iframeType === 'pdfViewer') {
        let iframe = document.getElementById(elmnt.id + '-iframe');
        // console.log(iframe.contentWindow)
        iframe.contentWindow.changePdfViewerDimension(parseInt((elmnt.style.height).substring(0, (elmnt.style.height).length - 2)), parseInt((elmnt.style.width).substring(0, (elmnt.style.width).length - 2)))
      }
    }
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    // console.log('chaning dimension')
    // console.log(parseInt((elmnt.style.width).substring(0, (elmnt.style.width).length - 2)))
    // console.log(e.clientX - elmnt.offsetLeft)

    // elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
    // elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
  }

  function stopResizeElement(e) {
    // stop moving when mouse button is released:
    e.preventDefault()
    changeObjPosition(elmnt.id, '', '', elmnt.style.height, elmnt.style.width)
    // console.log(elmnt.id)
    document.getElementById(elmnt.id + '-iframe').style.zIndex = 0;
    window.onmouseup = null;
    window.onmousemove = null;
    window.removeEventListener("mousemove", elementResize, false);
    window.removeEventListener("mouseup", stopResizeElement, false);
    // document.removeEventListener("mousedown", mouseDownResize, false);
  }
}

// Make the DIV element draggable:


function dragElement(elmnt, changeObjPosition) {
  let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
  if (document.getElementById(elmnt.id + "header")) {
    // if present, the header is where you move the DIV from:
    document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
  }
  // else {
  //   // otherwise, move the DIV from anywhere inside the DIV:
  //   elmnt.onmousedown = dragMouseDown;
  // }

  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    window.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    window.onmousemove = elementDrag;
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
    elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
  }

  function closeDragElement(e) {
    // stop moving when mouse button is released:
    e.preventDefault()
    changeObjPosition(elmnt.id, elmnt.style.left, elmnt.style.top)
    // console.log(elmnt.id)
    window.onmouseup = null;
    window.onmousemove = null;
    window.removeEventListener("mousemove", elementDrag, false);
    window.removeEventListener("mouseup", closeDragElement, false);
  }
}