import React, { useEffect, useState } from 'react';
import { THEME_COLOR } from '../whiteboard/src/config/constants';

const MicrophoneCheck = () => {
    const [isMicWorking, setIsMicWorking] = useState(null);
    const [bandwidth, setBandwidth] = useState(null);
    const [cameraStatus, setCameraStatus] = useState('Checking...');
    const [cameraStream, setCameraStream] = useState(null);
    const [cameraDevices, setCameraDevices] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [audioDevices, setAudioDevices] = useState([]);
    const [selectedAudioDevice, setSelectedAudioDevice] = useState(null);

    useEffect(() => {
        const getCameraDevices = async () => {
            try {
                const devices = await navigator.mediaDevices.enumerateDevices();
                const videoDevices = devices.filter(device => device.kind === 'videoinput');
                setCameraDevices(videoDevices);
                setSelectedDevice(videoDevices.length > 0 ? videoDevices[0].deviceId : null);

                const audioDevices = devices.filter(device => device.kind === 'audioinput');
                // console.log('audioDevices', audioDevices)
                setAudioDevices(audioDevices);
                setSelectedAudioDevice(audioDevices.length > 0 ? audioDevices[0].deviceId : null);
            } catch (error) {
                console.error('Error enumerating devices:', error);
            }
        };

        getCameraDevices();
    }, []);

    const checkCamera = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: { deviceId: selectedDevice } });

            if (stream) {
                setCameraStream(stream);
                setCameraStatus('Camera is working!');
            }
        } catch (error) {
            setCameraStatus('Error accessing camera: ' + error.message);
        }
    };

    const handleDeviceChange = (event) => {
        setSelectedDevice(event.target.value);
    };

    const checkAudio = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: { deviceId: selectedAudioDevice } });
            stream.getTracks().forEach(track => track.stop());
            setIsMicWorking(true);
        } catch (error) {
            console.error('Error accessing microphone:', error);
            setIsMicWorking(false);
        }
    };

    const handleAudioDeviceChange = (event) => {
        setSelectedAudioDevice(event.target.value);
    };

    const checkBandwidth = () => {
        if (navigator.connection) {
            const connection = navigator.connection;
            const effectiveType = connection.effectiveType || 'unknown';
            setBandwidth(effectiveType);
        } else {
            setBandwidth('Not supported in this browser');
        }
    };

    //   useEffect(() => {
    //     checkBandwidth();
    //   }, []);

    return (
        <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', overflowY: 'auto', paddingBottom: '30px' }}>
            <h2>Microphone Check</h2>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <h2>Audio Device:</h2>
                <label htmlFor="audioDevices">Select Audio Device:</label>
                <select id="audioDevices" onChange={handleAudioDeviceChange} value={selectedAudioDevice}>
                    {audioDevices.map(device => (
                        <option key={device.deviceId} value={device.deviceId}>
                            {device.label || `Audio ${device.deviceId.substring(0, 5)}`}
                        </option>
                    ))}
                </select>
                <button onClick={checkAudio} style={{ marginTop: '20px' }}>Check Microphone</button>
                {isMicWorking === true && <p style={{ color: THEME_COLOR }}>Microphone is working!</p>}
                {isMicWorking === false && <p style={{ color: 'red' }}>Microphone is not working or access is denied.</p>}
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <h2>Estimated Bandwidth: <span style={{ color: THEME_COLOR }}>{bandwidth}</span></h2>
                <button onClick={checkBandwidth}>Check Bandwidth</button>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <h2>Camera Status:</h2>
                <label htmlFor="cameraDevices">Select Camera:</label>
                <select id="cameraDevices" onChange={handleDeviceChange} value={selectedDevice}>
                    {cameraDevices.map(device => (
                        <option key={device.deviceId} value={device.deviceId}>
                            {device.label || `Camera ${device.deviceId.substring(0, 5)}`}
                        </option>
                    ))}
                </select>
                {cameraStream ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <p style={{ color: 'green' }}>{cameraStatus}</p>
                        <video width="400" height="300" autoPlay ref={(videoRef) => {
                            if (videoRef) {
                                (videoRef.srcObject = cameraStream)
                            }
                        }}></video>
                    </div>
                ) : (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <p>{cameraStatus}</p>
                        <button onClick={checkCamera}>Check Camera</button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MicrophoneCheck;
