/*eslint no-unused-vars: 0*/

import FabricCanvasTool from "../fabrictool";

class Select extends FabricCanvasTool {
  configureCanvas(props) {
    let canvas = this._canvas;
    canvas.defaultCursor = 'default';
    canvas.hoverCursor = 'move';
    canvas.isDrawingMode = false;
    canvas.selection = true;
    canvas.forEachObject((o) => {
      // console.log('eraser tool selected', o)
      // console.log('eraser tool selected left and top', o.left, o.top)
      o.eraserLeft = o.left;
      o.eraserTop = o.top;
      if (!o.isPdf) {
        o.selectable = o.evented = true;
      }
      if (o.objectType === 'iframe') {
        o.selectable = false
      }
    });
  }
}

export default Select;
