import React, { useState, useEffect } from 'react';
import { Button, IconButton, Hidden, Typography, Tooltip, Popover, Box, Grid, OutlinedInput, Icon, Dialog, DialogTitle, DialogContent, DialogActions, } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { useNavigate } from 'react-router-dom';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material';
import PdfViewerCustom from '../PdfViewer/CustomPdfViewer';
import { THEME_COLOR, THEME_HOVER_COLOR } from '../../whiteboard/src/config/constants';



const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



const Content = ({ content, array, index, user, setArray, isWhiteboard, handleIsPdf, handlePptxDocs, addImage, uploadCanvasFromContentLibrary, setContentBox }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const [openPdfDialog, setOpenPdfDialog] = useState(false)
    const [fileType, setFileType] = useState('')

    const handleClickForWhiteboard = () => {
        if (fileType === 'pdf') {
            handleIsPdf(null, content.fileLink)

        }
        else if (fileType === 'jpeg' || fileType === 'png' || fileType === 'jpg' || fileType === 'image') {
            // console.log('add img')
            if (addImage) {
                addImage.addImg(content.fileLink, false)
            }

        }
        else if (fileType === 'txt') {
            uploadCanvasFromContentLibrary(content.fileLink)
        }
        else if (fileType === 'mp4' || fileType === 'mov' || fileType === 'webm' || fileType === 'video') {
            if (addImage) {
                let elementNameArray = (content.fileLink).split('/')
                let elementName = elementNameArray[elementNameArray.length - 1]
                addImage.addAudioVideoTag(content.fileLink, 'video', elementName)
            }
            //isvideo
        }
        else if (fileType === 'mp3' || fileType === 'wav' || fileType === 'audio') {
            if (addImage) {
                let elementNameArray = (content.fileLink).split('/')
                let elementName = elementNameArray[elementNameArray.length - 1]
                addImage.addAudioVideoTag(content.fileLink, 'audio', elementName)
            }
            //isaudio
        }
        // else if (fileType === 'pptx') {
        //     handlePptxDocs(null, 'pptx', uploadedFileUrl)
        // }
        // else if (fileType === 'docx') {
        //     handlePptxDocs(null, 'docx', uploadedFileUrl)
        // }
        // else if (fileType === 'xlsx') {
        //     handlePptxDocs(null, 'xlsx', uploadedFileUrl)
        // }
        // else if (fileType === 'ppt') {
        //     handlePptxDocs(null, 'ppt', uploadedFileUrl)
        // }
        // else if (fileType === 'doc') {
        //     handlePptxDocs(null, 'doc', uploadedFileUrl)
        // }
        // else if (fileType === 'xls') {
        //     handlePptxDocs(null, 'xls', uploadedFileUrl)
        // }
        setContentBox(false)
        window.whiteboardScrollDisable = false
    }

    return (
        <>
            <Grid container
                sx={{
                    width: { xs: '80%', sm: '60%', md: '60%', lg: '70%' },
                    // '&:hover': {
                    //     backgroundColor: '#D9F9E2',
                    // },
                    backgroundColor: '#FFFFFF',
                    margin: '10px',
                    borderRadius: '10px',
                    border: '1px solid #e0e0e0',
                    padding: '20px 10px'
                }}
            // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >

                {isWhiteboard ? <Grid item xs={12} sm={12} md={12} lg={12} sx={{
                    paddingTop: '5px', paddingLeft: '5px',
                    cursor: 'pointer',

                }}
                    onClick={async () => {
                        let elementNameArray = (content.fileLink).split('.')
                        let elementType = elementNameArray[elementNameArray.length - 1]
                        // console.log(elementType)
                        if (elementType === 'pdf') {
                            setOpenPdfDialog(true)
                            //ispdf
                            setFileType('pdf')

                        }
                        else if (elementType === 'jpeg' || elementType === 'png' || elementType === 'jpg') {
                            //isimg
                            setOpenPdfDialog(true)
                            setFileType('image')
                        }
                        else if (elementType === 'txt') {
                            setOpenPdfDialog(true)
                            setFileType('txt')
                            //is txt
                        }
                        else if (elementType === 'mp4' || elementType === 'mov' || elementType === 'webm') {
                            setFileType('video')
                            setOpenPdfDialog(true)

                        }
                        else if (elementType === 'mp3' || elementType === 'wav') {
                            setFileType('audio')
                            setOpenPdfDialog(true)
                        }
                        // else if (elementType === 'pptx') {
                        //     handlePptxDocs(null, 'pptx', uploadedFileUrl)
                        // }
                        // else if (elementType === 'docx') {
                        //     handlePptxDocs(null, 'docx', uploadedFileUrl)
                        // }
                        // else if (elementType === 'xlsx') {
                        //     handlePptxDocs(null, 'xlsx', uploadedFileUrl)
                        // }
                        // else if (elementType === 'ppt') {
                        //     handlePptxDocs(null, 'ppt', uploadedFileUrl)
                        // }
                        // else if (elementType === 'doc') {
                        //     handlePptxDocs(null, 'doc', uploadedFileUrl)
                        // }
                        // else if (elementType === 'xls') {
                        //     handlePptxDocs(null, 'xls', uploadedFileUrl)
                        // }


                    }}
                >
                    <TableRow>
                        <Typography sx={{ marginTop: '5px', color: "#828282", paddingLeft: '5px', fontSize: '0.9rem', fontWeight: 500 }}>

                            {content.topic && <>
                                <span style={{ color: 'black', fontWeight: 550 }}>
                                    Topic: &nbsp;
                                </span>
                                {content.topic}
                            </>}
                        </Typography>
                    </TableRow>
                    <TableRow>
                        <Typography sx={{ marginTop: '5px', color: "#828282", paddingLeft: '5px', fontSize: '0.9rem', fontWeight: 500 }}>

                            {content.description && <>
                                <span style={{ color: 'black', fontWeight: 550 }}>
                                    Description: &nbsp;
                                </span>
                                {content.description}
                            </>}
                        </Typography>
                    </TableRow>
                </Grid> :
                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{
                        paddingTop: '5px', paddingLeft: '5px'
                    }}>
                        <TableRow>
                            <Button
                                onClick={async () => {
                                    let elementNameArray = (content.fileLink).split('.')
                                    let elementType = elementNameArray[elementNameArray.length - 1]
                                    // console.log(elementType)

                                    if (elementType === 'pdf') {
                                        setOpenPdfDialog(true)
                                        //ispdf
                                        setFileType('pdf')
                                    }
                                    else if (elementType === 'jpeg' || elementType === 'png' || elementType === 'jpg') {
                                        //isimg
                                        setFileType('image')
                                        setOpenPdfDialog(true)
                                        // console.log('opening img')
                                    }
                                    else if (elementType === 'txt') {
                                        //is txt
                                    }
                                    else if (elementType === 'mp4' || elementType === 'mov' || elementType === 'webm') {
                                        setFileType('video')
                                        setOpenPdfDialog(true)
                                        // console.log('opening video')
                                    }
                                    else if (elementType === 'mp3' || elementType === 'wav') {
                                        setFileType('audio')
                                        setOpenPdfDialog(true)
                                    }
                                    // else if (elementType === 'pptx') {
                                    //     handlePptxDocs(null, 'pptx', uploadedFileUrl)
                                    // }
                                    // else if (elementType === 'docx') {
                                    //     handlePptxDocs(null, 'docx', uploadedFileUrl)
                                    // }
                                    // else if (elementType === 'xlsx') {
                                    //     handlePptxDocs(null, 'xlsx', uploadedFileUrl)
                                    // }
                                    // else if (elementType === 'ppt') {
                                    //     handlePptxDocs(null, 'ppt', uploadedFileUrl)
                                    // }
                                    // else if (elementType === 'doc') {
                                    //     handlePptxDocs(null, 'doc', uploadedFileUrl)
                                    // }
                                    // else if (elementType === 'xls') {
                                    //     handlePptxDocs(null, 'xls', uploadedFileUrl)
                                    // }


                                }}
                            >
                                View
                            </Button>
                        </TableRow>
                        <TableRow>
                            <Typography sx={{ marginTop: '5px', color: "#828282", paddingLeft: '5px', fontSize: '0.9rem', fontWeight: 500 }}>

                                {content.topic && <>
                                    <span style={{ color: 'black', fontWeight: 550 }}>
                                        Topic: &nbsp;
                                    </span>
                                    {content.topic}
                                </>}
                            </Typography>
                        </TableRow>
                        <TableRow>
                            <Typography sx={{ marginTop: '5px', color: "#828282", paddingLeft: '5px', fontSize: '0.9rem', fontWeight: 500 }}>

                                {content.description && <>
                                    <span style={{ color: 'black', fontWeight: 550 }}>
                                        Description: &nbsp;
                                    </span>
                                    {content.description}
                                </>}
                            </Typography>
                        </TableRow>
                        <TableRow>
                            <Typography sx={{ marginTop: '5px', color: "#828282", paddingLeft: '5px', fontSize: '0.9rem', fontWeight: 500 }}>

                                {content.grade && <>
                                    <span style={{ color: 'black', fontWeight: 550 }}>
                                        Grade:&nbsp;
                                    </span>
                                    {content.grade}
                                </>},&nbsp;
                                {content.board && <>
                                    <span style={{ color: 'black', fontWeight: 550 }}>
                                        Board:&nbsp;
                                    </span>
                                    {content.board}
                                </>},&nbsp;
                                {content.subject && <>
                                    <span style={{ color: 'black', fontWeight: 550 }}>
                                        Subject:&nbsp;
                                    </span>
                                    {content.subject.map((item, index) => {
                                        return <>{index > 0 ? ', ' : ''}{item}</>

                                    })}
                                </>}
                            </Typography>
                        </TableRow>
                    </Grid>
                }
            </Grid>
            <Dialog fullWidth maxWidth="lg" open={openPdfDialog} onClose={() => setOpenPdfDialog(false)} fullScreen={fullScreen}>
                <DialogTitle sx={{ justifyContent: 'center', textAlign: 'center', position: 'relative', backgroundColor: fileType === 'video' ? "black" : 'none' }}>
                    <IconButton
                        sx={{ color: fileType === 'video' ? 'white' : 'black', position: 'absolute', right: '5px', top: "5px", zIndex: 10000 }}
                        onClick={() => {
                            setOpenPdfDialog(false);
                        }}
                        size="small">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ justifyContent: 'center', padding: 0 }}>
                    <Box sx={{
                        justifyContent: 'center',
                        textAlign: 'center',
                        width: '100%',
                        height: '100%',
                        '&:hover': {
                            cursor: 'pointer'
                        }
                    }}
                        onClick={() => {
                            // Placeholder for handling click inside the dialog
                        }}
                    >
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            height: '10%',
                        }}>

                        </Box>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '90%',
                            width: '100%',
                            paddingBottom: fileType === 'video' ? 0 : '20px'
                        }}>
                            {fileType === 'pdf' && <PdfViewerCustom pdfFileLink={content.fileLink} />}
                            {fileType === 'image' &&
                                <img
                                    src={content.fileLink}
                                    style={{
                                        height: '80%',
                                        width: '80%'
                                    }}
                                />}
                            {fileType === 'video' &&
                                <video
                                    style={{
                                        width: '100%'
                                    }}
                                    // height='20%'
                                    // width='100%'
                                    controls
                                    controlsList="nodownload"
                                >
                                    <source src={content.fileLink} type="audio/ogg" />
                                    Your browser does not support the audio tag.
                                </video>}
                            {fileType === 'audio' &&
                                <audio
                                    style={{ width: '80%' }}
                                    controls
                                    controlsList="nodownload"
                                >
                                    <source src={content.fileLink} type="audio/ogg" />
                                    Your browser does not support the audio tag.
                                </audio>

                            }

                            {fileType === 'txt' &&
                                <Typography sx={{ fontSize: '1.5rem' }}>
                                    Whiteboard file
                                </Typography>

                            }
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    {isWhiteboard && <Button
                        sx={{
                            marginTop: '10px',
                            textTransform: 'none',
                            backgroundColor: THEME_COLOR,
                            color: '#FFFFFF',
                            fontSize: '0.8rem',
                            fontWeight: '500',
                            height: '35px',
                            borderRadius: '5px',
                            '&:hover': {
                                backgroundColor: THEME_HOVER_COLOR,
                                boxShadow: 'none',
                            },
                        }}
                        onClick={() => {
                            handleClickForWhiteboard()
                            setOpenPdfDialog(false)
                        }}
                    >
                        Paste to whiteboard
                    </Button>}
                </DialogActions>
            </Dialog>

        </>

    );
}


export default Content;


