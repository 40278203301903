/*eslint no-unused-vars: 0, no-console: 0*/

import React from "react";
import { createTheme, ThemeProvider, StyledEngineProvider, adaptV4Theme } from "@mui/material";
import { styled } from '@mui/material/styles';
import { CompactPicker } from "react-color";
import { SnackbarProvider } from "notistack"
//import "./main.css";

import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import AppBar from "@mui/material/AppBar";
import Select from "@mui/material/Select";

import Grid from "@mui/material/Grid";
import ImageListItem from "@mui/material/ImageListItem";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import TextField from "@mui/material/TextField";

import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";

import RemoveIcon from "@mui/icons-material/Remove";
import DownloadIcon from "@mui/icons-material/CloudDownload";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
//import dataJson from "./data.json";
//import dataJsonControlled from "./data.json.controlled";
import SketchField from "./src/components/SketchField/SketchField";
//import dataUrl from "./data.url";
//import DropZone from "react-dropzone";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import Typography from "@mui/material/Typography/Typography";
import { v4 as uuidv4 } from 'uuid';
//import  sampleobject from "../src/components/SketchField/sampleobject.json"
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import YoutubeSearchedForIcon from '@mui/icons-material/YoutubeSearchedFor';
// import Amplify, { API, graphqlOperation } from 'aws-amplify';
// import { onCreateWhiteBoardEventByWhiteBoardID } from 'src/graphql/subscriptions';
// import { createWhiteBoardEvent } from 'src/graphql/mutations';
// import { getWhiteBoardEventsByWhiteBoardID } from 'src/graphql/queries';
// import { connect } from "react-redux";
//import awsconfig from 'src/aws-exports';
import { object } from "prop-types";
import { pdfjs, } from "react-pdf";
import { Image, Page, Document, pdf, Text, View } from "@react-pdf/renderer";
// import awsmobile from 'src/aws-exports';
// import { Storage } from 'aws-amplify';
import moment from "moment";
import * as fabric from 'fabric'
import CreateIcon from '@mui/icons-material/Create';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Crop169Icon from '@mui/icons-material/Crop169';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import PanToolIcon from '@mui/icons-material/PanTool';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import BorderStyleIcon from '@mui/icons-material/BorderStyle';
import { Paper, Tooltip, Checkbox, Popover, CircularProgress } from "@mui/material";
// import withStyles from '@mui/styles/withStyles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ImageIcon from '@mui/icons-material/Image';
import NearMeIcon from '@mui/icons-material/NearMe';
import { ExpandLess } from "@mui/icons-material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
//import * as fabric from 'fabric'
import BookIcon from '@mui/icons-material/Book';
import ToJSONFields from "./src/toJSONFields";
import { getLastUpdatedEventsInAscending } from "./src/utils";
import { blueGrey as color } from '@mui/material/colors';
import fastChunkString from '@shelf/fast-chunk-string';
import axios from 'axios';
import { DataPacket_Kind, RoomEvent } from 'livekit-client';
import { BACKEND_API_URL, THEME_COLOR, UPLOAD_API_URL } from "./src/config/constants";
import { getSessionStartTimeByID, getSlateIdBySessionId, getSlateTitleByID } from "../components/helperFunctions";
import { getSessionsBySlate, listWhiteboardFiles } from "./src/service/slateService";
const PREFIX = 'index';

const classes = {
  canvasArea: `${PREFIX}-canvasArea`,
  iconButton: `${PREFIX}-iconButton`,
  addText: `${PREFIX}-addText`
};

const StyledDocument = styled(Document)((
  {
    theme
  }
) => ({
  [`& .${classes.canvasArea}`]: {
    border: "0.1px solid #9fa3ab",
    height: "calc(100vh - 72px)",
    [theme.breakpoints.down('md')]: {
      height: "calc(100vh - 200px)",
    }
  },

  [`& .${classes.iconButton}`]: {
    color: "white",
  },

  [`& .${classes.addText}`]: {
    backgroundColor: "white",
    color: "#311b92",
    borderRadius: "0",
    width: "25px",
    height: "25px",
    textAlign: "center",
    fontWeight: "bolder",
    marginLeft: "8px",
    marginRight: "8px",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    '&:hover': {
      backgroundColor: "white",
      color: "#311b92",
    }
  }
}));


// Storage.configure(awsmobile)

//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;


const colors = ['#4D4D4D', '#999999', '#FFFFFF', '#F44E3B', '#FFD580', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF']



const ToolsIcons = [
  //{
  //  value:"Select",
  //  icon:<NearMeIcon/>
  //},
  {
    value: "Pencil",
    icon: <CreateIcon />,
    hasColor: true,
  },
  {
    value: "Pan",
    icon: <PanToolIcon />,
  },
  {
    value: "Eraser",
    icon: <BookIcon />,
  },
  {
    value: "Circle",
    icon: <RadioButtonUncheckedIcon />,
    hasColor: true
  },
  {
    value: "Rectangle",
    icon: <Crop169Icon />,
    hasColor: true
  },
  {
    value: "Line",
    icon: <RemoveIcon />,
    hasColor: true
  },
  {
    value: "Arrow",
    icon: <ArrowRightAltIcon />,
    hasColor: true
  },
  {
    value: "Highlighter",
    icon: <BorderColorIcon />,
    hasColor: true
  },
];


const styles = {
  root: {
    padding: "3px",
    display: "flex",
    flexWrap: "wrap",
    margin: "10px 10px 5px 10px",
    justifyContent: "space-around",
  },
  gridList: {
    width: "100%",
    marginBottom: "24px",
  },
  gridTile: {
    backgroundColor: "#fcfcfc",
  },
  appBar: {
    backgroundColor: "#311b92",
    minHeight: "70px",

  },
  radioButton: {
    marginTop: "3px",
    marginBottom: "3px",
  },
  separator: {
    height: "42px",
    backgroundColor: "white",
  },
  iconButton: {
    fill: "white",
    width: "42px",
    height: "42px",
  },
  dropArea: {
    width: "100%",
    height: "64px",
    border: "2px dashed rgb(102, 102, 102)",
    borderStyle: "dashed",
    borderRadius: "5px",
    textAlign: "center",
    paddingTop: "20px",
  },
  activeStyle: {
    borderStyle: "solid",
    backgroundColor: "#eee",
  },
  rejectStyle: {
    borderStyle: "solid",
    backgroundColor: "#ffdddd",
  },
  card: {
    margin: "5px",
    padding: "5px",
    border: "0.1px solid #9fa3ab"
  },
  selected: {
    backgroundColor: "#532efe",
  },
  expandedCard: {
    position: "relative",
    bottom: "140px",
    padding: "5px",
    margin: "10px",
  }
};

/**
 * Helper function to manually fire an event
 *
 * @param el the element
 * @param etype the event type
 */
function eventFire(el, etype) {
  if (el.fireEvent) {
    el.fireEvent("on" + etype);
  } else {
    var evObj = document.createEvent("Events");
    evObj.initEvent(etype, true, false);
    el.dispatchEvent(evObj);
  }
}

const getQueryObject = (search) => {
  var query;
  var queryFields
  if (search && search.length > 0) {
    query = search.substr(1);
    queryFields = query.split("&")
  }
  var queryObject = {}
  if (queryFields && queryFields.length > 0) {
    for (var queryField of queryFields) {
      var arr = queryField.split("=")
      if (arr && arr.length === 2) {
        queryObject[arr[0]] = arr[1];
      }
    }
  }
  return queryObject;
}


class SketchFieldDemo extends React.Component {
  constructor(props) {
    super(props);
    //const { location } = this.props
    //const {search} = location
    //const queryObject =getQueryObject(search);
    //console.log("query",queryObject)
    this.state = {
      lineWidth: 4,
      lineColor: "black",
      fillColor: "#68CCCA",
      backgroundColor: "",
      shadowWidth: 0,
      shadowOffset: 0,
      toolName: Boolean(this.props.isPlayer || !this.props.isToolBarEnabled) ? "DefaultTool" : "Pencil",
      enableRemoveSelected: false,
      fillWithColor: false,
      drawings: [],
      canUndo: false,
      canRedo: false,
      controlledSize: false,
      sketchWidth: '100%',
      sketchHeight: '100vh',
      stretched: true,
      stretchedX: false,
      stretchedY: false,
      originX: "left",
      originY: "top",
      imageUrl: "https://mk0omfourop3d6at17y.kinstacdn.com/wp-content/uploads/grapes.jpg",
      expandTools: false,
      expandControls: false,
      expandColors: false,
      expandBack: false,
      expandImages: false,
      expandControlled: false,
      text: "Type here!",
      enableCopyPaste: false,
      clientID: this.props.clientID ? this.props.clientID : uuidv4(),
      onCreateWhiteBoardEventSubscription: null,
      whiteBoardID: this.props.whiteBoardID ? this.props.whiteBoardID : uuidv4(),
      isPlayer: Boolean(this.props.isPlayer),
      canvasImageObjects: [],
      handleChangeCanPlayWhiteBoard: this.props.handleChangeCanPlayWhiteBoard ?
        this.props.handleChangeCanPlayWhiteBoard : () => { },
      timeouts: [],
      unsetBackgroundColor: "#000000",
      isTeacher: this.props.isTeacher,
      isToolBarEnabled: this.props.isToolBarEnabled,
      isZoomEnabled: this.props.isZoomEnabled,
      isPanEnabled: this.props.isPanEnabled,
      isDeleteAllEnabled: this.props.isDeleteAllEnabled,



      openBackgroundColorPicker: false,
      openLineColorPicker: false,
      isLoading: false,
    };
    this.handleChangeLoading = this.handleChangeLoading.bind(this);
  }

  _selectTool = (event) => {
    //console.log("onchange select tool",event.target.value)
    //console.log("Tools",Tools,Tools[event.target.value])
    this.setState({
      toolName: event.target.value,
      enableRemoveSelected: event.target.value === "Select",
      enableCopyPaste: event.target.value === "Select",
    });
  };

  _save = () => {
    let drawings = this.state.drawings;
    drawings.push(this._sketch.toDataURL());
    this.setState({ drawings: drawings });
  };

  _download = () => {
    //console.save(this._sketch.toDataURL(), "toDataURL.png");
    console.save(JSON.stringify(this._sketch.toJSON()), "toDataJSON.txt");

    /*eslint-enable no-console*/

    let { imgDown } = this.refs;
    let event = new Event("click", {});

    // imgDown.href = this._sketch.toDataURL("png");
    //  imgDown.download = "toPNG.png";
    //  imgDown.dispatchEvent(event);
  };

  _add = (object) => {
    //console.save(this._sketch.toDataURL(), "toDataURL.png");
    if (object.type === "image") {
      this._sketch.addImg(object.src, object.isPdf, object);
    }
    else
      this._sketch.addObj(object)

  };
  _modify = (object) => {
    //console.save(this._sketch.toDataURL(), "toDataURL.png");
    this._sketch.modifyObj(object)

  };


  _remove = (object) => {
    //console.save(this._sketch.toDataURL(), "toDataURL.png");
    this._sketch.removeObj(object)

  };

  _renderTile = (drawing, index) => {
    return (
      <ImageListItem
        key={index}
        title="Canvas Image"
        actionPosition="left"
        titlePosition="top"
        titleBackground="linear-gradient(to bottom, rgba(0,0,0,0.7) 0%,rgba(0,0,0,0.3) 70%,rgba(0,0,0,0) 100%)"
        cols={1}
        rows={1}
        style={styles.gridTile}
        actionIcon={
          <IconButton onTouchTap={(c) => this._removeMe(index)} size="large">
            <ClearIcon color="white" />
          </IconButton>
        }>
        <img alt='drawing' src={drawing} />
      </ImageListItem>
    );
  };

  _removeMe = (index) => {
    let drawings = this.state.drawings;
    drawings.splice(index, 1);
    this.setState({ drawings: drawings });
  };

  _undo = () => {
    this._sketch.undo();
    this.setState({
      canUndo: this._sketch.canUndo(),
      canRedo: this._sketch.canRedo(),
    });
  };

  _redo = () => {
    this._sketch.redo();
    this.setState({
      canUndo: this._sketch.canUndo(),
      canRedo: this._sketch.canRedo(),
    });
  };

  _clear = (object) => {
    this._sketch.clear(object);
    //this._sketch.setBackgroundFromDataUrl("");
    this.setState({
      controlledValue: null,
      backgroundColor: '',
      canUndo: this._sketch.canUndo(),
      canRedo: this._sketch.canRedo(),
    });
  };

  _removeSelected = () => {
    this._sketch.removeSelected();
  };

  _changePan = (obj) => {
    this._sketch.changePan(obj);
  }

  _onSketchChange = () => {
    let prev = this.state.canUndo;
    let now = this._sketch.canUndo();
    if (prev !== now) {
      this.setState({ canUndo: now });
    }
  };

  _onBackgroundImageDrop = (accepted /*, rejected*/) => {
    if (accepted && accepted.length > 0) {
      let sketch = this._sketch;
      let reader = new FileReader();
      let { stretched, stretchedX, stretchedY, originX, originY } = this.state;
      reader.addEventListener(
        "load",
        () =>
          sketch.setBackgroundFromDataUrl(reader.result, {
            stretched: stretched,
            stretchedX: stretchedX,
            stretchedY: stretchedY,
            originX: originX,
            originY: originY,
          }),
        false
      );
      reader.readAsDataURL(accepted[0]);
    }
  };

  _addText = () => { this._sketch.addText(this.state.text) };

  _changeBackgroundColor = (e) => {
    this._sketch.changeBackgroundColor(e);
    //console.log("changing bg color to",e)
    this.setState({ backgroundColor: e.color })
  }

  _zoom = (e) => { this._sketch.zoom(e) }

  _restoreZoom = (e) => {
    var zoomFactor = 1 / this._sketch.state.zoomFactors;
    this._zoom({ zoomFactor: zoomFactor, clientID: this.state.clientID });
  }
  onObjectAdded = (e) => {
    //console.log("target" + e.target.toObject())
    let eventData = e.target.toObject(ToJSONFields)
    // console.log("event data on object added" + eventData)
    if (eventData.objectID === undefined || !eventData.objectID) {

      let tempObjectID = uuidv4()
      // console.log("setting object ID" + tempObjectID)
      e.target.set('objectID', tempObjectID);

    }
    // console.log("event data on object added" + JSON.stringify(eventData))



    if (eventData.clientID === undefined && !eventData.initialState && !eventData.peer) {
      // console.log("setting client ID" + this.state.clientID)
      e.target.set('clientID', this.state.clientID);
      e.target.set('containerWidth', this._sketch._fc.getWidth());
      e.target.set('containerHeight', this._sketch._fc.getHeight());
      let objectID = e.target.objectID;
      // console.log(objectID)
      eventData = e.target.toObject(ToJSONFields);
      // console.log(eventData)
      // console.log(JSON.stringify(eventData))
      let eventType = "object:added";

      this.createWhiteBoardEventFunc(this.state.whiteBoardID, this.state.clientID, eventType, JSON.stringify(eventData), objectID)
    }
    e.target.set('initialState', false);

  }

  onObjectModified = (e) => {
    //is being called only when we do the modification through UI so we always assume that this modification
    // is done by the current client
    //console.log("object modified called")
    var objectID = e.target.objectID
    // console.log(e.target.objectID);
    e.target.set('clientID', this.state.clientID);
    e.target.set('containerWidth', this._sketch._fc.getWidth());
    e.target.set('containerHeight', this._sketch._fc.getHeight());
    var eventData = e.target.toObject(ToJSONFields)
    // console.log(JSON.stringify(eventData))
    // console.error("modified",eventData.initialState)
    var eventType = "object:modified";
    //  console.log("modified" + JSON.stringify(e))
    if (!eventData.initialState)
      this.createWhiteBoardEventFunc(this.state.whiteBoardID, this.state.clientID, eventType, JSON.stringify(eventData), objectID)
    e.target.set('initialState', false);

    // send event and object to the backend if the client id is the same as this client id

  }

  onBackgroundColorChanged = (e) => {
    //console.error("bgchnaged",e.initialState)
    if (e.clientID === this.state.clientID && !e.initialState) {
      var objectID = uuidv4();
      var eventData = { objectID: objectID, ...e }
      var eventType = "backgroundColor:changed";
      this.createWhiteBoardEventFunc(this.state.whiteBoardID, this.state.clientID, eventType, JSON.stringify(eventData), objectID)

    }
    //console.log("onBackgroundColorChanged",e);
  }


  onMouseUp = (newObj) => {
    //console.error("mousup",newObj.initialState)
    if (newObj && newObj.objectID && !newObj.initialState) {
      //console.log("mouseUp called",newObj)
      var objectID = newObj.objectID
      //console.log(newObj.objectID);
      newObj.set('clientID', this.state.clientID);
      newObj.set('containerWidth', this._sketch._fc.getWidth());
      newObj.set('containerHeight', this._sketch._fc.getHeight());
      var eventData = newObj.toJSON(ToJSONFields)
      //console.log(JSON.stringify(eventData))
      var eventType = "object:modified";
      //  console.log("modified" + JSON.stringify(e))
      this.createWhiteBoardEventFunc(this.state.whiteBoardID, this.state.clientID, eventType, JSON.stringify(eventData), objectID)
    }

  }
  onObjectRemoved = (e) => {
    e.target.set("clientID", this.state.clientID);
    var eventData = e.target.toObject(ToJSONFields)
    console.log("whiteBoard:onObjectRemoved", eventData)
    //console.error("removed",eventData.initialState)
    var eventType = "object:removed";
    var objectID = eventData.objectID;
    if (eventData.removedBy === this.state.clientID && objectID && !eventData.initialState) {
      this.createWhiteBoardEventFunc(this.state.whiteBoardID, this.state.clientID, eventType, JSON.stringify(eventData), objectID)
    }
    //console.log("removed" + JSON.stringify(e))
    // send event and object to the backend if the client id is the same as this client id

  }
  onPathCreated = (e) => {
    console.log("path created" + JSON.stringify(e))
  }
  onPanChanged = (eventData) => {
    var eventType = "pan:changed";
    //console.error("pan",eventData.initialState)
    var objectID = uuidv4();
    if (eventData.clientID === this.state.clientID && !eventData.initialState) {
      this.createWhiteBoardEventFunc(this.state.whiteBoardID, this.state.clientID, eventType, JSON.stringify(eventData), objectID)
    }
  }

  onZoomChanged = (eventData) => {
    var eventType = "zoom:changed";
    //console.error("zoom",eventData.initialState)
    var objectID = uuidv4();
    if (eventData.clientID === this.state.clientID && !eventData.initialState) {
      this.createWhiteBoardEventFunc(this.state.whiteBoardID, this.state.clientID, eventType, JSON.stringify(eventData), objectID)
    }
  }


  onNewZoomChanged = (eventData) => {
    var eventType = "newZoom:changed";
    // console.error("zoom", eventData)
    var objectID = uuidv4();
    if (eventData.clientID === this.state.clientID && !eventData.initialState) {
      this.createWhiteBoardEventFunc(this.state.whiteBoardID, this.state.clientID, eventType, JSON.stringify(eventData), objectID)
    }
  }

  onObjectMoving = (e) => {
    console.log("moving" + JSON.stringify(e))
  }
  onCanvasCleared = (eventData) => {
    var eventType = "canvas:objects:cleared";
    var objectID = uuidv4();
    if (eventData.clientID === this.state.clientID && !eventData.initialState) {
      this.createWhiteBoardEventFunc(this.state.whiteBoardID, this.state.clientID, eventType, JSON.stringify(eventData), objectID)
    }
  }
  // _add = (object) => this._sketch.add(object);

  createWhiteBoardEventFunc = async (whiteBoardID, clientId, eventType, eData, objectID) => {
    //    console.log("🚀 ~ file: main.jsx createWhiteBoardEvent Mutation ~ SketchFieldDemo ~ whiteBoardID,clientID,eventType,eventData", whiteBoardID, clientId, eventType, eData)
    // console.log("object id", objectID)
    var timeStampedEventData = JSON.parse(eData);
    timeStampedEventData = { ...timeStampedEventData, timestamp: moment().toISOString() }
    timeStampedEventData = JSON.stringify(timeStampedEventData);
    var eventData = timeStampedEventData;
    var eventDataWithParams = "$whiteBoardID=" + whiteBoardID + "$" + "$clientID=" + clientId + "$"
      + "$eventType=" + eventType + "$" + "$objectID=" + objectID + "$" + "$eventData=" + eventData + "$$"
    var eventDataToSend = "$whiteBoardEvent$" + eventDataWithParams
    let encodedEventDataToSend = new TextEncoder().encode(eventDataToSend)
    // console.log("event data encoded size" + encodedEventDataToSend.length)
    // console.log("the event data is " + eventDataWithParams);

    if (this.props.isPublishWhiteBoardDataViaLiveKit) {
      if (encodedEventDataToSend.length < 64000) {
        console.log("publish data via live kit");
        this.props.liveKitRoom.localParticipant.publishData(encodedEventDataToSend, DataPacket_Kind.RELIABLE);

      }
      else {
        //split the original string into multiple chunks and then send chunks
        let chunks = fastChunkString(eventDataWithParams, { size: 64000, unicodeAware: false });
        let messageKey = uuidv4();
        // console.log("the message key sent" + messageKey);
        for (let index = 0; index < chunks.length; index++) {

          const chunk = chunks[index];
          var chunkStartStr = "$whiteBoardEvent$" + "$messageKey=" + messageKey + "$" + "$totalChunks=" + chunks.length + "$" + "$chunkIndex=" + index + "$";
          var chunkToSend = chunkStartStr + "$chunkDataSeperator$" + chunk
          let encodedChunk = new TextEncoder().encode(chunkToSend);
          console.log("publish data via live kit");
          this.props.liveKitRoom.localParticipant.publishData(encodedChunk, DataPacket_Kind.RELIABLE);
          //console.log(chunkToSend);


        }


      }

    }
    // we have to always save the data to the server
    // let id = uuidv4()

    // const tok = 'couchpublic:couchpublic';
    // const hash = btoa(tok);
    // const Basic = 'Basic ' + hash;
    // let dbName = "wbdb" + this.props.whiteBoardID

    // sendMutex.runExclusive(async () => {
    //   await axios({
    //     method: 'put',
    //     headers: { 'Authorization': Basic },
    //     url: 'https://' + this.props.DB_SERVER + ':6984/' + dbName + '/' + id,
    //     data: {
    //       whiteBoardID, clientId, eventType, eventData, objectID
    //     }
    //   });
    // })
    //   .then(function (result) {
    //   });


    // const res =



    //     const response = await fetch("https://couchpublic:couchpublic@avserver.slatemates.in:6984/whiteboardevents/"+id, {
    // method: 'PUT',
    // headers: {
    //   'Accept': 'application/json',
    //   'Content-Type': 'application/json'
    // },
    // body: `{
    //     whiteBoardID, clientId, eventType, eventData, objectID
    //   }`,
    // });
    //Commenting out the data to dynamo
    // await API.graphql(
    //     { query: createWhiteBoardEvent, variables: { input: { id, whiteBoardID, clientId, eventType, eventData, objectID } }, authMode: 'API_KEY' }
    // );

  }

  //  async getAllEvents(whiteBoardID){
  //   var allEvents = [];
  //   var nextToken;
  //   var result = await API.graphql(
  //     {query:getWhiteBoardEventsByWhiteBoardID, variables:{ whiteBoardID:whiteBoardID},authMode:'API_KEY'}

  //   );
  //   allEvents = [...allEvents,...result.data.getWhiteBoardEventsByWhiteBoardID.items]
  //   nextToken = result.data.getWhiteBoardEventsByWhiteBoardID.nextToken;
  //   while(nextToken!=null){
  //     result = await API.graphql(
  //       {query:getWhiteBoardEventsByWhiteBoardID,
  //         variables:{ whiteBoardID:whiteBoardID,nextToken:nextToken},authMode:'API_KEY'}

  //     );
  //     allEvents = [...allEvents,...result.data.getWhiteBoardEventsByWhiteBoardID.items]
  //     nextToken = result.data.getWhiteBoardEventsByWhiteBoardID.nextToken;
  //   }
  //   //console.log("allEvents",allEvents);
  //   allEvents.sort(function(a,b){
  //     var eventDataA = JSON.parse(a.eventData);
  //     var eventDataB = JSON.parse(b.eventData);
  //     var momentA = moment(eventDataA.timestamp);
  //     var momentB = moment(eventDataB.timestamp);
  //     if(momentA.isBefore(momentB))
  //       return -1;
  //     return 1;
  //   })
  //   console.log("totalEvents length",allEvents.length)
  //   return allEvents;

  // }

  async getAllEventsFromDB(whiteBoardID, dbServer, accessToken) {
    // console.log(dbServer)
    // console.log('loading from server')
    const allEvents = []
    const res = await axios.get(BACKEND_API_URL + 'whiteBoard/getWhiteBoardEventsByWhiteBoardId', {
      params: {
        whiteBoardId: whiteBoardID,
        token: accessToken
      }

    });
    // console.log("whiteboard id is " + whiteBoardID);
    // console.log("access token is " + this.props.accessToken)
    // console.log("the response from db" + JSON.stringify(res.data.whiteBoardEvents))
    for (let index = 0; index < res.data.whiteBoardEvents.length; index++) {
      allEvents.push({
        id: res.data.whiteBoardEvents[index].id,
        whiteBoardID: res.data.whiteBoardEvents[index]["whiteboard_id"],
        clientId: res.data.whiteBoardEvents[index]["client_id"],
        eventData: res.data.whiteBoardEvents[index]["event_data"],
        eventType: res.data.whiteBoardEvents[index]["event_type"],
        objectID: res.data.whiteBoardEvents[index]["object_id"],
        createdAt: res.data.whiteBoardEvents[index]["created_at"],
        updatedAt: res.data.whiteBoardEvents[index]["updated_at"]
      })

    }

    allEvents.sort(function (a, b) {
      return new Date(a.createdAt) - new Date(b.createdAt);
    });


    return allEvents



  }


  processReceivedEvent = async (whiteBoardEvent, initialState = false) => {
    if (!(whiteBoardEvent.clientId !== this.state.clientID || initialState)) return;
    console.log("whiteBoard:processReceivedEvent", whiteBoardEvent.eventType, initialState);
    if (whiteBoardEvent.eventType === "file:added") {
      let eventdata = JSON.parse(whiteBoardEvent.eventData);
      console.log(eventdata.canvasFileUrl)
      await this.processCanvasToWhiteboardEvent(eventdata.canvasFileUrl, eventdata.objectIdSuffix, eventdata.canvasFileUrl2)
      // console.log('alleventsff', eventdata.canvasFileUrl)
      // console.log('alleventsff', res)
    }
    if (whiteBoardEvent.eventType === "newZoom:changed") {
      let eventdata = JSON.parse(whiteBoardEvent.eventData);
      // console.log(eventdata)
      this._sketch.newZoom(eventdata, false)
    }
    if (whiteBoardEvent.eventType === "object:added") {
      //console.log(whiteBoardEvent.clientId);
      //if(!initialState)
      //  console.log("object added  by other client",whiteBoardEvent);
      //else
      //  console.log("object added on initializing",whiteBoardEvent)
      let scaledJson = this.scaleJSON(JSON.parse(whiteBoardEvent.eventData));
      scaledJson = {
        ...scaledJson,
        initialState: initialState,
      }
      this._add(scaledJson);
    }
    if (whiteBoardEvent.eventType === "object:modified") {
      //console.log(whiteBoardEvent.clientId);
      // if(!initialState)
      //   console.log("object modified  by other client",whiteBoardEvent);
      // else
      //   console.log("object modified on initializing",whiteBoardEvent)
      let scaledJson = this.scaleJSON(JSON.parse(whiteBoardEvent.eventData));
      scaledJson = {
        ...scaledJson,
        initialState: initialState,
      }
      this._modify(scaledJson);
    }
    if (whiteBoardEvent.eventType === "object:removed") {
      //console.log(whiteBoardEvent.clientId);
      //if(!initialState)
      //  console.log("object removed  by other client",whiteBoardEvent);
      //else
      //  console.log("object removed on initializing",whiteBoardEvent)
      let json = JSON.parse(whiteBoardEvent.eventData)
      json = {
        ...json,
        initialState: initialState,
      }
      this._remove(json);
    }
    if (whiteBoardEvent.eventType === "canvas:objects:cleared") {
      //console.log(whiteBoardEvent.clientId);
      //if(!initialState)
      //  console.log("object cleared  by other client",whiteBoardEvent);
      //else
      //  console.log("object cleared on initializing",whiteBoardEvent)
      let json = JSON.parse(whiteBoardEvent.eventData)
      json = {
        ...json,
        initialState: initialState,
      }
      this._clear(json);
    }
    if (whiteBoardEvent.eventType === "backgroundColor:changed") {
      //console.log(whiteBoardEvent.clientId);
      //if(!initialState)
      //  console.log("object bgcolor  by other client",whiteBoardEvent);
      //else
      //  console.log("object bgcolor on initializing",whiteBoardEvent)
      let json = JSON.parse(whiteBoardEvent.eventData)
      json = {
        ...json,
        initialState: initialState,
      }
      this._changeBackgroundColor(json);
    }
    if (whiteBoardEvent.eventType === "pan:changed") {
      //if(!initialState)
      //  console.log("pan changed  by other client",whiteBoardEvent);
      //else
      //  console.log("pan modified on initializing",whiteBoardEvent)
      let obj = JSON.parse(whiteBoardEvent.eventData);
      //this._sketch._fc.absolutePan(point)
      let { offsetWidth = 1, clientHeight = 1 } = this._sketch._container;
      let { containerWidth = 1, containerHeight = 1 } = obj;
      let wfactor = (offsetWidth / containerWidth);
      let hfactor = (clientHeight / containerHeight);
      let viewportTransform = obj.viewportTransform;
      viewportTransform[4] = viewportTransform[4] * wfactor;
      viewportTransform[5] = viewportTransform[5] * hfactor;
      //console.log("factors,",hfactor,wfactor);
      //console.log("moving to",viewportTransform)
      let scaledJson = {
        ...obj,
        viewportTransform: viewportTransform,
        initialState: initialState
      }
      this._changePan(scaledJson);
    }
    if (whiteBoardEvent.eventType === "zoom:changed") {
      //if(!initialState)
      //  console.log("zoom chnaged  by other client",whiteBoardEvent);
      //else
      //  console.log("zoom changed on initializing",whiteBoardEvent)
      let json = JSON.parse(whiteBoardEvent.eventData)
      json = {
        ...json,
        initialState: initialState,
      }
      this._zoom(json);
    }

    if (whiteBoardEvent.eventType === "zoomAndPan:changed") {
      console.log("received zoom and pan event ")
      //if(!initialState)
      //  console.log("zoom chnaged  by other client",whiteBoardEvent);
      //else
      //  console.log("zoom changed on initializing",whiteBoardEvent)
      let json = JSON.parse(whiteBoardEvent.eventData)
      let panObj = json.panObj;
      json = {
        ...json.zoomObj,
        initialState: initialState,
      }
      this._zoom(json);
      let obj = panObj
      //this._sketch._fc.absolutePan(point)
      let { offsetWidth = 1, clientHeight = 1 } = this._sketch._container;
      let { containerWidth = 1, containerHeight = 1 } = obj;
      let wfactor = (offsetWidth / containerWidth);
      let hfactor = (clientHeight / containerHeight);
      let viewportTransform = obj.viewportTransform;
      viewportTransform[4] = viewportTransform[4] * wfactor;
      viewportTransform[5] = viewportTransform[5] * hfactor;
      //console.log("factors,",hfactor,wfactor);
      //console.log("moving to",viewportTransform)
      let scaledJson = {
        ...obj,
        viewportTransform: viewportTransform,
        initialState: initialState
      }
      this._changePan(scaledJson);



    }

  }
  componentDidMount = () => {
    (function (console) {
      console.save = function (data, filename) {
        if (!data) {
          console.error("Console.save: No data");
          return;
        }
        if (!filename) filename = "console.json";
        if (typeof data === "object") {
          data = JSON.stringify(data, undefined, 4);
        }
        var blob = new Blob([data], { type: "text/json" }),
          e = document.createEvent("MouseEvents"),
          a = document.createElement("a");
        a.download = filename;
        a.href = window.URL.createObjectURL(blob);
        a.dataset.downloadurl = ["text/json", a.download, a.href].join(":");
        e.initMouseEvent(
          "click",
          true,
          false,
          window,
          0,
          0,
          0,
          0,
          0,
          false,
          false,
          false,
          false,
          0,
          null
        );
        a.dispatchEvent(e);
      };
    })(console);
    // if (!this.state.isPlayer) {
    //   this.onCreateWhiteBoardEventSubscription = API.graphql(
    //     { query: onCreateWhiteBoardEventByWhiteBoardID, variables: { whiteBoardID: this.state.whiteBoardID }, authMode: 'API_KEY' }
    //   ).subscribe({
    //     next: data => {
    //       const {
    //         value: {
    //           data: { onCreateWhiteBoardEventByWhiteBoardID }
    //         }
    //       } = data;
    //       //console.log(data.value.data.onCreateGroupBookingByGroupIDLambda);
    //       var whiteBoardEvent = data.value.data.onCreateWhiteBoardEventByWhiteBoardID;
    //       //console.log('whiteBoardEvent ' + JSON.stringify(whiteBoardEvent));
    //       this.processReceivedEvent(whiteBoardEvent);
    //     }
    //   });
    // }
    async function setAllEvents() {
      const canvas = this._sketch._fc
      var allEvents;
      try {
        allEvents = await this.getAllEventsFromDB(this.state.whiteBoardID, "server1.slatemates.in");
      }
      catch (err) {
        // allEvents = await this.getAllEvents(this.state.whiteBoardID);
      }
      if (allEvents.length === 0)
        return;
      var firstEvent = JSON.parse(allEvents[0].eventData)
      var lastEvent = JSON.parse(allEvents[allEvents.length - 1].eventData);
      var lastUpdatedEvents = getLastUpdatedEventsInAscending(allEvents, firstEvent.timestamp, lastEvent.timestamp)
      var eventsIndex = lastUpdatedEvents.length - 1;
      while (eventsIndex >= 0) {
        if (lastUpdatedEvents[eventsIndex].eventType === "canvas:objects:cleared") {
          break;
        }
        eventsIndex--;
      }
      eventsIndex = Math.max(0, eventsIndex)
      for (let i = eventsIndex; i < lastUpdatedEvents.length; i++) {
        let whiteBoardEvent = lastUpdatedEvents[i];
        await new Promise(async (resolveP, rejectP) => {
          let eventData = JSON.parse(whiteBoardEvent.eventData);
          if (eventData.type === "image") {
            const oImg = await fabric.FabricImage.fromURL(eventData.src, { crossOrigin: 'anonymous' });
            //console.log("promise",eventData.left,eventData.top)
            let scaledJson = this.scaleJSON(eventData);
            oImg.set({
              ...scaledJson,
              initialState: true,
            })
            if (whiteBoardEvent.eventType === "object:added") {
              canvas.add(oImg);
              if (oImg.isPdf)
                canvas.sendObjectToBack(oImg)
            }
            else if (whiteBoardEvent.eventType === "object:modified")
              this._modify(oImg)
            else if (whiteBoardEvent.eventType === "object:removed")
              this._remove(oImg)
            resolveP();
          }
          else {
            this.processReceivedEvent(whiteBoardEvent, true);
            resolveP();
          }
        })
      }
    }

    //
    if (!this.state.isPlayer) {
      setAllEvents.bind(this)();
    }

    //getAllEvents(this.state.whiteBoardID,this.processReceivedEvent,this._sketch._fc,this.scaleJSON.bind(this));
    //console.log("all events are",allEvents)

  };

  componentDidUpdate(prevProps) {
    if (this.props.whiteBoardID !== prevProps.whiteBoardID) {
      this.setState({ whiteBoardID: this.props.whiteBoardID });
    }
    if (this.props.clientID !== prevProps.clientID) {
      this.setState({ clientID: this.props.clientID });
    }
    //console.log("playWhiteBoard:updated",this.props.playWhiteBoard,prevProps.playWhiteBoard);
    if (prevProps.playWhiteBoard !== this.props.playWhiteBoard) {
      if (this.props.playWhiteBoard) {
        // console.log("playWhiteBoard:calling playEvents")
        const canvas = this._sketch._fc;
        canvas.setViewportTransform([1, 0, 0, 1, 0, 0])
        canvas.clear()
        this.playAllEvents();
      }
      else {
        for (let timeout of this.state.timeouts) {
          clearTimeout(timeout);
        }
      }
    }
    if (prevProps.startTimeOffset !== this.props.startTimeOffset) {
      console.log("startTimeOffset changed,replaying events in whiteboard", this.props.startTimeOffset)
      for (let timeout of this.state.timeouts) {
        clearTimeout(timeout);
      }
      if (this.props.playWhiteBoard) {
        // console.log("playWhiteBoard:calling playEvents")
        const canvas = this._sketch._fc;
        canvas.setViewportTransform([1, 0, 0, 1, 0, 0])
        canvas.clear()
        this.playAllEvents();
      }

    }
  }




  async getCanvasImageObjects(allEvents) {
    const canvas = this._sketch._fc
    const { handleChangeIsWhiteBoardLoaded = () => { } } = this.props;

    //    console.log("allevents are",allEvents)
    //this.props.handleChangeWhiteBoardEvents(allEvents);
    //this.setState({
    //  allEvents:allEvents,
    //})
    let imageObjects = []
    for (let whiteBoardEvent of allEvents) {
      let eventData = JSON.parse(whiteBoardEvent.eventData);
      if (eventData.type === "image") {
        await new Promise(async (resolveP, rejectP) => {

          const oImg = await fabric.FabricImage.fromURL(eventData.src, { crossOrigin: 'anonymous' });
          //console.log("promise",eventData.left,eventData.top)
          var scaledJson = this.scaleJSON(eventData);
          oImg.set({
            ...scaledJson,
            initialState: true,
          })
          imageObjects.push(oImg)
          //canvas.add(oImg);
          resolveP();

        });
      }
    }
    handleChangeIsWhiteBoardLoaded(true);
    return imageObjects;
  }

  playAllEvents() {
    var waitingTimes = this.props.whiteBoardEventsTimeout;
    var startTimeOffset = this.props.startTimeOffset;
    if (isNaN(startTimeOffset))
      startTimeOffset = 0;
    console.log("startTimeOffset is", startTimeOffset)
    const canvas = this._sketch._fc;
    var timeouts = [];
    var allEvents = this.state.allEvents;
    console.log("playWhiteBoard:playing allevents", waitingTimes, allEvents);
    var imageObjects = this.state.canvasImageObjects;
    var imageObjIndex = 0;
    for (let i = 0; i < waitingTimes.length; i++) {
      var eventData = JSON.parse(allEvents[i].eventData);
      if (eventData.type === "image") {
        const callTimeoutFunc = (obj, whiteBoardEvent) => (
          setTimeout(() => {
            var newImageObject = fabric.util.object.clone(obj);
            if (whiteBoardEvent.eventType === "object:added")
              canvas.add(newImageObject);
            else if (whiteBoardEvent.eventType === "object:modified")
              this._modify(newImageObject)
            else if (whiteBoardEvent.eventType === "object:removed")
              this._remove(newImageObject)
            //canvas.add(newImageObject);
          },
            Math.max(waitingTimes[i] - startTimeOffset, 0))
        )
        timeouts.push(callTimeoutFunc(imageObjects[imageObjIndex], allEvents[i]))
        imageObjIndex++;
      }
      else {
        const callTimeoutFunc = async (obj) => (
          setTimeout(async () => { await this.processReceivedEvent(obj, true); },
            Math.max(waitingTimes[i] - startTimeOffset, 0))
        )
        timeouts.push(callTimeoutFunc(allEvents[i]))
      }
    }
    //console.log("all events played");
    //console.log("playWhiteBoard:timeouts",timeouts);
    this.setState({ timeouts: timeouts });
  }



  scaleJSON(object) {
    let { offsetWidth = 1, clientHeight = 1 } = this._sketch._container;
    let { containerWidth = 1, containerHeight = 1 } = object;
    let wfactor = (offsetWidth / containerWidth);
    let hfactor = (clientHeight / containerHeight);
    let scaleX = object.scaleX;
    let scaleY = object.scaleY;
    let left = object.left;
    let top = object.top;
    let strokeWidth = object.strokeWidth;
    let tempScaleX = scaleX * wfactor;
    let tempScaleY = scaleY * hfactor;
    let tempLeft = left * wfactor;
    let tempTop = top * hfactor;
    object.scaleX = tempScaleX;
    object.scaleY = tempScaleY;
    // var tmpAvg = (hfactor + wfactor)
    object.strokeWidth = (strokeWidth / ((tempScaleX + tempScaleY) / 2));
    if (object.type === "circle") {
      object.scaleX = Math.min(tempScaleY, tempScaleX);
      object.scaleY = Math.min(tempScaleY, tempScaleX);
    }
    object.left = tempLeft;
    object.top = tempTop;
    //console.log("factor",hfactor,wfactor);
    //console.log("scale x,y",scaleX,scaleY);
    //console.log("scaled object",object);
    return object;
  }

  panDown = () => {
    const canvas = this._sketch._fc;
    var viewportTransform = canvas.viewportTransform.slice();
    let { offsetWidth = 10, clientHeight = 10 } = this._sketch._container;
    viewportTransform[5] -= clientHeight;
    this._changePan({
      clientID: this.state.clientID,
      viewportTransform: viewportTransform,
      containerHeight: clientHeight,
      containerWidth: offsetWidth,
    })
  }

  panToNewPdf = () => {
    const canvas = this._sketch._fc;
    var viewportTransform = canvas.viewportTransform.slice();
    let { offsetWidth = 10, clientHeight = 10 } = this._sketch._container;
    var top = this._sketch.state.totalImagesHeight;
    viewportTransform[5] = -top;
    this._changePan({
      clientID: this.state.clientID,
      viewportTransform: viewportTransform,
      containerHeight: clientHeight,
      containerWidth: offsetWidth,
    })
  }

  panUp = () => {
    const canvas = this._sketch._fc;
    var viewportTransform = canvas.viewportTransform.slice();
    let { offsetWidth = 10, clientHeight = 10 } = this._sketch._container;
    viewportTransform[5] += clientHeight;
    this._changePan({
      clientID: this.state.clientID,
      viewportTransform: viewportTransform,
      containerHeight: clientHeight,
      containerWidth: offsetWidth,
    })
  }

  panLeft = () => {
    const canvas = this._sketch._fc;
    var viewportTransform = canvas.viewportTransform.slice();
    let { offsetWidth = 10, clientHeight = 10 } = this._sketch._container;
    viewportTransform[4] += offsetWidth;
    this._changePan({
      clientID: this.state.clientID,
      viewportTransform: viewportTransform,
      containerHeight: clientHeight,
      containerWidth: offsetWidth,
    })
  }

  panRight = () => {
    const canvas = this._sketch._fc;
    var viewportTransform = canvas.viewportTransform.slice();
    let { offsetWidth = 10, clientHeight = 10 } = this._sketch._container;
    viewportTransform[4] -= offsetWidth;
    this._changePan({
      clientID: this.state.clientID,
      viewportTransform: viewportTransform,
      containerHeight: clientHeight,
      containerWidth: offsetWidth,
    })
  }

  componentWillUnmount() {
    console.log("unsubscribing")
    if (this.onCreateWhiteBoardEventSubscription) {
      this.onCreateWhiteBoardEventSubscription.unsubscribe();
    }
  }

  getUrlOfPreviousWhiteboard = async (accessToken) => {
    const slateID = await getSlateIdBySessionId(this.state.whiteBoardID, accessToken)
    const previousWhiteboardID = await this.getPreviousWhiteboardID(slateID, accessToken)
    console.log('heyy', previousWhiteboardID)
    if (previousWhiteboardID) {
      const sessionStartTime = await getSessionStartTimeByID(previousWhiteboardID, accessToken)
      const slateTitle = await getSlateTitleByID(accessToken, slateID)
      let previousWhiteboardFileLink, previousWhiteboardFileLink2;
      let fileName, fileName2;
      // const fileName = slateTitle + '_' + sessionStartTime + '_whiteBoardID:' + previousWhiteboardID + '_data.txt';
      fileName = slateTitle + '_' + sessionStartTime + '_whiteBoardID:' + previousWhiteboardID + '.whiteboardData';
      previousWhiteboardFileLink = UPLOAD_API_URL + "uploads/whiteBoardStorage/" + previousWhiteboardID + '/JSON/' + fileName;
      fileName2 = slateTitle + '_' + sessionStartTime + '_whiteBoardID:' + previousWhiteboardID + '_data.txt';
      previousWhiteboardFileLink2 = UPLOAD_API_URL + "uploads/whiteBoardStorage/" + previousWhiteboardID + '/JSON/' + fileName2;

      console.log('previous whiteboard link', previousWhiteboardFileLink)
      return {
        'previousWhiteboardUrl': previousWhiteboardFileLink,
        'previousWhiteboardUrl2': previousWhiteboardFileLink2,
        'sessionStartTime': sessionStartTime
      }
    }
    //check for previous whiteboard file is added

  }

  getPreviousWhiteboardID = async (slateID, accessToken) => {
    const sessionsArray = await this.getSessionsList(slateID, accessToken)
    sessionsArray.sort(function (a, b) {
      return new Date(b.start_time) - new Date(a.start_time);
    });
    // console.log('sessions', sessionsArray)
    const inactiveSessionsArray = sessionsArray.filter((session) => {
      return session.is_active === false;
    })
    console.log('sessions', inactiveSessionsArray)
    if (inactiveSessionsArray.length > 0) {
      return inactiveSessionsArray[0].id;
    }
    else {
      return null;
    }
  }

  getSessionsList = async (slateID, accessToken) => {
    // console.log(accessToken);

    const SlateIDObject = {
      "slateId": slateID
    }
    let res, status;
    try {
      res = await getSessionsBySlate(accessToken, SlateIDObject)
      status = res.status
    }
    catch (err) {
      console.log(err)
      status = err.response.status
    }

    if (status === 200) {

      // console.log(res.data.sessions)
      return res.data.sessions
    }
    else {
      return ''
    }

  };

  processCanvasToWhiteboardEvent = async (fileUrl, objectIdSuffix, fileUrl2) => {
    let canvas = this._sketch._fc;
    try {
      // console.log('yo')
      console.log(fileUrl)
      let r = await fetch(fileUrl)
      // console.log(r)
      let t = await r.json()
      // console.log(t)
      let tempCanvas = t
      let data = JSON.stringify(tempCanvas)
      let objects = (JSON.parse(data)).objects
      for (let i = 0; i < (objects).length; ++i) {
        objects[i].objectID = objects[i].objectID + objectIdSuffix
      }
      console.log(objects)
      let background;
      if ((JSON.parse(data)).background) {
        background = (JSON.parse(data)).background
      }
      await this.canvasLoadingFromJsonEnliven(canvas, objects, background)
      // console.log('hey', flag)
      // canvas.renderAll();
    }
    catch (err) {
      console.log(err)
      try {
        // console.log('yo')
        console.log(fileUrl2)
        let r = await fetch(fileUrl2)
        // console.log(r)
        let t = await r.json()
        // console.log(t)
        let tempCanvas = t
        let data = JSON.stringify(tempCanvas)
        let objects = (JSON.parse(data)).objects
        for (let i = 0; i < (objects).length; ++i) {
          objects[i].objectID = objects[i].objectID + objectIdSuffix
        }
        console.log(objects)
        let background;
        if ((JSON.parse(data)).background) {
          background = (JSON.parse(data)).background
        }
        await this.canvasLoadingFromJsonEnliven(canvas, objects, background)
        // console.log('hey', flag)
        // canvas.renderAll();
      }
      catch (err) {
        console.log(err)
      }
    }
    return 'done'
  }

  // canvasLoadingFromJson = async (canvas, data) => {
  //   await new Promise((resolveP, rejectP) => {
  //     canvas.loadFromJSON(data, () => {
  //       canvas.renderAll.bind(canvas)
  //       resolveP();
  //     }, (o, object) => {
  //       // console.log(o, object)
  //       // console.log(object)
  //       this.scaleJSON(object)
  //       return object
  //     });


  //   })

  // }

  //   callEnlivenFunctionInSequence = async (canvas, objects) => {
  //     let j = 0;
  //     for (let i = 0; i < objects.length; i += 1000) {
  //         if (i == 1) {
  //             this.handleChangeLoading(false)
  //         }
  //         // console.log(j * 20000)
  //         // if (i === 2) {
  //         //     this.handleChangeLoading(false)
  //         // }
  //         setTimeout(() => {
  //             this.canvasLoadingFromJsonEnliven(canvas, objects.slice(i, i + 1000))
  //             console.log(i + 1000, ' done')
  //         }, j * 5000)
  //         // if (i >= 2)
  //         ++j;
  //     }
  // }

  handleBackgroundColor = (val) => {
    this.setState({ backgroundColor: val });
  }

  canvasLoadingFromJsonEnliven = async (canvas, objects, background) => {
    if (background) {
      canvas.setBackgroundColor(background, () => canvas.renderAll());
      this.handleBackgroundColor(background)
    }
    await new Promise((resolveP, rejectP) => {
      // console.log(objects)
      // fabric.util.requestRenderAll();
      fabric.util.enlivenObjects(objects, {})
        .then(objs => {
          objs.forEach((item) => {
            this.scaleJSON(item)
            // item.dirty = false
            canvas.add(item);
            if (item.isPdf) {
              canvas.sendObjectToBack(item)
            }
            // item.moveTo(-10000)
          });
          canvas.requestRenderAll();
          resolveP()
        })
    })
  }


  handleChangeLoading(val) {
    this.setState({ isLoading: val });
  }

  onNewZoomRestore = () => {
    this._sketch.newZoom({ zoomLevel: 1, clientID: this.state.clientID })
  }

  onNewZoom = (zoomFactor) => {
    let canvas = this._sketch._fc
    let currentZoom = canvas.getZoom();
    // canvas.setZoom(1.5 * zoom);
    let newZoomLevel = currentZoom * zoomFactor
    this._sketch.newZoom({ zoomLevel: newZoomLevel, clientID: this.state.clientID })
  }


  render = () => {
    // let { controlledValue } = this.state;
    // const { } = this.props;
    const theme = createTheme(adaptV4Theme({
      typography: {
        useNextVariants: true,
      },
      palette: {
        primary: { main: color[500] }, // Purple and green play nicely together.
        secondary: { main: "#11cb5f" }, // This is just green.A700 as hex.
      },
    }));
    const { RecordingButton } = this.props;
    console.log("whiteBoard:loading", this.state.isLoading);
    return (<SnackbarProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Grid container direction="row">
            <Grid item xs={12}>
              <AppBar title="Sketch Tool" position="static" style={styles.appBar}>
                <Toolbar style={{ display: "flex", flexWrap: "wrap", textAlign: 'center', justifyContent: 'center' }}>
                  <Typography variant="h6" color="inherit" >
                    {this.state.isPlayer ? "Class recording" : "Teach Matter"}
                  </Typography>
                  <>
                    {this.props.isWhiteboardPlayerLoading ? <Grid sx={{
                      position: "absolute", width: "100%", height: "90vh", opacity: "1", top: "0", left: "0",
                      backgroundColor: "white", display: "flex", alignItems: "center", alignContent: "center", justifyContent: "center"
                    }}>
                      <Box>
                        <CircularProgress size={"2rem"} sx={{
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                          color: THEME_COLOR
                        }} />
                      </Box>
                    </Grid> : <>
                      {!this.state.isPlayer &&
                        <>
                          <RecordingButton />
                          <Tooltip title={"Pencil"}>
                            <IconButton
                              className={classes.iconButton}
                              onClick={(event) => {
                                this._selectTool({ target: { value: "Pencil" } })
                                this.setState({ openLineColorPicker: event.currentTarget })
                              }}
                              style={this.state.toolName === "Pencil" ? styles.selected : {}}
                              size="large">
                              <CreateIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Select linewidth">
                            <Select
                              labelId="lineWidth-label"
                              id="select-lineWidth"
                              value={this.state.lineWidth}
                              className={classes.iconButton}
                              style={{ marginLeft: "8px", marginRight: "8px", }}
                              onChange={(e) => {
                                console.log("selected width", e.target.value)
                                this.setState({ lineWidth: e.target.value })
                              }}
                            >
                              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((val) =>
                                <MenuItem value={val} key={val}>{val}</MenuItem>
                              )}
                            </Select>
                          </Tooltip>
                          <Tooltip title={"Highlighter"}>
                            <IconButton
                              className={classes.iconButton}
                              onClick={(event) => {
                                this._selectTool({ target: { value: "Highlighter" } })
                                this.setState({ openLineColorPicker: event.currentTarget })
                              }}
                              style={this.state.toolName === "Highlighter" ? styles.selected : {}}
                              size="large">
                              <BorderColorIcon />
                            </IconButton>
                          </Tooltip>
                          {this.state.isPanEnabled && <Tooltip title={"Pan"}>
                            <IconButton
                              className={classes.iconButton}
                              onClick={(event) => {
                                this._selectTool({ target: { value: "Pan" } })
                              }}
                              style={this.state.toolName === "Pan" ? styles.selected : {}}
                              size="large">
                              <PanToolIcon />
                            </IconButton>
                          </Tooltip>
                          }
                          <Tooltip title="Add text">
                            <div onClick={this._addText} className={classes.addText}>T</div>
                          </Tooltip>
                          <Tooltip title={"Select"}>
                            <IconButton
                              className={classes.iconButton}
                              onClick={(event) => {
                                this._selectTool({ target: { value: "Select" } })
                              }}
                              style={this.state.toolName === "Select" ? styles.selected : {}}
                              size="large">
                              <NearMeIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Remove selected">
                            <IconButton
                              className={classes.iconButton}
                              onClick={this._removeSelected}
                              size="large">
                              <HighlightOffIcon />
                            </IconButton>
                          </Tooltip>
                          {this.state.isDeleteAllEnabled &&
                            <Tooltip title="Clear all">
                              <IconButton
                                className={classes.iconButton}
                                onClick={() => this._clear({ clientID: this.state.clientID })}
                                size="large">
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          }
                          <Tooltip title="Eraser">
                            <IconButton
                              className={classes.iconButton}
                              onClick={(event) => {
                                this._selectTool({ target: { value: "Eraser" } })
                              }}
                              style={this.state.toolName === "Eraser" ? styles.selected : {}}
                              size="large">
                              <BookIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={"Circle"}>
                            <IconButton
                              className={classes.iconButton}
                              onClick={(event) => {
                                this._selectTool({ target: { value: "Circle" } })
                                this.setState({ openLineColorPicker: event.currentTarget })
                              }}
                              style={this.state.toolName === "Circle" ? styles.selected : {}}
                              size="large">
                              <RadioButtonUncheckedIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={"Rectangle"}>
                            <IconButton
                              className={classes.iconButton}
                              onClick={(event) => {
                                this._selectTool({ target: { value: "Rectangle" } })
                                this.setState({ openLineColorPicker: event.currentTarget })
                              }}
                              style={this.state.toolName === "Rectangle" ? styles.selected : {}}
                              size="large">
                              <Crop169Icon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={"Line"}>
                            <IconButton
                              className={classes.iconButton}
                              onClick={(event) => {
                                this._selectTool({ target: { value: "Line" } })
                                this.setState({ openLineColorPicker: event.currentTarget })
                              }}
                              style={this.state.toolName === "Line" ? styles.selected : {}}
                              size="large">
                              <RemoveIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={"Arrow"}>
                            <IconButton
                              className={classes.iconButton}
                              onClick={(event) => {
                                this._selectTool({ target: { value: "Arrow" } })
                                this.setState({ openLineColorPicker: event.currentTarget })
                              }}
                              style={this.state.toolName === "Arrow" ? styles.selected : {}}
                              size="large">
                              <ArrowRightAltIcon />
                            </IconButton>
                          </Tooltip>
                          {this.state.isZoomEnabled && <Tooltip title="Zoom in">
                            <IconButton
                              className={classes.iconButton}
                              onClick={(e) =>
                                this._zoom({ zoomFactor: 1.25, clientID: this.state.clientID })}
                              size="large">
                              <ZoomInIcon />
                            </IconButton>
                          </Tooltip>
                          }
                          {this.state.isZoomEnabled &&
                            <Tooltip title="Restore zoom">
                              <IconButton className={classes.iconButton} onClick={this._restoreZoom} size="large">
                                <YoutubeSearchedForIcon />
                              </IconButton>
                            </Tooltip>
                          }
                          {this.state.isZoomEnabled &&
                            <Tooltip title="Zoom out">
                              <IconButton
                                className={classes.iconButton}
                                onClick={(e) => this._zoom({ zoomFactor: 0.8, clientID: this.state.clientID })}
                                size="large">
                                <ZoomOutIcon />
                              </IconButton>
                            </Tooltip>
                          }
                          <Tooltip title="Upload pdf">
                            <IconButton
                              id="upload-button"
                              className={classes.iconButton}
                              onClick={() => document.getElementById("file-to-upload").click()}
                              size="large">
                              <AttachFileIcon />
                            </IconButton>
                          </Tooltip>
                          <input
                            type="file"
                            id="file-to-upload"
                            accept="application/pdf"
                            hidden
                          // onChange={(e) => convertPdfToImages(
                          //   e, this._sketch.addImg,
                          //   this.panToNewPdf,
                          //   this.handleChangeLoading,
                          //   this.state.whiteBoardID,
                          //   this.props.enqueueSnackbar
                          // )}
                          />
                          <Tooltip title="Upload image">
                            <IconButton
                              id="upload-image"
                              className={classes.iconButton}
                              onClick={() => document.getElementById("image-to-upload").click()}
                              size="large">
                              <ImageIcon />
                            </IconButton>
                          </Tooltip>
                          <input
                            type="file"
                            id="image-to-upload"
                            accept="image/*"
                            hidden
                            onChange={(e) => addImageUrl(e, this._sketch.addImg, this.handleChangeLoading, this.state.whiteBoardID, this.props.enqueueSnackbar)}
                          />
                          {/*<InputLabel id="lineWidth-label">lineWidth</InputLabel>*/}

                          <TextField
                            label="Text"
                            helperText="Add text to Sketch"
                            onChange={(e) => this.setState({ text: e.target.value })}
                            value={this.state.text}
                            placeholder={"type here"}
                            style={{ display: "none" }}
                          />

                          <Tooltip title={"Download"}>
                            <IconButton
                              className={classes.iconButton}
                              // onClick={() => downloadPdf(this._sketch._fc, this.handleChangeLoading)}
                              size="large">
                              <DownloadIcon />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Change background">
                            <Button
                              className={classes.iconButton}
                              aria-describedby={"backgroundColorPicker"}
                              onClick={(event) =>
                                this.setState({
                                  openBackgroundColorPicker: event.currentTarget
                                })}

                            >
                              <FiberManualRecordIcon style={{ color: this.state.backgroundColor }} />
                              bg
                            </Button>
                          </Tooltip>
                          <Popover
                            open={this.state.openBackgroundColorPicker}
                            anchorEl={this.state.openBackgroundColorPicker}
                            onClose={() => this.setState({ openBackgroundColorPicker: false })}
                          >
                            <>
                              <Checkbox
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                checked={this.state.backgroundColor !== ""}
                                onChange={(e) => {
                                  if (!e.target.checked) {
                                    this._changeBackgroundColor({
                                      color: "",
                                      clientID: this.state.clientID
                                    })
                                  }
                                  else {
                                    this._changeBackgroundColor({
                                      color: this.state.unsetBackgroundColor,
                                      clientID: this.state.clientID
                                    })
                                  }
                                }

                                }
                              >
                              </Checkbox>
                              <label htmlFor="backgroundColorPicker" style={{ textAlign: "center" }}>Fill Background color</label>
                              <br />
                              <CompactPicker
                                id="backgroundColorPicker"
                                color={this.state.backgroundColor === "" ?
                                  this.state.unsetBackgroundColor :
                                  this.state.backgroundColor
                                }
                                colors={colors}
                                onChange={(color) => {
                                  if (this._sketch._fc.backgroundColor !== "") {
                                    this._changeBackgroundColor({
                                      color: color.hex,
                                      clientID: this.state.clientID
                                    })
                                  }
                                  else {
                                    this.setState({
                                      unsetBackgroundColor: color.hex,
                                    })
                                  }
                                  this.setState({ openBackgroundColorPicker: false })
                                }

                                }
                              />
                            </>
                          </Popover>
                          <Popover
                            open={this.state.openLineColorPicker}
                            anchorEl={this.state.openLineColorPicker}
                            onClose={() => this.setState({ openLineColorPicker: false })}
                          >
                            <label htmlFor="lineColor" style={{ textAlign: "center" }}>drawing color</label>
                            <br />
                            <CompactPicker
                              colors={colors}
                              id="lineColor"
                              color={this.state.lineColor}
                              onChange={(color) => this.setState({ lineColor: color.hex, openLineColorPicker: false })}
                            />
                          </Popover>

                          {/*
                  <Tooltip title="undo">
                    <IconButton
                      color="primary"
                      disabled={!this.state.canUndo}
                      onClick={this._undo}>
                      <UndoIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="redo">
                    <IconButton
                      color="primary"
                      disabled={!this.state.canRedo}
                      onClick={this._redo}>
                      <RedoIcon />
                    </IconButton>
                  </Tooltip>
                  */}
                          {/*
                  <IconButton color="primary" onClick={this._save}>
                    <SaveIcon />
                  </IconButton>
                  <IconButton color="primary" onClick={this._download}>
                    <DownloadIcon />
                  </IconButton>
                  /*}
                  {/*}
                  <IconButton color="primary" onClick={()=>this._add(sampleobject.origObj)}>
                    <AddIcon />
                  </IconButton>
                  <IconButton color="primary" onClick={()=>this._modify(sampleobject.changedObj)}>
                    <ArrowForwardIcon />
                  </IconButton>
                  <IconButton color="primary" onClick={this._remove}>
                    <ArrowBackIcon />
                  </IconButton>
                  */}

                        </>
                      }</>
                    } </>
                </Toolbar>
              </AppBar>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} style={{ position: "relative" }}>
                <SketchField
                  name="sketch"
                  className={classes.canvasArea}
                  ref={(c) => (this._sketch = c)}
                  lineColor={this.state.lineColor}
                  lineWidth={this.state.lineWidth}
                  fillColor={
                    this.state.fillWithColor ? this.state.fillColor : "transparent"
                  }
                  width={this.state.controlledSize ? this.state.sketchWidth : null}
                  height={this.state.controlledSize ? this.state.sketchHeight : null}
                  defaultValue={null}
                  value={null}
                  forceValue
                  onChange={this._onSketchChange}
                  clientID={this.state.clientID}
                  isPlayer={this.state.isPlayer}
                  isTeacher={this.state.isTeacher}
                  isToolBarEnabled={this.state.isToolBarEnabled}
                  onObjectAdded={this.onObjectAdded}
                  onObjectModified={this.onObjectModified}
                  onCanvasCleared={this.onCanvasCleared}
                  onObjectRemoved={this.onObjectRemoved}
                  onMouseUp={this.onMouseUp}
                  onPanChanged={this.onPanChanged}
                  onZoomChanged={this.onZoomChanged}
                  onBackgroundColorChanged={this.onBackgroundColorChanged}
                  onNewZoomChanged={this.onNewZoomChanged}
                  // onPathCreated ={onPathCreated} No need as the object added is always called

                  tool={this.state.toolName}
                />
                {this.state.isLoading &&
                  <div style={{
                    position: "absolute", width: "100%", height: "90vh", zIndex: "2000", opacity: "1", top: "0", left: "0",
                    backgroundColor: "white", display: "flex", alignItems: "center", alignContent: "center", justifyContent: "center"
                  }}>
                    <div style={{}}>
                      <CircularProgress size={"2rem"} style={{
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto"
                      }} />
                      <p style={{
                        fontSize: "2rem",
                        display: "block",
                        textAlign: "center",
                        color: "black"
                      }}>
                        loading...
                      </p>
                    </div>
                  </div>
                }
                {!this.state.isPlayer && this.state.isToolBarEnabled && this.state.isPanEnabled &&
                  <Grid style={{ position: "absolute", right: "10px", bottom: "10px" }}>
                    <Grid container item justifyContent="center">
                      <IconButton color="primary" onClick={this.panUp} size={"small"}>
                        <ArrowDropUpIcon />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton color="primary"
                        onClick={this.panLeft}
                        size={"small"}>
                        <ArrowLeftIcon />
                      </IconButton>
                      <IconButton
                        color="primary"
                        onClick={() => this._changePan({
                          clientID: this.state.clientID,
                          viewportTransform: [1, 0, 0, 1, 0, 0]
                        })}
                        size={"small"}
                      >
                        <RotateLeftIcon />
                      </IconButton>
                      <IconButton color="primary" onClick={this.panRight} size={"small"}>
                        <ArrowRightIcon />
                      </IconButton>
                    </Grid>
                    <Grid item container justifyContent="center">
                      <IconButton color="primary" onClick={this.panDown} size={"small"}>
                        <ArrowDropDownIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                }
              </Grid>
              {/*!this.state.isPlayer && this.state.isToolBarEnabled &&
            <Grid container>
              <Grid item xs={12} style={{position:"fixed",bottom:"0px"}}>
              <Card style={styles.card}>
              <CardHeader
                subheader="Available tools"
                style={{margin:"0",padding:"0 0 0 8px"}}
                action={
                  <IconButton
                    onClick={(e) =>
                      this.setState({ expandTools: !this.state.expandTools })
                    }>
                      {!this.state.expandTools?
                      <ExpandLess/>:
                    <ExpandMore />}
                  </IconButton>
                }
              />
            <Collapse in={this.state.expandTools}>
              <Grid container direction={"row"} justify="center">
                <Grid container item xs={12} md={6} justify="center">
                  {ToolsIcons.map(toolicon=>(
                    <Grid item key={toolicon.value}>
                      <Tooltip title={toolicon.value}>
                        <IconButton
                        onClick={()=>this._selectTool({target:{value:toolicon.value}})}
                        style={this.state.toolName==toolicon.value?styles.selected:{}}
                        >
                          {toolicon.icon}
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  ))}
                  <Grid item>
                    <Tooltip title="zoomIn">
                      <IconButton onClick={(e) =>
                        this._zoom({zoomFactor:1.25,clientID:this.state.clientID})}>
                        <ZoomInIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip title="zoomOut">
                      <IconButton onClick={(e) => this._zoom({zoomFactor:0.8,clientID:this.state.clientID})}>
                        <ZoomOutIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip title="upload pdf">
                      <IconButton
                        id="upload-button"
                        onClick={() => document.getElementById("file-to-upload").click()}
                      >
                      <AttachFileIcon/>
                      </IconButton>
                    </Tooltip>
                    <input
                      type="file"
                      id="file-to-upload"
                      accept="application/pdf"
                      hidden
                      onChange={(e)=>convertPdfToImages(
                        e,this._sketch.addImg,
                        this.panToNewPdf,
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <Tooltip title="upload image">
                      <IconButton
                        id="upload-image"
                        onClick={() => document.getElementById("image-to-upload").click()}
                      >
                      <ImageIcon/>
                      </IconButton>
                    </Tooltip>
                    <input
                      type="file"
                      id="image-to-upload"
                      accept="image/*"
                      hidden
                      onChange={(e)=>addImageUrl(e,this._sketch.addImg)}
                    />
                  </Grid>
                  <Grid item container xs={12} md={2} justify="center">
                    <InputLabel id="lineWidth-label">lineWidth</InputLabel>
                    <Select
                      labelId="lineWidth-label"
                      id="select-lineWidth"
                      value={this.state.lineWidth}
                      onChange={(e) => {
                        console.log("selected width",e.target.value)
                        this.setState({ lineWidth: e.target.value})
                      }}
                    >
                      {[1,2,3,4,5,6,7,8,9,10,11,12].map((val)=>
                        <MenuItem value={val} key={val}>{val}</MenuItem>
                        )}
                    </Select>

                  </Grid>
                  <Grid item container xs={12} md={5} justify="center">
                      <TextField
                        label="Text"
                        helperText="Add text to Sketch"
                        onChange={(e) => this.setState({ text: e.target.value })}
                        value={this.state.text}
                        placeholder={"type here"}
                      />
                      <IconButton color="primary" size={"small"} onClick={this._addText}>
                        <AddIcon />
                      </IconButton>
                  </Grid>
                  {/*
                  <Grid item container xs={12} md={5} justify="center">
                    <TextField
                      label="Image URL"
                      helperText="Copy/Paste an image URL"
                      onChange={(e) => this.setState({ imageUrl: e.target.value })}
                      value={this.state.imageUrl}
                    />
                     <IconButton color="primary" size={"small"} onClick={(e) => {
                        this._sketch.addImg(this.state.imageUrl,false);
                      }}>
                        <AddIcon />
                      </IconButton>
                  </Grid>


                </Grid>
                <Grid item container xs={12} md={6} justify="center" alignItems="flex-end">
                  <Grid item>
                    <label htmlFor="lineColor" style={{textAlign:"center"}}>drawing color</label>
                    <br />
                    <CompactPicker
                      id="lineColor"
                      color={this.state.lineColor}
                      onChange={(color) => this.setState({ lineColor: color.hex })}
                    />
                  </Grid>
                  <Grid item>
                    <Checkbox
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      checked={this.state.backgroundColor!=""}
                      onChange={(e)=>{
                        if(!e.target.checked){
                          this._changeBackgroundColor({
                            color:"",
                            clientID:this.state.clientID
                          })
                        }
                        else{
                          this._changeBackgroundColor({
                            color:this.state.unsetBackgroundColor,
                            clientID:this.state.clientID
                          })
                        }
                      }

                      }
                    >
                      </Checkbox>
                    <label htmlFor="bgColor" style={{textAlign:"center"}}>Fill Background color</label>
                    <br />
                    <CompactPicker
                      id="bgColor"
                      color={this.state.backgroundColor==""?
                        this.state.unsetBackgroundColor:
                        this.state.backgroundColor
                      }
                      onChange={(color) =>{
                        if(this._sketch._fc.backgroundColor!=""){
                          this._changeBackgroundColor({
                            color:color.hex,
                            clientID:this.state.clientID
                          })
                        }
                        else{
                          this.setState({
                            unsetBackgroundColor:color.hex,
                          })
                        }

                      }

                      }
                    />
                  </Grid>
                </Grid>

              </Grid>

            </Collapse>
            </Card>
            </Grid>
              {/*
              <Card style={styles.card}>
                <CardHeader
                  title="Tools"
                  subheader="Available tools"
                  action={
                    <IconButton
                      onClick={(e) =>
                        this.setState({ expandTools: !this.state.expandTools })
                      }>
                      <ExpandMore />
                    </IconButton>
                  }
                />
                <Collapse in={this.state.expandTools}>
                  <CardContent>
                    <div className="row">
                      <div className="col-lg-12">
                      <FormControl variant="outlined">

                      <InputLabel id="select-tool-label">
                        <Typography color="textPrimary">Canvas tool</Typography>
                      </InputLabel>

                      <Select
                        labelId="select-tool-label"
                        id="Tutor"
                        value={this.state.toolName}
                        onChange={this._selectTool}
                        MenuProps={{variant:"menu",autoFocus:false,disableAutoFocus:true}}
                      >
                          <MenuItem value={"Select"} key="Select">
                            Select
                          </MenuItem>
                          <MenuItem value={"Pencil"} key="Pencil">
                            Pencil
                          </MenuItem>
                          <MenuItem value={"Line"} key="Line">
                            Line
                          </MenuItem>
                          <MenuItem value="Arrow" key="Arrow">
                            Arrow
                          </MenuItem>
                          <MenuItem value="Rectangle" key="Rectangle">
                            Rectangle
                          </MenuItem>
                          <MenuItem value="Circle" key="Circle">
                            Circle
                          </MenuItem>
                          <MenuItem value="Pan" key="Pan">
                            Pan
                          </MenuItem>
                          <MenuItem value="Highlighter" key="Highlighter">
                            Highlighter
                          </MenuItem>
                          <MenuItem value="RectangleLabel" key="RectangleLabel">
                            RectangleLabel
                          </MenuItem>
                      </Select>
                    </FormControl>
                      </div>
                    </div>
                    <br />
                    <br />
                    <Typography id="slider">Line Weight</Typography>
                    <Slider
                      step={1}
                      min={0}
                      max={100}
                      aria-labelledby="slider"
                      value={this.state.lineWidth}
                      onChange={(e, v) => this.setState({ lineWidth: v })}
                    />
                    <br />
                    <label htmlFor="zoom">Zoom</label>
                    <div>
                      <IconButton onClick={(e) =>
                        this._zoom({zoomFactor:1.25,clientID:this.state.clientID})}>
                        <ZoomInIcon />
                      </IconButton>
                      <IconButton onClick={(e) => this._zoom({zoomFactor:0.8,clientID:this.state.clientID})}>
                        <ZoomOutIcon />
                      </IconButton>
                    </div>
                    <div className="row">
                      <div className="col-lg-7">
                        <TextField
                          label="Text"
                          helperText="Add text to Sketch"
                          onChange={(e) => this.setState({ text: e.target.value })}
                          value={this.state.text}
                        />
                      </div>
                      <div className="col-lg-3">
                        <IconButton color="primary" onClick={this._addText}>
                          <AddIcon />
                        </IconButton>
                      </div>
                    </div>
                  </CardContent>
                </Collapse>
              </Card>
              <Card style={styles.card}>
                <CardHeader
                  title="Controls"
                  subheader="Copy/Paste etc."
                  action={
                    <IconButton
                      onClick={(e) =>
                        this.setState({
                          expandControls: !this.state.expandControls,
                        })
                      }>
                      <ExpandMore />
                    </IconButton>
                  }
                />
                <Collapse in={this.state.expandControls}>
                  <CardContent>
                    <div className="row">
                      <div className="col-lg-12">
                        <FormControlLabel
                          control={
                            <Switch
                              value={this.state.controlledSize}
                              onChange={(e) =>
                                this.setState({
                                  controlledSize: !this.state.controlledSize,
                                })
                              }
                            />
                          }
                          label="Control size"
                        />
                        <br />
                        <Typography id="xSize">Change Canvas Width</Typography>
                        <Slider
                          step={1}
                          min={10}
                          max={1000}
                          value={this.state.sketchWidth}
                          onChange={(e, v) => this.setState({ sketchWidth: v })}
                        />
                        <br />
                        <Typography id="ySize">Change Canvas Height</Typography>
                        <Slider
                          step={1}
                          min={10}
                          max={1000}
                          value={this.state.sketchHeight}
                          onChange={(e, v) => this.setState({ sketchHeight: v })}
                        />
                        <br />
                      </div>
                    </div>
                    <label htmlFor="zoom">
                      Selection Actions (Select an object first!)
                    </label>
                    <div className="row">
                      <div className="col">
                        <IconButton
                          color="primary"
                          disabled={!this.state.enableCopyPaste}
                          onClick={(e) => {
                            this._sketch.copy();
                            this._sketch.paste();
                          }}>
                          <CopyIcon />
                        </IconButton>
                      </div>
                      <div className="col">
                        <IconButton
                          color="primary"
                          disabled={!this.state.enableRemoveSelected}
                          onClick={this._removeSelected}>
                          <RemoveIcon />
                        </IconButton>
                      </div>
                    </div>
                  </CardContent>
                </Collapse>
              </Card>
              <Card style={styles.card}>
                <CardHeader
                  title="Colors"
                  subheader="Put some color on your drawing"
                  action={
                    <IconButton
                      onClick={(e) =>
                        this.setState({ expandColors: !this.state.expandColors })
                      }>
                      <ExpandMore />
                    </IconButton>
                  }
                />
                <Collapse in={this.state.expandColors}>
                  <CardContent>
                    <label htmlFor="lineColor">Line</label>
                    <br />
                    <CompactPicker
                      id="lineColor"
                      color={this.state.lineColor}
                      onChange={(color) => this.setState({ lineColor: color.hex })}
                    />
                    <br />
                    <br />
                    <FormControlLabel
                      control={
                        <Switch
                          value={this.state.fillWithColor}
                          onChange={(e) =>
                            this.setState({
                              fillWithColor: !this.state.fillWithColor,
                            })
                          }
                        />
                      }
                      label="Fill"
                    />
                    <CompactPicker
                      color={this.state.fillColor}
                      onChange={(color) => this.setState({ fillColor: color.hex })}
                    />
                  </CardContent>
                </Collapse>
              </Card>
              <Card style={styles.card}>
                <CardHeader
                  title="Background"
                  subheader="Background of drawing"
                  action={
                    <IconButton
                      onClick={(e) =>
                        this.setState({ expandBack: !this.state.expandBack })
                      }>
                      <ExpandMore />
                    </IconButton>
                  }
                />
                <Collapse in={this.state.expandBack}>
                  <CardContent>
                    {/*<FormControlLabel
                      label="Background Color"
                      control={
                        <Switch
                          value={this.state.fillWithBackgroundColor.value}
                          onChange={(e) =>
                            this.setState({
                              fillWithBackgroundColor: {
                                value:!this.state.fillWithBackgroundColor.value,
                                backgroundColorChangedBy:this.state.clientID,
                              }
                            })
                          }
                        />
                      }
                    />
                    <CompactPicker
                      onChange={(color) =>
                        this._changeBackgroundColor({
                          color:color.hex,
                          clientID:this.state.clientID
                        })
                      }
                    />
                    <br />
                    <br />
                    <label htmlFor="lineColor">Set Image Background</label>
                    <br />
                    <FormControlLabel
                      label="Fit canvas (X,Y)"
                      control={
                        <Switch
                          value={this.state.stretched}
                          onChange={(e) =>
                            this.setState({ stretched: !this.state.stretched })
                          }
                        />
                      }
                    />
                    <FormControlLabel
                      label="Fit canvas (X)"
                      control={
                        <Switch
                          value={this.state.stretchedX}
                          onChange={(e) =>
                            this.setState({ stretchedX: !this.state.stretchedX })
                          }
                        />
                      }
                    />
                    <FormControlLabel
                      label="Fit canvas (Y)"
                      control={
                        <Switch
                          value={this.state.stretchedY}
                          onChange={(e) =>
                            this.setState({ stretchedY: !this.state.stretchedY })
                          }
                        />
                      }
                    />
                    <div>
                      <DropZone
                        accept="image/*"
                        multiple={false}
                        style={styles.dropArea}
                        activeStyle={styles.activeStyle}
                        rejectStyle={styles.rejectStyle}
                        onDrop={this._onBackgroundImageDrop}>
                         {dropzoneProps => {  return (
        <div>
          <p>Drop some files here</p>
        </div>
      );
    }}
                      </DropZone>
                    </div>
                  </CardContent>
                </Collapse>
              </Card>
              <Card style={styles.card}>
                <CardHeader
                  title="Images"
                  subheader="Upload Images as drawing"
                  action={
                    <IconButton
                      onClick={(e) =>
                        this.setState({ expandImages: !this.state.expandImages })
                      }>
                      <ExpandMore />
                    </IconButton>
                  }
                />
                <Collapse in={this.state.expandImages}>
                  <CardContent>
                    <div>
                      <TextField
                        label="Image URL"
                        helperText="Copy/Paste an image URL"
                        onChange={(e) => this.setState({ imageUrl: e.target.value })}
                        value={this.state.imageUrl}
                      />
                      <Button
                        id="upload-button"
                        onClick={() => document.getElementById("file-to-upload").click()}
                      >
                        Select PDF
                      </Button>
                      <input
                        type="file"
                        id="file-to-upload"
                        accept="application/pdf"
                        hidden
                        onChange={(e)=>convertPdfToImages(e,this._sketch.addImg)}
                      />
                      <Button
                        variant="outlined"
                        onClick={(e) => {
                          this._sketch.addImg(this.state.imageUrl);
                        }}>
                        Load Image from URL
                      </Button>
                    </div>
                    <br />
                    <Button
                      variant="outlined"
                      onClick={(e) => this._sketch.addImg(dataUrl)}>
                      Load Image from Data URL
                    </Button>
                  </CardContent>
                </Collapse>
              </Card>
              <Card style={styles.card}>
                <CardHeader
                  title="Controlled value"
                  subheader="Control Component externally"
                  action={
                    <IconButton
                      onClick={(e) =>
                        this.setState({
                          expandControlled: !this.state.expandControlled,
                        })
                      }>
                      <ExpandMore />
                    </IconButton>
                  }
                />
                <Collapse in={this.state.expandControlled}>
                  <CardContent>
                    <Button
                      variant="outlined"
                      onClick={(e) =>
                        this.setState({
                          controlledValue: dataJsonControlled,
                        })
                      }>
                      Load controlled Value
                    </Button>
                  </CardContent>
                </Collapse>
              </Card>

            </Grid>
            */}
            </Grid>
          </Grid>
          {/*
          <div style={{ width: 0 }}>
            <div className="col-xs-7 col-sm-7 col-md-9 col-lg-9">
              Sketch area

              <div className="col-xs-5 col-sm-5 col-md-3 col-lg-3"></div>
            </div>
          </div>
          */}
        </ThemeProvider>
      </StyledEngineProvider>
    </SnackbarProvider>
    );
  };
}


const addImageUrl = async (event, addImg, handleChangeLoading, whiteBoardID, enqueueSnackbar) => {
  handleChangeLoading(true);
  const file = event.target.files[0];
  if (file && file.size > 1000000) {
    handleChangeLoading(false);
    enqueueSnackbar("file size limit exceeded!(file size less than 1MB is allowed)", { variant: "error" });
    return
  }
  if (file) {
    // const fileLink = "https://" + awsmobile.aws_user_files_s3_bucket + ".s3.ap-south-1.amazonaws.com/public/";
    var currentTime = moment().toISOString();
    var filename = "whiteBoard_image_" + whiteBoardID + "_" + currentTime;
    //   await Storage.put(filename, file)
    //     .then(async result => {
    //       var storageFileName = '' + result.key;
    //       var storageFileLink = fileLink + storageFileName;
    //       console.log("file link is", storageFileLink)
    //       addImg(storageFileLink, false);
    //     })
    // }
    document.getElementById('image-to-upload').value = null;
    handleChangeLoading(false);

  }


  const readFileData = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target.result);
      };
      reader.onerror = (err) => {
        reject(err);
      };
      reader.readAsDataURL(file);
    });
  };

  //param: file -> the input file (e.g. event.target.files[0])
  //return: images -> an array of images encoded in base64
  const convertPdfToImages = async (event, addImg, changePan, handleChangeLoading, whiteBoardID, enqueueSnackbar) => {
    //console.log("here");
    //const images = [];
    handleChangeLoading(true);
    const file = event.target.files[0];
    if (file && file.size > 10000000) {
      handleChangeLoading(false);
      enqueueSnackbar("file size limit exceeded!(file size less than 10MB is allowed)", { variant: "error" });
      return
    }
    const data = await readFileData(file);
    const pdf = await pdfjs.getDocument(data).promise;
    const canvas = document.createElement("canvas");
    var pannedToNewPdf = false;
    // const fileLink = "https://" + awsmobile.aws_user_files_s3_bucket + ".s3.ap-south-1.amazonaws.com/public/";
    for (let i = 0; i < pdf.numPages; i++) {
      const page = await pdf.getPage(i + 1);
      const viewport = page.getViewport({ scale: 1 });
      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      await page.render({ canvasContext: context, viewport: viewport }).promise;
      const daturl = canvas.toDataURL("image/png")
      var currentTime = moment().toISOString();
      var fileName = "whiteBoard_image_" + whiteBoardID + "_" + currentTime;
      const blob = await fetch(daturl).then(res => res.blob())
      // await Storage.put(fileName, blob)
      //   .then(async result => {
      //     var storageFileName = '' + result.key;
      //     var storageFileLink = fileLink + storageFileName;
      //     console.log("file link is", storageFileLink)
      //     if (!pannedToNewPdf) {
      //       pannedToNewPdf = true;
      //       changePan();
      //     }
      //     addImg(storageFileLink);
      //   })

    }
    canvas.remove();
    document.getElementById('file-to-upload').value = null;
    handleChangeLoading(false);
    //console.log(JSON.stringify(images));
    //return images;
  };

  async function downloadPdf(canvas, handleChangeLoading) {
    handleChangeLoading(true);
    const screenHeight = canvas.getHeight();
    const screenWidth = canvas.getWidth();
    var objects = canvas.getObjects().slice();
    objects.sort((a, b) => {
      if (a.top < b.top)
        return -1;
      if (a.top > b.top)
        return 1;
      return 0;
    })
    console.log("sorted objects", objects);
    if (objects.length === 0)
      return
    var viewportTransform = canvas.viewportTransform
    console.log("current viewportTrasform", viewportTransform);
    var currentTop = viewportTransform[5]
    var currentLeft = viewportTransform[4];
    var minY = 0;
    var maxY = 0;
    var minX = 0;
    var maxX = 0;
    for (const obj of objects) {
      minY = Math.min(obj.top, minY);
      maxY = Math.max(obj.top + obj.height * obj.scaleY, maxY);
      minX = Math.min(obj.left, minX);
      maxX = Math.max(obj.left, maxX);
    }
    var imageSources = [];
    var totalHeight;
    if (minY % screenHeight === 0)
      totalHeight = minY;
    else {
      totalHeight = Math.floor(minY / screenHeight) * screenHeight;
    }
    var objectsIndex = 0;
    var objectsMaxIndex = objects.length;
    while (totalHeight <= maxY) {
      var leftmin = 0;
      var leftMax = screenWidth;
      var pageStartY = totalHeight;
      var pageEndY = totalHeight + screenHeight;
      while (objectsIndex < objectsMaxIndex) {
        var currentObject = objects[objectsIndex];
        var currentObjectStart = currentObject.top;
        var currentObjectEnd = currentObject.top + currentObject.height * currentObject.scaleY;
        var isInThisPage = false;
        if (currentObjectStart >= pageStartY && currentObjectStart <= pageEndY) {
          isInThisPage = true;
        }
        if (currentObjectStart < pageStartY && currentObjectEnd > pageEndY) {
          isInThisPage = true;
        }
        if (currentObjectStart < pageStartY && currentObjectEnd <= pageEndY && currentObjectEnd > pageStartY) {
          isInThisPage = true;
        }
        if (isInThisPage) {
          var leftStart = currentObject.left;
          var leftEnd = Math.floor(currentObject.left + currentObject.width * currentObject.scaleX - 1);
          var scaledLeftStart = Math.floor(leftStart / screenWidth) * screenWidth;
          var scaledLeftEnd = Math.floor(leftEnd / screenWidth) * screenWidth + screenWidth;
          leftmin = Math.min(leftmin, scaledLeftStart)
          leftMax = Math.max(leftMax, scaledLeftEnd)
          objectsIndex++;
        }
        else {
          break;
        }
      }
      console.log("leftmin", leftmin)
      console.log("multiplier", leftMax - leftmin, leftmin + currentLeft)
      var src = canvas.toDataURL({
        format: 'png',
        quality: 0.7,
        top: totalHeight + currentTop,
        left: leftmin + currentLeft,
        height: screenHeight,
        width: leftMax - leftmin,
      });
      imageSources.push(src);
      totalHeight += screenHeight;
    }
    console.log("imageSorces are", imageSources);
    var doc = MyDoc(imageSources);
    console.log("blob loading")
    const blob = await pdf(doc).toBlob();
    handleChangeLoading(false);
    var href = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = href;
    a.download = "download.pdf";
    a.click();
    console.log("the blob gernerated", blob)
  }
  /*
  const downloadCanvas = (canvas) => {
    //var imgData = canvas.toDataURL("image/jpeg", 1.0);
    var href = canvas.toDataURL({
      format: 'png',
      quality: 1
    });
    //var pdf = new jsPDF();

    //pdf.addImage(imgData, 'JPEG', 0, 0);
    //pdf.save("download.jpeg");
    //let url = imgData;
    let a = document.createElement('a');
    a.href = href;
    a.download = "download.png";
    a.click();
  }
  */
  const MyDoc = (imageSources) => (
    <StyledDocument>

      {imageSources.map((src) =>
        <Page orientation={"landscape"} pageIndex={1} style={{ display: "flex", marginTop: "auto", marginBottom: "auto" }}>
          <Image src={src}
            style={{
              objectFit: "fill",
              display: "block",
              height: "100%",
              marginTop: "30px",
              marginBottom: "30px",
              marginLeft: "auto",
              marginRight: "auto"
            }}
          />
        </Page>)}

    </StyledDocument>
  );

}
export default ((SketchFieldDemo))
