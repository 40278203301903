import { isObjectHoverable } from "../../utils";
import FabricCanvasTool from "../fabrictool";
import * as fabric from 'fabric'

class Pencil extends FabricCanvasTool {
  configureCanvas(props) {
    // console.log('goody', props)
    let cursorSize = 8;
    // console.log("the line width is" + props.lineWidth);
    if (props.lineWidth === 6) {
      cursorSize = 12;
    }
    else if (props.lineWidth === 9) {
      cursorSize = 16;
    }
    const getDrawCursor = () => {
      const circle = `
        <svg
          height="${cursorSize}"
          fill="${props.lineColor}"
          viewBox="0 0 ${cursorSize * 2} ${cursorSize * 2}"
          width="${cursorSize}"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="50%"
            cy="50%"
            r="${cursorSize}"
          />
        </svg>
      `;

      return `data:image/svg+xml;base64,${window.btoa(circle)}`;
    };
    // console.log(this._canvas)
    this._canvas.isDrawingMode = true;
    this._canvas.freeDrawingBrush = new fabric.PencilBrush(this._canvas)
    this._canvas.freeDrawingBrush.width = props.lineWidth;
    this._canvas.freeDrawingBrush.color = props.lineColor;
    this._canvas.freeDrawingCursor = `url(${getDrawCursor()}) ${cursorSize / 2} ${cursorSize / 2}, crosshair`
    this._canvas.forEachObject((o) => {
      if (isObjectHoverable(o)) {
        (o.selectable = o.evented = true)
      }
      else {
        (o.selectable = o.evented = false)
      }
    });










  }




}

export default Pencil;
