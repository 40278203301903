const ToJSONFields = [
   'objectID',
   'clientID',
   'initialState',
   'isPdf',
   'crossOrigin',
   'containerHeight',
   'containerWidth',
   'removedBy',
   '__originalState',
   'selectable',
   'evented',
   'hasBorders',
   'hasControls',
   'hasRotatingPoint',
   'lockMovementY',
   'lockMovementX',
   'peer',
   'loadingSource',
   'scaleX',
   'scaleY',
   'createdAt',
   'objectType',
   'mediaFileUrl',
   'subTargetCheck',
   'hoverCursor',
   'objectState',
   'iframeType',
   'notVisibleTo',
   'layoutManager',
   'prevState',
   'currState',
   'actionDone',
   'lastAction',
   'left',
   'top'
]

export default ToJSONFields;