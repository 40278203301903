/*eslint no-unused-vars: 0*/

import { isObjectHoverable } from "../../utils";
import FabricCanvasTool from "../fabrictool";
import * as fabric from 'fabric'

class Line extends FabricCanvasTool {
  configureCanvas(props) {
    let canvas = this._canvas;
    canvas.isDrawingMode = false;
    canvas.selection = false;
    canvas.forEachObject((o) => {
      if (isObjectHoverable(o)) {
        (o.selectable = o.evented = true)
      }
      else {
        (o.selectable = o.evented = false)
      }
    });
    this._width = props.lineWidth;
    this._color = props.lineColor;
  }

  doMouseDown(o) {
    this.isDown = true;
    let canvas = this._canvas;
    var pointer = canvas.getPointer(o.e);
    var points = [pointer.x, pointer.y, pointer.x, pointer.y];
    this.line = new fabric.Line(points, {
      strokeWidth: this._width,
      fill: this._color,
      stroke: this._color,
      originX: "center",
      originY: "center",
      selectable: false,
      evented: false,
      objectType:'line',
      dontAdd: true
    });
    canvas.add(this.line);
  }

  doMouseMove(o) {
    if (!this.isDown) return;
    let canvas = this._canvas;
    var pointer = canvas.getPointer(o.e);
    this.line.set({ x2: pointer.x, y2: pointer.y });
    this.line.setCoords();
    canvas.renderAll();
  }

  doMouseUp(o) {
    this.isDown = false;
  }

  doMouseOut(o) {
    this.isDown = false;
  }
}

export default Line;
