import React from 'react';

const Error404View = () => {
    return (
        <div style={{ position: 'fixed', top: '50%', left: '45%' }}>
            <h1>Page Not found :(</h1>
        </div>
    )
}

export default Error404View;
