import * as React from "react";
import { useRef, useState } from 'react';
import io from "socket.io-client";
import PropTypes from "prop-types";
import { createTheme, ThemeProvider } from "@mui/material";
// import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
// import Divider from "@mui/material/Divider";
// import ScienceIcon from '@mui/icons-material/Science';
// import { Storage } from 'aws-amplify';
// import InboxIcon from "@mui/icons-material/MoveToInbox";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemIcon from "@mui/material/ListItemIcon";
// import ListItemText from "@mui/material/ListItemText";
// import MailIcon from "@mui/icons-material/Mail";
// import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
// import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

// import AccountCircle from "@mui/icons-material/AccountCircle";
// import Menu from "@mui/material/Menu";
// import ChatIcon from '@mui/icons-material/Chat';
import Webrtc from "../audioVideo/Webrtc/meeting-ui";
// import Draggable from 'react-draggable';
// import awsmobile from './../aws-exports';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { Grid, Button, IconButton } from "@mui/material";
// import { RecordRTC, RecordRTCPromisesHandler } from 'recordrtc';
import { v4 as uuidv4 } from 'uuid';
// import {
//   getAudioRecordingBlobsByRoomNameAndClientID as getAudioRecordingBlobsByRoomNameAndClientIdQuery,
// } from 'src/graphql/queries';
// import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { useLocation } from "react-router-dom";
import { getQueryObject } from "../utils/common";
// import { createAudioRecordingBlob } from 'src/graphql/mutations';

// import MenuBookIcon from '@mui/icons-material/MenuBook';
// import DvrIcon from '@mui/icons-material/Dvr';
// import RoomPage from '../audioVideo/LiveKitCustom';
import PeriodicTable from "./PeriodicTable"
import { getSession, getSlate, getSlateMessages, getUser, markMessageAsDeliveredByUser, markMessageAsReadByUser, uploadWhiteboardFiles } from "../whiteboard/src/service/slateService";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Peer } from "peerjs";
import axios from 'axios';
import debounce from 'lodash.debounce';
import MousePointer from "../audioVideo/Webrtc/MousePointer"
import { COOKIE_EXPIRATION_DAYS, FRONTEND_URL, SCREENSHARING_DISABLED_EVENT_NAME, THEME_COLOR, THEME_HOVER_COLOR, UPLOAD_API_URL } from "../whiteboard/src/config/constants";
import TabStatus from "./TabStatus";
import { capitalizeFirstAlphabet, findDisplayNamebyId, findNameFromSlateUsingUserId, findNamebyId, recordSessionEvents, removeDuplicates } from "../components/helperFunctions";
import FeedbackDialog from "./FeedbackForm";
import html2canvas from "html2canvas";
import CloseIcon from "@mui/icons-material/Close";
import { checkAndMarkAsDelivered, getCookie, isChrome, setCookie } from "../components/utils";
import MediaPlayer from "./MediaPlayer";
import AddParticipants from "./AddParticipants";
// import ScreenshotWindow from "./ScreenshotWindow";
import SlatePad from "./SlatePad";
import Weblink from "./Weblink";
import IframeBox from "./IframeBox";
import PdfViewer from "./PdfViewer";
import { isToolbarEnabledAtom } from "../components/jotai/atom/isToolbarEnabledAtom";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import Chat from "../Chat/Chat";
import { messageArrayAtom } from "../components/jotai/atom/messageArrayAtom";
import { messageDataReceivedAtom, updateMessageNotificationAtom } from "../components/jotai/atom/notificationAtom";
import { isChatOpenAtom } from "../components/jotai/atom/isChatOpenAtom";
import { useAtomCallback } from 'jotai/utils'
import { sessionMembersArrayAtom } from "../components/jotai/atom/sessionMembersArrayAtom";
import LiveKitNew from "../audioVideo/LiveKitNew/liveKitNew";
import EndSessionStepperForm from "./EndSessionStepperForm";
import { useStartAudio } from "@livekit/components-react";
import { isPanEnabledAtom } from "../components/jotai/atom/isPanEnabledAtom";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { keyframes } from '@mui/system';
import { isCameraEnabledAtom } from "../components/jotai/atom/isCameraEnabledAtom";
import { isMicEnabledAtom } from "../components/jotai/atom/isMicEnabledAtom";
import { isScreenSharingEnabledAtom } from "../components/jotai/atom/isScreenSharingEnabledAtom";
import { isRecordingEnabledAtom } from "../components/jotai/atom/isRecordingEnabledAtom";
import Timer from "./Timer";
import { openTimerBoxAtom } from "../components/jotai/atom/openTimerBoxAtom";
import { timerIdAtom } from "../components/jotai/atom/timerIdAtom";
import { isVideoTileFullscreenAtom } from "../components/jotai/atom/isVideoTileFullscreenAtom";
import WhiteBoard from "../whiteboard/whiteboard2";
import { slateDataReceivedAtom } from "../components/jotai/atom/slateDataReceivedAtom";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

window.lesson = {}

// async function createAudioBlobFunc(whiteBoardID, blobLink, blobBatchID, blobBatchNumber, recordingStartTime, duration, clientID) {
//   console.log("createAudioRecordingBlob props", whiteBoardID, blobLink, blobBatchID, blobBatchNumber, recordingStartTime, duration)
//   var roomName = whiteBoardID;
//   await API.graphql(
//     {
//       query: createAudioRecordingBlob,
//       variables: {
//         input: {
//           roomName,
//           blobLink,
//           blobBatchID,
//           blobBatchNumber,
//           recordingStartTime,
//           duration,
//           clientID
//         }
//       }
//       , authMode: 'API_KEY'
//     }
//   )
//     .then(res => console.log("result of createAudioRecordingBlob", res))
//     .catch(err => console.error("createAudioRecordingBlob", err));
// }
// function downloadBlob(blob, filename) {
//   const url = URL.createObjectURL(blob);
//   const a = document.createElement('a');
//   a.href = url;
//   a.download = filename || 'download';
//   const clickHandler = () => {
//     setTimeout(() => {
//       URL.revokeObjectURL(url);
//       a.removeEventListener('click', clickHandler);
//     }, 150);
//   };
//   a.addEventListener('click', clickHandler, false);
//   a.click();
//   return a;
// }
function Classroom(props) {
  // const [audioVideoOpen, setAudioVideoOpen] = React.useState(false);
  const moment = extendMoment(Moment);
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const [recordingTime, setRecordingTime] = React.useState(0);
  // const [isRecording, setIsRecording] = React.useState(false);
  // const [isRecordingEnabled, setIsRecordingEnabled] = React.useState(false);
  // const [recordingInterval, setRecordingInterval] = React.useState(null);
  // const [recorder, setRecorder] = React.useState(null)
  // const [blobBatchID, setBlobBatchID] = React.useState(uuidv4());
  // const [chunks, setChunks] = React.useState([]);
  const { search } = useLocation();
  // const [showWhiteBoard, setShowWhiteBoard] = React.useState(true);
  const [roomName, setRoomName] = React.useState("");
  // const [recordVideo,setRecordVideo] = React.useState(null)
  // const [src, setSrc] = React.useState(null)
  // const [uploadSuccess, setUploadSuccess] = React.useState(null)
  // const [uploading, setUploading] = React.useState(false)
  const [isTeacher, setIsTeacher] = React.useState(false);
  const [shouldSendDataToAllPeers, setShouldSendDataToAllPeers] = React.useState(true);
  const [isZoomEnabled, setIsZoomEnabled] = React.useState(false);
  const [isDeleteAllEnabled, setIsDeleteAllEnabled] = React.useState(false);
  const [userName, setUserName] = React.useState("");
  const [isMuteAllEnabled, setIsMuteAllEnabled] = React.useState(false);
  const [autoRecord, setAutoRecord] = React.useState(false);
  const [whiteBoardID, setWhiteBoardID] = React.useState("");
  const [previousWhiteBoardID, setPreviousWhiteBoardID] = React.useState("");
  const [clientID, setClientID] = React.useState(null);
  // const [audioVideoPlatform, setAudioVideoPlatform] = React.useState("jitsi");
  // const screenRef = React.useRef(null);
  const [screenShare, setScreenShare] = React.useState(false);
  const theme = createTheme();
  const [isWhiteboard, setIsWhiteboard] = React.useState(false);
  const [filesDownloadFolder, setFilesDownloadFolder] = React.useState('');
  const whiteBoardRef = useRef(null);
  const [liveKitRoom, setLiveKitRoom] = React.useState(null);
  // const [isPublishWhiteBoardDataViaLiveKit, setIsPublishWhiteBoardDataViaLiveKit] = React.useState(false);
  const [accessToken, setAccessToken] = React.useState(null);
  const [slateID, setSlateID] = React.useState(null);
  const [openJoinNotification, setOpenJoinNotification] = React.useState(false);
  const [openLeftNotification, setOpenLeftNotification] = React.useState(false);
  const [joiningUser_Name, setJoiningUser_Name] = React.useState('');
  const [leavingUser_Name, setLeavingUser_Name] = React.useState('');
  const [openDeactivateDialog, setOpenDeactivateDialog] = React.useState(false);
  const [openSharedFileDialog, setOpenSharedFileDialog] = React.useState(false);
  const [sharedFileLink, setSharedFileLink] = React.useState('');
  const [sharedFileBy, setSharedFileBy] = React.useState('');
  const [sharedFileName, setSharedFileName] = React.useState('');
  const [isSessionInactive, setIsSessionInActive] = React.useState(false);
  const [isViewer, setIsViewer] = React.useState(false);
  const [screenRecording, setScreenRecording] = React.useState(false);
  const [isTourEnabled, setIsTourEnabled] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [sessionReport, setSessionReport] = React.useState(false)
  const [isLivekitFullscreen, setIsLivekitFullscreen] = React.useState(false)
  const [isWhiteboardPlayer, setIsWhiteboardPlayer] = useState(false)

  const socketRef = useRef(null);
  const slateRef = useRef(null)
  const peerJSRef = useRef(null)
  const usersInSessionRef = useRef(null);
  const peerJSConnectionRefArr = useRef([]);
  const canvasWidthRef = useRef(null);
  const canvasHeightRef = useRef(null);
  const mousePointerRef = useRef(null);
  // const tabStatusRef = useRef(null);
  const mediaPlayerRef = useRef(null);
  const iframeBoxRef = useRef(null)
  const screenshotWindowRef = useRef(null);
  const slatePadRef = useRef(null);
  const pdfViewerRef = useRef(null);
  const weblinkRef = useRef(null)
  const feedbackFormRef = useRef(null)
  const isTextFocused = useRef(null)
  // const [openTabStatus, setOpenTabStatus] = React.useState(false);
  // const RecordingMuteRef = React.useRef();
  // const RecordingUnmuteRef = React.useRef();
  const recordingTimeRef = React.useRef({});
  var sendDataToAllPeersWebRtc = React.useRef();
  var resetCoordsWebRtc = React.useRef();
  // const unReadMessageCount = useSelector((state) => state.chat.totalUnReadMessageCount);
  var isAddMessageFocusRef = React.useRef(false);
  const sessionDataRef = React.useRef(null)

  const [openAddParticipantForm, setOpenAddParticipantForm] = React.useState(false);
  const [isPrejoin, setIsPrejoin] = React.useState(false)

  //all states used below are global states using jotai atoms
  const setSlateDataReceived = useSetAtom(slateDataReceivedAtom)
  const setIsToolbarEnabled = useSetAtom(isToolbarEnabledAtom);
  const setMessageArray = useSetAtom(messageArrayAtom);
  const setUpdateMessageNotification = useSetAtom(updateMessageNotificationAtom)
  const setMessageDataReceived = useSetAtom(messageDataReceivedAtom)
  const setSessionMembersArray = useSetAtom(sessionMembersArrayAtom)
  const setIsPanEnabled = useSetAtom(isPanEnabledAtom)
  const setIsCameraEnabled = useSetAtom(isCameraEnabledAtom)
  const setIsMicEnabled = useSetAtom(isMicEnabledAtom)
  const setIsScreenSharingEnabled = useSetAtom(isScreenSharingEnabledAtom)
  const setIsRecordingEnabled = useSetAtom(isRecordingEnabledAtom)
  const setOpenTimerBox = useSetAtom(openTimerBoxAtom);
  const setTimerId = useSetAtom(timerIdAtom)
  const setIsVideoTileFullscreen = useSetAtom(isVideoTileFullscreenAtom)


  const checkIsChatOpen = useAtomCallback(
    React.useCallback((get) => {
      const value = get(isChatOpenAtom)
      return value
    }, [])
  )

  const getSessionMemberList = useAtomCallback(
    React.useCallback((get) => {
      const value = get(sessionMembersArrayAtom)
      return value
    }, [])
  )

  // const isChatOpen = useAtomCallback(
  //   (get,set) => {
  //     const value = get(isChatOpenAtom)
  //     set(isChatOpenAtom)
  //   }
  // )
  // const handleChangeRoomName = (val) => {
  //   setRoomName(val);
  // }
  React.useEffect(() => {

    const query = getQueryObject(search);
    var {

      isMuteAllEnabled,
      autoRecord,
      previousWhiteBoardID,
      whiteBoardID,
      // audioVideoPlatform,
      filesDownloadFolder,
      accessToken,
      isViewer,
      screenRecording,
      isTourEnabled,
      isPrejoin,
      sessionReport,
      isLivekitFullscreen,
      isWhiteboardPlayer
    } = query;
    setFilesDownloadFolder(filesDownloadFolder);
    setIsTourEnabled(isTourEnabled === 'true')
    setScreenRecording(screenRecording === 'true')
    setIsViewer(isViewer === 'true');
    setIsMuteAllEnabled(isMuteAllEnabled === 'true');
    setIsMuteAllEnabled(isMuteAllEnabled === 'true');
    setAutoRecord(autoRecord === 'true');
    setPreviousWhiteBoardID(previousWhiteBoardID);
    setWhiteBoardID(whiteBoardID);
    setIsWhiteboard(true)
    setRoomName(whiteBoardID);
    setAccessToken(accessToken ? accessToken : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiIyYzNhYzZmNy01OTNjLTQ5YWYtODQ3YS0wNmNiMjM0NTU0ZTgiLCJ1c2VyTmFtZSI6ImFhc2h3aW4ubWFsaWtAZ21haWwuY29tIiwidG9rZW5UeXBlIjoibG9naW4iLCJpYXQiOjE2NzY0NTMxODAsImV4cCI6MTcwNzk4OTE4MH0.1GSPDu9MMdK8TWQwfpBnqAZdkDvqt3R7obOQPK-6_1E');
    setIsPrejoin(isPrejoin === 'true')
    setSessionReport(sessionReport === 'true');
    setIsLivekitFullscreen(isLivekitFullscreen === 'true')
    setIsWhiteboardPlayer(isWhiteboardPlayer === 'true')
    // setAudioVideoPlatform(audioVideoPlatform);
  }, [search])

  // console.log('lesson rerender')

  React.useEffect(() => {
    if (!isViewer && clientID && whiteBoardID && !isWhiteboardPlayer) {

      setTimeout(() => {
        initialisePeers(whiteBoardID, clientID);
      }, 5000);
      initialiseSocket(socketRef, whiteBoardID);
      setTimeout(() => {
        let lessonEvent = {
          eventType: 'followScreenEvent',
          eventData: {
            'livekitState': 'sideview',
            'sentBy': userName
          }
        }
        console.log('screensharing:off')
        recordSessionEvents(accessToken, whiteBoardID, clientID, SCREENSHARING_DISABLED_EVENT_NAME, null)
        sendLessonEventViaWebSocket(lessonEvent)
      }, [3000])
      /**
       * Every 5 seconds we check if user is focused on the current tab or not
         and send the status as online or offline respectively
       */

      setInterval(() => {
        // console.log('hey')
        let tabStatusEvent;
        let displayName = '';
        if (slateRef && slateRef.current) {
          if (slateRef.current.creator_id === clientID) {
            if (slateRef.current.creatorDetails.firstName !== '' && slateRef.current.creatorDetails.lastName !== '') {
              displayName = capitalizeFirstAlphabet(slateRef.current.creatorDetails.firstName) + " " + capitalizeFirstAlphabet(slateRef.current.creatorDetails.lastName)
            }
            else {
              displayName = slateRef.current.creatorDetails.email
            }

          }
          else if (slateRef.current.hosts.includes(clientID)) {
            displayName = findNamebyId(slateRef.current.hostDetails, clientID)
          }
          else if (slateRef.current.participants.includes(clientID)) {
            displayName = findNamebyId(slateRef.current.participantDetails, clientID)
          }
        }
        if (document.hasFocus()) {
          // console.log('InFocus')
          tabStatusEvent = {
            'sentBy': userName,
            'tabStatus': 'online',
            'displayName': displayName
          }
        }
        else {
          // console.log('OutOfFocus')
          tabStatusEvent = {
            'sentBy': userName,
            'tabStatus': 'offline',
            'displayName': displayName
          }
        }

        socketRef.current.emit("tabStatus", whiteBoardID, tabStatusEvent)
      }, 5000)


      /**
       * If the user is a teacher then screenshots of the whiteboard are uploaded every 15 minutes which can be
         later viewed from the dashboard
       */
      if (isTeacher) {
        setInterval(() => {
          const screenshotTarget = document.getElementById('lesson-box-screenshot')

          html2canvas(screenshotTarget, {
            scale: 0.5
          }).then(async (canvas) => {
            const daturl = canvas.toDataURL("image/png")
            let currentTime = moment().toISOString();
            var uploadFileName = 'screenshot_' + currentTime + '.png';
            const blob = await fetch(daturl).then(res => res.blob())
            const imageData = new FormData();
            // console.log(blob)
            const myFile = new File([blob], uploadFileName, {
              type: blob.type,
            });
            imageData.append('file', myFile);
            let status, res;
            try {
              res = uploadWhiteboardFiles(accessToken, whiteBoardID, 'screenshots/', imageData);
              status = res.status
            }

            catch (err) {
              console.log(err);
              status = err.response.status;
            }
            // console.log(index)
            if (status === 200) {

            }
            else {
              // console.log(status)
            }
            const storageFileLink = UPLOAD_API_URL + "uploads/whiteBoardStorage/" + whiteBoardID + '/screenshots/' + uploadFileName
            // console.log(storageFileLink)
          });

        }, 900000)
      }

    }

  }, [whiteBoardID, clientID])

  //Code for checking first time user to show tour or not
  // React.useEffect(() => {

  //   if (clientID && whiteBoardID) {
  //     checkFirstTimeUser()
  //   }

  // }, [whiteBoardID, clientID])

  const checkFirstTimeUser = async () => {
    const res = await getCookie('LESSON_FIRST_TIME_USER')
    if (res === null) {
      setIsTourEnabled(true)
      setInterval(() => {
        setCookie('LESSON_FIRST_TIME_USER', false, COOKIE_EXPIRATION_DAYS)
      }, [5000])

    }
  }

  React.useEffect(() => {
    if (whiteBoardID) {
      initialize();
    }
  }, [whiteBoardID, isViewer])

  React.useEffect(() => {
    // if (!isChrome()) {
    //   setTimeout(() => {
    //     alert('Please use the chrome browser for a better user experience!')
    //   }, [3000])
    // }
    // const handleTabClose = event => {
    //   event.preventDefault();

    //   console.log('beforeunload event triggered');

    //   return (event.returnValue =
    //     'Are you sure you want to exit?');
    // };

    // window.addEventListener('beforeunload', handleTabClose);

    // return () => {
    //   window.removeEventListener('beforeunload', handleTabClose);
    // };
  }, [])



  function updateMousePosition(xCoord, yCoord, peerID, canvasWidth, canvasHeight, color, name, pencilWidth) {
    mousePointerRef.current.setMouseCoords(xCoord, yCoord, peerID, canvasWidth, canvasHeight, color, name, pencilWidth)

  }
  const debouncedUpdateMousePostion = debounce(updateMousePosition, 1);


  function sendDataToAllPeerJSUsers(e) {
    // console.log("event" + e);
    try {
      //get the canvas height and widht even if there are no peers
      var w = e.width
      var h = e.height;
      canvasWidthRef.current = w;
      canvasHeightRef.current = h;
      let displayName;
      if (window.displayName) {
        displayName = window.displayName;
        // console.log(displayName)
      }
      else {
        if (slateRef && slateRef.current) {
          if (slateRef.current.creator_id === clientID) {
            if (slateRef.current.creatorDetails.firstName !== '' && slateRef.current.creatorDetails.lastName !== '') {
              displayName = capitalizeFirstAlphabet(slateRef.current.creatorDetails.firstName)
            }
            else {
              displayName = slateRef.current.creatorDetails.email.substring(0, 2)
            }

          }
          else if (slateRef.current.hosts.includes(clientID)) {
            displayName = findDisplayNamebyId(slateRef.current.hostDetails, clientID)
          }
          else if (slateRef.current.participants.includes(clientID)) {
            displayName = findDisplayNamebyId(slateRef.current.participantDetails, clientID)
          }
        }
        else {
          displayName = userName;
        }
        window.displayName = displayName;
      }

      for (let index = 0; index < peerJSConnectionRefArr.current.length; index++) {
        const conn = peerJSConnectionRefArr.current[index];
        //     console.log("x value is " + e.pageX)

        try {
          // console.log(displayName)
          conn.send(e.pageX / w + "$" + e.pageY / h + "$" + e.pencil + "$" + e.mouseDown + "$" + e.pencilWidth + "$" + e.pencilColor + "$" + e.myPathUUID + "$" + e.width + "$" + e.height + "$" + displayName);

        } catch (e) {
          //   console.log(e)
        }

      };
    } catch (error) {

      console.log(error)
    }





    //console.log("peerjs broadcast" +peerJSConnectionRefArr.current.length)






    //console.log("sending data to peerjs " + conn.peer)
    //   conn.send("data from  " + index + "   " + data)



  }




  async function initialisePeers(sessionId, clientID) {
    // console.log("peer now initialising peer")
    const myPeerId = getMyPeerId(sessionId, clientID)
    // console.log(" returning getting peers for session", myPeerId)
    usersInSessionRef.current = await getPeers(sessionId)
    // console.log("total peers in session " + usersInSessionRef.current.length)
    // console.log("the peers for the session are  " + usersInSessionRef.current)
    connectToPeerServer(myPeerId);


    peerJSRef.current.on('open', function (id) {
      // console.log("peer we are now inside the open event  ")
      // console.log('My peer ID is: peerjs' + id);
      // console.log('otherUsers in session peerjs ' + JSON.stringify(usersInSessionRef.current));

      if (usersInSessionRef.current) {
        //console.log("now trying to connec peerjs" + usersInSessionRef.current.length )
        for (let index = 0; index < usersInSessionRef.current.length; index++) {
          const peerId = usersInSessionRef.current[index]
          if (peerId !== myPeerId) {


            // console.log("now connecting peerjs with " + peerId)
            // console.log("peer id " + JSON.stringify(peerId))
            const conn = peerJSRef.current.connect(peerId, {
              reliable: true,
              metadata: { userId: clientID, id: id, userName: userName, peerId: myPeerId }
            });
            // console.log("peerjs connection established" + conn)
            conn.on('open', function () {
              // console.log("connection got opened ")
              // Receive messages
              ////console.log("all connections for peerjs  " + JSON.stringify(peerJSRef.current.connections))
              //console.log("peerjs now connected with  this person " + conn.peer)
              //console.log("peerjs adding connection because we called with the peer " + conn.peer )
              peerJSConnectionRefArr.current.push(conn);
              // console.log("peerJSconnections" + peerJSConnectionRefArr)


              // Send messages
              //console.log("peerjs sending data to " + "user "  + conn.peer + " with connection details " + (conn))
              // conn.send('Hello!');
            });
            conn.on('data', function (messageStr) {
              //    console.log("data is received peerjs on the caller side from the peer " + conn.peer)
              //console.log("connection metadata peerjs" + JSON.stringify(conn.metadata))
              //when we receive data from the peer we set the mouse
              setMousePointer(conn.peer, messageStr)


            });
            conn.on('error', function (err) {
              //console.log(" peerjs error in connection on the caller side" + conn.peer)
              //console.log("peer")
              //console.log(JSON.stringify(err))

              //conn.close();
            });
            conn.on('close', function (data) {
              //console.log("peerjs connection closed on the caller side")
            });

          }
        }


      }
    });

    peerJSRef.current.on('connection', function (conn) {
      // console.log("connection metadata received peerjs" + JSON.stringify(conn.metadata))
      //console.log("peerjs now connected with  this person received" + conn.peer)
      //console.log("peerjs adding connection because we received with the peer " + conn.peer )
      peerJSConnectionRefArr.current.push(conn);

      conn.on('data', function (data) {
        //    console.log("data is received peerjs on the receiver side from the peer " + conn.peer)
        setMousePointer(conn.peer, data)
      });
      conn.on('error', function (err) {
        //console.log(" peerjs error in connection on the receiver side " + conn.peer)
        // console.log(JSON.stringify(err))
      });
      conn.on('close', function (data) {
        // console.log("peerjs connection closed on the receiver side")
      });


    });


  }


  function setMousePointer(id, messageStr) {
    if (messageStr.includes("$")) {


      let xyCoord = messageStr.split("$")
      //  console.log("x co " + Math.ceil(xyCoord[0]*w))
      //  console.log("y co " + Math.ceil(xyCoord[1]*h))
      //  createPath(Math.ceil(xyCoord[0]*w),Math.ceil(xyCoord[1]*h),Math.ceil(xyCoord[0]*w)+1,Math.ceil(xyCoord[1]*h)+1,"green",6)  ;


      //  receivedDataFromPeer(id, xyCoord)

      // console.log(mousePointerRef)
      // console.log("current is " + mousePointerRef.current);
      if (mousePointerRef && mousePointerRef.current) {


        debouncedUpdateMousePostion(xyCoord[0], xyCoord[1], id, canvasWidthRef.current, canvasHeightRef.current, xyCoord[5], xyCoord[9], xyCoord[4]);
        // mousePointerRef.current.setMouseCoords(xyCoord[0], xyCoord[1],id,canvasWidthRef.current, canvasHeightRef.current,xyCoord[5])
      }
    }
  }


  async function getPeers(sessionId) {
    let res;
    try {
      res = await axios.get('https://peers.teachmatter.com/getPeersBySession', {
        params: {
          sessionId: sessionId
        }
      })
    }
    catch (err) {
      console.log(err)
      return null
    }

    // console.log("returning the peer data now " + res.data)
    return res.data
  }




  // function isAlreadyConnectedToPeerJSUser(socketId) {

  //   for (let index = 0; index < peerJSConnectionRefArr.current.length; index++) {
  //     const conn = peerJSConnectionRefArr.current[index];
  //     if (conn) {
  //       if (conn.peer == socketId && conn.open) {
  //         //console.log("we are already connected to this peerjs")
  //         return true

  //       }

  //     }

  //   }
  //   //console.log("we are not connected to this peerjs")
  //   return false
  // }

  function connectToPeerServer(id) {
    // console.log("trying to connect to the peer server with id " + id)
    peerJSRef.current = new Peer(id
      , {
        host: "peers.teachmatter.com",
        // port: 3008,
        secure: true,
        //debug:3,
        config: {
          'iceServers': [
            // { url: 'stun:stun.l.google.com:19302' },
            { url: "turn:coturn.slatemates.in:3477?transport=tcp", "username": "turnpublic", "credential": "turnpublic" }
          ]
        } /* Sample servers, please use appropriate ones */
      });


  }

  // function listOtherUsersInSession(allUsersInSession) {


  // }



  function getMyPeerId(sessionId, clientID) {
    // for (let index = 0; index < usersInSession.length; index++) {
    //   const user = usersInSession[index];
    //   //console.log("peerjs getting my peer id from user" + JSON.stringify(user))
    //   if(user.socketId == socketId ){
    //     return user.userData.peerId
    //   }

    // }

    // console.log("returning my peer id for session" + sessionId)
    return sessionId + "session_id-" + clientID
  }

  const getUserState = (sessionState, clientID, isEvent = false) => {
    // console.log(sessionState)
    if (sessionState.hosts) {
      for (let i = 0; i < sessionState.hosts.length; ++i) {
        if (clientID === sessionState.hosts[i].id) {
          setUserPermissions(sessionState.hosts[i].permissions, isEvent)
        }
      }
    }
    if (sessionState.participants) {
      // console.log('condition fulfilled')
      for (let i = 0; i < sessionState.participants.length; ++i) {
        // console.log('condition fulfilled', clientID, sessionState.participants[i].id)
        if (clientID === sessionState.participants[i].id) {
          setUserPermissions(sessionState.participants[i].permissions, isEvent)
        }
      }
    }
    if (sessionState.timer) {
      // console.log('condition fulfilled')
      if (sessionState.timer.isActive) {
        setOpenTimerBox(true)
        setTimerId(sessionState.timer.id)
      }
      else {
        setOpenTimerBox(false)
      }
    }
  }

  const setUserPermissions = (permissions, isEvent) => {
    //mic permissions
    if (permissions.micEnabled) {
      setIsMicEnabled(true)
    }
    else {
      if (isEvent) {
        if (!window.lesson.isAudioMuted) {
          if (document.getElementById('mic-button-livekit')) {
            document.getElementById('mic-button-livekit').click()
          }
        }
      }
      setIsMicEnabled(false)
    }

    //pan permissions
    if (permissions.panEnabled) {
      setIsPanEnabled(true)
      window.lesson.isPanEnabled = true;
    }
    else {
      setIsPanEnabled(false)
      window.lesson.isPanEnabled = false;
      if (isEvent) {
        if (whiteBoardRef.current.currentTool.current === "Pan") {
          whiteBoardRef.current._customToolbar.current._selectTool({ target: { value: "DefaultTool" } })
        }
      }
    }

    //toolbar permissions
    // console.log('toolbar permission', permissions.toolBarEnabled)
    if (permissions.toolBarEnabled) {
      setIsToolbarEnabled(true)
    }
    else {
      setIsToolbarEnabled(false)
    }

    //cameraenabled
    if (permissions.cameraEnabled) {
      setIsCameraEnabled(true)
    }
    else {
      if (isEvent) {
        if (!window.lesson.isVideoMuted) {
          if (document.getElementById('camera-button-livekit')) {
            document.getElementById('camera-button-livekit').click()
          }
        }
      }
      setIsCameraEnabled(false)
    }

    // console.log('heyy1', permissions.recordingEnabled)
    if (permissions.recordingEnabled && userName !== 'recordbot') {
      // console.log('heyy1 setting', permissions.recordingEnabled)
      setIsRecordingEnabled(true)
    }
    else {
      setIsRecordingEnabled(false)
    }

    if (permissions.screenSharingEnabled) {
      setIsScreenSharingEnabled(true)
    }
    else {
      setIsScreenSharingEnabled(false)
    }

    if (permissions.deleteAllEnabled) {
      // console.log('heyy delete all true')
      setIsDeleteAllEnabled(true)
    }
    else {
      setIsDeleteAllEnabled(false)
    }

    if (permissions.zoomEnabled) {
      // console.log('heyy delete all true')
      setIsZoomEnabled(true)
    }
    else {
      setIsZoomEnabled(false)
    }

  }

  const checkIfHost = (hostArray, userId) => {
    let flag = true
    for (let i = 0; i < hostArray.length; ++i) {
      if (userId === hostArray[i].id) {
        setIsTeacher(true)
        flag = false
      }
    }
    if (flag) {
      setIsTeacher(false)
    }
  }

  // console.log('heyy1 lesson', isRecordingEnabled)
  /**
   * Checks if session is still active or not
   * initialises websockets
   */
  const initialize = async () => {

    // setIsLoading(true)
    const user = await getUserData(accessToken)
    window.lesson.user = user
    window.lesson.followSlatePad = false;
    window.lesson.followScreen = false;
    if (user.username === 'recordbot') {
      window.lesson.followSlatePad = true;
      window.lesson.followScreen = true;
    }
    console.log('heyy user', user)
    if (user) {
      const session = await getSessionByID(whiteBoardID)
      if (session.state) {
        checkIfHost(session.state.hosts, user.id)
      }
      setUserName(user.username)
      setClientID(user.id)
      //console.log('the session is', session);
      sessionDataRef.current = session
      let sessionState = session.state
      window.lesson.sessionState = sessionState;
      window.lesson.accessToken = accessToken;
      window.lesson.session = session
      //On opening lesson we check if the session is active or not and then proceed accordingly

      // console.log('heyy', session)
      // console.log('heyy', window.lesson.sessionState)
      if (session.is_active) {
        //if session is active we check if the slate has any participants or not and if participants are 0 we show a popup to add
        //participants to the slate
        if (session.slate_id) {
          slateRef.current = await getSlateByID(session.slate_id)
          window.lesson.slate = slateRef.current
        }
        // console.log('last of')
        setSlateID(session.slate_id)
        // console.log('first of')
        if (sessionState) {
          getUserState(sessionState, user.id, false)
        }
        if (slateRef.current) {
          let res, status
          try {
            res = await getSlateMessages(accessToken, slateRef.current.id)
            status = res.status
          }
          catch (err) {
            // console.log(err)
            status = err.response.status
          }
          if (status === 200) {
            let messageArrayBySlate = res.data.messages
            messageArrayBySlate.sort((a, b) => {
              return new Date(a.updated_at) - new Date(b.updated_at);
            })
            setMessageArray(messageArrayBySlate)
            checkAndMarkAsDelivered(accessToken, session.slate_id, messageArrayBySlate, user)
          }
        }
        setSlateDataReceived(true)
        if (slateRef.current && (slateRef.current.participantDetails.length === 0 && slateRef.current.hostDetails.length === 0)) {
          if (slateRef.current.creatorDetails.user_id === user.id) {
            setOpenAddParticipantForm(true)
          }
        }
        // setSlate(response);
        // console.log('the slate is rerendering')
      }
      else {

        if (!isViewer && !isWhiteboardPlayer) {
          setSlateID(session.slate_id)
          setIsSessionInActive(true)
        }
        else {
          if (session.slate_id) {
            slateRef.current = await getSlateByID(session.slate_id)
            window.lesson.slate = slateRef.current
          }
          setSlateID(session.slate_id)
        }
      }
      // setIsLoading(false)
    }
  }

  const getUserData = async (token) => {
    // console.log(token)
    if (token !== null) {
      let status, res;
      try {
        res = await getUser(token);
        status = res.status
      }
      catch (err) {
        console.log(err)
        // status = err.response.status
      }
      // console.log(res);
      if (status === 200) {
        // console.log(res.data.user)
        return (res.data.user);

      }
      else {
        // console.log(res)
      }

    }
    return null;

  };

  /**
   *
   * @param {uuid} whiteboardID - unique id of the current session
   * @returns {object} - returns the session data for the specific whiteboard ID
   */
  const getSessionByID = async (whiteboardID) => {
    let token = accessToken;

    let res, status
    try {
      res = await getSession(token, whiteboardID)
      status = res.status
    }
    catch (err) {
      console.log(err)
      status = err.response.status
    }
    if (status === 200) {
      //console.log(res.data)
      return res.data
    }



    // console.log(userData.data.user.id)

  }

  /**
   *
   * @returns {object} - returns slate data by using slateID obtained from url params
   */
  const getSlateByID = async (slateId) => {
    let token = accessToken;
    if (token !== null) {
      if (slateId) {
        const SlateID = {
          "id": slateId
        }
        // console.log(SlateID)
        let res, status
        try {
          res = await getSlate(token, SlateID)
          status = res.status
        }
        catch (err) {
          console.log(err)
          status = err.response.status
        }
        if (status === 200) {
          //    console.log(res.data)
          return res.data
        }

      }

      // console.log(userData.data.user.id)

    }
  }


  /**
  *
  * @param {array} objectArray - host details,participant details or creator details
  * @param {uuid} objectId - user id of the user
  * @returns {string} - email of the user is returned
  * This function finds emailof user in hostdetails or participant details by using the id of the user
  */

  const findEmailbyId = (objectArray, objectId) => {
    for (let i = 0; i <= objectArray.length - 1; ++i) {
      if (objectArray[i].user_id === objectId) {
        return objectArray[i].email
      }
    }
    return '';
  }

  function initialiseSocket(socketRef, whiteBoardID) {

    //  console.log(accessToken)
    socketRef.current = io.connect("https://websocket.teachmatter.com",
      {
        transports: ["websocket"],
        withCredentials: false,
        extraHeaders: {
          'my-custom-header': 'abcd'
        },
        auth: {
          token: accessToken
        }
      }
    );
    socketRef.current.on("connect", () => {
      // console.log("connected to 3003 server " + socketRef.current.id)
      // console.log("connection time" + Date().toLocaleString());
      socketRef.current.emit("joinSession", roomName)
      socketRef.current.emit("usersInSession", roomName);


    });
    socketRef.current.on("usersInSession", (usersInSession) => {
      // console.log('heyy', usersInSession)
      // console.log(JSON.stringify("usersInSession" + JSON.stringify(usersInSession)))
      usersInSessionRef.current = usersInSession
      // console.log('users are', usersInSession)
      let tabStatusArray = []
      for (let i = 0; i < usersInSession.length; ++i) {
        // if (usersInSession[i].userData.userName !== userName) {
        let displayName = '';
        if (slateRef.current && slateRef.current.creator_id === usersInSession[i].userData.userId) {
          if (slateRef.current.creatorDetails.firstName !== '' && slateRef.current.creatorDetails.lastName !== '') {
            displayName = capitalizeFirstAlphabet(slateRef.current.creatorDetails.firstName) + " " + capitalizeFirstAlphabet(slateRef.current.creatorDetails.lastName)
          }
          else {
            displayName = slateRef.current.creatorDetails.email
          }

        }
        else if (slateRef.current && slateRef.current.hosts.includes(usersInSession[i].userData.userId)) {
          displayName = findNamebyId(slateRef.current.hostDetails, usersInSession[i].userData.userId)
        }
        else if (slateRef.current && slateRef.current.participants.includes(usersInSession[i].userData.userId)) {
          displayName = findNamebyId(slateRef.current.participantDetails, usersInSession[i].userData.userId)
        }

        let userData = {
          'emailID': usersInSession[i].userData.userName,
          'tabStatus': '',
          'displayName': displayName

        }
        tabStatusArray.push(userData)
        // }
      }
      // console.log(tabStatusArray)
      handleSessionMembersArray(tabStatusArray)
    })
    socketRef.current.on('userJoined', (userId) => {
      // //console.log("user joined 3003 with userid " + userId)
      // socketRef.current.emit("usersInSession",whiteBoardID)

      // console.log(userId)
      if (userId !== clientID) {
        if (slateRef.current.creator_id === userId) {
          if (slateRef.current.creatorDetails.firstName !== '' && slateRef.current.creatorDetails.lastName !== '') {
            setJoiningUser_Name(capitalizeFirstAlphabet(slateRef.current.creatorDetails.firstName) + " " + capitalizeFirstAlphabet(slateRef.current.creatorDetails.lastName))
            // addToSessionMembersArray(slateRef.current.creatorDetails.email)
          }
          else {
            setJoiningUser_Name(slateRef.current.creatorDetails.email)
            // addToSessionMembersArray(slateRef.current.creatorDetails.email)
          }

        }
        else if (slateRef.current.hosts.includes(userId)) {
          //console.log(findNamebyId(slateRef.current.hostDetails, userId))
          setJoiningUser_Name(findNamebyId(slateRef.current.hostDetails, userId))
          // addToSessionMembersArray(slateRef.current.hostDetails.email)
        }
        else if (slateRef.current.participants.includes(userId)) {
          //console.log(findNamebyId(slateRef.current.participantDetails, userId))
          setJoiningUser_Name(findNamebyId(slateRef.current.participantDetails, userId))
          // addToSessionMembersArray(slateRef.current.participantDetails.email)
        }

        //console.log('hey')
        setOpenJoinNotification(true);
      }
    });

    socketRef.current.on('userLeft', (userId) => {
      //console.log("user left 3003 with userid " + userId)
      //socketRef.current.emit("usersInSession",whiteBoardID)
      //console.log(userId)
      //console.log(slateRef.current)
      if (userId !== clientID) {
        if (slateRef.current.creator_id === userId) {
          if (slateRef.current.creatorDetails.firstName !== '' && slateRef.current.creatorDetails.lastName !== '') {
            setLeavingUser_Name(capitalizeFirstAlphabet(slateRef.current.creatorDetails.firstName) + " " + capitalizeFirstAlphabet(slateRef.current.creatorDetails.lastName))
            removeFromSessionMembersArray(slateRef.current.creatorDetails.email)
          }
          else {
            setLeavingUser_Name(slateRef.current.creatorDetails.email)
            removeFromSessionMembersArray(slateRef.current.creatorDetails.email)
          }
        }
        else if (slateRef.current.hosts.includes(userId)) {
          //console.log(findNamebyId(slateRef.current.hostDetails, userId))
          setLeavingUser_Name(findNamebyId(slateRef.current.hostDetails, userId))
          removeFromSessionMembersArray(findEmailbyId(slateRef.current.hostDetails, userId))
        }
        else if (slateRef.current.participants.includes(userId)) {
          //console.log(findNamebyId(slateRef.current.participantDetails, userId))
          setLeavingUser_Name(findNamebyId(slateRef.current.participantDetails, userId))
          removeFromSessionMembersArray(findEmailbyId(slateRef.current.participantDetails, userId))
        }
        //console.log('hey')
        setOpenLeftNotification(true);
        if (mousePointerRef && mousePointerRef.current) {
          mousePointerRef.current.handleDeletePeerMap(whiteBoardID + "session_id-" + userId)
        }
      }
    });
    socketRef.current.on('sessionDeactivated', async (data) => {
      console.log("this session 3003 has been deactivated" + JSON.stringify(data))
      if (data.session.id === whiteBoardID) {
        setOpenDeactivateDialog(true)
        // if (isTeacher) {
        //   await whiteBoardRef.current.uploadWhiteboardData()
        // }
        // console.log('session deactivated')
        window.open(FRONTEND_URL + 'slateDashboard', '_self')
      }
    })
    socketRef.current.on("disconnect", () => {
      //console.log("Disconnected now from 3003");
    });
    socketRef.current.on("allSlateMessagesDeliveredToUser", (data) => {
      // console.log("delivered event all del" + JSON.stringify(data));
      MarkInMessageArray(data, 'delivered')
      // handleEditSlate(data.slate);
    });
    socketRef.current.on("allSlateMessagesReadByUser", (data) => {
      // console.log("read event all read" + JSON.stringify(data));
      MarkInMessageArray(data, 'read')
      // handleEditSlate(data.slate);
    });
    socketRef.current.on("messageReadByUser", (data) => {
      // console.log("read event read" + JSON.stringify(data));
      MarkInMessageArray(data, 'read')
      // handleEditSlate(data.slate);
    });
    socketRef.current.on("messageDeliveredToUser", (data) => {
      // console.log("delivered event del" + JSON.stringify(data));
      MarkInMessageArray(data, 'delivered')
      // handleEditSlate(data.slate);
    });
    socketRef.current.on("messageCreated", (data) => {
      // console.log("message created" + JSON.stringify(data));
      if (slateRef && slateRef.current && slateRef.current.id === data.slate_id) {
        if (data.creator_id !== clientID) {
          EditMessageArray(data)
          // console.log('ischatopen', checkIsChatOpen())
          if (!checkIsChatOpen()) {
            setMessageDataReceived(data)
            setUpdateMessageNotification(true)
            if (!data.message_delivered_user_ids || !(data.message_delivered_user_ids.includes(clientID))) {
              markMessageAsDeliveredByUser(accessToken, data.id, data.slate_id, data.creator_id);
              let dataTemp = {
                slateId: data.slate_id,
                userId: clientID
              }
              MarkInMessageArray(dataTemp, 'delivered')
            }
          }
          else {
            if (!data.message_read_user_ids || !(data.message_read_user_ids.includes(clientID))) {
              console.log('marking as read');
              markMessageAsReadByUser(accessToken, data.id, data.slate_id, data.creator_id);
              let dataTemp = {
                slateId: data.slate_id,
                userId: clientID
              }
              MarkInMessageArray(dataTemp, 'read')
            }

          }
        }
      }
    });
    socketRef.current.on('whiteBoardEvent', (whiteBoardEvent) => {
      // console.log("white board event" + JSON.stringify(whiteBoardEvent))
      if (whiteBoardRef && whiteBoardRef.current) {
        // console.log('process')
        whiteBoardRef.current.processReceivedEvent(whiteBoardEvent)
      }

    })
    socketRef.current.on('fileShared', (fileSharedEvent) => {
      // console.log("file shared event" + JSON.stringify(fileSharedEvent))
      if (fileSharedEvent.sharedBy !== userName) {
        let displayName = '';
        if (slateRef.current.creator_id === fileSharedEvent.sharedBy) {
          if (slateRef.current.creatorDetails.firstName !== '' && slateRef.current.creatorDetails.lastName !== '') {
            displayName = capitalizeFirstAlphabet(slateRef.current.creatorDetails.firstName) + " " + capitalizeFirstAlphabet(slateRef.current.creatorDetails.lastName)
          }
          else {
            displayName = slateRef.current.creatorDetails.email
          }

        }
        else if (slateRef.current.hosts.includes(fileSharedEvent.sharedBy)) {
          displayName = findNamebyId(slateRef.current.hostDetails, fileSharedEvent.sharedBy)
        }
        else if (slateRef.current.participants.includes(fileSharedEvent.sharedBy)) {
          displayName = findNamebyId(slateRef.current.participantDetails, fileSharedEvent.sharedBy)
        }

        setSharedFileBy(displayName)
        setSharedFileLink(fileSharedEvent.fileLink)
        setSharedFileName(fileSharedEvent.fileName)
        setOpenSharedFileDialog(true)
      }
    })
    socketRef.current.on('tabStatus', (tabStatusEvent) => {
      // console.log("tab status event" + JSON.stringify(tabStatusEvent))
      if (tabStatusEvent.sentBy !== userName) {
        handleTabStatusChange(tabStatusEvent)
        // console.log(tabStatusEvent.sentBy, ' is ', tabStatusEvent.tabStatus)
      }
    })
    socketRef.current.on('lessonEvent', (lessonEvent) => {
      // console.log("lesson event" + JSON.stringify(lessonEvent))
      if (lessonEvent.eventType === 'feedback') {
        let feedbackEventData = lessonEvent.eventData
        // console.log(feedbackEventData)
        if (feedbackEventData.sentBy !== userName) {
          // console.log(feedbackEventData.sentBy)
          feedbackFormRef.current.handleOpenFeedbackDialog(feedbackEventData.openFeedbackDialog)
        }
      }

      if (lessonEvent.eventType === 'handleScreenshotCapture') {
        let handleScreenshotCaptureEventData = lessonEvent.eventData
        // console.log(handleScreenshotCaptureEventData)
        if (handleScreenshotCaptureEventData.sentBy !== userName) {
          screenshotWindowRef.current.handleScreenshotUrl(handleScreenshotCaptureEventData.screenshotUrl)
          screenshotWindowRef.current.handleOpenScreenshotWindow(handleScreenshotCaptureEventData.openScreenshotWindow)
        }
      }

      if (lessonEvent.eventType === 'handleMediaPlayer') {
        let handleMediaPlayerEventData = lessonEvent.eventData
        // console.log(handleMediaPlayerEventData)
        if (handleMediaPlayerEventData.sentBy !== userName) {
          mediaPlayerRef.current.handleMediaUrl(handleMediaPlayerEventData.mediaUrl)
          if (handleMediaPlayerEventData.openMediaPlayer) {
            const positionDimensionObj = whiteBoardRef.current.findObjectAndGetPositionAndDimensions(handleMediaPlayerEventData.objectID)
            mediaPlayerRef.current.handlePositionOfPlayer(positionDimensionObj.left, positionDimensionObj.top)
            mediaPlayerRef.current.handleSizeOfPlayer(positionDimensionObj.width, positionDimensionObj.height)
            if (handleMediaPlayerEventData.fileType.includes('audio')) {
              mediaPlayerRef.current.handleisAudio(true)
            }
            else {
              mediaPlayerRef.current.handleisAudio(false)
            }
          }

          mediaPlayerRef.current.handleOpenMediaPlayer(handleMediaPlayerEventData.openMediaPlayer)
        }
      }

      if (lessonEvent.eventType === 'handleIframe') {
        let handleIframeEventData = lessonEvent.eventData
        // console.log(handleIframeEventData)
        // console.log(handleIframeEventData.sentBy !== userName)
        if (handleIframeEventData.sentBy !== userName) {
          iframeBoxRef.current.handleMediaUrl(handleIframeEventData.mediaUrl)
          // console.log('hey 1')
          if (handleIframeEventData.openIframeBox) {
            // console.log('hey 2')
            const positionDimensionObj = whiteBoardRef.current.findObjectAndGetPositionAndDimensions(handleIframeEventData.objectID)
            iframeBoxRef.current.handlePositionOfPlayer(positionDimensionObj.left, positionDimensionObj.top)
            iframeBoxRef.current.handleSizeOfPlayer(positionDimensionObj.width, positionDimensionObj.height)
          }

          iframeBoxRef.current.handleOpenIframeBox(handleIframeEventData.openIframeBox)
        }
      }

      if (lessonEvent.eventType === 'handlePdfViewer') {
        let handlePdfViewerEventData = lessonEvent.eventData
        // console.log(handlePdfViewerEventData)
        // console.log(handlePdfViewerEventData.sentBy !== userName)
        if (handlePdfViewerEventData.sentBy !== userName) {
          pdfViewerRef.current.handleMediaUrl(handlePdfViewerEventData.mediaUrl)
          // console.log('hey 1')
          if (handlePdfViewerEventData.openIframeBox) {
            // console.log('hey 2')
            const positionDimensionObj = whiteBoardRef.current.findObjectAndGetPositionAndDimensions(handlePdfViewerEventData.objectID)
            pdfViewerRef.current.handlePositionOfPlayer(positionDimensionObj.left, positionDimensionObj.top)
            pdfViewerRef.current.handleSizeOfPlayer(positionDimensionObj.width, positionDimensionObj.height)
          }

          pdfViewerRef.current.handleOpenIframeBox(handlePdfViewerEventData.openIframeBox)
        }
      }
      if (lessonEvent.eventType === 'changePdfViewerPage') {
        let changePdfViewerPageEventData = lessonEvent.eventData
        if (changePdfViewerPageEventData.sentBy !== userName) {
          pdfViewerRef.current.handlePageNumber(changePdfViewerPageEventData.pageNumber)

        }
      }

      if (lessonEvent.eventType === 'toggleMediaPlayerState') {
        let toggleMediaPlayerStateEventData = lessonEvent.eventData
        // console.log(toggleMediaPlayerStateEventData)
        if (toggleMediaPlayerStateEventData.sentBy !== userName) {
          if (toggleMediaPlayerStateEventData.mediaPlayerState) {
            if (toggleMediaPlayerStateEventData.mediaPlayerState === 'pause') {
              if (document.getElementById("video-player-lesson")) {
                document.getElementById("video-player-lesson").pause()
              }
            }
            else {
              if (document.getElementById("video-player-lesson")) {
                document.getElementById("video-player-lesson").play()
              }

            }
          }
          if (toggleMediaPlayerStateEventData.newTime) {
            document.getElementById("video-player-lesson").currentTime = toggleMediaPlayerStateEventData.newTime
          }
        }
      }
      if (lessonEvent.eventType === 'handleWebLink') {
        let handleWebLinkEventData = lessonEvent.eventData
        // console.log(handleWebLinkEventData)
        if (handleWebLinkEventData.sentBy !== userName) {
          weblinkRef.current.handleOpenWeblink(handleWebLinkEventData.openWeblink)
          weblinkRef.current.handleWebsitelink(handleWebLinkEventData.websiteLink)
        }
      }

      if (lessonEvent.eventType === 'isWebLinkOpen') {
        let isWebLinkOpenEventData = lessonEvent.eventData
        // console.log(isWebLinkOpenEventData)
        if (isWebLinkOpenEventData.sentBy !== userName) {
          // console.log(weblinkRef)
          weblinkRef.current.checkWebsiteLinkOpen()

        }
      }

      if (lessonEvent.eventType === 'handleSlatePad') {
        let handleSlatePadEventData = lessonEvent.eventData
        // console.log(handleSlatePadEventData)
        if (handleSlatePadEventData.sentBy !== userName) {
          if (handleSlatePadEventData.openSlatePad) {
            slatePadRef.current.handleOpenSlatePad(handleSlatePadEventData.openSlatePad)
          }
          else {
            if (window.lesson.followSlatePad) {
              slatePadRef.current.handleOpenSlatePad(handleSlatePadEventData.openSlatePad)
            }
          }
        }
      }
      if (lessonEvent.eventType === 'uploadClickedPicture') {
        let uploadClickedPictureEventData = lessonEvent.eventData
        // console.log(uploadClickedPictureEventData)
        // console.log(uploadClickedPictureEventData.sentBy !== clientID)
        // console.log(uploadClickedPictureEventData.sentBy)
        // console.log(clientID)
        if (uploadClickedPictureEventData.sentBy === clientID) {
          // console.log(whiteBoardRef)
          whiteBoardRef.current._sketch.addImg(uploadClickedPictureEventData.uploadFileUrl, false)
        }
      }

      // if (lessonEvent.eventType === 'disableCustomToolbar') {
      //   let eventDataTemp = lessonEvent.eventData
      //   console.log(eventDataTemp)
      //   // console.log(uploadClickedPictureEventData.sentBy !== clientID)
      //   // console.log(uploadClickedPictureEventData.sentBy)
      //   // console.log(clientID)
      //   if (!isTeacher && eventDataTemp.sentBy !== clientID) {
      //     setIsToolbarDisabledAtom(eventDataTemp.disableToolbarState)
      //     if (eventDataTemp.disableToolbarState) {
      //       whiteBoardRef.current._customToolbar.current._selectTool({ target: { value: "DefaultTool" } })
      //     }
      //     else {
      //       whiteBoardRef.current._customToolbar.current._selectTool({ target: { value: "Pencil" } })
      //     }

      //   }
      // }
      if (lessonEvent.eventType === 'togglePan') {
        let eventDataTemp = lessonEvent.eventData
        console.log(eventDataTemp)
        // console.log(uploadClickedPictureEventData.sentBy !== clientID)
        // console.log(uploadClickedPictureEventData.sentBy)
        // console.log(clientID)
        if (!isTeacher && eventDataTemp.sentBy !== clientID) {
          console.log('changing', eventDataTemp.enablePan)
          setIsPanEnabled(eventDataTemp.enablePan)
          window.lesson.isPanEnabled = eventDataTemp.enablePan;
          if (!eventDataTemp.enablePan) {
            if (whiteBoardRef.current.currentTool.current === "Pan") {
              whiteBoardRef.current._customToolbar.current._selectTool({ target: { value: "DefaultTool" } })
            }
          }
          else {
            if (whiteBoardRef.current.currentTool.current !== "Pan") {
              whiteBoardRef.current._customToolbar.current._selectTool({ target: { value: "Pan" } })
            }
          }

        }

      }

      if (lessonEvent.eventType === 'toggleVideo') {
        let eventDataTemp = lessonEvent.eventData
        // console.log(eventDataTemp)
        // console.log(uploadClickedPictureEventData.sentBy !== clientID)
        // console.log(uploadClickedPictureEventData.sentBy)
        // console.log(clientID)
        if (!isTeacher && eventDataTemp.sentBy !== clientID) {
          // console.log('changing', eventDataTemp.cameraState)
          setIsCameraEnabled(eventDataTemp.cameraState)
        }

      }
      if (lessonEvent.eventType === 'toggleAudio') {
        let eventDataTemp = lessonEvent.eventData
        // console.log(eventDataTemp)
        // console.log(uploadClickedPictureEventData.sentBy !== clientID)
        // console.log(uploadClickedPictureEventData.sentBy)
        // console.log(clientID)
        if (!isTeacher && eventDataTemp.sentBy !== clientID) {
          // console.log('changing', eventDataTemp.micState)
          setIsMicEnabled(eventDataTemp.micState)
        }

      }

      if (lessonEvent.eventType === 'toggleAudio') {
        let eventDataTemp = lessonEvent.eventData
        // console.log(eventDataTemp)
        // console.log(uploadClickedPictureEventData.sentBy !== clientID)
        // console.log(uploadClickedPictureEventData.sentBy)
        // console.log(clientID)
        if (!isTeacher && eventDataTemp.sentBy !== clientID) {
          // console.log('changing', eventDataTemp.micState)
          setIsMicEnabled(eventDataTemp.micState)
        }

      }

      if (lessonEvent.eventType === 'sessionStateUpdated') {
        let eventDataTemp = lessonEvent.eventData
        // console.log(eventDataTemp)
        // console.log(uploadClickedPictureEventData.sentBy !== clientID)
        // console.log(uploadClickedPictureEventData.sentBy)
        // console.log(clientID)
        if (eventDataTemp.sentBy !== clientID) {
          window.lesson.sessionState = eventDataTemp.sessionState
          // console.log('changing', eventDataTemp.sessionState)
          getUserState(eventDataTemp.sessionState, clientID, true)
          checkIfHost(eventDataTemp.sessionState.hosts, clientID)
        }

      }

      if (lessonEvent.eventType === 'followScreenEvent') {
        let eventDataTemp = lessonEvent.eventData
        if (window.lesson.followScreen) {
          // console.log(eventDataTemp)
          if (eventDataTemp.livekitState === 'fullscreen') {
            setIsVideoTileFullscreen(true)
          }
          else if (eventDataTemp.livekitState === 'sideview') {
            setIsVideoTileFullscreen(false)

          }
        }

      }

      if (lessonEvent.eventType === 'participantClicked') {
        let eventDataTemp = lessonEvent.eventData
        if (window.lesson.followScreen) {
          // console.log(eventDataTemp)
          let elementId;
          if (eventDataTemp.type === 'camera') {
            elementId = eventDataTemp.nameOfClickedParticipant + '-video-tile'
          }
          else if (eventDataTemp.type === 'screenshare') {
            elementId = eventDataTemp.nameOfClickedParticipant + '-screenShare-tile'
          }
          // console.log(document.getElementById(elementId))
          if (document.getElementById(elementId)) {
            document.getElementById(elementId).click()
          }

        }

      }


      if (lessonEvent.eventType === 'sendToBack') {
        console.log('sendtoback', lessonEvent)
        let eventDataTemp = lessonEvent.eventData
        if (eventDataTemp.sentBy !== clientID) {
          console.log('yo')
          if (whiteBoardRef && whiteBoardRef.current && whiteBoardRef.current._sketch && whiteBoardRef.current._sketch._fc.current) {
            console.log('yo1')
            let canvas = whiteBoardRef.current._sketch._fc.current;
            let objects = canvas.getObjects().slice();
            for (let obj of objects) {
              console.log(obj.objectID === eventDataTemp.objectID)
              if (obj.objectID === eventDataTemp.objectID) {
                console.log('object match')
                canvas.sendObjectToBack(obj)
                canvas.renderAll()
              }
            }
          }
        }
      }

      if (lessonEvent.eventType === 'bringToFront') {
        console.log('bringTOFront', lessonEvent)
        let eventDataTemp = lessonEvent.eventData
        if (eventDataTemp.sentBy !== clientID) {
          if (whiteBoardRef && whiteBoardRef.current && whiteBoardRef.current._sketch && whiteBoardRef.current._sketch._fc.current) {
            let canvas = whiteBoardRef.current._sketch._fc.current;
            let objects = canvas.getObjects().slice();
            for (let obj of objects) {
              if (obj.objectID === eventDataTemp.objectID) {
                canvas.bringObjectToFront(obj)
                canvas.renderAll()
              }
            }
          }
        }
      }

    })


    socketRef.current.on('smileyEvent', (smileyEventData) => {
      // console.log("smiley event" + JSON.stringify(smileyEventData))
      if (smileyEventData.sentBy !== userName) {
        document.getElementById(smileyEventData.smileyTileID).innerHTML = smileyEventData.smiley;
        document.getElementById(smileyEventData.smileyTileID).style.display = 'flex';
        setTimeout(() =>
          document.getElementById(smileyEventData.smileyTileID).style.display = 'none'
          , 2000)
      }
    })
  }



  if (isWhiteboard && !whiteBoardID)
    return <p style={{ margin: "15px" }}>Invalid whiteBoardID</p>

  // const handleMenu = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  // const handleDrawerToggle = () => {
  //   setMobileOpen(!mobileOpen);
  // };
  // const createPath = (startX, startY, endX, endY, color, strokeWidth) => {
  //   whiteBoardRef.current.createPath(startX, startY, endX, endY, color, strokeWidth)
  // }


  const receivedDataFromPeer = (peerSocketId, data) => {

    whiteBoardRef.current.receivedDataFromPeer(peerSocketId, data);
  }


  /**
   *
   * @param {object} whiteBoardEvent -contains whiteboard event data which is shared with other users using websockets
   */
  const sendWhiteBoardEventViaWebSocket = (whiteBoardEvent) => {

    // console.log("trying to send data " + whiteBoardEvent)
    socketRef.current.emit("whiteBoardEvent", whiteBoardEvent)

  }

  /**
   *
   * @param {object} fileSharedEvent -contains fileSharedEvent event data which is shared with other users using websockets
   */
  const sendFileSharedEventViaWebSocket = (fileSharedEvent) => {

    // console.log("trying to send data " + JSON.stringify(fileSharedEvent))
    socketRef.current.emit("fileShared", whiteBoardID, fileSharedEvent)

  }

  /**
 *
 * @param {object} smileyEventData -contains smileyEventData event data which is shared with other users using websockets
 */
  const sendSmileyEventViaWebSocket = (smileyEventData) => {
    // console.log("trying to send data " + JSON.stringify(smileyEventData))
    socketRef.current.emit("smileyEvent", whiteBoardID, smileyEventData)

  }

  /**
*
* @param {object} lessonEvent -contains lessonEvent event data which is shared with other users using websockets
*/
  const sendLessonEventViaWebSocket = (lessonEvent) => {
    // console.log("trying to send data " + JSON.stringify(lessonEvent))
    if (socketRef && socketRef.current) {
      socketRef.current.emit("lessonEvent", whiteBoardID, lessonEvent)
    }
  }




  const sendDataToAllPeers = (e) => {

    // console.log("trying to send data " + sendDataToAllPeersWebRtc)

    sendDataToAllPeerJSUsers(e)


    if (sendDataToAllPeersWebRtc && sendDataToAllPeersWebRtc.current) {
      // console.log("calling webrtc component function ");
      sendDataToAllPeersWebRtc.current(e)
    }
  }


  const resetCoords = (e) => {

    if (resetCoordsWebRtc && resetCoordsWebRtc.current) {
      resetCoordsWebRtc.current()
    }
  }


  // const AudioVideoBox = React.forwardRef((props, ref) => {

  //   return (<Box sx={{
  //   }}>
  //     {roomName ?
  //       <Webrtc userName={userName}
  //         ref={ref}
  //         whiteBoardRef={whiteBoardRef}
  //         resetCoords={resetCoordsWebRtc}
  //         sendDataToAllPeers={sendDataToAllPeersWebRtc}
  //         receivedDataFromPeer={props.receivedDataFromPeer}
  //         createPath={createPath}
  //         setWebRtcKey={setWebRtcKey}
  //         key={webRtcKey}
  //         setScreenShare={setScreenShare}
  //         lastPublishedScreenSharingStream={lastPublishedScreenSharingStream}
  //         setLastPublishedScreenSharingStream={setLastPublishedScreenSharingStream}
  //         lastPublishedScreenSharingStreamRef={lastPublishedScreenSharingStreamRef}
  //         roomName={roomName}
  //         isAudioEnabled={isAudioEnabled}
  //         isVideoEnabled={isVideoEnabled}
  //         isScreenSharingEnabled={isScreenSharingEnabled}
  //         isWebrtcTilesCenter={isWebrtcTilesCenter}
  //         isWebrtcFullScreen={isWebrtcFullScreen}
  //         isWebrtcData={isWebrtcData}
  //         isWebrtcMinimize={isWebrtcMinimize}
  //         isWebrtcSmiley={isWebrtcSmiley}
  //         isWebrtcScreenshot={isWebrtcScreenshot}
  //         whiteBoardID={whiteBoardID}
  //         recordingTimeRef={recordingTimeRef}
  //       >
  //       </Webrtc> : null}

  //   </Box>)
  // })

  const handleCloseJoinNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenJoinNotification(false);
  };

  const handleCloseLeftNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenLeftNotification(false);
  };

  const EditMessageArray = (data) => {
    setMessageArray(prevValue =>
      [...prevValue, data]
    );
  }

  const MarkInMessageArray = (data, markType) => {
    // console.log(data)
    setMessageArray(prev => {

      let tempArray = [];
      if (markType === 'delivered') {
        tempArray = getDeliveredList(prev, data.userId)
      }
      else if (markType === 'read') {
        tempArray = getReadList(prev, data.userId)
      }
      // console.log(tempArray)
      return tempArray
    }
    )

  }

  const getReadList = (messageList, userId) => {
    let msgArray = JSON.parse(JSON.stringify(messageList))
    for (let msg of msgArray) {
      if (msg.message_read_user_ids) {
        if (!(msg.message_read_user_ids.includes(userId))) {
          msg.message_read_user_ids.push(userId)
        }
      }
      else {
        msg.message_read_user_ids = [userId]
      }

    }
    return msgArray
  }

  const getDeliveredList = (messageList, userId) => {
    let msgArray = JSON.parse(JSON.stringify(messageList))
    for (let msg of msgArray) {
      if (msg.message_delivered_user_ids) {
        if (!(msg.message_delivered_user_ids.includes(userId))) {
          msg.message_delivered_user_ids.push(userId)
        }
      }
      else {
        msg.message_delivered_user_ids = [userId]
      }

    }
    return msgArray
  }



  const handleSessionMembersArray = (value) => {
    setSessionMembersArray(removeDuplicates(value))
    // console.log('setting current members', value)
  }

  const handleTabStatusChange = (eventData) => {
    const currentListArray = JSON.parse(JSON.stringify(getSessionMemberList()))
    const tempArray = [];
    let counter = 0;
    for (let i = 0; i < currentListArray.length; ++i) {
      if (currentListArray[i].emailID === eventData.sentBy) {
        tempArray.push({
          'emailID': eventData.sentBy,
          'tabStatus': eventData.tabStatus,
          'displayName': eventData.displayName
        })
        counter++;
      }
      else {
        tempArray.push(currentListArray[i])
      }
    }
    // console.log('counter is ', counter === 0)
    if (counter === 0) {
      tempArray.push({
        'emailID': eventData.sentBy,
        'tabStatus': eventData.tabStatus,
        'displayName': eventData.displayName
      })
    }
    // console.log(tempArray)
    setSessionMembersArray(removeDuplicates(tempArray))
    // let hasOfflineMembers = false;
    // for (let i = 0; i < tempArray.length; ++i) {
    //   if (tempArray[i].tabStatus === 'offline') {
    //     hasOfflineMembers = true;
    //   }
    // }
    // if (hasOfflineMembers) {
    //   setIconColor('orange')
    // }
    // else {
    //   setIconColor('green')
    // }
  }


  const removeFromSessionMembersArray = (emailID) => {

    setSessionMembersArray((prevState) => {
      return prevState.filter((element) => element.emailID !== emailID)
    });
    // console.log('removed emailID', emailID)
  }



  console.log('lesson rerendering')
  return (<>
    {/* {isLoading ? <Grid sx={{
      position: "absolute", width: "100%", height: "90vh", opacity: "1", top: "0", left: "0",
      backgroundColor: "white", display: "flex", alignItems: "center", alignContent: "center", justifyContent: "center"
    }}>
      <Box>
        <CircularProgress size={"2rem"} sx={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
        }} />
      </Box>
    </Grid> : <> */}

    {isSessionInactive ? <Box sx={{ display: isSessionInactive ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100%' }}>
      <Typography sx={{ fontSize: '2rem', fontWeight: '600', color: THEME_COLOR, justifyContent: 'center', textAlign: 'center' }}>
        Oops!
        <br />
        <span style={{ fontSize: '1.5rem' }}>  This session has been deactivated.</span>


      </Typography>
    </Box> :
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <CssBaseline />
          <Box
            id='lesson-box-screenshot'
            sx={[
              {
                // position: "fixed",
                flexDirection: "row",
                // alignSelf:"stretch",

                height: "100%",

                display: 'flex',

                //  mt: 1,
                //  overflow: 'hidden',
                alignItems: "center",
                justifyContent: 'flexStart',
                // border: 1
              },

            ]}
            aria-label="classroom menu"
          >
            <Dialog
              open={openDeactivateDialog}
              onClose={() => {
                setOpenDeactivateDialog(false)
              }}
            >
              <DialogTitle sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', alignItems: 'center', color: THEME_COLOR, width: '100%' }}>
                Session ended successfully!
                <CheckBoxIcon sx={{
                  color: 'green',
                  fontSize: '3rem',
                  margin: '10px 0 0',
                  opacity: 0,
                  animation: `${fadeIn} 2s ease-in-out forwards`,
                }} />
              </DialogTitle>
              <DialogContent>
                <Box sx={{ padding: '5px 20px 20px', justifyContent: 'center', textAlign: 'center' }}>
                  <Typography variant="h6" sx={{ marginBottom: '10px', color: '#000000', textAlign: 'center' }}>
                    Please wait while we
                    save your session...
                  </Typography>

                </Box>
              </DialogContent>

            </Dialog>

            <Dialog
              open={openSharedFileDialog}
              onClose={() => {
                setOpenSharedFileDialog(false)
              }}
              fullWidth
            >
              <DialogTitle sx={{ justifyContent: 'center', textAlign: 'center', position: 'relative' }}>
                <Typography sx={{ fontSize: '2.5rem', fontWeight: '600', color: THEME_COLOR }}>
                  Notification
                </Typography>
                <IconButton
                  sx={{ color: 'black', position: 'absolute', right: '5px', top: "5px", zIndex: 10000 }}
                  onClick={() => {
                    setOpenSharedFileDialog(false)
                  }}
                  size="medium">
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <Grid container item xs={12} sm={12} md={12} lg={12} justifyContent='center' textAlign='center' alignItems='center' sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant='body1' sx={{ marginBottom: '15px' }}>
                    <b> {sharedFileBy}</b> shared a File with you
                  </Typography>
                  <br></br>
                  <Button
                    type='submit'
                    sx={{
                      borderRadius: '25px',
                      width: '50%',
                      height: '50px',
                      textTransform: 'none',
                      backgroundColor: THEME_COLOR,
                      color: '#FFFFFF',
                      fontSize: '1rem',
                      fontWeight: '500',
                      '&:hover': {
                        backgroundColor: THEME_HOVER_COLOR,
                        boxShadow: 'none',
                      },

                    }}
                    onClick={() => {
                      fetch(sharedFileLink)
                        .then(resp => resp.blob())
                        .then(blob => {
                          const url = window.URL.createObjectURL(blob);
                          const a = document.createElement('a');
                          a.style.display = 'none';
                          a.href = url;
                          // the filename you want
                          a.download = sharedFileName;
                          document.body.appendChild(a);
                          a.click();
                          window.URL.revokeObjectURL(url);
                        })
                        .catch(() => alert('Could not download image '));
                      setOpenSharedFileDialog(false)

                    }}
                  >
                    Download
                  </Button>
                </Grid>
              </DialogContent>
            </Dialog>

            <FeedbackDialog
              ref={feedbackFormRef}
              isTeacher={isTeacher}
              sendLessonEventViaWebSocket={sendLessonEventViaWebSocket}
              userName={userName}
            />


            {/* <Snackbar open={openJoinNotification} autoHideDuration={3000} onClose={handleCloseJoinNotification}>
              <Alert onClose={handleCloseJoinNotification} severity="info" sx={{ width: '100%' }}>
                {joiningUser_Name}&nbsp;joined!
              </Alert>
            </Snackbar>
            <Snackbar open={openLeftNotification} autoHideDuration={3000} onClose={handleCloseLeftNotification}>
              <Alert onClose={handleCloseLeftNotification} severity="info" sx={{ width: '100%' }}>
                {leavingUser_Name}&nbsp;left:(
              </Alert>
            </Snackbar> */}
            <Box sx={[{ height: "100%", width: '100%' }, screenShare && { display: "none" }]}>

              {/* {!isViewer && isWebrtc && <AudioVideoBox receivedDataFromPeer={receivedDataFromPeer} ></AudioVideoBox>} */}
              {/* {!isViewer && isAVServer && AV_SERVER && <RoomPage
                userName={userName}
                roomName={roomName}
                setLiveKitRoom={setLiveKitRoom}
                isLesson={true}
                recordingTimeRef={recordingTimeRef}
                whiteBoardRef={whiteBoardRef}
                whiteBoardID={whiteBoardID}
                AV_SERVER={AV_SERVER}
                slateID={slateID}
                accessToken={accessToken}
                isTeacher={isTeacher}
                slateRef={slateRef}
              />} */}
              {isWhiteboard && slateID && <WhiteBoard
                whiteBoardID={whiteBoardID}
                liveKitRoom={liveKitRoom}
                isTeacher={isTeacher}
                isZoomEnabled={isZoomEnabled}
                isDeleteAllEnabled={isDeleteAllEnabled}
                previousWhiteBoardID={previousWhiteBoardID}
                clientID={clientID}
                recordingTimeRef={recordingTimeRef}
                // setAudioVideoOpen={setAudioVideoOpen}
                ref={whiteBoardRef}
                sendDataToAllPeers={sendDataToAllPeers}
                resetCoords={resetCoords}
                shouldSendDataToAllPeers={shouldSendDataToAllPeers}
                isAddMessageFocusRef={isAddMessageFocusRef}
                isWhiteboard={isWhiteboard}
                filesDownloadFolder={filesDownloadFolder}
                // isPublishWhiteBoardDataViaLiveKit={isPublishWhiteBoardDataViaLiveKit}
                accessToken={accessToken}
                slateID={slateID}
                sendWhiteBoardEventViaWebSocket={sendWhiteBoardEventViaWebSocket}
                sendFileSharedEventViaWebSocket={sendFileSharedEventViaWebSocket}
                isViewer={isViewer}
                slateRef={slateRef}
                screenRecording={screenRecording}
                isTourEnabled={isTourEnabled}
                userName={userName}
                sendSmileyEventViaWebSocket={sendSmileyEventViaWebSocket}
                mediaPlayerRef={mediaPlayerRef}
                sendLessonEventViaWebSocket={sendLessonEventViaWebSocket}
                isTextFocused={isTextFocused}
                iframeBoxRef={iframeBoxRef}
                pdfViewerRef={pdfViewerRef}
                autoRecord={autoRecord}
                sessionDataRef={sessionDataRef}
                isWhiteboardPlayer={isWhiteboardPlayer}
              />}
              {/* {!isViewer &&
                <TabStatus ref={tabStatusRef} setOpenTabStatus={setOpenTabStatus} openTabStatus={openTabStatus} isTeacher={isTeacher} accessToken={accessToken} slateRef={slateRef} />
              } */}
              {/* {!isViewer &&
                <EndSessionStepperForm sessionReport={sessionReport} />
              } */}
              {!isWhiteboardPlayer && !isViewer &&
                <MediaPlayer ref={mediaPlayerRef} sendLessonEventViaWebSocket={sendLessonEventViaWebSocket} userName={userName} />
              }
              {!isWhiteboardPlayer && !isViewer &&
                <IframeBox ref={iframeBoxRef} sendLessonEventViaWebSocket={sendLessonEventViaWebSocket} userName={userName} />
              }
              {!isWhiteboardPlayer && !isViewer &&
                <PdfViewer ref={pdfViewerRef} isTextFocused={isTextFocused} sendLessonEventViaWebSocket={sendLessonEventViaWebSocket} userName={userName} />
              }
              {!isWhiteboardPlayer && !isViewer && <>
                <PeriodicTable />
              </>}
              {!isWhiteboardPlayer && !isViewer && <MousePointer ref={mousePointerRef}></MousePointer>}
              {!isWhiteboardPlayer && !isViewer && <AddParticipants
                accessToken={accessToken}
                slateRef={slateRef}
                openAddParticipantForm={openAddParticipantForm}
                setOpenAddParticipantForm={setOpenAddParticipantForm}
                isTextFocused={isTextFocused}
              />}
              {!isWhiteboardPlayer && <Timer sendLessonEventViaWebSocket={sendLessonEventViaWebSocket} isTeacher={isTeacher} />}
              {/* {!isViewer &&
                <ScreenshotWindow ref={screenshotWindowRef} sendLessonEventViaWebSocket={sendLessonEventViaWebSocket} userName={userName} accessToken={accessToken} whiteBoardID={whiteBoardID} />
              } */}
              {!isWhiteboardPlayer && !isViewer &&
                <SlatePad ref={slatePadRef} sendLessonEventViaWebSocket={sendLessonEventViaWebSocket} userName={userName} slateRef={slateRef} />
              }
              {!isWhiteboardPlayer && !isViewer &&
                <Weblink ref={weblinkRef} sendLessonEventViaWebSocket={sendLessonEventViaWebSocket} userName={userName} isTextFocused={isTextFocused} />
              }
              {!isWhiteboardPlayer && !isViewer && whiteBoardID && userName && <LiveKitNew
                isLivekitFullscreen={isLivekitFullscreen}
                userName={userName}
                recordingTimeRef={recordingTimeRef}
                whiteBoardID={whiteBoardID}
                isLiveKitIntegrated={true}
                slateID={slateID}
                accessToken={accessToken}
                isTeacher={isTeacher}
                isPrejoin={isPrejoin}
                slateRef={slateRef}
                isTextFocused={isTextFocused}
                sendLessonEventViaWebSocket={sendLessonEventViaWebSocket}
                clientID={clientID}
                whiteBoardRef={whiteBoardRef}
              />}

            </Box>

          </Box>
        </Box>
      </ThemeProvider>}
  </>
  );
}




Classroom.propTypes = {

  window: PropTypes.func
};
export default Classroom;
