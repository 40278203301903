// import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Classroom from './classroom2/lesson';
import WhiteBoardPlayer from './pages/whiteBoardPlayer';
import Error404View from './pages/404';
// import RoomPage from './audioVideo/LiveKitCustom';
import { useState, useEffect } from 'react';
import React from 'react';
// import { Box, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import PdfViewer from './pages/PdfViewer';
import ClickPicture from './pages/ClickPicture';
import LiveKitNew from './audioVideo/LiveKitNew/liveKitNew';
import MicrophoneCheck from './pages/CheckMic';
// import SearchContent from './pages/SearchContent';
import SketchField from './whiteboard/src/components/SketchField2/SketchField';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function App() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [openReconnectedNotification, setOpenReconnectedNotification] = useState(false)
  useEffect(() => {
    function onlineHandler() {
      setIsOnline(true);
      setOpenReconnectedNotification(true)
    }

    function offlineHandler() {
      setIsOnline(false);
    }

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);


    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);

  const handleCloseReconnectedNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenReconnectedNotification(false);
  };


  return (
    <div>
      <Snackbar open={!isOnline}  >
        <Alert severity="error" sx={{ width: '100%' }}>
          Connection lost. Reconnecting...
        </Alert>
      </Snackbar>
      <Snackbar open={openReconnectedNotification} autoHideDuration={5000} onClose={handleCloseReconnectedNotification}>
        <Alert onClose={handleCloseReconnectedNotification} severity="success" sx={{ width: '100%' }}>
          Reconnected. You're back online!
        </Alert>
      </Snackbar>
      {isOnline ? (
        <BrowserRouter>
          <Routes>
            <Route
              path="/app/lesson"
              element={<Classroom />}
            />
            <Route
              path="/lesson"
              element={<Classroom />}
            />
            <Route
              path='/whiteboardPlayer'
              element={<WhiteBoardPlayer />}
            />
            <Route
              path='/pdfViewer'
              element={<PdfViewer />}
            />
            <Route
              path='/clickPicture'
              element={<ClickPicture />}
            />
            <Route
              path="/livekitNew"
              element={<LiveKitNew />}
            />
            <Route
              path="/checkMic"
              element={<MicrophoneCheck />}
            />
            <Route
              path="/sketch"
              element={<SketchField height='100vh' width='100%' />}
            />
            {/* <Route
              path="/searchContent"
              element={<SearchContent />}
            /> */}
            <Route
              path="*"
              element={<Error404View />}
            />
          </Routes>
        </BrowserRouter>

      ) : (
        <></>
      )}
    </div>



  );
}

export default App;
