/*eslint no-unused-vars: 0*/
// @ts-nocheck

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import History from '../../history';
import { uuid4 } from '../../utils';
import Arrow from './../../tools/Arrow';
import Tool from './../../tools';
import DefaultTool from './../../tools/defaul-tool';
import Select from './../../tools/Select';
import Pencil from './../../tools/Pencil';
import Line from './../../tools/Line';
import Rectangle from './../../tools/Rectangle';
import RectangleLabel from './../../tools/Rectangle/rectangle-label';
import Circle from './../../tools/Circle';
import Pan from './../../tools/Pan';
import Eraser from './../../tools/Eraser';
import Highlighter from './../../tools/Highlighter';
import * as fabric from 'fabric'
import ToJSONFields from "../../toJSONFields";
import { inlineSVGString } from '../../../../components/helperFunctions';

/**
 * Sketch Tool based on FabricJS for React Applications
 */
class SketchField extends PureComponent {
  static propTypes = {
    // the color of the line
    lineColor: PropTypes.string,
    // The width of the line
    lineWidth: PropTypes.number,
    // the color of the  highlighter
    highlighterColor: PropTypes.string,
    // The width of the  highlighter
    highlighterWidth: PropTypes.number,
    // the fill color of the shape when applicable
    fillColor: PropTypes.string,
    // the background color of the sketch
    backgroundColorObject: PropTypes.object,
    // the opacity of the object
    opacity: PropTypes.number,
    // number of undo/redo steps to maintain
    undoSteps: PropTypes.number,
    // The tool to use, can be pencil, rectangle, circle, brush;
    tool: PropTypes.any,
    // image format when calling toDataURL
    imageFormat: PropTypes.string,
    // Sketch data for controlling sketch from
    // outside the component
    value: PropTypes.object,
    // Set to true if you wish to force load the given value, even if it is  the same
    forceValue: PropTypes.bool,
    // Specify some width correction which will be applied on auto resize
    widthCorrection: PropTypes.number,
    // Specify some height correction which will be applied on auto resize
    heightCorrection: PropTypes.number,
    // Specify action on change
    onChange: PropTypes.func,
    // Default initial value
    defaultValue: PropTypes.object,
    // Sketch width
    width: PropTypes.number,
    // Sketch height
    height: PropTypes.number,
    // event object added
    onObjectAdded: PropTypes.func,

    //event path created
    onPathCreated: PropTypes.func,

    // event object modified

    onObjectModified: PropTypes.func,
    // event object removed
    onObjectRemoved: PropTypes.func,
    // event mouse down
    onMouseDown: PropTypes.func,
    // event mouse move
    onMouseMove: PropTypes.func,
    // event mouse up
    onMouseUp: PropTypes.func,
    // event mouse out
    onMouseOut: PropTypes.func,
    // event object move
    onObjectMoving: PropTypes.func,
    // event object scale
    onObjectScaling: PropTypes.func,
    // event object rotating
    onObjectRotating: PropTypes.func,
    // Class name to pass to container div of canvas
    className: PropTypes.string,
    // Style options to pass to container div of canvas
    style: PropTypes.object
  };

  static defaultProps = {
    lineColor: 'black',
    lineWidth: 10,
    fillColor: 'transparent',
    backgroundColorObject: { color: 'transparent' },
    opacity: 1.0,
    undoSteps: 25,
    tool: Tool.Pencil,
    widthCorrection: 0,
    heightCorrection: 0,
    forceValue: false,
    onObjectAdded: () => null,
    onPathCreated: () => null,
    onObjectModified: () => null,
    onObjectRemoved: () => null,
    onMouseDown: () => null,
    onMouseMove: () => null,
    onMouseUp: () => null,
    onMouseOut: () => null,
    onObjectMoving: () => null,
    onObjectScaling: () => null,
    onObjectRotating: () => null
  };

  state = {
    action: true,
    totalImagesHeight: 0,
    zoomFactors: 1,
  };
  _initTools = fabricCanvas => {
    this._tools = {};
    this._tools['Select'] = new Select(fabricCanvas);
    this._tools['Pencil'] = new Pencil(fabricCanvas);
    this._tools['Line'] = new Line(fabricCanvas);
    this._tools['Arrow'] = new Arrow(fabricCanvas);
    this._tools['Rectangle'] = new Rectangle(fabricCanvas);
    this._tools['RectangleLabel'] = new RectangleLabel(fabricCanvas);
    this._tools['Circle'] = new Circle(fabricCanvas);
    this._tools['Pan'] = new Pan(fabricCanvas);
    this._tools['Highlighter'] = new Highlighter(fabricCanvas);
    this._tools['DefaultTool'] = new DefaultTool(fabricCanvas);
    this._tools['Eraser'] = new Eraser(fabricCanvas);
  };

  /**
   * Enable touch Scrolling on Canvas
   */
  enableTouchScroll = () => {
    let canvas = this._fc;
    if (canvas.allowTouchScrolling) return;
    canvas.allowTouchScrolling = true;
  };

  /**
   * Disable touch Scrolling on Canvas
   */
  disableTouchScroll = () => {
    let canvas = this._fc;
    if (canvas.allowTouchScrolling) {
      canvas.allowTouchScrolling = false;
    }
  };

  /**
   * Add an image as object to the canvas
   *
   * @param dataUrl the image url or Data Url
   * @param options object to pass and change some options when loading image, the format of the object is:
   *
   * {
   *   left: <Number: distance from left of canvas>,
   *   top: <Number: distance from top of canvas>,
   *   scale: <Number: initial scale of image>
   * }
   */
  addImg = async (dataUrl, isPdf = true, options = {}) => {
    let canvas = this._fc;
    const oImg = await fabric.FabricImage.fromURL(dataUrl, { crossOrigin: 'anonymous' });
    let scaleFactor = canvas.getWidth() / oImg.width;
    let viewportTransform = canvas.viewportTransform
    let screenTop = -viewportTransform[5];
    let screenLeft = -viewportTransform[4];
    let imageScaleFactor = 0.8;
    if (imageScaleFactor * oImg.width < canvas.getWidth()) {
      screenLeft += (canvas.getWidth() - imageScaleFactor * oImg.width) / 2
    }
    else {
      imageScaleFactor = (canvas.getWidth() / oImg.width)
    }
    //console.log("width of client, image",canvas.getWidth(),oImg.width);
    //console.log("height of client, image",canvas.getHeight(),oImg.height);
    if (imageScaleFactor * oImg.height < canvas.getHeight()) {
      screenTop += (canvas.getHeight() - imageScaleFactor * oImg.height) / 2
    }
    let opts = {
      left: isPdf ? 0 : screenLeft,
      top: isPdf ? this.state.totalImagesHeight : screenTop,
      scaleX: isPdf ? scaleFactor : imageScaleFactor,
      scaleY: isPdf ? scaleFactor : imageScaleFactor,
      selectable: !isPdf,
      evented: !isPdf,
      hasBorders: !isPdf,
      hasControls: !isPdf,
      hasRotatingPoint: !isPdf,
      lockMovementY: isPdf,
      lockMovementX: isPdf,
      isPdf: isPdf
    };
    Object.assign(opts, options);
    oImg.set(opts);
    console.log("img object", oImg)
    //console.log("scaleFactor is")
    canvas.add(oImg);
    if (isPdf)
      canvas.sendObjectToBack(oImg)
    //console.log("CROS anonymous")
  };

  /**
   * Action when an object is added to the canvas
   */
  _onObjectAdded = e => {
    const { onObjectAdded } = this.props;
    if (!this.state.action) {
      this.setState({ action: true });
      return;
    }
    let obj = e.target;
    if (obj.loadingSource !== 'file') {

      obj.__version = 1;
      // record current object state as json and save as originalState
      let objState = obj.toJSON(ToJSONFields);
      obj.__originalState = objState;
      let state = JSON.stringify(objState);
      if (obj.type === "image" && obj.isPdf) {
        //console.log("adding image",obj)
        //console.log("totalImagesHeight",this.state.totalImagesHeight)
        this.setState({
          totalImagesHeight: obj.height * obj.scaleY + obj.top,
        })
      }
      // object, previous state, current state
      this._history.keep([obj, state, state]);
      onObjectAdded(e);
    }
  };

  _onPathCreated = e => {
    const { onPathCreated } = this.props;
    // if (!this.state.action) {
    //   this.setState({ action: true });
    //   return;
    // }
    // let obj = e.target;
    // obj.__version = 1;
    // // record current object state as json and save as originalState
    // let objState = obj.toJSON();
    // obj.__originalState = objState;
    // let state = JSON.stringify(objState);
    // // object, previous state, current state
    // this._history.keep([obj, state, state]);
    onPathCreated(e);
  };

  /**
   * Action when an object is moving around inside the canvas
   */
  _onObjectMoving = e => {
    const { onObjectMoving } = this.props;
    onObjectMoving(e);
  };

  /**
   * Action when an object is scaling inside the canvas
   */
  _onObjectScaling = e => {
    const { onObjectScaling } = this.props;
    onObjectScaling(e);
  };

  _onBackgroundColorChanged = e => {
    const { onBackgroundColorChanged } = this.props;
    onBackgroundColorChanged(e);
  }

  _onPanChanged = e => {
    const { onPanChanged } = this.props;
    onPanChanged(e);
  }

  _onNewZoomChanged = e => {
    const { onNewZoomChanged } = this.props;
    // console.log("total zoomfactor is ", this.state.zoomFactors)
    // console.log('distane from origin', this.state.totalImagesHeight);
    onNewZoomChanged(e);
    // this.props.calculateNumberOfPages()
    // this.props.identifyCurrentPage()
  }

  _onZoomChanged = e => {
    const { onZoomChanged } = this.props;
    console.log("total zoomfactor is ", this.state.zoomFactors)
    if (e.zoomFactor) {
      this.setState({ zoomFactors: this.state.zoomFactors * e.zoomFactor })
    }
    onZoomChanged(e);
  }
  /**
   * Action when an object is rotating inside the canvas
   */
  _onObjectRotating = e => {
    const { onObjectRotating } = this.props;
    onObjectRotating(e);
  };

  _onObjectModified = e => {
    const { onObjectModified } = this.props;
    let obj = e.target;
    obj.__version += 1;
    let prevState = JSON.stringify(obj.__originalState);
    let objState = obj.toJSON(ToJSONFields);
    // record current object state as json and update to originalState
    obj.__originalState = objState;
    let currState = JSON.stringify(objState);
    this._history.keep([obj, prevState, currState]);
    onObjectModified(e);
  };

  _recordInHistoryOnObjectModificationReceived = (e) => {
    const { onObjectModified } = this.props;
    let obj = e.target;
    obj.__version += 1;
    let prevState = JSON.stringify(obj.__originalState);
    let objState = obj.toJSON(ToJSONFields);
    // record current object state as json and update to originalState
    obj.__originalState = objState;
    let currState = JSON.stringify(objState);
    this._history.keep([obj, prevState, currState]);
    // onObjectModified(e);
  };


  /**
   * Action when an object is removed from the canvas
   */
  _onObjectRemoved = e => {
    const { onObjectRemoved } = this.props;
    let obj = e.target;
    console.log('_onObjectRemoved function ~SketchField.jsx', obj);
    if (obj.__removed) {
      obj.__version += 1;
    }
    else
      obj.__version = 0;
    onObjectRemoved(e);
  };

  /**
   * Action when the mouse button is pressed down
   */
  _onMouseDown = e => {
    const { onMouseDown } = this.props;
    this._selectedTool.doMouseDown(e);
    onMouseDown(e);
  };

  /**
   * Action when the mouse cursor is moving around within the canvas
   */
  _onMouseMove = e => {
    const { onMouseMove } = this.props;
    this._selectedTool.doMouseMove(e);
    onMouseMove(e);
  };

  _onCanvasCleared = e => {
    const { onCanvasCleared } = this.props;
    console.log("_onCanvasCleared sketchField.js is called");
    this.setState({
      totalImagesHeight: 0,
    })
    onCanvasCleared(e);
  };

  /**
   * Action when the mouse cursor is moving out from the canvas
   */
  _onMouseOut = e => {
    const { onMouseOut } = this.props;
    this._selectedTool.doMouseOut(e);
    if (this.props.onChange) {
      let onChange = this.props.onChange;
      setTimeout(() => {
        onChange(e.e);
      }, 10);
    }
    onMouseOut(e);
  };

  _onMouseUp = e => {
    const { onMouseUp } = this.props;
    this._selectedTool.doMouseUp(e);
    //console.log("mouse up is called",e);
    // Update the final state to new-generated object
    // Ignore Path object since it would be created after mouseUp
    // Assumed the last object in canvas.getObjects() in the newest object
    if (this.props.tool === "Eraser") {
      const canvas = this._fc;
      let activeObjects = canvas.getActiveObjects();
      for (let obj of activeObjects) {
        obj.set("__removed", true);
        obj.set("removedBy", this.props.clientID)
        canvas.remove(obj);
      }
      canvas.renderAll();
    }
    if (this.props.tool === "Pan") {
      const canvas = this._fc;
      let { offsetWidth = 0, clientHeight = 0 } = this._container;
      let newObj = {
        clientID: this.props.clientID,
        eventType: "pan:changed",
        viewportTransform: canvas.viewportTransform,
        containerWidth: offsetWidth,
        containerHeight: clientHeight,
      }
      canvas.fire("pan:changed", newObj);
    }
    else if (this.props.tool === "Line" ||
      this.props.tool === "Arrow" ||
      this.props.tool === "Rectangle" ||
      this.props.tool === "Circle") {
      const canvas = this._fc;
      //console.log("cnavs points",canvas.getPointer(e))

      const objects = canvas.getObjects();
      const newObj = objects[objects.length - 1];
      if (newObj && newObj.__version === 1) {
        newObj.__originalState = newObj.toJSON(ToJSONFields);
      }
      //console.log("newobj",newObj);
      onMouseUp(newObj);
    }

    if (this.props.onChange) {
      let onChange = this.props.onChange;
      setTimeout(() => {
        onChange(e.e);
      }, 10);
    }
    //onMouseUp(e);
  };

  /**
   * Track the resize of the window and update our state
   *
   * @param e the resize event
   * @private
   */
  _resize = (e, canvasWidth = null, canvasHeight = null) => {
    if (e) e.preventDefault();
    let { widthCorrection, heightCorrection } = this.props;
    let canvas = this._fc;
    let { offsetWidth, clientHeight } = this._container;
    let prevWidth = canvasWidth || canvas.getWidth();
    let prevHeight = canvasHeight || canvas.getHeight();
    let wfactor = ((offsetWidth - widthCorrection) / prevWidth).toFixed(2);
    let hfactor = ((clientHeight - heightCorrection) / prevHeight).toFixed(2);
    canvas.setWidth(offsetWidth - widthCorrection);
    canvas.setHeight(clientHeight - heightCorrection);
    if (canvas.backgroundImage) {
      // Need to scale background images as well
      let bi = canvas.backgroundImage;
      bi.width = bi.width * wfactor;
      bi.height = bi.height * hfactor;
    }
    let objects = canvas.getObjects();
    for (let i in objects) {
      let obj = objects[i];
      let scaleX = obj.scaleX;
      let scaleY = obj.scaleY;
      let left = obj.left;
      let top = obj.top;
      let tempScaleX = scaleX * wfactor;
      let tempScaleY = scaleY * hfactor;
      let tempLeft = left * wfactor;
      let tempTop = top * hfactor;
      obj.scaleX = tempScaleX;
      obj.scaleY = tempScaleY;
      obj.left = tempLeft;
      obj.top = tempTop;
      obj.setCoords();
    }
    canvas.renderAll();
    canvas.calcOffset();
  };

  /**
   * Sets the background color for this sketch
   * @param color in rgba or hex format
   */
  changeBackgroundColor = e => {
    if (!e) return;
    let canvas = this._fc;
    // console.log("changeBackgroundColor sketchField", e);
    this.setBackgroundColorForCanvas(canvas, e.color)

    // if (e.backgroundPattern) {
    //   let colorOfPattern = '#9F9EB2';
    //   if (e.patternColor) {
    //     colorOfPattern = e.patternColor
    //   }
    //   let tileSvgString
    //   if (e.patternType === 'grid') {

    //     tileSvgString = `<svg  style="background-color:${e.color}" width="600" height="800" xmlns="http://www.w3.org/2000/svg">
    //     <!-- Background -->
    //     <rect width="100%" height="100%" />

    //     <!-- Border on the right side and top -->
    //     <rect x="0" y="0" width="100%" height="100%" fill="${e.color}" stroke="${colorOfPattern}" stroke-width="1"/>

    //     <!-- Horizontal Lines -->
    //     <g stroke="${colorOfPattern}" stroke-width="1">
    //       <line x1="0" y1="50" x2="600" y2="50"/>
    //       <line x1="0" y1="100" x2="600" y2="100"/>
    //       <line x1="0" y1="150" x2="600" y2="150"/>
    //       <line x1="0" y1="200" x2="600" y2="200"/>
    //       <line x1="0" y1="250" x2="600" y2="250"/>
    //       <line x1="0" y1="300" x2="600" y2="300"/>
    //       <line x1="0" y1="350" x2="600" y2="350"/>
    //       <line x1="0" y1="400" x2="600" y2="400"/>
    //       <line x1="0" y1="450" x2="600" y2="450"/>
    //       <line x1="0" y1="500" x2="600" y2="500"/>
    //       <line x1="0" y1="550" x2="600" y2="550"/>
    //       <line x1="0" y1="600" x2="600" y2="600"/>
    //       <line x1="0" y1="650" x2="600" y2="650"/>
    //       <line x1="0" y1="700" x2="600" y2="700"/>
    //       <line x1="0" y1="750" x2="600" y2="750"/>
    //     </g>

    //     <!-- Vertical Lines -->
    //     <g stroke="${colorOfPattern}" stroke-width="1">
    //       <line x1="50" y1="0" x2="50" y2="800"/>
    //       <line x1="100" y1="0" x2="100" y2="800"/>
    //       <line x1="150" y1="0" x2="150" y2="800"/>
    //       <line x1="200" y1="0" x2="200" y2="800"/>
    //       <line x1="250" y1="0" x2="250" y2="800"/>
    //       <line x1="300" y1="0" x2="300" y2="800"/>
    //       <line x1="350" y1="0" x2="350" y2="800"/>
    //       <line x1="400" y1="0" x2="400" y2="800"/>
    //       <line x1="450" y1="0" x2="450" y2="800"/>
    //       <line x1="500" y1="0" x2="500" y2="800"/>
    //       <line x1="550" y1="0" x2="550" y2="800"/>
    //     </g>
    //   </svg>`
    //     this.setBackgroundImageForCanvas(canvas, inlineSVGString(tileSvgString))
    //     //     canvas.setBackgroundColor({ source: inlineSVGString(tileSvgString) }, () => setTimeout(() => canvas.requestRenderAll(), 0));
    //   }
    //   else if (e.patternType === 'dotted-grid') {
    //     let size = 20
    //     let gap = 30
    //     const circlePositions = [[gap, 0], [0, 0], [gap, gap], [0, gap]]
    //     const circleStyle = `fill:${colorOfPattern};stroke:#9d5867;stroke-width:0;`
    //     let r = 2
    //     tileSvgString = `<svg style="background-color:${e.color}" width="${size}" height="${size}" viewBox="0 0 ${size} ${size}" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs/><g>
    //     ${circlePositions.map(([cx, cy]) => `<circle style="${circleStyle}" cx="${cx}" cy="${cy}" r="${r}"/>`).join("\n")}
    // </g></svg>`
    //     this.setBackgroundImageForCanvas(canvas, inlineSVGString(tileSvgString))
    //     //    canvas.setBackgroundColor({ source: inlineSVGString(tileSvgString) }, () => setTimeout(() => canvas.requestRenderAll(), 0));
    //   }
    //   else if (e.patternType === 'horizontal-lines') {
    //     tileSvgString = `<svg width="600" height="400" xmlns="http://www.w3.org/2000/svg">
    //     <!-- Background -->
    //     <rect width="100%" height="100%" fill="${e.color}"/>

    //     <!-- Lines -->
    //     <g stroke="${colorOfPattern}" stroke-width="1">
    //       <line x1="0" y1="50" x2="600" y2="50"/>
    //       <line x1="0" y1="100" x2="600" y2="100"/>
    //       <line x1="0" y1="150" x2="600" y2="150"/>
    //       <line x1="0" y1="200" x2="600" y2="200"/>
    //       <line x1="0" y1="250" x2="600" y2="250"/>
    //       <line x1="0" y1="300" x2="600" y2="300"/>
    //       <line x1="0" y1="350" x2="600" y2="350"/>
    //     </g>

    //     <!-- Border at the bottom -->
    //     <line x1="0" y1="400" x2="600" y2="400" stroke="${colorOfPattern}" stroke-width="2"/>
    //   </svg>`
    //     // canvas.setBackgroundColor({ source: inlineSVGString(tileSvgString) }, () => setTimeout(() => canvas.requestRenderAll(), 0));
    //     this.setBackgroundImageForCanvas(canvas, inlineSVGString(tileSvgString))
    //   }
    //   else if (e.patternType === 'None') {
    //     this.setBackgroundColorForCanvas(canvas, e.color)
    //     // canvas.setBackgroundColor(e.color, () => canvas.renderAll());
    //   }
    //   else {
    //     let size = 20
    //     let gap = 30
    //     const circlePositions = [[gap, 0], [0, 0], [gap, gap], [0, gap]]
    //     const circleStyle = `fill:${colorOfPattern};stroke:#9d5867;stroke-width:0;`
    //     let r = 2
    //     tileSvgString = `<svg style="background-color:${e.color}" width="${size}" height="${size}" viewBox="0 0 ${size} ${size}" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs/><g>
    //     ${circlePositions.map(([cx, cy]) => `<circle style="${circleStyle}" cx="${cx}" cy="${cy}" r="${r}"/>`).join("\n")}
    // </g></svg>`
    //     this.setBackgroundImageForCanvas(canvas, inlineSVGString(tileSvgString))
    //     //    canvas.setBackgroundColor({ source: inlineSVGString(tileSvgString) }, () => setTimeout(() => canvas.requestRenderAll(), 0));
    //   }

    // }

    // else {
    //   this.setBackgroundColorForCanvas(canvas, e.color)
    //   // canvas.setBackgroundColor(e.color, () => canvas.renderAll());
    // }

    canvas.fire("backgroundColor:changed", e);
  };

  //fabric version 6
  setBackgroundColorForCanvas = (canvas, color) => {
    console.log(canvas)
    canvas.backgroundColor = color;
    canvas.requestRenderAll()
    console.log('changing bg', color)
  }

  setBackgroundImageForCanvas = (canvas, img) => {
    // console.log(canvas)
    // canvas.backgroundImage = img;
    // canvas.requestRenderAll()
    // console.log('changing bg', img)
  }

  changePan = (obj) => {
    if (obj.viewportTransform) {
      this._fc.setViewportTransform(obj.viewportTransform)
      this._fc.renderAll();
      this._fc.fire("pan:changed", obj);
    }

  }

  /**
   * Zoom the drawing by the factor specified
   *
   * The zoom factor is a percentage with regards the original, for example if factor is set to 2
   * it will double the size whereas if it is set to 0.5 it will half the size
   *
   * @param factor the zoom factor
   */
  zoom = obj => {
    if (obj.zoomFactor) {
      let factor = obj.zoomFactor;
      let canvas = this._fc;
      let objects = canvas.getObjects();
      for (let i in objects) {
        objects[i].scaleX = objects[i].scaleX * factor;
        objects[i].scaleY = objects[i].scaleY * factor;
        objects[i].left = objects[i].left * factor;
        objects[i].top = objects[i].top * factor;
        objects[i].setCoords();
      }
      canvas.renderAll();
      canvas.calcOffset();
      canvas.fire("zoom:changed", obj);
    }
  };

  newZoom = (obj, fireZoomChangedEvent = true) => {
    if (obj.zoomLevel) {
      let canvas = this._fc;
      console.log('setting zoom level with factor', obj.zoomLevel)
      canvas.zoomToPoint(new fabric.Point(canvas.width / 2, canvas.height / 2), obj.zoomLevel);
      console.log('after setting zoom level', canvas.getZoom())
      canvas.renderAll();
      if (fireZoomChangedEvent) {
        canvas.fire("newZoom:changed", obj);
      }
    }
  };

  /**
   * Perform an undo operation on canvas, if it cannot undo it will leave the canvas intact
   */
  undo = () => {
    let history = this._history;
    let [obj, prevState, currState] = history.getCurrent();
    history.undo();
    if (obj.__removed) {
      this.setState({ action: false }, () => {
        this._fc.add(obj);
        obj.__version -= 1;
        obj.__removed = false;
      });
    } else if (obj.__version <= 1) {
      this._fc.remove(obj);
    } else {
      obj.__version -= 1;
      obj.setOptions(JSON.parse(prevState));
      obj.setCoords();
      this._fc.renderAll();
    }
    if (this.props.onChange) {
      this.props.onChange();
    }
  };

  /**
   * Perform a redo operation on canvas, if it cannot redo it will leave the canvas intact
   */
  redo = () => {
    let history = this._history;
    if (history.canRedo()) {
      let canvas = this._fc;
      //noinspection Eslint
      let [obj, prevState, currState] = history.redo();
      if (obj.__version === 0) {
        this.setState({ action: false }, () => {
          canvas.add(obj);
          obj.__version = 1;
        });
      } else {
        obj.__version += 1;
        obj.setOptions(JSON.parse(currState));
      }
      obj.setCoords();
      canvas.renderAll();
      if (this.props.onChange) {
        this.props.onChange();
      }
    }
  };

  /**
   * Delegation method to check if we can perform an undo Operation, useful to disable/enable possible buttons
   *
   * @returns {*} true if we can undo otherwise false
   */
  canUndo = () => {
    return this._history.canUndo();
  };

  /**
   * Delegation method to check if we can perform a redo Operation, useful to disable/enable possible buttons
   *
   * @returns {*} true if we can redo otherwise false
   */
  canRedo = () => {
    return this._history.canRedo();
  };

  /**
   * Exports canvas element to a dataurl image. Note that when multiplier is used, cropping is scaled appropriately
   *
   * Available Options are
   * <table style="width:100%">
   *
   * <tr><td><b>Name</b></td><td><b>Type</b></td><td><b>Argument</b></td><td><b>Default</b></td><td><b>Description</b></td></tr>
   * <tr><td>format</td> <td>String</td> <td><optional></td><td>png</td><td>The format of the output image. Either "jpeg" or "png"</td></tr>
   * <tr><td>quality</td><td>Number</td><td><optional></td><td>1</td><td>Quality level (0..1). Only used for jpeg.</td></tr>
   * <tr><td>multiplier</td><td>Number</td><td><optional></td><td>1</td><td>Multiplier to scale by</td></tr>
   * <tr><td>left</td><td>Number</td><td><optional></td><td></td><td>Cropping left offset. Introduced in v1.2.14</td></tr>
   * <tr><td>top</td><td>Number</td><td><optional></td><td></td><td>Cropping top offset. Introduced in v1.2.14</td></tr>
   * <tr><td>width</td><td>Number</td><td><optional></td><td></td><td>Cropping width. Introduced in v1.2.14</td></tr>
   * <tr><td>height</td><td>Number</td><td><optional></td><td></td><td>Cropping height. Introduced in v1.2.14</td></tr>
   *
   * </table>
   *
   * @returns {String} URL containing a representation of the object in the format specified by options.format
   */
  toDataURL = options => this._fc.toDataURL(options);

  /**
   * Returns JSON representation of canvas
   *
   * @param propertiesToInclude Array <optional> Any properties that you might want to additionally include in the output
   * @returns {string} JSON string
   */
  toJSON = propertiesToInclude => {
    if (propertiesToInclude) {
      return this._fc.toJSON(ToJSONFields);
    } else {
      return this._fc.toJSON(ToJSONFields);
    }
  };

  /**
   * Populates canvas with data from the specified JSON.
   *
   * JSON format must conform to the one of fabric.Canvas#toDatalessJSON
   *
   * @param json JSON string or object
   */
  fromJSON = json => {
    if (!json) return;
    console.log("got", json)
    let canvas = this._fc;
    setTimeout(() => {
      canvas.loadFromJSON(json, () => {
        if (this.props.tool === Tool.DefaultTool) {
          canvas.isDrawingMode = canvas.selection = false;
          canvas.forEachObject(o => (o.selectable = o.evented = false));
        }
        canvas.renderAll();
        if (this.props.onChange) {
          this.props.onChange();
        }
      });
    }, 100);
  };

  /**
   * Clear the content of the canvas, this will also clear history but will return the canvas content as JSON to be
   * used as needed in order to undo the clear if possible
   *
   * @param propertiesToInclude Array <optional> Any properties that you might want to additionally include in the output
   * @returns {string} JSON string of the canvas just cleared
   */


  clear = (object) => {
    this._fc.setViewportTransform([1, 0, 0, 1, 0, 0])
    this._fc.clear();
    this._history.clear();
    this.setState({
      totalImagesHeight: 0,
      zoomFactors: 1,
    });
    if (!this.props.isPlayer)
      this._fc.fire('canvas:objects:cleared', object);
  };

  hasSelection = () => {
    let canvas = this._fc;
    return !!canvas.getActiveObject();
  };

  clearSelection = () => {
    let canvas = this._fc;
    canvas.discardActiveObject();
    canvas.requestRenderAll();
  };

  /**
   * Remove selected object from the canvas
   */
  removeSelected = () => {
    let canvas = this._fc;
    let activeObj = canvas.getActiveObject();
    if (activeObj) {
      let selected = [];
      if (activeObj.type === 'activeSelection') {
        activeObj.forEachObject(obj => selected.push(obj));
      } else {
        selected.push(activeObj);
      }
      //console.log("selected objects",activeObj,selected)
      selected.forEach(obj => {
        obj.__removed = true;
        obj.set("removedBy", this.props.clientID)
        let objState = obj.toJSON(ToJSONFields);
        obj.__originalState = objState;
        let state = JSON.stringify(objState);
        this._history.keep([obj, state, state]);
        canvas.remove(obj);
      });
      canvas.discardActiveObject();
      canvas.requestRenderAll();
    }
  };

  copy = () => {
    let canvas = this._fc;
    canvas.getActiveObject().clone(cloned => (this._clipboard = cloned));
  };

  paste = () => {
    // clone again, so you can do multiple copies.
    this._clipboard.clone(clonedObj => {
      let canvas = this._fc;
      canvas.discardActiveObject();
      clonedObj.set({
        left: clonedObj.left + 10,
        top: clonedObj.top + 10,
        evented: true
      });
      if (clonedObj.type === 'activeSelection') {
        // active selection needs a reference to the canvas.
        clonedObj.canvas = canvas;
        clonedObj.forEachObject(obj => canvas.add(obj));
        clonedObj.setCoords();
      } else {
        canvas.add(clonedObj);
      }
      this._clipboard.top += 10;
      this._clipboard.left += 10;
      canvas.setActiveObject(clonedObj);
      canvas.requestRenderAll();
    });
  };

  /**
   * Sets the background from the dataUrl given
   *
   * @param dataUrl the dataUrl to be used as a background
   * @param options
   */
  setBackgroundFromDataUrl = (dataUrl, options = {}) => {
    let canvas = this._fc;
    let img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');
    const { stretched, stretchedX, stretchedY, ...fabricOptions } = options;
    img.onload = () => {
      const imgObj = new fabric.Image(img);
      if (stretched || stretchedX) imgObj.scaleToWidth(canvas.width);
      if (stretched || stretchedY) imgObj.scaleToHeight(canvas.height);
      canvas.setBackgroundImage(
        imgObj,
        () => canvas.renderAll(),
        fabricOptions
      );
    };
    img.src = dataUrl;
  };

  addText = (text, options = {}) => {
    let canvas = this._fc;
    let iText = new fabric.IText(text, options);
    let opts = {
      left: (canvas.getWidth() - iText.width) * 0.5,
      top: (canvas.getHeight() - iText.height) * 0.5
    };
    let viewportTransform = canvas.viewportTransform;
    Object.assign(options, opts);
    iText.set({
      left: options.left - viewportTransform[4],
      top: options.top - viewportTransform[5]
    });

    canvas.add(iText);
  };

  getObjectById = objectID => {
    let objs = this._fc.getObjects();
    let arr = []
    for (let i = 0, len = objs.length; i < len; i++) {
      if (objs[i].objectID === objectID) {
        arr.push(objs[i]);
      }
    }
    return arr;
  };
  //This function is called when any  modification is received
  // from another client.When we set an object in the current canvas [object:modify] is not fired
  //Any other modification done through UI by this client will fire [object:modify] which will lead to the
  // a call to onObjectModified. In that function we can assume that the object was modified by this client
  modifyObj = object => {
    // let canvas = this._fc;
    this.removeObj(object);
    this.addObj(object);
    //existingObj.set(object)
    //if(existingObj.type=="line"){
    //  existingObj.top = object.top
    //  existingObj.left = object.left
    //}
    //console.log("received object for modification1",existingObj)
    //existingObj.set('dirty', true);

    //existingObj.setCoords();
    //canvas.requestRenderAll();
    //canvas.calcOffset();
    //this._recordInHistoryOnObjectModificationReceived({ target: existingObj });
    // canvas.fire("object:modified",{target:existingObj});

    //  console.log("🚀 ~ file: SketchField.jsx ~ line 670 ~ SketchField ~ existingObj", existingObj)
    // console.log("🚀 ~ file: SketchField.jsx ~ line 682 ~ SketchField ~ existingObj", existingObj)
    // console.log("🚀 ~ file: SketchField.jsx ~ line 682 ~ SketchField ~ existingObj", existingObj)
  };

  addObj = object => {
    console.log(
      '🚀addObj ~SketchField.jsx ',
      object
    );
    let canvas = this._fc;
    fabric.util.enlivenObjects([object], {})
      .then(objs => {
        // console.log(
        //   '🚀 ~ file: SketchField.jsx ~ line 673 ~ SketchField ~ fabric.util.enlivenObjects ~ objects',
        //   objects
        // );
        let origRenderOnAddRemove = canvas.renderOnAddRemove;
        // console.log(
        //   '🚀 ~ file: SketchField.jsx ~ line 674 ~ SketchField ~ fabric.util.enlivenObjects ~ origRenderOnAddRemove',
        //   origRenderOnAddRemove
        // );
        canvas.renderOnAddRemove = true;

        objs.forEach(function (o) {

          canvas.add(o);
          if (o.isPdf)
            canvas.sendObjectToBack(o)
        });

        canvas.renderOnAddRemove = origRenderOnAddRemove;
        canvas.renderAll();
      })

  };
  removeObj = object => {
    let canvas = this._fc;
    let existingObjects = this.getObjectById(object.objectID);
    for (let existingObj of existingObjects) {
      existingObj.set('__removed', true);
      canvas.remove(existingObj);
      // this._onObjectRemoved({target:existingObj})
      // canvas.fire("object:removed",{target:existingObj});
      canvas.renderAll();
    }

  };

  callEvent = (e, eventFunction) => {
    if (this._selectedTool) eventFunction(e);
  };

  componentDidMount = () => {
    let { tool, value, undoSteps, defaultValue, backgroundColorObject } = this.props;

    let canvas = (this._fc = new fabric.Canvas(this._canvas, {
      preserveObjectStacking: true,
      //  renderOnAddRemove: false,
      //  skipTargetFind: true
      stateful: true,
      selection: !this.props.isPlayer,
      allowTouchScrolling: true,
      isDrawingMode: !this.props.isPlayer,
      skipTargetFind: this.props.isPlayer,
    }));

    this._initTools(canvas);

    // set initial backgroundColor
    this.changeBackgroundColor(backgroundColorObject);

    let selectedTool = this._tools[tool];
    if (selectedTool) selectedTool.configureCanvas(this.props);
    this._selectedTool = selectedTool;

    // Control resize
    window.addEventListener('resize', this._resize, false);

    // Initialize History, with maximum number of undo steps
    this._history = new History(undoSteps);

    // Events binding
    canvas.on('object:added', e => this.callEvent(e, this._onObjectAdded));
    canvas.on('path:created', e => this.callEvent(e, this._onPathCreated));

    canvas.on('object:modified', e =>
      this.callEvent(e, this._onObjectModified)
    );
    canvas.on('object:removed', e => this.callEvent(e, this._onObjectRemoved));
    canvas.on('canvas:objects:cleared', e => this.callEvent(e, this._onCanvasCleared));
    canvas.on('mouse:down', e => this.callEvent(e, this._onMouseDown));
    canvas.on('mouse:move', e => this.callEvent(e, this._onMouseMove));
    canvas.on('mouse:up', e => this.callEvent(e, this._onMouseUp));
    canvas.on('mouse:out', e => this.callEvent(e, this._onMouseOut));
    canvas.on('object:moving', e => this.callEvent(e, this._onObjectMoving));
    canvas.on('object:scaling', e => this.callEvent(e, this._onObjectScaling));
    canvas.on('backgroundColor:changed', e => this.callEvent(e, this._onBackgroundColorChanged));
    canvas.on('object:rotating', e =>
      this.callEvent(e, this._onObjectRotating)
    );
    canvas.on('pan:changed', e => this.callEvent(e, this._onPanChanged));
    canvas.on('zoom:changed', e => this.callEvent(e, this._onZoomChanged));
    canvas.on('newZoom:changed', e => this.callEvent(e, this._onNewZoomChanged))
    // IText Events fired on Adding Text
    // canvas.on("text:event:changed", console.log)
    // canvas.on("text:selection:changed", console.log)
    // canvas.on("text:editing:entered", console.log)
    // canvas.on("text:editing:exited", console.log)

    this.disableTouchScroll();

    this._resize();

    // initialize canvas with controlled value if exists

    (value || defaultValue) && this.fromJSON(value || defaultValue);
  };
  componentWillUnmount = () =>
    window.removeEventListener('resize', this._resize);

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.width !== prevProps.width ||
      this.props.height !== prevProps.height
    ) {
      this._resize();
    }

    if (this.props.tool !== prevProps.tool) {
      this._selectedTool = this._tools[this.props.tool];
      //Bring the cursor back to default if it is changed by a tool
      this._fc.defaultCursor = 'default';
      if (this._selectedTool) {
        this._selectedTool.configureCanvas(this.props);
      }
    }
    if (this.props.lineColor !== prevProps.lineColor) {
      if (this._selectedTool) {
        this._selectedTool.configureCanvas(this.props);
      }
    }
    if (this.props.lineWidth !== prevProps.lineWidth) {
      if (this._selectedTool) {
        this._selectedTool.configureCanvas(this.props);
      }
    }

    if (this.props.highlighterColor !== prevProps.highlighterColor) {
      if (this._selectedTool) {
        this._selectedTool.configureCanvas(this.props);
      }
    }
    if (this.props.highlighterWidth !== prevProps.highlighterWidth) {
      if (this._selectedTool) {
        this._selectedTool.configureCanvas(this.props);
      }
    }

    if (
      this.props.value !== prevProps.value ||
      (this.props.value && this.props.forceValue)
    ) {
      this.fromJSON(this.props.value);
    }
  };

  render = () => {
    let { className, style, width, height } = this.props;
    let canvasDivStyle = Object.assign(
      {},
      style ? style : {},
      width ? { width: width } : {},
      height ? { height: height } : {}
    );
    return (
      <div
        className={className}
        ref={c => (this._container = c)}
        style={canvasDivStyle}
      >
        <canvas id={uuid4()} ref={c => (this._canvas = c)} height={window.innerHeight}>
          Sorry, Canvas HTML5 element is not supported by your browser :(
        </canvas>
      </div>
    );
  };
}

export default SketchField;
