import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { IconButton, Box, Grid } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";

import Draggable from "react-draggable";

const MediaPlayer = forwardRef((props, ref) => {
    const [openMediaPlayer, setOpenMediaPlayer] = useState(false);
    const [mediaUrl, setMediaUrl] = useState('')
    const [isAudio, setIsAudio] = useState(false)
    const [positionFromLeft, setPositionFromLeft] = useState('80px')
    const [positionFromTop, setPositionFromTop] = useState('12.5%')
    const [playerWidth, setPlayerWidth] = useState('50vw')
    const [playerHeight, setPlayerHeight] = useState('60px')

    useImperativeHandle(ref, () => ({
        handleOpenMediaPlayer,
        handleMediaUrl,
        handleisAudio,
        handlePositionOfPlayer,
        handleSizeOfPlayer
    }));

    const handlePositionOfPlayer = (left, top) => {
        setPositionFromLeft(left)
        setPositionFromTop(top)
    }

    const handleSizeOfPlayer = (width, height) => {
        setPlayerHeight(height)
        setPlayerWidth(width)
    }

    const handleOpenMediaPlayer = (value) => {
        setOpenMediaPlayer(value)

    }


    const handleMediaUrl = (value) => {
        setMediaUrl(value)

    }
    const handleisAudio = (value) => {
        setIsAudio(value)

    }

    useEffect(() => {

    }, [mediaUrl, openMediaPlayer])

    return <>

        {openMediaPlayer && <Box sx={{
            position: 'fixed',
            left: positionFromLeft,
            top: positionFromTop,
            boxShadow: "2",
            borderRadius: '10px',
            zIndex: 105,
            backgroundColor: 'black'
        }}>
            <Box sx={{ position: 'relative', overscrollBehavior: "none", maxHeight: '100%', minHeight: '25px' }} >
                <Grid container sx={{ color: 'black', height: '10px', position: 'sticky', right: 0, top: 0, zIndex: 10000, width: playerWidth }} justifyContent='right' textAlign='right'>
                    <IconButton
                        sx={{ color: 'white', position: 'sticky', zIndex: 10000 }}
                        onClick={() => {
                            setOpenMediaPlayer(false);
                            props.sendLessonEventViaWebSocket({
                                eventType: 'handleMediaPlayer',
                                eventData: {
                                    'openMediaPlayer': false,
                                    'sentBy': props.userName,
                                    'mediaUrl': '',
                                    'fileType': ''
                                }
                            })
                        }}
                        size="small">
                        <CloseIcon sx={{ fontSize: '1rem' }} />
                    </IconButton>
                </Grid>
                {isAudio ? <Box sx={{
                    // display: maximize ? 'block' : 'none',
                    height: playerHeight,
                    padding: '10px 10px 20px 10px',
                    width: playerWidth
                }}>
                    <video
                        key={mediaUrl}
                        id='video-player-lesson'
                        width="100%"
                        height="100%"
                        autoPlay
                        controls
                        onPause={() => {
                            props.sendLessonEventViaWebSocket({
                                eventType: 'toggleMediaPlayerState',
                                eventData: {
                                    'sentBy': props.userName,
                                    'mediaPlayerState': 'pause',
                                }
                            })
                        }}
                        onPlay={() => {
                            props.sendLessonEventViaWebSocket({
                                eventType: 'toggleMediaPlayerState',
                                eventData: {
                                    'sentBy': props.userName,
                                    'mediaPlayerState': 'play',
                                }
                            })
                        }}
                    // onSeeked={(e) => {
                    //     console.log(e)
                    //     console.log(document.getElementById('video-player-lesson').currentTime)
                    //     // document.getElementById("video-player-lesson").pause()
                    //     // document.getElementById('video-player-lesson').currentTime = 10
                    //     props.sendLessonEventViaWebSocket({
                    //         eventType: 'toggleMediaPlayerState',
                    //         eventData: {
                    //             'sentBy': props.userName,
                    //             'newTime': document.getElementById('video-player-lesson').currentTime
                    //         }
                    //     })
                    // }}
                    >
                        <source src={mediaUrl} type="video/mp4"></source>
                        Your browser does not support the video tag.
                    </video>
                </Box> :
                    <Box sx={{
                        height: playerHeight,
                        padding: '10px 5px 10px 5px',
                        width: playerWidth
                    }}>
                        <video
                            key={mediaUrl}
                            id='video-player-lesson'
                            width="100%"
                            height="100%"
                            autoPlay
                            controls
                            onPause={() => {
                                props.sendLessonEventViaWebSocket({
                                    eventType: 'toggleMediaPlayerState',
                                    eventData: {
                                        'sentBy': props.userName,
                                        'mediaPlayerState': 'pause',
                                    }
                                })
                            }}
                            onPlay={() => {
                                props.sendLessonEventViaWebSocket({
                                    eventType: 'toggleMediaPlayerState',
                                    eventData: {
                                        'sentBy': props.userName,
                                        'mediaPlayerState': 'play',
                                    }
                                })
                            }}
                        >
                            <source src={mediaUrl} type="video/mp4"></source>
                            Your browser does not support the video tag.
                        </video>
                    </Box>}

            </Box>
        </Box>}

    </>;
});

export default MediaPlayer