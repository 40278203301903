import * as React from 'react';
import { Participant, TrackPublication } from 'livekit-client';
import { Track } from 'livekit-client';
// import { ParticipantClickEvent, TrackReferenceOrPlaceholder } from '@livekit/components-core';
import { isTrackReference, isTrackReferencePinned } from '@livekit/components-core';
import { ConnectionQualityIndicator, FocusToggle } from '@livekit/components-react';
import { ParticipantName } from '@livekit/components-react';
import { TrackMutedIndicator } from '@livekit/components-react';
import {
    ParticipantContext,
    TrackRefContext,
    useEnsureParticipant,
    useFeatureContext,
    useMaybeLayoutContext,
    useMaybeParticipantContext,
    useMaybeTrackRefContext,
} from '@livekit/components-react';
import { ParticipantPlaceholder } from '../assets/images';
import { LockLockedIcon, ScreenShareIcon } from '../assets/icons';
import { VideoTrack, AudioTrack } from '@livekit/components-react';
import { useParticipantTile } from '@livekit/components-react';
import { useIsEncrypted } from '../hooks/useIsEncrypted';
import { getNameFromEmail } from '../../../components/utils';
import { CustomConnectionQualityIndicator } from './CustomConnectionQualityIndicator';
import { CustomParticipantPlaceholder } from './CustomParticipantPlaceholder';
import { Box, IconButton } from '@mui/material';
import ScreenshotIcon from '@mui/icons-material/Screenshot';

/**
 * The `ParticipantContextIfNeeded` component only creates a `ParticipantContext`
 * if there is no `ParticipantContext` already.
 * @example
 * ```tsx
 * <ParticipantContextIfNeeded participant={trackReference.participant}>
 *  ...
 * </ParticipantContextIfNeeded>
 * ```
 * @public
 */
export function ParticipantContextIfNeeded(props) {
    const hasContext = !!useMaybeParticipantContext()
    return props.participant && !hasContext ? (
        <ParticipantContext.Provider value={props.participant}>
            {props.children}
        </ParticipantContext.Provider>
    ) : (
        <>{props.children}</>
    )
}

/**
 * Only create a `TrackRefContext` if there is no `TrackRefContext` already.
 */
function TrackRefContextIfNeeded(props) {
    const hasContext = !!useMaybeTrackRefContext()
    return props.trackRef && !hasContext ? (
        <TrackRefContext.Provider value={props.trackRef}>
            {props.children}
        </TrackRefContext.Provider>
    ) : (
        <>{props.children}</>
    )
}


/** @public */
// export  ParticipantTileProps extends React.HTMLAttributes {
//   /** The track reference to display. */
//   trackRef?: TrackReferenceOrPlaceholder;
//   disableSpeakingIndicator?: boolean;
//   /** @deprecated This parameter will be removed in a future version use `trackRef` instead. */
//   participant?: Participant;
//   /** @deprecated This parameter will be removed in a future version use `trackRef` instead. */
//   source?: Track.Source;
//   /** @deprecated This parameter will be removed in a future version use `trackRef` instead. */
//   publication?: TrackPublication;
//   onParticipantClick?: (event: ParticipantClickEvent) => void;
// }

/**
 * The `ParticipantTile` component is the base utility wrapper for displaying a visual representation of a participant.
 * This component can be used as a child of the `TrackLoop` component or by passing a track reference as property.
 *
 * @example Using the `ParticipantTile` component with a track reference:
 * ```tsx
 * <ParticipantTile trackRef={trackRef} />
 * ```
 * @example Using the `ParticipantTile` component as a child of the `TrackLoop` component:
 * ```tsx
 * <TrackLoop>
 *  <ParticipantTile />
 * </TrackLoop>
 * ```
 * @public
 */
export function CustomParticipantTile({
    trackRef,
    participant,
    children,
    source = Track.Source.Camera,
    onParticipantClick,
    publication,
    disableSpeakingIndicator,
    slateRef,
    accessToken,
    whiteBoardID,
    userName,
    isSideView,
    ...htmlProps
}) {
    // TODO: remove deprecated props and refactor in a future version.
    const [showScreenshotButton, setShowScreenshotButton] = React.useState(false)
    const maybeTrackRef = useMaybeTrackRefContext();
    const p = useEnsureParticipant(participant);
    const trackReference = React.useMemo(() => {
        return {
            participant: trackRef?.participant ?? maybeTrackRef?.participant ?? p,
            source: trackRef?.source ?? maybeTrackRef?.source ?? source,
            publication: trackRef?.publication ?? maybeTrackRef?.publication ?? publication,
        };
    }, [maybeTrackRef, p, publication, source, trackRef]);

    const { elementProps } = useParticipantTile({
        participant: trackReference.participant,
        htmlProps,
        source: trackReference.source,
        publication: trackReference.publication,
        disableSpeakingIndicator,
        onParticipantClick,
    });
    const isEncrypted = useIsEncrypted(p);
    const layoutContext = useMaybeLayoutContext();

    const autoManageSubscription = useFeatureContext()?.autoSubscription;

    const handleSubscribe = React.useCallback(
        (subscribed) => {
            if (
                trackReference.source &&
                !subscribed &&
                layoutContext &&
                layoutContext.pin.dispatch &&
                isTrackReferencePinned(trackReference, layoutContext.pin.state)
            ) {
                layoutContext.pin.dispatch({ msg: 'clear_pin' });
            }
        },
        [trackReference, layoutContext],
    );

    function takeScreenshot(tileID, isVideoTile) {
        let screenshotID
        if (isVideoTile) {
            screenshotID = tileID + '-video';
        }
        else {
            screenshotID = tileID + '-screenShare'
        }
        let canvas = document.createElement('canvas');
        let video = null;
        if (document.getElementById(screenshotID) !== null)
            video = document.getElementById(screenshotID).getElementsByTagName('video')[0];
        // console.log(screenshotID)
        // console.log(video)
        if (video !== null) {
            canvas.width = 960
            canvas.height = 540;

            let ctx = canvas.getContext('2d');
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

            let image = canvas.toDataURL('image/jpeg');
            // console.log(image)
            // console.log(whiteBoardID)
            // console.log(whiteBoardRef)
            if (window.pasteImageToWhiteboard) {
                if (window.addImgToSketch)
                    window.pasteImageToWhiteboard(image, window.addImgToSketch, whiteBoardID, true)
            }


            // const a = document.createElement("a");
            // a.href = image;
            // a.download = 'screenshot';
            // a.click();
            canvas.remove();
        }
    }


    return (<>
        {trackReference.participant.identity !== 'recordbot' &&
            <div
                id={trackReference.source === Track.Source.Camera ? trackReference.participant.identity + '-video-tile' : trackReference.participant.identity + '-screenShare-tile'}
                style={{
                    position: 'relative',
                    maxHeight: '80vh',
                    height: isSideView ? (window.innerHeight > 700 ? '100%' : window.innerHeight > 650 ? '20.5vh' : '120px') : '100%'
                }}
                {...elementProps}
            >
                <div id={trackReference.participant.identity + '-smiley'} style={{
                    display: 'none',
                    position: 'absolute',
                    bottom: '0',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '80px',
                    height: '100%',
                    width: '100%',
                    zIndex: 100
                }}>
                </div>
                <TrackRefContextIfNeeded trackRef={trackReference}>
                    <ParticipantContextIfNeeded participant={trackReference.participant}>
                        {children ?? (
                            <>
                                {isTrackReference(trackReference) &&
                                    (trackReference.publication?.kind === 'video' ||
                                        trackReference.source === Track.Source.Camera ||
                                        trackReference.source === Track.Source.ScreenShare) ? (
                                    <Box
                                        id={trackReference.source === Track.Source.Camera ? trackReference.participant.identity + '-video' : trackReference.participant.identity + '-screenShare'}
                                        sx={{ height: '100%', width: '100%' }}
                                        onMouseOver={() => {
                                            // console.log('mouse over')
                                            setShowScreenshotButton(true)
                                        }}
                                        onMouseOut={() => {
                                            // console.log('mouse away')
                                            setShowScreenshotButton(false)
                                        }}
                                    >
                                        {trackReference && trackReference.source && trackReference.publication && trackReference.publication.track && !(trackReference.publication.track.isMuted) && showScreenshotButton && <IconButton
                                            sx={{
                                                zIndex: 1,
                                                color: 'red',
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                display: ''
                                            }}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                if (trackReference.source === Track.Source.Camera) {
                                                    takeScreenshot(trackReference.participant.identity, true)
                                                }
                                                else {
                                                    takeScreenshot(trackReference.participant.identity, false)
                                                }

                                            }}
                                            size='small'
                                        >
                                            <ScreenshotIcon />
                                        </IconButton>}
                                        <VideoTrack
                                            trackRef={trackReference}
                                            onSubscriptionStatusChanged={handleSubscribe}
                                            manageSubscription={autoManageSubscription}
                                        />
                                    </Box>) : (
                                    isTrackReference(trackReference) && (
                                        <AudioTrack
                                            trackRef={trackReference}
                                            onSubscriptionStatusChanged={handleSubscribe}
                                        />
                                    )
                                )}
                                <div className="lk-participant-placeholder">
                                    {accessToken ?
                                        <CustomParticipantPlaceholder
                                            accessToken={accessToken}
                                            participantEmail={trackReference.participant.identity}

                                        /> :
                                        <CustomParticipantPlaceholder
                                            showEmailOnly={true}
                                            participantEmail={trackReference.participant.identity}

                                        />}
                                </div>
                                <div className="lk-participant-metadata">
                                    <div className="lk-participant-metadata-item">
                                        {trackReference.source === Track.Source.Camera ? (
                                            <>

                                                {isEncrypted && <LockLockedIcon style={{ marginRight: '0.25rem' }} />}
                                                <TrackMutedIndicator
                                                    trackRef={{
                                                        participant: trackReference.participant,
                                                        source: Track.Source.Microphone,
                                                    }}
                                                    show={'muted'}
                                                ></TrackMutedIndicator>
                                                <>{slateRef && slateRef.current ? getNameFromEmail(slateRef, trackReference.participant.identity) : <ParticipantName />}</>
                                            </>
                                        ) : (
                                            <>
                                                <ScreenShareIcon style={{ marginRight: '0.25rem' }} />
                                                <>{slateRef && slateRef.current ? getNameFromEmail(slateRef, trackReference.participant.identity) : <ParticipantName />}&apos;s screen</>
                                            </>
                                        )}
                                    </div>
                                    <CustomConnectionQualityIndicator className="lk-participant-metadata-item" />
                                </div>
                            </>
                        )}
                        <FocusToggle trackRef={trackReference} />
                    </ParticipantContextIfNeeded>
                </TrackRefContextIfNeeded>
            </div>}
    </>
    );
}