import FabricCanvasTool from "../fabrictool";
import { hexToRgbA, colorNameToHex, isObjectHoverable } from "../../utils";

class Highlighter extends FabricCanvasTool {
  configureCanvas(props) {
    const cursorSize = 8;
    const getDrawCursor = () => {
      const circle = `
        <svg
          height="${cursorSize}"
          fill="${props.highlighterColor}"
          viewBox="0 0 ${cursorSize * 2} ${cursorSize * 2}"
          width="${cursorSize}"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="50%"
            cy="50%"
            r="${cursorSize}" 
          />
        </svg>
      `;

      return `data:image/svg+xml;base64,${window.btoa(circle)}`;
    };
    this._canvas.freeDrawingCursor = `url(${getDrawCursor()}) ${cursorSize / 2} ${cursorSize / 2}, crosshair`

    this._canvas.isDrawingMode = true;
    this._canvas.freeDrawingBrush.width = props.highlighterWidth;
    this._canvas.freeDrawingBrush.color = 
    props.highlighterColor.indexOf("#") > -1
        ? hexToRgbA(props.highlighterColor)
        : hexToRgbA(colorNameToHex(props.highlighterColor));;

    this._canvas.forEachObject((o) => {
      if (isObjectHoverable(o)) {
        (o.selectable = o.evented = true)
      }
      else {
        (o.selectable = o.evented = false)
      }
    });
  }
}

export default Highlighter;
