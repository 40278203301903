import { getSession, getSlate, recordSessionEvent } from "../whiteboard/src/service/slateService";

export const getSessionStartTimeByID = async (whiteboardID, token) => {
    let res, status
    try {
        res = await getSession(token, whiteboardID)
        status = res.status
    }
    catch (err) {
        console.log(err)
        // status = err.response.status
    }
    if (status === 200) {
        //console.log(res.data)
        return res.data.start_time

    }



    // console.log(userData.data.user.id)

}

export const getSlateIdBySessionId = async (whiteboardID, token) => {
    let res, status
    try {
        res = await getSession(token, whiteboardID)
        status = res.status
    }
    catch (err) {
        console.log(err)
        status = err.response.status
    }
    if (status === 200) {
        //console.log(res.data)
        return res.data.slate_id

    }



    // console.log(userData.data.user.id)

}

export const getSlateTitleByID = async (token, slateID) => {
    if (token !== null) {
        const SlateID = {
            "id": slateID
        }
        //      console.log(SlateID)
        let res, status
        try {
            res = await getSlate(token, SlateID)
            status = res.status
        }
        catch (err) {
            //      console.log(err)
            status = err.response.status
        }
        if (status === 200) {
            //      console.log(res.data)
            return res.data.title
        }



        // console.log(userData.data.user.id)

    }
}

export function capitalizeFirstAlphabet(str) {
    let str2;
    if (str) {
        if (str.length === 1) {
            str2 = str.charAt(0).toUpperCase()
        }
        else {
            str2 = str.charAt(0).toUpperCase() + str.slice(1);
        }

        return str2;
    }
    else {
        return ''
    }

}

export function findObjectById(objectArray, objectId) {

    for (let i = 0; i <= objectArray.length - 1; ++i) {
        if (objectArray[i].user_id === objectId) {
            // console.log('object found',objectArray[i].user_id)
            return true;
        }
    }
    return false;
}
export function findObjectByEmailId(objectArray, emailId) {
    //returns true if object is found and false if not found
    for (let i = 0; i <= objectArray.length - 1; ++i) {
        if (objectArray[i].email === emailId) {
            // console.log('object found',objectArray[i].user_id)
            return true;
        }
    }
    return false;
}

export const convertTimeToLocal = (time) => {
    var date = new Date(time);
    // console.log(time)
    return date.toLocaleString()

}

export function inlineSVGString(svgString) {
    return `data:image/svg+xml;utf8,${encodeURIComponent(svgString)}`
}

export function urlSVGString(svgString) {
    return `url("${inlineSVGString(svgString)}")`
}

/**
* 
* @param {array} objectArray - host details,participant details or creator details
* @param {uuid} objectId - user id of the user
* @returns {string} - name of user  and email incase no name is found 
*This function finds name of user in hostdetails or participant details by using the id of the user
*/


export const findNamebyId = (objectArray, objectId) => {
    for (let i = 0; i <= objectArray.length - 1; ++i) {
        if (objectArray[i].user_id === objectId) {
            // console.log('object found',objectArray[i].user_id)
            if (objectArray[i].firstName !== '') {
                return capitalizeFirstAlphabet(objectArray[i].firstName) + " " + capitalizeFirstAlphabet(objectArray[i].lastName)
            }
            else {
                return objectArray[i].email
            }
        }
    }
    return '';
}


export const findDisplayNamebyId = (objectArray, objectId) => {
    for (let i = 0; i <= objectArray.length - 1; ++i) {
        if (objectArray[i].user_id === objectId) {
            // console.log('object found',objectArray[i].user_id)
            if (objectArray[i].firstName !== '') {
                return capitalizeFirstAlphabet(objectArray[i].firstName)
            }
            else {
                return objectArray[i].email.substring(0, 2)
            }
        }
    }
    return '';
}

export const findNameFromSlateUsingUserId = (slateObj, userId) => {
    // console.log(slateObj, userId)
    if (findObjectById(slateObj.hostDetails, userId)) {
        return findNamebyId(slateObj.hostDetails, userId)
    }

    if (findObjectById(slateObj.participantDetails, userId)) {
        return findNamebyId(slateObj.participantDetails, userId)
    }

    if (!(findObjectById(slateObj.hostDetails, userId) || findObjectById(slateObj.participantDetails, userId))) {
        if (slateObj.creatorDetails.firstName && slateObj.creatorDetails.lastName && slateObj.creatorDetails.firstName !== '' && slateObj.creatorDetails.lastName !== '') {
            return capitalizeFirstAlphabet(slateObj.creatorDetails.firstName) + ' ' + capitalizeFirstAlphabet(slateObj.creatorDetails.lastName)
        }
        else {
            return slateObj.creatorDetails.email
        }
    }

    return ''
}

export function removeDuplicates(arr) {
    return arr.filter((element, index, array) => array.findIndex(subElement => subElement.emailID === element.emailID) === index);

}

export const recordSessionEvents = async (token, sessionId, clientId, eventType, eventData) => {
    const data = {
        "sessionId": sessionId,
        "eventType": eventType,
        "eventData": eventData,
        "clientId": clientId
    }

    let res;
    try {
        res = recordSessionEvent(token, data);
        // status = res.status
    }

    catch (err) {
        console.log(err);
        // status = err.response.status;
    }
    // // console.log(index)
    // if (status === 200) {
    //     //success
    // }
    // else if (status === 401) {
    //     alert("You are not authorized to edit slate")
    // }
    // else {
    //     console.log(status)
    // }

    // console.log(userData.data.user.id)

}