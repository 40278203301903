import * as fabric from 'fabric'


export class PeerBrush  extends fabric.PencilBrush  {


    constructor(canvas,objectID){
        super(canvas)
        this.objectID = objectID

    }


// This method lets us add properties to the path before created event . Wherever peer brush is used this method is called
    _finalizeAndAddPath() {
        const ctx = this.canvas.contextTop;

        ctx.closePath();
        if (this.decimate) {
          this._points = this.decimatePoints(this._points, this.decimate);
        }
        const pathData = this.convertPointsToSVGPath(this._points);
        // if (this.isEmptySVGPath(pathData)) {
        //   // do not create 0 width/height paths, as they are
        //   // rendered inconsistently across browsers
        //   // Firefox 4, for example, renders a dot,
        //   // whereas Chrome 10 renders nothing
        //   this.canvas.requestRenderAll();
        //   return;
        // }

        var path = this.createPath(pathData);
        path.objectID= this.objectID;


        this.canvas.clearContext(this.canvas.contextTop);
        this.canvas.fire('before:path:created', { path: path,objectID:this.objectID,peer:true});
        this.canvas.add(path);
        this.canvas.requestRenderAll();
        path.setCoords();

        this._resetShadow();


        // fire event 'path' created
        this.canvas.fire('path:created', { path: path });
      }






}

// expected output: "19-Aug-1975"
