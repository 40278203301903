import React, { useEffect, useState } from "react";
import { Box, Button } from '@mui/material';
import io from "socket.io-client";
import { useRef } from "react";
import { useLocation } from "react-router-dom";
import { getQueryObject } from "../utils/common";
// import { uuid4 } from "../whiteboard/src/utils";
import UploadIcon from '@mui/icons-material/Upload';
import { uploadFile } from "../whiteboard/src/service/slateService";
import { THEME_COLOR, THEME_HOVER_COLOR, UPLOAD_API_URL } from "../whiteboard/src/config/constants";
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ClickPicture = (() => {
    const socketRef = useRef(null);
    const { search } = useLocation();
    const [roomName, setRoomName] = useState("");
    const [clientID, setClientID] = useState(null);
    const [accessToken, setAccessToken] = useState(null);
    const [openUploadNotification, setOpenUploadNotification] = useState(false);


    useEffect(() => {
        const query = getQueryObject(search);
        var {
            roomName,
            accessToken,
            clientID,
        } = query;
        setRoomName(roomName);
        setClientID(clientID);
        setAccessToken(accessToken ? accessToken : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiIyYzNhYzZmNy01OTNjLTQ5YWYtODQ3YS0wNmNiMjM0NTU0ZTgiLCJ1c2VyTmFtZSI6ImFhc2h3aW4ubWFsaWtAZ21haWwuY29tIiwidG9rZW5UeXBlIjoibG9naW4iLCJpYXQiOjE2NzY0NTMxODAsImV4cCI6MTcwNzk4OTE4MH0.1GSPDu9MMdK8TWQwfpBnqAZdkDvqt3R7obOQPK-6_1E');
        // setAudioVideoPlatform(audioVideoPlatform);
    }, [search])

    useEffect(() => {
        if (roomName && accessToken) {
            initialiseSocket(socketRef, roomName, accessToken);
        }

    }, [roomName, accessToken])

    function initialiseSocket(socketRef, roomName, accessToken) {

        // console.log(accessToken)
        // console.log(roomName)
        socketRef.current = io.connect("https://websocket.teachmatter.com",
            {
                transports: ["websocket"],
                withCredentials: false,
                extraHeaders: {
                    'my-custom-header': 'abcd'
                },
                auth: {
                    token: accessToken
                }
            }
        );
        socketRef.current.on("connect", () => {
            // console.log("connected to 3003 server " + socketRef.current.id)
            // console.log("connection time" + Date().toLocaleString());
            //roomname for pdf_viewer roomname
            socketRef.current.emit("joinSession", roomName)


        });

        socketRef.current.on("disconnect", () => {
            // console.log("Disconnected now from 3003");
        });


        socketRef.current.on('lessonEvent', (lessonEvent) => {
            // console.log("lesson event" + JSON.stringify(lessonEvent))
        })



    }

    const handleUpload = async (event) => {
        const file = event.target.files[0];
        const filePathArray = event.target.value.split("\\");
        // console.log("file path array " + filePathArray)
        const uploadFileName = filePathArray[filePathArray.length - 1];
        // console.log("file name " + uploadFileName)
        const imageData = new FormData();
        imageData.append('file', file);
        let uploadFileUrl = UPLOAD_API_URL + 'uploads/' + clientID + '/' + uploadFileName;
        let status, res;
        try {
            res = await uploadFile(accessToken, imageData);
            status = res.status
        }

        catch (err) {
            console.log(err);
            status = err.response.status;
        }
        // console.log(index)
        if (status === 200) {
            sendLessonEventViaWebSocket({
                eventType: 'uploadClickedPicture',
                eventData: {
                    'sentBy': clientID,
                    'uploadFileUrl': uploadFileUrl,
                }
            })
            setOpenUploadNotification(true)
        }
        else {
            console.log(status)
        }
    }

    const sendLessonEventViaWebSocket = (lessonEvent) => {
        // console.log("trying to send data " + JSON.stringify(lessonEvent))
        socketRef.current.emit("lessonEvent", roomName, lessonEvent)
        // console.log("emitted event")
    }

    const handleCloseUploadNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenUploadNotification(false);
    };


    return <Box sx={{ height: '100%', width: '100%' }}>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openUploadNotification} autoHideDuration={5000} onClose={handleCloseUploadNotification}>
            <Alert onClose={handleCloseUploadNotification} severity="success" sx={{ width: '100%' }}>
                File uploaded to whiteboard!
            </Alert>
        </Snackbar>
        <Box
            sx={{
                // display: maximize ? 'block' : 'none',
                height: '100%',
                width: '100%'
            }}>

            <Box sx={{ justifyContent: 'center', textAlign: 'center', alignItems: 'center', paddingTop: '25%' }}>
                <Button
                    sx={{
                        textTransform: 'none',
                        backgroundColor: THEME_COLOR,
                        color: '#FFFFFF',
                        fontSize: '0.8rem',
                        fontWeight: '500',
                        height: '35px',
                        borderRadius: '10px',
                        '&:hover': {
                            backgroundColor: THEME_HOVER_COLOR,
                            boxShadow: 'none',
                        },
                    }}
                    onClick={() => document.getElementById("file-to-upload-click-picture").click()}
                >
                    Upload File&nbsp;<UploadIcon sx={{ fontSize: '1rem', color: '#FFFFFF', }} />
                </Button>
                <input
                    type="file"
                    id="file-to-upload-click-picture"
                    accept='image/*'
                    hidden
                    onChange={handleUpload}
                />
            </Box>

        </Box>
    </Box>;
});

export default ClickPicture