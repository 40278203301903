import React from "react"
import { Box, LinearProgress, Typography } from "@mui/material"
import { useAtomValue } from "jotai"
import { fileDownloadingProgressAtom, isFileDownloadingInChatAtom } from "../jotai/atom/isFileDownloadingInChatAtom"
import { THEME_COLOR } from "../../whiteboard/src/config/constants"



const DownloadFileLoader = () => {
    const isDownloading = useAtomValue(isFileDownloadingInChatAtom)
    const fileDownloadProgress = useAtomValue(fileDownloadingProgressAtom)

    return <>{isDownloading &&
        <Box
            sx={{
                top: '7%',
                width: '100%',
                height: '92.5%',
                overflowY: 'auto',
             
                position: 'absolute',
                zIndex: 10
            }}>
            <Box sx={{
                display: 'flex',
                backgroundColor: '#f8f8f8',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                wordWrap: 'break-word !important',
                padding: "10px 10px 25px",
            }} >
                <Typography variant='h6' sx={{ color: THEME_COLOR }}> <span style={{ color: 'black' }}>Downloading file</span>  {fileDownloadProgress > 0 ? fileDownloadProgress + '%' : ''}</Typography>
                <Typography variant='h6' sx={{ color: THEME_COLOR }}></Typography>

                <LinearProgress
                    sx={{
                        marginTop: '10px',
                        width: '100%',
                        height: '10px',
                        color: THEME_COLOR,
                        position: 'relative',
                        borderRadius: '20px'
                    }}
                    variant="determinate" value={fileDownloadProgress} />
            </Box>
        </Box>}
    </>
}

export default DownloadFileLoader