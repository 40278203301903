import React, { useEffect, useState, useRef } from "react";
import io from "socket.io-client";
import { createTheme, ThemeProvider } from "@mui/material";
import Peer from "simple-peer";
import styled from "styled-components";
import { styled as materialStyled } from '@mui/material/styles';
import { useLocation } from "react-router-dom";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import ScreenShareIcon from "@mui/icons-material/ScreenShare";
import StopScreenShareIcon from '@mui/icons-material/StopScreenShare';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import Box from "@mui/material/Box";

// import MusicNoteIcon from '@mui/icons-material/MusicNote';
// import MusicOffIcon from '@mui/icons-material/MusicOff';
// import { pink } from '@mui/material/colors';
import { IconButton } from "@mui/material";
// import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import moment from 'moment';
import { CircularProgress } from "@mui/material";
import MousePointer from "./MousePointer"
// import { createPath } from "history";
import debounce from 'lodash.debounce';
import ScreenshotIcon from '@mui/icons-material/Screenshot';
import { getQueryObject } from "../../utils/common";

const Video = styled.video`
  width: 100%;
  height: 100%;
  
`;
const rootcolor = '#2e3033';
const videocolor = '#686a6e';

const Root = materialStyled(Box)(({ theme }) => ({
  position: "absolute",
  display: "flex",
  right: '0',
  left: '0',
  margin: '0 auto',
  width: '20%',
  minWidth: '320px',
  height: '250px',
  flexDirection: "column",
  alignItems: "center",
  zIndex: "10000000",
  backgroundColor: rootcolor,
  justifyContent: 'space-evenly',
  '.top': {
    '&-1': {
      '&-m': {
        display: 'none',
      },
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignContent: "center",
      justifyContent: "center",
      width: '100%',
      height: 'calc(100% - 35px)',
      minWidth: '200px',
      '&-f': {
        height: 'calc(100% - 7vh)',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',
      },
      //paddingTop : '3%',
      '&-s': {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignContent: "center",
        justifyContent: "center",
        width: '100%',
        height: 'calc(100% - 35px)',
        minWidth: '200px',
      },
    },
    '&-2': {
      '&-m': {
        display: 'none',
      },
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignContent: "center",
      justifyContent: "center",
      width: '100%',
      height: 'calc(100% - 35px)',
      minWidth: '200px',
      '&-f': {
        height: 'calc(100% - 7vh)',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',
      },
      '&-s': {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignContent: "center",
        justifyContent: "center",
        width: '100%',
        height: 'calc(100% - 35px)',
        minWidth: '200px',
      },
      '&-ss': {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignContent: "center",
        justifyContent: "space-evenly",
        width: '100%',
        height: '90%',
        paddingTop: '3%',
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
          paddingTop: '0',
          height: '93%',
        }
      },
    },
    '&-3': {
      '&-m': {
        display: 'none',
      },
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignContent: "center",
      justifyContent: "center",
      width: '100%',
      height: 'calc(100% - 35px)',
      minWidth: '200px',
      '&-f': {
        height: 'calc(100% - 7vh)',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',
      },
      '&-s': {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignContent: "center",
        justifyContent: "center",
        width: '100%',
        height: 'calc(100% - 35px)',
        minWidth: '200px',
      },
      '&-ss': {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignContent: "center",
        alignItems: 'center',
        justifyContent: "space-evenly",
        width: '95vw',
        height: '93%',
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
        },
      },
    },
    '&-4': {
      '&-m': {
        display: 'none',
      },
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
      width: '100%',
      height: 'calc(100% - 35px)',
      minWidth: '200px',
      '&-f': {
        height: 'calc(100% - 7vh)',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',
      },
      '&-s': {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignContent: "center",
        justifyContent: "center",
        width: '100%',
        height: 'calc(100% - 35px)',
        minWidth: '200px',
      },
      '&-ss': {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignContent: "center",
        alignItems: 'center',
        justifyContent: "space-evenly",
        width: '95vw',
        height: '93%',
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
        },
      },
    },
    '&-5': {
      '&-m': {
        display: 'none',
      },
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
      width: '100%',
      height: 'calc(100% - 35px)',
      minWidth: '200px',
      '&-f': {
        height: '80%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
      },
      '&-s': {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignContent: "center",
        justifyContent: "center",
        width: '100%',
        height: 'calc(100% - 35px)',
        minWidth: '200px',
      },
      '&-ss': {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignContent: "center",
        alignItems: 'center',
        justifyContent: "space-evenly",
        width: '95vw',
        height: '93%',
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
        },
      },
    },
    '&-6': {
      '&-m': {
        display: 'none',
      },
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignContent: "center",
      justifyContent: "center",
      width: '100%',
      height: 'calc(100% - 35px)',
      minWidth: '200px',
      '&-f': {
        height: '80%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
      },
      '&-s': {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignContent: "center",
        justifyContent: "center",
        width: '100%',
        height: 'calc(100% - 35px)',
        minWidth: '200px',
      },
      '&-ss': {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignContent: "center",
        alignItems: 'center',
        justifyContent: "space-evenly",
        width: '95vw',
        height: '93%',
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
        },
      },
    },
  },
  '.screen-share': {
    '&-disabled': {
      display: 'none'
    },
    '&-1': {
      display: 'none',
    },
    '&-2': {
      width: '65%',
      height: '100%',
      borderRadius: '5px',
      backgroundColor: videocolor,
      [theme.breakpoints.down('md')]: {
        width: '95%',
        height: '55%',
        marginLeft: '2.5%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '90%',
        height: '35%',
        marginLeft: '5%',
      },
    },
    '&-3': {
      width: '75%',
      height: '90%',
      backgroundColor: videocolor,
      borderRadius: '5px',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: '55%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '23%',
        marginTop: '2%'
      },

    },
    '&-4': {
      width: '75%',
      height: '90%',
      backgroundColor: videocolor,
      borderRadius: '5px',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: '55%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '23%',
        marginTop: '2%'
      },

    },
    '&-5': {
      width: '60%',
      height: '90%',
      backgroundColor: videocolor,
      borderRadius: '5px',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: '45%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '30%',
        marginTop: '2%'
      },

    },
    '&-6': {
      width: '60%',
      height: '90%',
      backgroundColor: videocolor,
      borderRadius: '5px',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: '45%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '30%',
        marginTop: '2%'
      },

    },
  },
  '.video-container': {
    '&-1': {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      flexDirection: "row",
      width: '95%',
      height: '95%',
      '&-f': {
        display: "flex",
        height: '95%',
        width: '97%',
        alignItems: 'center',
      },
      '&-s': {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        flexDirection: "row",
        width: '95%',
        height: '95%',
      },
    },
    '&-2': {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      flexDirection: "row",
      width: '99%',
      height: '95%',
      '&-f': {
        display: "flex",
        alignItems: 'center',
        height: '95%',
        width: '99%',
      },
      '&-s': {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        flexDirection: "row",
        width: '95%',
        height: '95%',
      },
      '&-ss': {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        flexDirection: "column",
        width: '30%',
        height: '100%',
        [theme.breakpoints.down("md")]: {
          flexDirection: 'row',
          width: '100%',
          height: '40%',
        },
        [theme.breakpoints.down("sm")]: {
          flexDirection: 'column',
          width: '100%',
          height: '60%',
        },
      }
    },
    '&-3': {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      flexDirection: "row",
      width: '99%',
      height: '95%',
      '&-f': {
        height: '95%',
        width: '99%',
      },
      '&-s': {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        flexDirection: "row",
        width: '95%',
        height: '99%',
      },
      '&-ss': {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        flexDirection: "column",
        width: '20%',
        height: '100%',
        [theme.breakpoints.down("md")]: {
          flexDirection: 'row',
          width: '100%',
          height: '40%',
        },
        [theme.breakpoints.down("sm")]: {
          flexDirection: 'column',
          width: '100%',
          height: '75%',
        },
      },
    },
    '&-4': {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      flexDirection: "row",
      width: '99%',
      height: '95%',
      '&-f': {
        height: '95%',
        width: '99%',
      },
      '&-s': {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        flexDirection: "row",
        width: '95%',
        height: '99%',
      },
      '&-ss': {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        flexDirection: "column",
        width: '20%',
        height: '100%',
        [theme.breakpoints.down("md")]: {
          flexDirection: 'row',
          width: '100%',
          height: '40%',
        },
        [theme.breakpoints.down("sm")]: {
          flexDirection: 'column',
          width: '100%',
          height: '75%',
        },
      },
    },
    '&-5': {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      flexDirection: "row",
      width: '99%',
      height: '99%',
      '&-f': {
        height: '100%',
        width: '90%',
      },
      '&-s': {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        flexDirection: "row",
        width: '99%',
        height: '99%',
      },
      '&-ss': {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        flexDirection: "column",
        width: '35%',
        height: '100%',
        [theme.breakpoints.down("md")]: {
          flexDirection: 'row',
          width: '100%',
          height: '50%',
        },
        [theme.breakpoints.down("sm")]: {
          flexDirection: 'column',
          width: '100%',
          height: '65%',
        },
      },
    },
    '&-6': {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      flexDirection: "row",
      width: '99%',
      height: '99%',
      '&-f': {
        height: '100%',
        width: '90%',
      },
      '&-s': {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        flexDirection: "row",
        width: '99%',
        height: '99%',
      },
      '&-ss': {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        flexDirection: "column",
        width: '35%',
        height: '100%',
        [theme.breakpoints.down("md")]: {
          flexDirection: 'row',
          width: '100%',
          height: '50%',
        },
        [theme.breakpoints.down("sm")]: {
          flexDirection: 'column',
          width: '100%',
          height: '65%',
        },
      },
    },
  },
  '.video-stream': {
    '&-1': {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: 'row',
      justifyContent: "center",
      width: '100%',
      height: '100%',
      [theme.breakpoints.down("sm")]: {
        flexDirection: 'column'
      },
      '&-f': {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      '&-s': {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: 'row',
        justifyContent: "center",
        width: '100%',
        height: '100%',
        [theme.breakpoints.down("sm")]: {
          flexDirection: 'column'
        },
      },
    },
    '&-2': {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: 'row',
      justifyContent: "space-around",
      width: '100%',
      height: '100%',
      [theme.breakpoints.down("sm")]: {
        flexDirection: 'row',
      },
      '&-f': {
        height: '100%',
        width: '100%',
        flexWrap: 'wrap',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
          alignItems: 'center',
        }
      },
      '&-s': {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: 'column',
        justifyContent: "space-between",
        width: '100%',
        height: '100%',
        [theme.breakpoints.down("sm")]: {
          flexDirection: 'column',
        },
      },
      '&-ss': {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: 'column',
        justifyContent: "space-evenly",
        width: '100%',
        height: '100%',
        [theme.breakpoints.down("md")]: {
          flexDirection: 'row',
        },
        [theme.breakpoints.down("sm")]: {
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
      },
    },
    '&-3': {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: 'row',
      justifyContent: "space-around",
      width: '100%',
      height: '100%',
      [theme.breakpoints.down("sm")]: {
        flexDirection: 'row',
      },
      '&-f': {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
          justifyContent: 'space-evenly',
          alignItems: 'center',
        },
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          alignItems: 'center',
        }
      },
      '&-s': {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: 'column',
        justifyContent: "space-around",
        width: '100%',
        height: '100%',
        [theme.breakpoints.down("sm")]: {
          flexDirection: 'column',
        },
      },
      '&-ss': {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: 'column',
        justifyContent: "space-evenly",
        width: '100%',
        height: '100%',
        [theme.breakpoints.down("md")]: {
          flexDirection: 'row',
        },
        [theme.breakpoints.down("sm")]: {
          flexDirection: 'column',
          height: '100%',
          width: '100%',
        },
      },
    },
    '&-4': {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: 'row',
      justifyContent: "space-around",
      width: '100%',
      height: '100%',
      [theme.breakpoints.down("sm")]: {
        flexDirection: 'row',
      },
      '&-f': {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
          justifyContent: 'space-evenly',
        },
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
        }
      },
      '&-s': {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: 'column',
        justifyContent: "space-around",
        width: '100%',
        height: '100%',
        [theme.breakpoints.down("sm")]: {
          flexDirection: 'column',
        },
      },
      '&-ss': {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: 'column',
        justifyContent: "space-evenly",
        width: '100%',
        height: '100%',
        [theme.breakpoints.down("md")]: {
          flexDirection: 'row',
        },
        [theme.breakpoints.down("sm")]: {
          flexDirection: 'column',
          height: '100%',
          width: '100%',
        },
      },
    },
    '&-5': {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: 'row',
      justifyContent: "space-around",
      alignItems: 'center',
      width: '100%',
      height: '100%',
      [theme.breakpoints.down("sm")]: {
        flexDirection: 'row',
      },
      '&-f': {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
          justifyContent: 'space-evenly',
          alignItems: 'center',
        },
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
        }
      },
      '&-s': {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: 'column',
        justifyContent: "space-evenly",
        alignItems: 'center',
        width: '100%',
        height: '100%',
        [theme.breakpoints.down("sm")]: {
          flexDirection: 'column',
        },
      },
      '&-ss': {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: 'column',
        justifyContent: "space-evenly",
        alignContent: 'space-around',
        width: '100%',
        height: '100%',
        [theme.breakpoints.down("md")]: {
          flexDirection: 'row',
        },
        [theme.breakpoints.down("sm")]: {
          flexDirection: 'column',
          height: '100%',
          width: '100%',
        },
      },
    },
    '&-6': {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: 'row',
      justifyContent: "space-around",
      alignItems: 'center',
      width: '100%',
      height: '100%',
      [theme.breakpoints.down("sm")]: {
        flexDirection: 'row',
      },
      '&-f': {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
          justifyContent: 'space-evenly',
          alignItems: 'center',
        },
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
        }
      },
      '&-s': {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: 'column',
        justifyContent: "space-evenly",
        alignItems: 'center',
        width: '100%',
        height: '100%',
        [theme.breakpoints.down("sm")]: {
          flexDirection: 'column',
        },
      },
      '&-ss': {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: 'column',
        justifyContent: "space-evenly",
        alignContent: 'space-around',
        width: '100%',
        height: '100%',
        [theme.breakpoints.down("md")]: {
          flexDirection: 'row',
        },
        [theme.breakpoints.down("sm")]: {
          flexDirection: 'column',
          height: '100%',
          width: '100%',
        },
      },
    },
  },
  '.remote-video': {

    '&-1': {
      display: "flex",
      flexDirection: "column",
      width: '100%',
      height: '100%',
      alignItems: "center",
      backgroundColor: videocolor,
      '&-f': {

      },
      '&-s': {
        display: "flex",
        flexDirection: "column",
        width: '100%',
        height: '100%',
        alignItems: "center",
        backgroundColor: videocolor,
      },
    },
    '&-2': {
      display: "flex",
      flexDirection: "column",
      width: '48.8125%',
      height: 'inherit',
      alignItems: "center",
      backgroundColor: videocolor,
      borderRadius: '5px',
      '&-f': {
        height: '100%',
        width: '49%',
        backgroundColor: videocolor,
        borderRadius: '5px',
        [theme.breakpoints.down('md')]: {
          width: '100%',
          height: '47%',
        },
      },
      '&-s': {
        display: "flex",
        flexDirection: "column",
        width: '100%',
        height: '49%',
        alignItems: "center",
        backgroundColor: videocolor,
        borderRadius: '5px',
      },
      '&-ss': {
        display: "flex",
        flexDirection: "column",
        width: '100%',
        height: '45%',
        alignItems: "center",
        borderRadius: '5px',
        backgroundColor: videocolor,
        [theme.breakpoints.down("md")]: {
          width: '45%',
          height: '100%',
        },
        [theme.breakpoints.down("sm")]: {
          width: '90%',
          height: '49%',
          marginLeft: '5%',
        },
      },
    },
    '&-3': {
      display: "flex",
      flexDirection: "column",
      width: '32%',
      height: 'inherit',
      alignItems: "center",
      backgroundColor: videocolor,
      borderRadius: '5px',
      '&-f': {
        height: '100%',
        width: '32%',
        alignItems: "center",
        backgroundColor: videocolor,
        borderRadius: '5px',
        [theme.breakpoints.down('md')]: {
          height: '47%',
          width: '47%',
        },
        [theme.breakpoints.down('sm')]: {
          height: '30%',
          width: '100%',
        },
      },
      '&-s': {
        display: "flex",
        flexDirection: "column",
        width: '100%',
        height: '32%',
        alignItems: "center",
        backgroundColor: videocolor,
        borderRadius: '5px',
      },
      '&-ss': {
        display: "flex",
        flexDirection: "column",
        width: '100%',
        height: '30%',
        alignItems: "center",
        backgroundColor: videocolor,
        borderRadius: '5px',
        [theme.breakpoints.down("md")]: {
          width: '30%',
          height: '100%',
        },
        [theme.breakpoints.down("sm")]: {
          width: '100%',
          height: '29%',
        },
      },
    },
    '&-4': {
      display: "flex",
      flexDirection: "column",
      width: '24%',
      height: 'inherit',
      alignItems: "center",
      backgroundColor: videocolor,
      borderRadius: '5px',
      '&-f': {
        height: '49%',
        width: '49%',
        alignItems: "center",
        backgroundColor: videocolor,
        borderRadius: '5px',
        [theme.breakpoints.down('sm')]: {
          height: '24%',
          width: '100%',
        },
      },
      '&-s': {
        display: "flex",
        flexDirection: "column",
        width: '100%',
        height: '24%',
        alignItems: "center",
        backgroundColor: videocolor,
        borderRadius: '5px',
      },
      '&-ss': {
        display: "flex",
        flexDirection: "column",
        width: '100%',
        height: '22%',
        alignItems: "center",
        backgroundColor: videocolor,
        borderRadius: '5px',
        [theme.breakpoints.down("md")]: {
          width: '22%',
          height: '100%',
        },
        [theme.breakpoints.down("sm")]: {
          width: '100%',
          height: '22%',
        },
      },
    },
    '&-5': {
      display: "flex",
      flexDirection: "column",
      width: '32%',
      height: '49%',
      alignItems: "center",
      backgroundColor: videocolor,
      borderRadius: '5px',
      '&-f': {
        height: '47%',
        width: '30%',
        alignItems: "center",
        backgroundColor: videocolor,
        borderRadius: '5px',
        [theme.breakpoints.down('md')]: {
          height: '47%',
          width: '30%',
        },
        [theme.breakpoints.down('sm')]: {
          height: '19%',
          width: '100%',
        },
      },
      '&-s': {
        display: "flex",
        flexDirection: "column",
        width: '49%',
        height: '32%',
        alignItems: "center",
        backgroundColor: videocolor,
        borderRadius: '5px',
      },
      '&-ss': {
        display: "flex",
        flexDirection: "column",
        width: '45%',
        height: '30%',
        alignItems: "center",
        backgroundColor: videocolor,
        borderRadius: '5px',
        [theme.breakpoints.down("md")]: {
          width: '30%',
          height: '45%',
        },
        [theme.breakpoints.down("sm")]: {
          width: '45%',
          height: '30%',
        },
      },
    },
    '&-6': {
      display: "flex",
      flexDirection: "column",
      width: '32%',
      height: '49%',
      alignItems: "center",
      backgroundColor: videocolor,
      borderRadius: '5px',
      '&-f': {
        height: '47%',
        width: '30%',
        alignItems: "center",
        backgroundColor: videocolor,
        borderRadius: '5px',
        [theme.breakpoints.down('md')]: {
          height: '47%',
          width: '30%',
        },
        [theme.breakpoints.down('sm')]: {
          height: '19%',
          width: '100%',
        },
      },
      '&-s': {
        display: "flex",
        flexDirection: "column",
        width: '49%',
        height: '32%',
        alignItems: "center",
        backgroundColor: videocolor,
        borderRadius: '5px',
      },
      '&-ss': {
        display: "flex",
        flexDirection: "column",
        width: '45%',
        height: '30%',
        alignItems: "center",
        backgroundColor: videocolor,
        borderRadius: '5px',
        [theme.breakpoints.down("md")]: {
          width: '30%',
          height: '45%',
        },
        [theme.breakpoints.down("sm")]: {
          width: '45%',
          height: '30%',
        },
      },
    },
  },
  '.local-video': {
    '&-1': {
      width: '100%',
      height: '100%',
      backgroundColor: videocolor,
      borderRadius: '5px',
      '&-f': {
        height: '100%',
        width: '100%',
        backgroundColor: videocolor,
        borderRadius: '5px',
      },
      '&-s': {
        width: '100%',
        height: '100%',
        backgroundColor: videocolor,
        borderRadius: '5px',
      },
    },
    '&-2': {
      width: '48.8125%',
      height: 'inherit',
      backgroundColor: videocolor,
      borderRadius: '5px',
      '&-f': {
        height: '100%',
        width: '49%',
        backgroundColor: videocolor,
        borderRadius: '5px',
        [theme.breakpoints.down('md')]: {
          width: '100%',
          height: '47%',
        },
      },
      '&-s': {
        width: '100%',
        height: '49%',
        backgroundColor: videocolor,
        borderRadius: '5px',
      },
      '&-ss': {
        width: '100%',
        height: '45%',
        backgroundColor: videocolor,
        borderRadius: '5px',
        [theme.breakpoints.down("md")]: {
          width: '45%',
          height: '100%',
        },
        [theme.breakpoints.down("sm")]: {
          width: '90%',
          height: '48%',
          marginLeft: '5%',
        },
      },
    },
    '&-3': {
      width: '32%',
      height: 'inherit',
      backgroundColor: videocolor,
      borderRadius: '5px',
      '&-f': {
        height: '100%',
        width: '32%',
        backgroundColor: videocolor,
        borderRadius: '5px',
        alignItems: "center",
        [theme.breakpoints.down('md')]: {
          height: '47%',
          width: '47%',
        },
        [theme.breakpoints.down('sm')]: {
          height: '30%',
          width: '100%',
        },
      },
      '&-s': {
        width: '100%',
        height: '32%',
        backgroundColor: videocolor,
        borderRadius: '5px',
      },
      '&-ss': {
        width: '100%',
        height: '30%',
        backgroundColor: videocolor,
        borderRadius: '5px',
        [theme.breakpoints.down("md")]: {
          width: '30%',
          height: '100%',
        },
        [theme.breakpoints.down("sm")]: {
          width: '100%',
          height: '29%',
        },
      },
    },
    '&-4': {
      width: '24%',
      height: 'inherit',
      backgroundColor: videocolor,
      borderRadius: '5px',
      '&-f': {
        height: '49%',
        width: '49%',
        backgroundColor: videocolor,
        borderRadius: '5px',
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
          height: '24%',
          width: '100%',
        },
      },
      '&-s': {
        width: '100%',
        height: '24%',
        backgroundColor: videocolor,
        borderRadius: '5px',
      },
      '&-ss': {
        width: '100%',
        height: '22%',
        backgroundColor: videocolor,
        borderRadius: '5px',
        [theme.breakpoints.down("md")]: {
          width: '22%',
          height: '100%',
        },
        [theme.breakpoints.down("sm")]: {
          width: '100%',
          height: '22%',
        },
      },
    },
    '&-5': {
      width: '32%',
      height: '49%',
      backgroundColor: videocolor,
      borderRadius: '5px',
      '&-f': {
        height: '47%',
        width: '30%',
        backgroundColor: videocolor,
        borderRadius: '5px',
        alignItems: "center",
        [theme.breakpoints.down('md')]: {
          height: '47%',
          width: '30%',
        },
        [theme.breakpoints.down('sm')]: {
          height: '19%',
          width: '100%',
        },
      },
      '&-s': {
        width: '49%',
        height: '32%',
        backgroundColor: videocolor,
        borderRadius: '5px',
      },
      '&-ss': {
        width: '45%',
        height: '30%',
        backgroundColor: videocolor,
        borderRadius: '5px',
        [theme.breakpoints.down("md")]: {
          width: '30%',
          height: '45%',
        },
        [theme.breakpoints.down("sm")]: {
          width: '45%',
          height: '30%',
        },
      },
    },
    '&-6': {
      width: '32%',
      height: '49%',
      backgroundColor: videocolor,
      borderRadius: '5px',
      '&-f': {
        height: '47%',
        width: '30%',
        backgroundColor: videocolor,
        borderRadius: '5px',
        alignItems: "center",
        [theme.breakpoints.down('md')]: {
          height: '47%',
          width: '30%',
        },
        [theme.breakpoints.down('sm')]: {
          height: '19%',
          width: '100%',
        },
      },
      '&-s': {
        width: '49%',
        height: '32%',
        backgroundColor: videocolor,
        borderRadius: '5px',
      },
      '&-ss': {
        width: '45%',
        height: '30%',
        backgroundColor: videocolor,
        borderRadius: '5px',
        [theme.breakpoints.down("md")]: {
          width: '30%',
          height: '45%',
        },
        [theme.breakpoints.down("sm")]: {
          width: '45%',
          height: '30%',
        },
      },
    },
  },
  '.bottom': {
    display: "flex",
    justifyContent: "center",
    flexDirection: 'row',
    m: 1,
    width: '100%',
    height: '30px',
    alignItems: 'flex-start',
    //padding: '1% 0',
    '&-ss': {
      display: "flex",
      justifyContent: "center",
      flexDirection: 'row',
      m: 1,
      width: '100%',
      height: '7vh',
      alignItems: 'center',
    },
    '&-m': {
      display: "flex",
      justifyContent: "center",
      flexDirection: 'row',
      m: 1,
      width: '100%',
      height: '35px',
      alignItems: 'center',
    },
    '&-f': {
      display: "flex",
      justifyContent: "center",
      flexDirection: 'row',
      m: 1,
      width: '100%',
      height: '7vh',
      alignItems: 'center',
    },
  }
}));

//lastPublishedScreenSharingStreamRef,lastPublishedScreenSharingStream,setLastPublishedScreenSharingStream,
function Webrtc({
  whiteBoardRef,
  roomName,
  setWebRtcKey,
  createPath,
  receivedDataFromPeer,
  sendDataToAllPeers,
  resetCoords,
  userName,
  isAudioEnabled,
  isVideoEnabled,
  isScreenSharingEnabled,
  isWebrtcTilesCenter,
  isWebrtcFullScreen,
  isWebrtcData,
  isWebrtcMinimize,
  isWebrtcSmiley,
  isWebrtcScreenshot,
  whiteBoardID,
  recordingTimeRef
  //setScreenShare, 
  //lastPublishedScreenSharingStream, 
  //setLastPublishedScreenSharingStream, 
  //lastPublishedScreenSharingStreamRef,
}) {
  //const {vheight,vwidth} = useWindowDimensions();
  //console.log(roomid+'hi');
  const [minimize, setMinimize] = useState(isWebrtcMinimize ? true : false);
  const [fullScreen, setFullScreen] = useState(isWebrtcFullScreen ? true : false);
  const [onSide, setOnSide] = useState(isWebrtcTilesCenter ? false : true);
  const yourID = useRef(null);
  const theme = createTheme();
  const [isConnected, setIsConnected] = useState(false);
  const { search } = useLocation();
  const peerRefs = useRef({}); // Each ref can have an object{peerRef:,audioRef, audioVideoRef,videoRef}
  const [forceRender, setForceRender] = useState(true);
  const streamRef = useRef();
  //This stream will save the screen sharing stream
  const localScreenStreamRef = useRef();
  const localCameraTrackRef = useRef(null);
  const localCameraStreamRef = useRef(null);
  const localAudioStreamRef = useRef(null);
  const userVideo = useRef();
  // const screenVideo = useRef();;
  const lastPublishedTrackType = useRef(null);
  const socketRef = useRef();
  const iceServersRef = useRef();
  const [roomID, setRoomID] = useState(roomName);
  const [audioEnabled, setAudioEnabled] = useState(false);
  const [screenSharingEnabled, setScreenSharingEnabled] = useState(false);
  const [viewScreenShareEnabled, setViewScreenShareEnabled] = useState(false);
  const [videoEnabled, setVideoEnabled] = useState(false);
  const cameraButtonRef = useRef();
  const lastPublishedScreenSharingStreamRef = useRef();
  const lastPublishedScreenSharingStreamSocketID = useRef(0)
  const [showCameraOff, setShowCameraOff] = useState(false);
  const [showMicOff, setShowMicOff] = useState(false);
  const mousePointerRef = useRef(null);
  const prevCoords = useRef(null);
  const canvasWidthRef = useRef(null);
  const canvasHeightRef = useRef(null);

  const [showScreenshot, setShowScreenshot] = useState(null);

  // const [lastPublishedScreenSharingStream,setLastPublishedScreenSharingStream] = useState(true);

  useEffect(() => {
    // const audioEl = document.getElementsByClassName("audio-element")[0]

    //audioEl.play();
    //window.alert("Hi");
  }, [forceRender]);
  function updateMousePosition(xCoord, yCoord, peerID, canvasWidth, canvasHeight, color, name) {
    mousePointerRef.current.setMouseCoords(xCoord, yCoord, peerID, canvasWidth, canvasHeight, color, name)

  }
  const debouncedUpdateMousePostion = debounce(updateMousePosition, 20);
  async function initialiseIceServers() {
    // console.log("initialise servers called")
    // const response = await fetch(
    //   "https://server1.slatemates.in:3001/kinesis-video-url", {
    // }
    // );
    // const responsejson = await response.json();
    // const iceServers = responsejson.configuration.iceServers;
    // iceServers.unshift({ urls: ["stun:stun.l.google.com:19302", "stun:stun.kinesisvideo.ap-south-1.amazonaws.com:443"] });
    //  iceServersRef.current = iceServers;
    iceServersRef.current = [{ "urls": ["stun:stun.l.google.com:19302", "stun:stun.kinesisvideo.ap-south-1.amazonaws.com:443"] }, { "urls": ["turn:coturn.slatemates.in:3477"], "username": "turnpublic", "credential": "turnpublic" }];
    // console.log("the ice servers are" + JSON.stringify(iceServers));
  }
  useEffect(() => {
    const asyncFn = async () => {
      console.log("room id is " + roomID)
      if (!roomID) {
        const query = getQueryObject(search);
        let { roomID } = query;
        console.log("now setting room id" + roomID);


        setRoomID(roomID)
      }
      if (roomID) {
        await initialise();
      }
    };
    asyncFn();

    return () => {

      console.log("webrtc unmount now")
    }
  }, [roomID]);

  sendDataToAllPeers.current = (e) => {
    try {
      //get the canvas height and widht even if there are no peers
      var w = e.width
      var h = e.height;
      canvasWidthRef.current = w;
      canvasHeightRef.current = h
      Object.keys(peerRefs.current).map((socketId) => {
        //   console.log("x value is " + e.pageX)

        if (peerRefs && peerRefs.current) {
          let peerRef = peerRefs.current[socketId].peerRef;
          if (peerRef) {


            try {


              peerRef.send(e.pageX / w + "$" + e.pageY / h + "$" + e.pencil + "$" + e.mouseDown + "$" + e.pencilWidth + "$" + e.pencilColor + "$" + e.myPathUUID + "$" + e.width + "$" + e.height + "$" + userName);



            } catch (e) {
              //   console.log(e)
            }

          }
        }
      });
    } catch (error) {

    }
  };

  resetCoords.current = () => {
    //  console.log("now resetting")
    sendDataToAllPeers.current("mouseup")
    prevCoords.current = null
  }

  // useEvent("mousemove", handleMouseMove);


  useEffect(() => {
    return () => {
      console.log("unmount now " + videoEnabled)
      try {
        removeVideo();
      }
      catch (error) {
        console.log(error);
      }
      try {
        removeAudio();
      }
      catch (error) {
        console.log(error);
      }
      try {
        forceDisconnect();
      }
      catch (error) {
        console.log(error);
      }
    }
  }, []);

  async function initialise() {
    await initialiseIceServers();
    socketRef.current = io.connect("https://server1.slatemates.in:3001",
      {
        withCredentials: false,
        extraHeaders: {
          'my-custom-header': 'abcd'
        }
      }
    );


    socketRef.current.on("connect", () => {
      console.log("we are connected now and our socket id is " + socketRef.current.id)
      console.log("connection time" + Date().toLocaleString());
      socketRef.current.sendBuffer = [];
      socketRef.current.emit("joinRoom", roomID);
      yourID.current = socketRef.current.id;
      setIsConnected(true)
      console.log("is connected is " + isConnected)
    });
    socketRef.current.on("otherUserJoined", (otherUserSocketID) => { });
    socketRef.current.on("existingUser", (otherUserSocketIDs) => {
      console.log(
        "existing users in the room  " + JSON.stringify(otherUserSocketIDs)
      );
      //if there are other users beside you establish a data connection with these guys
      // otherUserRef.current = otherUserSocketID
      //establish a data connection immediately
      for (let index = 0; index < otherUserSocketIDs.length; index++) {
        const otherUserSocketID = otherUserSocketIDs[index];
        // console.log(
        //   "other user  socket id " +
        //   otherUserSocketID +
        //   "and our id" +
        //   yourID.current
        // );
        if (otherUserSocketID !== yourID.current) {
          console.log("now calling user" + otherUserSocketID);
          peerRefs.current[otherUserSocketID] = {
            audioRef: null,
            videoRef: null,
            peerRef: null,
            audioVideoRef: null,
          };
          callPeer(otherUserSocketID);
        }
      }
      //setOtherUser(otherUserSocketID);
    });
    socketRef.current.on("otherUserLeft", (otherUserSocketID) => {
      console.log("the other user got disconnected")
      console.log("disconnection time " + Date().toLocaleString());
      if (peerRefs.current[otherUserSocketID] &&
        peerRefs.current[otherUserSocketID].peerRef) {
        peerRefs.current[otherUserSocketID].peerRef.destroy();
        delete peerRefs.current[otherUserSocketID];
      }

      //somebody left so we are forcing a re render
      setForceRender({});
    });
    socketRef.current.on("signalByInitiator", (data) => {
      console.log("got signal data from  initator");
      // once we receive a signal from the initiator another peer we need to simply call the signal function with that same signal data,
      //data.from is the socket id of the initiator (the one who called us using the function call peer)
      try {
        peerRefs.current[data.from].peerRef.signal(JSON.stringify(data.signal));
      }
      catch (err) {
        console.log(err)
      }
    });
    socketRef.current.on("callByInitiator", async (data) => {
      console.log("received call from the initiator" + data.userData.userName);
      //Accept the calls only from the different userName
      if (data.userData.userName !== userName) {
        await initialiseIceServers();
        acceptCall(data.callFrom, streamRef.current);
      }

    });
    socketRef.current.on("disconnect", (reason) => {
      //peerRefs.current = {};
      console.log("disconnected now" + reason);
      console.log("disconnection time" + Date().toLocaleString());
      peerRefs.current = {};
      setIsConnected(false)
      // if (reason === "io server disconnect") {
      //   // the disconnection was initiated by the server, you need to reconnect manually
      //   //socket.connect();

      //   socketRef.current = io.connect(
      //     "https://server1.slatemates.in:3001",
      //     { forceNew: true }
      //   );
      // }


      //  if(reason === "transport close"){
      //   console.log("inside transport close");
      //   setWebRtcKey(Math.random())
      //  }
      //  if(reason === "io server disconnect"){
      //   console.log("inside transport close");
      //   setWebRtcKey(Math.random())
      //  }



    });

    // socketRef.current.on('error', function(){
    //   console.log("there is an error")
    //   socksocketRef.current.socket.reconnect();
    // });

    // socketRef.current.on("reconnecting", function(delay, attempt) {
    //   if (attempt === 6) {
    //     setTimeout(function(){ socket. socketRef.current.reconnect(); }, 5000);
    //     return console.log("Failed to reconnect. Lets try that again in 5 seconds.");
    //   }
    // });

  }

  function callPeer(socketIdToCall) {
    socketRef.current.emit("callByInitiator", {
      userToCall: socketIdToCall,
      userData: { userName: userName },
    });
    let peer = null;


    peer = new Peer({
      initiator: true,
      config: {
        iceServers: iceServersRef.current,
      },
      trickle: false,

      stream: streamRef.current
    });


    peerRefs.current[socketIdToCall].peerRef = peer;

    // the function is fired almost immediatedly by the initator
    peer.on("signal", (data) => {
      //When the initiator has signal data we need to give it to the other user
      console.log("about to emit signal by initiator")
      socketRef.current.emit("signalByInitiator", {
        userToCall: socketIdToCall,
        signalData: data,
        from: yourID.current,
      });
    });
    peer.on("track", (track, streamFromPeer) =>
      onTrack(track, streamFromPeer, socketIdToCall)
    );
    peer.on("data", (message) => onData(socketIdToCall, message));
    peer.on("connect", () => onConnect(peer, socketIdToCall));
    peer.on("error", (err) => onError(socketIdToCall, err));
    socketRef.current.on("signalByAcceptor", (data) => {
      console.log("got signal by acceptor");
      //When we receive signal by acceptor we need to call the signal again on  that data
      if (data.from === socketIdToCall)
        try {
          peer.signal(JSON.stringify(data.signal));
          console.log("now we have called the signal function from the caller side")
        }
        catch (error) {
          setForceRender(Math.random())

          console.log(error)
        }
    });
    return peer;
  }
  function onData(id, message) {

    var messageStr = message.toString();

    if (messageStr.includes("$")) {


      let xyCoord = messageStr.split("$")
      //  console.log("x co " + Math.ceil(xyCoord[0]*w))
      //  console.log("y co " + Math.ceil(xyCoord[1]*h))
      //  createPath(Math.ceil(xyCoord[0]*w),Math.ceil(xyCoord[1]*h),Math.ceil(xyCoord[0]*w)+1,Math.ceil(xyCoord[1]*h)+1,"green",6)  ;


      //  receivedDataFromPeer(id, xyCoord)

      // console.log(mousePointerRef)
      // console.log("current is " + mousePointerRef.current);
      if (mousePointerRef && mousePointerRef.current) {


        debouncedUpdateMousePostion(xyCoord[0], xyCoord[1], id, canvasWidthRef.current, canvasHeightRef.current, xyCoord[5], xyCoord[9]);
        // mousePointerRef.current.setMouseCoords(xyCoord[0], xyCoord[1],id,canvasWidthRef.current, canvasHeightRef.current,xyCoord[5])
      }
    }
    // console.log("the message is " + JSON.stringify(messageStr));  
    //  console.log('message is' + messageStr);
    if (messageStr.startsWith("lastPublishedTrackType-screenShare")) {
      //  console.log("printing last track type " + messageStr);
      if (peerRefs.current[id].videoStream.getVideoTracks().length > 1) {
        //   console.log("both screen sharing and camera are on")
        //   console.log("screensharing stream " + peerRefs.current[id].videoStream.getVideoTracks()[1])
        peerRefs.current[id].screenVideoStream = new MediaStream();
        peerRefs.current[id].screenVideoStream.addTrack(
          peerRefs.current[id].videoStream.getVideoTracks()[1]
        );
        peerRefs.current[id].cameraStream = new MediaStream();
        peerRefs.current[id].cameraStream.addTrack(
          peerRefs.current[id].videoStream.getVideoTracks()[0]
        );
        setForceRender(Math.random());
      } else {
        console.log("only screen sharing");
        peerRefs.current[id].screenVideoStream = new MediaStream();
        peerRefs.current[id].screenVideoStream.addTrack(
          peerRefs.current[id].videoTrack
        );

        setForceRender(Math.random());
      }
      //  screenStreamVideoRef.current.srcObject = peerRefs.current[id].screenVideoStream
      //  lastPublishedScreenSharingStreamRef.current.firstElementChild.srcObject = peerRefs.current[id].screenVideoStream
      // setLastPublishedScreenSharingStream(peerRefs.current[id].screenVideoStream)

    }
    if (messageStr.startsWith("lastPublishedTrackType-camera")) {
      if (peerRefs.current[id].videoStream.getVideoTracks().length > 1) {

        console.log("both screen sharing and camera are on");
        peerRefs.current[id].screenVideoStream = new MediaStream();
        peerRefs.current[id].screenVideoStream.addTrack(
          peerRefs.current[id].videoStream.getVideoTracks()[0]
        );
        peerRefs.current[id].cameraStream = new MediaStream();
        peerRefs.current[id].cameraStream.addTrack(
          peerRefs.current[id].videoStream.getVideoTracks()[1]
        );
        setForceRender(Math.random());
      } else {
        console.log("only camera ");
        peerRefs.current[id].cameraStream = new MediaStream();
        peerRefs.current[id].cameraStream.addTrack(
          peerRefs.current[id].videoTrack
        );
        setForceRender(Math.random());
      }
    }
    if (messageStr === "getLastPublishedTrackType") {
      if (peerRefs.current[id] && peerRefs.current[id].peerRef) {
        try {
          peerRefs.current[id].peerRef.send(
            "lastPublishedTrackType-" + lastPublishedTrackType.current
          );
        } catch (error) {
          setForceRender(Math.random())

          console.log(error)
        }

      }
    }
    if (messageStr === "removeVideo") {
      if (peerRefs.current[id] && peerRefs.current[id].peerRef) {
        peerRefs.current[id].cameraStream = false;
        // peerRefs.current[id].audioVideoRef.current.removeChild(
        //   peerRefs.current[id].videoRef.current
        // );
        setForceRender(Math.random());
      }
    }
    if (messageStr === "removeScreenShare") {

      if (peerRefs.current[id] && peerRefs.current[id].peerRef) {
        peerRefs.current[id].screenVideoStream = null;
        console.log("remove screen child");
        // peerRefs.current[id].audioVideoRef.current.removeChild(
        //   peerRefs.current[id].screenRef.current
        // );

        console.log("last socket id" + lastPublishedScreenSharingStreamSocketID.current);
        console.log("id" + id);
        if (lastPublishedScreenSharingStreamSocketID.current === id) {
          lastPublishedScreenSharingStreamRef.current.firstElementChild.srcObject = null
          lastPublishedScreenSharingStreamSocketID.current = 0
        }
        setViewScreenShareEnabled(false);
        setForceRender(Math.random());
      }
    }
    if (messageStr === "removeAudio") {
      if (peerRefs.current[id] && peerRefs.current[id].peerRef) {
        peerRefs.current[id].audioStream = null;
        // peerRefs.current[id].audioVideoRef.current.removeChild(
        //   peerRefs.current[id].videoRef.current
        // );
        setForceRender(Math.random());
      }
    }
  }
  function onError(id, err) {
    console.log("peer.err" + JSON.stringify(err));
    console.log("the socket id of the peer with err is " + id);



    if (peerRefs && peerRefs.current && peerRefs.current[id]) {
      peerRefs.current[id].peerRef = null
      delete peerRefs.current[id];
    }

    setForceRender(Math.random())
  }
  function removeVideo() {
    console.log("remove video start");

    //checkStream(streamRef.current)
    if (streamRef.current.getVideoTracks().length > 1 && screenSharingEnabled) {


      lastPublishedTrackType.current = "screenShare";
    }
    Object.keys(peerRefs.current).map((socketId) => {
      let peerRef = peerRefs.current[socketId].peerRef;
      try {
        peerRef.send("removeVideo");
      }
      catch (err) {

        if (peerRefs.current[socketId] &&
          peerRefs.current[socketId].peerRef) {
          peerRefs.current[socketId].peerRef.destroy();

          delete peerRefs.current[socketId];
        }

        //somebody left so we are forcing a re render
        setForceRender({});


        console.log(err)
      }
      try {
        peerRef.removeTrack(localCameraTrackRef.current, streamRef.current);
      } catch (error) {
        console.log(error)
      }
    });

    streamRef.current.removeTrack(localCameraTrackRef.current);
    localCameraTrackRef.current.stop();
    localCameraTrackRef.current = null;
    userVideo.current.srcObject = null;
    setVideoEnabled(false);
    setShowCameraOff(false);
  }
  async function startAudio() {
    console.log("start audio called");
    var currentMoment = moment()
    if (recordingTimeRef && recordingTimeRef.current) {
      recordingTimeRef.current.lastUnmutedTime = currentMoment.toISOString();
    }
    setAudioEnabled(true)
    setShowMicOff(false);
    // lastPublishedTrackType.current = "audio"
    await navigator.mediaDevices
      .getUserMedia({ video: false, audio: true })
      .then((localStream) => {

        if (!streamRef.current) {
          streamRef.current = new MediaStream();
        }
        streamRef.current.addTrack(localStream.getAudioTracks()[0]);
        localAudioStreamRef.current = localStream;
        Object.keys(peerRefs.current).map((socketId) => {
          console.log("socket id " + socketId);
          let peerRef = peerRefs.current[socketId].peerRef;
          peerRef.addTrack(
            localAudioStreamRef.current.getAudioTracks()[0],
            localAudioStreamRef.current
          );
        });
        // if (peerRef && peerRef.current && peer) {
        //   console.log("peer exists  and the stream also exists therefore adding/replacing tracks");
        //   peer.addTrack(localStream.getAudioTracks()[0], streamRef.current)
        //   console.log("now adding video track to local and peers are also present")
        // }

        //  setStream(streamRef.current);
        setShowMicOff(true);
        setAudioEnabled(true);
      });
  }
  function removeAudio() {
    console.log("remove audio start");
    var currentMoment = moment()
    if (recordingTimeRef && recordingTimeRef.current) {
      recordingTimeRef.current.lastMutedTime = currentMoment.toISOString();
    }
    setShowMicOff(false)

    checkStream(streamRef.current);
    Object.keys(peerRefs.current).map((socketId) => {
      let peerRef = peerRefs.current[socketId].peerRef;
      try {
        peerRef.send("removeAudio");

      } catch (error) {



        if (peerRefs.current[socketId] &&
          peerRefs.current[socketId].peerRef) {
          peerRefs.current[socketId].peerRef.destroy();
          delete peerRefs.current[socketId];
        }

        //somebody left so we are forcing a re render
        setForceRender({});


        console.log(error)
      }
      try {
        peerRef.removeTrack(
          localAudioStreamRef.current.getAudioTracks()[0],
          localAudioStreamRef.current
        );
      } catch (error) {
        console.log(error)
      }
    });
    streamRef.current.removeTrack(
      localAudioStreamRef.current.getAudioTracks()[0]
    );
    localAudioStreamRef.current.getAudioTracks()[0].stop();
    // setStream(streamRef.current)
    setAudioEnabled(false);
    setShowMicOff(false)
  }
  // function addCustomLabelToSdp(sdp, streamType, trackId) {
  //   let lines = sdp.split("\n");
  //   for (let i = 0; i < lines.length; i++) {
  //     let line = lines[i];
  //     line = line.replace(
  //       /(a=extmap:[0-9]+) [^ \n]+/gi,
  //       "$1 https://www.kevinmoreland.com/webrtc/" + streamType + "/" + trackId
  //     );
  //     lines[i] = line;
  //   }
  //   return lines.join("\n");
  // }
  function forceDisconnect() {
    socketRef.current.emit("forceDisconnect");
  }
  // function getAudioVideoPermissions() {
  //   navigator.mediaDevices
  //     .getUserMedia({ video: true, audio: true })
  //     .then((localStream) => {
  //       console.log("got permissions");
  //     });
  // }
  //we accept a call from another peer simply by creating a peer and later we will call signal function
  // with the data received from this peer in the event "signalByInitiator"
  function acceptCall(id) {
    //console.log("inside accept call from" + id );
    var peer = null;
    peer = new Peer({
      config: {
        iceServers: iceServersRef.current,
      },
      stream: streamRef.current,
      trickle: false
    });
    peerRefs.current[id] = {
      audioRef: null,
      videoRef: null,
      peerRef: null,
      audioVideoRef: null,
    };
    peerRefs.current[id].peerRef = peer;
    peerRefs.current[id].peerRef.on("signal", (data) => {
      //when we have our own signal data   we need to give it to the initiator
      //This event will then be captured by the caller who will call a signal function with this data
      socketRef.current.emit("signalByAcceptor", { signal: data, to: id });
    });
    peerRefs.current[id].peerRef.on("track", (track, streamFromPeer) =>
      onTrack(track, streamFromPeer, id)
    );
    peerRefs.current[id].peerRef.on("connect", () => {
      onConnect(peerRefs.current[id].peerRef, id)
    }
    );
    peerRefs.current[id].peerRef.on("data", (message) => onData(id, message));
    peerRefs.current[id] &&
      peerRefs.current[id].peerRef.on("error", (err) => onError(id, err));
    //when signal data received from the inititor call the signal function on that data
    //console.log('hi' + Object.keys(peerRefs.current).length + 'hi');
  }
  function onConnect(peer, peerSocketId) {
    // peer.send("I have accepted your call");
    //peers[peerSocketId] = peer;
    //console.log("peer refs are" + JSON.stringify(peerRefs.curren
    console.log("connected to peer now  with socket Id" + peerSocketId);
    console.log("peer connection time " + Date().toLocaleString())
    setForceRender(Math.random())
    // console.log("peers are" + JSON.stringify(peers))
  }
  function startScreenSharing() {
    //  console.log("screen sharing started ")
    // const gdmOptions = {
    //   deviceId:"screenid"
    // }
    var displayMediaOptions = {

      video: {

        cursor: "always"

      },

      audio: true

    };

    lastPublishedTrackType.current = "screenShare";
    navigator.mediaDevices.getDisplayMedia(displayMediaOptions).then((localStream) => {
      if (streamRef.current) {
        streamRef.current.addTrack(localStream.getVideoTracks()[0]);
      }
      if (!streamRef.current) {
        streamRef.current = new MediaStream();
        streamRef.current.addTrack(localStream.getVideoTracks()[0]);
      }
      localScreenStreamRef.current = localStream;
      console.log(
        "peer exists  and the stream also exists therefore adding/replacing tracks"
      );
      Object.keys(peerRefs.current).map((socketId) => {
        //console.log("socket id " + socketId)
        let peerRef = peerRefs.current[socketId].peerRef;
        peerRef.addTrack(localStream.getVideoTracks()[0], streamRef.current);
      });
      // screenVideo.current.srcObject = localStream;
      // lastPublishedScreenSharingStreamRef.current.firstElementChild.srcObject = localStream
      // setLastPublishedScreenSharingStream(localStream)
      //  setScreenSharingEnabled(true);
      // setForceRender(Math.random())
      console.log("after screensharing enabled");
      Object.keys(peerRefs.current).map(async (socketId) => {
        console.log("trying picture in picture");
        if (
          peerRefs.current[socketId].cameraStream &&
          peerRefs.current[socketId].videoRef.current
        ) {
          console.log("requesting picture in picture");
          // await peerRefs.current[
          //   socketId
          // ].videoRef.current.requestPictureInPicture();
        }
      });
      setScreenSharingEnabled(true);
      localStream.getVideoTracks()[0].onended = function () {

        if (streamRef.current.getVideoTracks().length > 1) {
          lastPublishedTrackType.current = "camera";
        }
        Object.keys(peerRefs.current).map((socketId) => {
          console.log("now removing the track from peer");
          checkStream(localScreenStreamRef.current);
          if (peerRefs.current[socketId].cameraStream) {
            // document.exitPictureInPicture();
          }
          let peerRef = peerRefs.current[socketId].peerRef;
          try {
            peerRef.send("removeScreenShare");
          }
          catch (err) {
            setForceRender(Math.random())

            console.log(err)
          }
          try {
            peerRef.removeTrack(
              localScreenStreamRef.current.getVideoTracks()[0],
              streamRef.current
            );
          }
          catch (error) {
            console.log(error)
          }
        });
        streamRef.current.removeTrack(
          localScreenStreamRef.current.getVideoTracks()[0]
        );
        localScreenStreamRef.current = null;
        setScreenSharingEnabled(false);

      };
    });
  }
  async function startCamera() {
    console.log("start camera called");
    setShowCameraOff(false)
    if (cameraButtonRef.current) {
      cameraButtonRef.current.setAttribute("disabled", "disabled");
    }
    if (videoEnabled) {
      console.log("camera already enabled");
      // setVideoEnabled(true)
      return
    }
    setVideoEnabled(true)

    await navigator.mediaDevices
      .getUserMedia({ video: true, audio: false })
      .then((localStream) => {
        //
        setVideoEnabled(true);
        if (streamRef.current) {
          streamRef.current.addTrack(localStream.getVideoTracks()[0]);
        }
        if (!streamRef.current) {
          streamRef.current = new MediaStream();
          streamRef.current.addTrack(localStream.getVideoTracks()[0]);
        }
        localCameraTrackRef.current = localStream.getVideoTracks()[0];
        localCameraStreamRef.current = localStream;
        lastPublishedTrackType.current = "camera";
        console.log("peer refs length" + peerRefs.current.length);
        Object.keys(peerRefs.current).map((socketId) => {
          //console.log("socket id " + socketId);
          let peerRef = peerRefs.current[socketId].peerRef;
          peerRef.addTrack(localCameraTrackRef.current, streamRef.current);
        });
        // if (peerRef && peerRef.current && peer) {
        //   console.log("peer exists  and the stream also exists therefore adding/replacing tracks");
        //   console.log("now adding video track to local and peers are also present")
        // }
        // streamRef.current.addTrack(localStream.getVideoTracks()[0], streamRef.current)
        // setStream(streamRef.current);
        // setScreenSharingEnabled(false);
        userVideo.current.srcObject = localStream;
        setShowCameraOff(true)
      });
  }
  // function addCustomLabelToSdp(sdp, streamType, trackId) {
  //   let lines = sdp.split("\n");
  //   for (let i = 0; i < lines.length; i++) {
  //     let line = lines[i];
  //     line = line.replace(
  //       /(a=extmap:[0-9]+) [^ \n]+/gi,
  //       "$1 https://www.kevinmoreland.com/webrtc/" + streamType + "/" + trackId
  //     );
  //     lines[i] = line;
  //   }
  //   return lines.join("\n");
  // }
  // function forceDisconnect() {
  //   socketRef.current.emit("forceDisconnect");
  // }
  // function disconnectFromPeer(peer) {
  //   console.log("disconnecting from peer")
  //   partnerAudioVideoDiv.current.innerHTML = "";
  //   peerRef.current = null;
  //   peer.destroy();
  //   setPeer(null);
  // }
  // function getAudioVideoPermissions() {
  //   navigator.mediaDevices
  //     .getUserMedia({ video: true, audio: true })
  //     .then((localStream) => {
  //       console.log("got permissions");
  //     });
  // }
  function onTrack(track, streamFromPeer, socketId) {
    console.log("logging from track event and asking publisher for tracktype");
    let settings = track.getSettings();
    let constraints = track.getConstraints();
    let peerRef = peerRefs.current[socketId];
    //get the state of video tracks  from the sender sendandthen
    //  console.log(JSON.stringify(peerRef))
    checkStream(streamFromPeer);
    console.log("yes we receive the stream on the accept side");
    console.log("setting remote stream");
    //console.log('content hint' + track.contentHint)
    if (track && track.kind === "video" && peerRef) {
      console.log("settings" + JSON.stringify(settings));
      console.log("constraints" + JSON.stringify(constraints));
      peerRef.videoStream = streamFromPeer;
      peerRef.videoTrack = track;
      try {

        peerRefs.current[socketId].peerRef.send("getLastPublishedTrackType");



      } catch (error) {

        peerRefs.current[socketId].peerRef.on('connect', () => {
          console.log('I am connected to the peer now')
          peerRefs.current[socketId].peerRef.send("getLastPublishedTrackType");
        })
        setForceRender(Math.random())


        console.log(error)
      }
      //partnerVideo.current.srcObject = streamFromPeer;
    }
    if (track && track.kind === "audio") {
      if (peerRef) {
        peerRef.audioStream = streamFromPeer;
        peerRef.audioTrack = track;
      }
      //partnerAudio.current.srcObject = streamFromPeer;
      setForceRender(track);
    }
    console.log("track event ended ");
    //force render needs to change every time , it can be anything but it needs to be different than the previous one else render wont happen
    //not forcing a render now but in the on data method
    //setForceRender(track);
  }
  //function informPeersAboutScreenSharing();
  function checkStream(stream) {
    if (stream) {
      let vidtracks = stream.getVideoTracks();
      let audtracks = stream.getAudioTracks();
      console.log("Video Track: ", vidtracks);
      console.log("Audio Track: ", audtracks);
    } else {
      console.log("stream is no tracks");
    }
  }
  // function sendMessageToPeer(socketId, message) {
  //   peerRefs.current[socketId].peerRef.send(message);
  // }

  // function hasPeers() {
  //   if (
  //     Object.keys(peerRefs.current) &&
  //     Object.keys(peerRefs.current).length > 0
  //   ) {
  //     console.log("yes peers");
  //     return true;
  //   } else return false;
  // }

  //panelId is the socket id or current id which is passed to the function
  // function sendSmiley(socketID, smiley) {
  //   const smileyID = socketID + '-smiley';
  //   document.getElementById(smileyID).innerHTML = smiley;
  //   document.getElementById(smileyID).style.display = 'flex';
  //   setTimeout(() =>
  //     document.getElementById(smileyID).style.display = 'none'
  //     , 1000)
  //     ;
  // }

  function takeScreenshot(socketID) {
    const screenshotID = socketID + '-video';
    let canvas = document.createElement('canvas');
    let video = document.getElementById(screenshotID);
    if (video !== null) {
      canvas.width = 960
      canvas.height = 540;

      let ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      let image = canvas.toDataURL('image/jpeg');
      console.log(image)
      console.log(whiteBoardRef)
      if (whiteBoardRef && whiteBoardRef.current) {
        whiteBoardRef.current.pasteImage(image, whiteBoardRef.current._sketch.addImg, whiteBoardID, true)
      }


      // const a = document.createElement("a");
      // a.href = image;
      // a.download = 'screenshot';
      // a.click();
      canvas.remove();
    }
  }

  let UserVideo;
  UserVideo =

    <Box sx={[{
      height: '100%',
      width: '100%',
    }, !videoEnabled && { display: "none" }]}>
      <Video
        id={yourID.current + '-video'}
        playsInline
        muted ref={userVideo}
        autoPlay
        style={{
          height: 'inherit',
          width: 'inherit',
        }} />
    </Box>
  // let UserScreen;
  // UserScreen = <Box sx={[{ width: "200px", height: "150px" }, !screenSharingEnabled && { display: "none" }]}>
  //   <Video playsInline muted ref={screenVideo} autoPlay /></Box>
  //   ;
  const PeerAudioVideo = ({ socketId }) => {
    const videoRef = useRef();
    const audioRef = useRef();
    const screenRef = useRef();
    const audioVideoRef = useRef();
    // const volumeRef = useRef();
    const [play, setPlay] = useState(false);
    useEffect(() => {
      setStreams();
    }, []);
    function setStreams() {
      if (
        peerRefs.current && peerRefs.current[socketId] && peerRefs.current[socketId].videoTrack &&
        peerRefs.current[socketId].videoTrack.kind === "video"
      ) {

        if (peerRefs.current[socketId] && peerRefs.current[socketId].screenVideoStream) {
          //this has been commented to remove the scree stream from being displayed 
          // screenRef.current.firstElementChild.srcObject =   peerRefs.current[socketId].screenVideoStream;
          // handleChangeShowWhiteBoard(false)
          // screenStreamVideoRef.current.srcObject =             peerRefs.current[socketId].screenVideoStream;

        }
        else {
          //handleChangeShowWhiteBoard(false)

        }
        if (peerRefs.current[socketId] && peerRefs.current[socketId].cameraStream) {
          console.log("setting camera stream" + peerRefs.current[socketId].cameraStream);
          videoRef.current.firstElementChild.srcObject = peerRefs.current[socketId].cameraStream;
        }
      }
      if (peerRefs.current[socketId] && peerRefs.current[socketId].audioStream &&
        peerRefs.current[socketId].audioTrack &&
        peerRefs.current[socketId].audioTrack.kind === "audio" && audioRef && audioRef.current && audioRef.current.firstElementChild
      ) {
        audioRef.current.firstElementChild.srcObject = peerRefs.current[socketId].audioStream;
      }
      if (peerRefs.current[socketId]) {
        peerRefs.current[socketId].audioVideoRef = audioVideoRef;
        peerRefs.current[socketId].audioRef = audioRef;
        peerRefs.current[socketId].videoRef = videoRef;
        peerRefs.current[socketId].screenRef = screenRef;
      }
      return true;
    }
    let PartnerVideo;
    let PartnerAudio = peerRefs.current[socketId].audioStream && (
      <Box sx={[{ display: "flex", justifyContent: "center", },
      ]} ref={audioRef} >
        <audio autoPlay={true} controls playsInline onPause={() => { setPlay(false) }} onPlay={() => { setPlay(true); console.log("playing") }} style={{ display: "none" }}>This is my audio</audio>

        {/* {!play && <MusicOffIcon sx={{ zIndex: 999, backgroundColor: 'white', borderRadius : '50px', padding : '5px', width : '5vh', height : '5vh',}} color="primary" onClick={() => { audioRef.current.firstElementChild.play() }}></MusicOffIcon>}
        {play && <MusicNoteIcon sx={{ zIndex: 999, backgroundColor: 'white', borderRadius : '50px', padding : '5px', width : '5vh', height : '5vh',}} color="success" onClick={() => { audioRef.current.firstElementChild.pause() }}></MusicNoteIcon>} */}

      </Box>
    );
    PartnerVideo = peerRefs.current[socketId].cameraStream && (
      <Box sx={{
        height: '100%',
        width: '100%',
      }} ref={videoRef}>     <Video
          id={socketId + '-video'}
          style={{ height: 'inherit', width: 'inherit' }}
          //style={!screenSharingEnabled ? { width: "100%" } : { width: "30px" }}
          playsInline
          muted
          autoPlay

        />
        {/* {PartnerAudio} */}
      </Box>

    );


    // let PartnerScreen = peerRefs.current[socketId].screenVideoStream && (
    //   <Box ref={screenRef}>
    //     <Video

    //       playsInline
    //       muted

    //       autoPlay
    //     ></Video>
    //   </Box>
    // );

    let PartnerScreenButton = forceRender && peerRefs.current[socketId].screenVideoStream && (
      lastPublishedScreenSharingStreamSocketID.current !== socketId ? (<ScreenShareIcon onClick={() => {
        lastPublishedScreenSharingStreamSocketID.current = socketId;
        lastPublishedScreenSharingStreamRef.current.firstElementChild.srcObject = peerRefs.current[socketId].screenVideoStream;
        //setLastPublishedScreenSharingStream(peerRefs.current[socketId].screenVideoStream)
        setForceRender(Math.random())
        setViewScreenShareEnabled(true)
      }} sx={{
        zIndex: 9999,
        backgroundColor: 'white',
        borderRadius: '50px',
        width: '3.5vh',
        height: '3.5vh',
        marginBottom: '10px'
      }} color="primary"
      />) : <StopScreenShareIcon

        onClick={() => {
          lastPublishedScreenSharingStreamSocketID.current = 0;
          lastPublishedScreenSharingStreamRef.current.firstElementChild.srcObject = null;
          setForceRender(Math.random())
          setViewScreenShareEnabled(false)
        }} sx={{
          zIndex: 9999,
          backgroundColor: 'white',
          borderRadius: '50px',
          padding: '5px',
          width: '5vh',
          height: '5vh',
          marginBottom: '10px'
        }} color="success" />)

    return (
      <Box ref={audioVideoRef} className={"remote-video-" + `${cond}` + `${viewScreenShareEnabled ? '-ss' : fullScreen ? '-f' : onSide ? '-s' : ''}`} sx={[
        peerRefs.current[socketId] && !peerRefs.current[socketId].cameraStream ? { justifyContent: "flex-end", height: "inherit", backgroundColor: "warning.main", position: 'relative' } : { position: 'relative' }]}
        onMouseEnter={() => setShowScreenshot(socketId)}
        onMouseLeave={() => setShowScreenshot(null)}
      >

        {PartnerVideo}

        {PartnerAudio}
        {peerRefs.current[socketId].screenVideoStream && PartnerScreenButton}
        {(typeof (whiteBoardRef.current) !== "undefined") && isWebrtcScreenshot && peerRefs.current[socketId].cameraStream && showScreenshot === socketId && <IconButton
          sx={{
            zIndex: 9999,
            color: 'red',
            position: 'absolute',
            top: 0,
            left: 0
          }}
          onClick={() => takeScreenshot(socketId)}
          size='small'
        >
          <ScreenshotIcon />
        </IconButton>
        }

        <div id={socketId + '-smiley'} style={{
          display: 'none',
          position: 'absolute',
          bottom: '0',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '100px',
          height: '100%',
          width: '100%'
        }}>

        </div>
        {/* <Box sx={{
          display: 'flex',
          height: '100%',
          width: '90%',
          justifyContent: 'center',
          gap: '25px',
          alignContent: 'center',
          position: 'relative',
          flexDirection: 'row',
          alignItems: 'flex-end',
        }}>
          {peerRefs.current[socketId].audioStream && PartnerAudio} 
          {PartnerScreen} 
          {!peerRefs.current[socketId].audioStream && PartnerAudio}

          {peerRefs.current[socketId].screenVideoStream && PartnerScreenButton}
        </Box> */}

      </Box>
    );
  };
  let cond = Object.keys(peerRefs.current).length + 1;

  return (

    roomID ? <ThemeProvider theme={theme}>
      <Box>
        {!isWebrtcData && <Root sx={{
          width: viewScreenShareEnabled ? '100%' : fullScreen ? '100%' : minimize ? '250px' : onSide ? cond >= 5 ? '320px' : '250px' : cond === 1 ? '15%' :
            cond = 2 ? '35%' :
              cond === 3 ? '55%' : cond === 4 ? '75%' : cond >= 5 ? '50%' : '100%',
          height: viewScreenShareEnabled ? '100%' : fullScreen ? '100%' : minimize ? '35px' : onSide ? cond === 1 ? '175px' :
            cond === 2 ? '330px' :
              cond === 3 ? '470px' : cond === 4 ? '600px' : '320px' : cond >= 5 ? '50%' : '27%',
          minWidth: onSide ? '0' : '250px',
          minHeight: onSide || minimize ? '0' : '178px',
          left: onSide ? 'auto' : '0',
          borderRadius: viewScreenShareEnabled || fullScreen ? '0' : '10px',

        }}>

          {/* {isWebrtcSmiley && (!minimize || fullScreen) && <Tooltip title="Reactions">
            <IconButton
              sx={{ position: 'fixed', bottom: 0, right: 0, color: "black" }}
              onClick={() => sendSmiley(yourID.current, '&#128077;')}
              size='large'
            >
              <AddReactionIcon />
            </IconButton>
          </Tooltip>} */}

          {/* <audio src={MeetTone} className="audio-element"></audio> */}
          <Box className={"top-" + `${cond}` + `${viewScreenShareEnabled ? '-ss' : fullScreen ? '-f' : onSide ? '-s' : minimize ? '-m' : ''}`} >
            <Box className={
              viewScreenShareEnabled ? "screen-share-" + `${cond}` : 'screen-share-disabled'}
              ref={lastPublishedScreenSharingStreamRef}>
              <Video style={{ objectFit: "contain", width: '100%', height: '100%' }}
                playsInline
                muted
                autoPlay />
            </Box>

            <Box className={"video-container-" + `${cond}` + `${viewScreenShareEnabled ? '-ss' : fullScreen ? '-f' : onSide ? '-s' : ''}`}>
              {/*<iframe src={process.env.PUBLIC_URL + '/static/silence.mp3'} allow="autoplay" style={{ display: "none" }}></iframe>*/}
              <Box className={"video-stream-" + `${cond}` + `${viewScreenShareEnabled ? '-ss' : fullScreen ? '-f' : onSide ? '-s' : ''}`} >
                {forceRender &&
                  peerRefs.current && Object.keys(peerRefs.current).map((socketId) => {
                    return (
                      <PeerAudioVideo
                        key={socketId}
                        socketId={socketId}
                      ></PeerAudioVideo>
                    );
                  })}
                <Box className={"local-video-" + `${cond}` + `${viewScreenShareEnabled ? '-ss' : fullScreen ? '-f' : onSide ? '-s' : ''}`}
                  sx={{ position: 'relative' }}
                  onMouseEnter={() => setShowScreenshot(yourID.current)}
                  onMouseLeave={() => setShowScreenshot(null)}>
                  {UserVideo}
                  {/*  {UserScreen}  */}
                  <div id={yourID.current + '-smiley'} style={{
                    display: 'none',
                    position: 'absolute',
                    bottom: '0',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '100px',
                    height: '100%',
                    width: '100%'
                  }}>
                  </div>
                  {(typeof (whiteBoardRef.current) !== "undefined") && isWebrtcScreenshot && videoEnabled && showScreenshot === yourID.current && <IconButton
                    sx={{
                      zIndex: 9999,
                      color: 'red',
                      position: 'absolute',
                      top: 0,
                      left: 0
                    }}
                    onClick={() => takeScreenshot(yourID.current)}
                    size='small'
                  >
                    <ScreenshotIcon />
                  </IconButton>
                  }

                </Box>
              </Box>

              {!isConnected && <Box sx={{
                width: "200px",
                position: 'relative',
                height: '0',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
              }}>
                <CircularProgress size={"2rem"} style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto"
                }} />
                <p style={{
                  fontSize: "1rem",
                  display: "block",
                  textAlign: "center",
                  color: "black"
                }}>
                  Connecting ...
                </p>
              </Box>}

            </Box>
          </Box>

          <Box className={"bottom" + `${viewScreenShareEnabled ? '-ss' : fullScreen ? '-f' : minimize ? '-m' : ''}`}>

            {/* isvideoenabled, isaudioenabled and is screensharing enabled are  passed from lesson to check if user has access to given features */}
            {isVideoEnabled && !videoEnabled &&
              <VideocamOffIcon sx={{
                borderRadius: '50px',
                backgroundColor: 'red',
                padding: '5px',
                width: 'fit-content',
                height: (viewScreenShareEnabled || fullScreen) ? '5vh' : '25px',
                margin: '0 5px',
              }} onClick={async () => {
                try { await startCamera(); } catch (err) {
                  if (err.toString().includes("Permission")) {
                    setVideoEnabled(false)
                  }
                  console.log(err)

                }
              }} />
            }


            {isVideoEnabled && showCameraOff && <VideocamIcon sx={{
              borderRadius: '50px',
              backgroundColor: 'red',
              padding: '5px',
              width: 'fit-content',
              height: (viewScreenShareEnabled || fullScreen) ? '5vh' : '25px',
              margin: '0 5px',
            }} onClick={removeVideo} />}


            {/* <ConnectWithoutContactIcon onClick={async () => {
                try {initialise() } catch (err) {
                 
                  console.log(err)

                }
              }} /> */}

            {isAudioEnabled && !audioEnabled &&
              <MicOffIcon sx={{
                borderRadius: '50px',
                backgroundColor: 'red',
                padding: '5px',
                width: 'fit-content',
                height: (viewScreenShareEnabled || fullScreen) ? '5vh' : '25px',
                margin: '0 5px',
              }} onClick={async () => {
                try { await startAudio(); } catch (err) {
                  if (err.toString().includes("Permission")) {
                    setAudioEnabled(false)
                  }
                  console.log(err)

                }
              }} />}

            {isAudioEnabled && showMicOff && <MicIcon sx={{
              borderRadius: '50px',
              backgroundColor: 'red',
              padding: '5px',
              width: 'fit-content',
              height: (viewScreenShareEnabled || fullScreen) ? '5vh' : '25px',
              margin: '0 5px',
            }} onClick={removeAudio} />}

            {isScreenSharingEnabled && !screenSharingEnabled && (
              <ScreenShareIcon sx={{
                borderRadius: '50px',
                backgroundColor: 'red',
                padding: '5px',
                width: 'fit-content',
                height: (viewScreenShareEnabled || fullScreen) ? '5vh' : '25px',
                margin: '0 5px',
              }} onClick={() => {
                try { startScreenSharing(); } catch (err) {
                  console.log(err)
                }
              }} />
            )}

            {onSide && !viewScreenShareEnabled && !fullScreen && (
              <ChevronLeftIcon sx={{
                borderRadius: '50px',
                backgroundColor: 'red',
                padding: '5px',
                width: 'fit-content',
                height: (viewScreenShareEnabled || fullScreen) ? '5vh' : '25px',
                margin: '0 5px',
              }} onClick={() => {
                setOnSide(!onSide);
              }} />
            )}
            {!onSide && !viewScreenShareEnabled && !fullScreen && (
              <ChevronRightIcon sx={{
                borderRadius: '50px',
                backgroundColor: 'red',
                padding: '5px',
                width: 'fit-content',
                height: (viewScreenShareEnabled || fullScreen) ? '5vh' : '25px',
                margin: '0 5px',
              }} onClick={() => {
                setOnSide(!onSide);
              }} />
            )}

            {!fullScreen && !viewScreenShareEnabled && (
              <FullscreenIcon sx={{
                borderRadius: '50px',
                backgroundColor: 'red',
                padding: '5px',
                width: 'fit-content',
                height: (viewScreenShareEnabled || fullScreen) ? '5vh' : '25px',
                margin: '0 5px',
              }} onClick={() => {
                setFullScreen(!fullScreen);
              }} />
            )}

            {fullScreen && !viewScreenShareEnabled && (
              <FullscreenExitIcon sx={{
                borderRadius: '50px',
                backgroundColor: 'red',
                padding: '5px',
                width: 'fit-content',
                height: (viewScreenShareEnabled || fullScreen) ? '5vh' : '25px',
                margin: '0 5px',
              }} onClick={() => {
                setFullScreen(!fullScreen);
              }} />
            )}

            {minimize && !viewScreenShareEnabled && !fullScreen && (
              <UnfoldMoreIcon sx={{
                borderRadius: '50px',
                backgroundColor: 'red',
                padding: '5px',
                width: 'fit-content',
                height: (viewScreenShareEnabled || fullScreen) ? '5vh' : '25px',
                margin: '0 5px',
              }} onClick={() => {
                setMinimize(!minimize);
              }} />
            )}

            {!minimize && !viewScreenShareEnabled && !fullScreen && (
              <UnfoldLessIcon sx={{
                borderRadius: '50px',
                backgroundColor: 'red',
                padding: '5px',
                width: 'fit-content',
                height: (viewScreenShareEnabled || fullScreen) ? '5vh' : '25px',
                margin: '0 5px',
              }} onClick={() => {
                setMinimize(!minimize);
              }} />
            )}



          </Box>


        </Root>}
        <MousePointer ref={mousePointerRef}></MousePointer>
      </Box>


    </ThemeProvider> : 'No Room Provided'
  );


}
export default Webrtc;