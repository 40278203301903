import { useEffect, useState } from "react";
import { getProfilePicture } from "../../../whiteboard/src/service/slateService";
import { Avatar, Card } from "@mui/material";
import { THEME_COLOR, THEME_LIGHT_COLOR } from "../../../whiteboard/src/config/constants";

export function CustomParticipantPlaceholder({
    participantEmail,
    accessToken,
    showEmailOnly
}) {
    const [profilePicture, setProfilePicture] = useState('')

    useEffect(() => {
        if (!showEmailOnly && participantEmail) {
            getProfilePictureUrl(accessToken, participantEmail)
        }
    }, [participantEmail])

    const getProfilePictureUrl = async (accessToken, participantEmail) => {
        // console.log('fetching profile')
        let status, res;
        try {
            res = await getProfilePicture(accessToken, participantEmail)
            status = res.status
        }

        catch (err) {
            console.log(err);
            // status = err.response.status;
        }
        // console.log(index)
        if (status === 200) {
            // console.log('profile url', res)
            setProfilePicture(res.data.picture)
        }
        else {
            // console.log(status)
        }
    }

    return <>
        {<Card
            sx={{
                height: '100%',
                width: '100%',
                backgroundColor: THEME_LIGHT_COLOR,
                fontSize: "1.75rem",
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
                color: '#FFFFFF'
            }}
        >

            <Avatar
                sx={{
                    height: '100%',
                    width: '100%',
                    backgroundColor: THEME_LIGHT_COLOR,
                    fontSize: "1.75rem",
                    fontWeight: "bolder",
                }}
            >
                <Avatar
                    sx={{
                        height: { xs: 80, sm: 80, md: 80, lg: 100 },
                        width: { xs: 80, sm: 80, md: 80, lg: 100 },
                        background: THEME_COLOR,
                        color: '#ffffff'
                    }}
                    src={showEmailOnly ? '' : profilePicture}
                >
                    {participantEmail && participantEmail.substr(0, 2).toUpperCase()}
                </Avatar>
            </Avatar>

        </Card>}
    </>

}