import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const colors =  ['#084887', '#44AF69', '#F1F5F6', '#4D95EA', '#17138B', '#171717']
const CustomLoader = () => {
    const [currentColor, setCurrentColor] = useState('#084887');

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentColor(colors[Math.floor(Math.random() * colors.length)]);
        }, 300)

        return () => clearInterval(intervalId);
    }, [])

    return (

        <CircularProgress sx={{ justifyContent: 'center', alignItems: 'center', color: currentColor }} />

    );

}
export default CustomLoader;
