import * as React from 'react';
import { useState, useEffect } from 'react';
import { Box, FormControl, Chip, IconButton, Typography, Autocomplete, TextField, createFilterOptions } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { getContactList, ValidateEmail } from '../../../../components/utils';
import { capitalizeFirstAlphabet } from '../../../../components/helperFunctions';
import { CHIP_COLOR, THEME_COLOR } from '../../config/constants';

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option.email + option.firstName + option.lastName + (option.firstName + ' ' + option.lastName),
});

const InputNames = ({ otherNamesList, namesList, setNamesList, placeholderName, accessToken, isTextFocused }) => {

    const [name, setName] = useState('')
    const [displayNamesList, setDisplayNamesList] = useState([])
    const [isEmailIdInvalid, setIsEmailIdInvalid] = useState(false);
    const [isUserAlreadyAdded, setIsUserAlreadyAdded] = useState(false);
    const [contactList, setContactList] = useState([])
    // const [isInputFocused, setIsInputFocused] = useState(false);
    useEffect(() => {

        initialize()
    }, [])

    const gettingContactList = async () => {
        const listOfContacts = await getContactList(accessToken)
        // console.log(listOfContacts)
        return listOfContacts;
    }

    const initialize = async () => {
        const result = await gettingContactList()
        setContactList(result)
        let tempDisplayList = []
        for (let i = 0; i < namesList.length; ++i) {
            let obj = {
                'email': namesList[i],
                'firstName': '',
                'lastName': ''
            }
            for (let j = 0; j < result.length; ++j) {
                if (namesList[i] === result[j].email) {
                    obj.firstName = result[j].firstName;
                    obj.lastName = result[j].lastName
                }
            }
            tempDisplayList.push(obj)
        }
        // console.log(tempDisplayList)
        setDisplayNamesList(tempDisplayList)
    }

    useEffect(() => {

        // console.log('nameslist changed')
        let tempDisplayList = []
        for (let i = 0; i < namesList.length; ++i) {
            let obj = {
                'email': namesList[i],
                'firstName': '',
                'lastName': ''
            }
            for (let j = 0; j < contactList.length; ++j) {
                if (namesList[i] === contactList[j].email) {
                    obj.firstName = contactList[j].firstName;
                    obj.lastName = contactList[j].lastName
                }
            }
            tempDisplayList.push(obj)
        }
        // console.log(tempDisplayList)
        setDisplayNamesList(tempDisplayList)
    }, [namesList])

    const addNames = () => {
        // console.log(name)
        if (name !== '') {

            if (!(namesList.includes(name)) && !(otherNamesList.includes(name))) {
                // console.log(ValidateEmail(name))
                if (ValidateEmail(name)) {
                    setName('')
                    setNamesList(prevValue => [...prevValue, name.toLowerCase()])
                    setIsEmailIdInvalid(false)
                }
                else {
                    document.getElementById(placeholderName).focus();
                    setIsEmailIdInvalid(true)
                    setTimeout(() => {
                        setIsEmailIdInvalid(false)
                    }, 1000)
                }
                setIsUserAlreadyAdded(false)
            }
            else {
                setIsUserAlreadyAdded(true)
            }
            // console.log(namesList)
        }
        setName('')
        // console.log(document.getElementById(placeholderName).value)
    }

    const checkKeyPress = (e) => {
        const { key, keyCode } = e;
        // console.log(key, keyCode);
        if (keyCode === 13) {
            addNames()
        }
    };


    const handleDeleteNames = (chipToDelete) => () => {
        const newList = namesList.filter((data, index) => index !== chipToDelete);
        // console.log(newList)
        setNamesList(newList)
    };
    return (<Box >
        <FormControl sx={{ width: { xs: '100%', sm: '80%', md: '80%', lg: '80%' }, marginBottom: '15px' }} >
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                {/* <Autocomplete
                    disablePortal
                    options={top100Films}
                    id={placeholderName}
                    sx={{ borderRadius: '25px', width: '85%', height: '60px', }}

                    name={placeholderName}
                    renderInput={(params) => <OutlinedInput
                        {...params}
                        type="email"
                        pattern=".+@globex\.com"
                        placeholder={placeholderName}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />}
                /> */}
                <Autocomplete
                    id={placeholderName}
                    options={contactList}
                    freeSolo
                    value={name}
                    // getOptionSelected={(option, value) => option === value}
                    renderOption={(props, option, { selected }) => (
                        <li {...props} key={option.user_id}>
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    '& span': {
                                        color: '#8b949e',
                                    },
                                    fontSize: '0.8rem'
                                }}
                            >
                                {capitalizeFirstAlphabet(option.firstName) + ' ' + capitalizeFirstAlphabet(option.lastName)}
                                <br />
                                <span>{option.email}</span>
                            </Box>

                        </li>

                    )}
                    getOptionLabel={(option) => typeof option === 'string'
                        || option instanceof String ? option : ""}
                    filterOptions={filterOptions}
                    onChange={(event, value) => {
                        // console.log(event)
                        // console.log(value)
                        if (value) {
                            setName(value.email)
                        }
                        else {
                            setName('')
                        }
                    }}
                    sx={{
                        width: '100%', height: '60px',
                        '& .MuiAutocomplete-inputRoot': {
                            borderRadius: '25px',
                            '&.Mui-focused fieldset': {
                                borderColor: `${THEME_COLOR} !important`,
                            },
                        },

                    }}
                    componentsProps={{
                        paper: {
                            sx: {
                                width: '100%',
                                borderRadius: '15px',
                            }
                        }
                    }}
                    renderInput={(params) =>
                        <TextField
                            {...params}

                            sx={{ borderRadius: '25px', width: '100%', height: '60px', }}
                            type="email"
                            pattern=".+@globex\.com"
                            placeholder={placeholderName}
                            value={name}
                            onFocus={() => {
                                isTextFocused.current = true;
                            }}
                            onBlur={() => {
                                isTextFocused.current = false;
                            }}
                            onChange={(e) => setName(e.target.value)}
                            onKeyDown={checkKeyPress}
                        />}
                />
                <IconButton onClick={addNames} sx={{ width: '15%' }} ><AddIcon /></IconButton>
            </Box>
            {isEmailIdInvalid && <Typography variant='body2' sx={{ color: 'red', marginTop: '5px', justifyContent: 'left', textAlign: 'left', paddingLeft: '10px' }}>Invalid Email ID</Typography>}
            {isUserAlreadyAdded && <Typography variant='body2' sx={{ color: 'red', marginTop: '5px', justifyContent: 'left', textAlign: 'left', paddingLeft: '10px' }}>User has already been added to Host(s)/Participant(s)</Typography>}
            <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '5px', flexWrap: 'wrap' }}>
                {displayNamesList.length > 0 && displayNamesList.map((data, index) => {
                    return (
                        <Chip
                            key={index}
                            label={data.firstName && data.lastName && data.firstName !== '' && data.lastName !== '' ? (capitalizeFirstAlphabet(data.firstName) + ' ' + capitalizeFirstAlphabet(data.lastName)) : (data.email)}
                            onDelete={handleDeleteNames(index)}
                            sx={{ marginTop: '5px', backgroundColor: CHIP_COLOR, marginRight: '5px', color: '#ffffff' }}
                        />

                    );
                })}
            </Box>
        </FormControl>
    </Box>

    )
};

export default InputNames;
