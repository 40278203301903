import * as React from 'react';
import { getConnectionQualityIcon } from '../assets/icons/utils'; 
import { useConnectionQualityIndicator } from '@livekit/components-react';

/** @public */


/**
 * The `ConnectionQualityIndicator` shows the individual connection quality of a participant.
 *
 * @example
 * ```tsx
 * <ConnectionQualityIndicator />
 * ```
 * @public
 */
export function CustomConnectionQualityIndicator(props) {
    const { className, quality } = useConnectionQualityIndicator(props);
    const elementProps = React.useMemo(() => {
        return { ...(props, { className: className }), 'data-lk-quality': quality };
    }, [quality, props, className]);
    return <div {...elementProps}>{props.children ?? getConnectionQualityIcon(quality)}</div>;
}