export const getQueryObject = (search) => {
   var query;
   var queryFields
   if (search && search.length > 0) {
      query = search.substr(1);
      queryFields = query.split("&")
      //console.log("splitted",queryFields);
   }
   var queryObject = {}
   if (queryFields && queryFields.length > 0) {
      for (var queryField of queryFields) {
         var arr = queryField.split("=")
         if (arr && arr.length === 2) {
            queryObject[arr[0]] = arr[1];
         }
      }
   }
   //console.log("queryobject",queryObject);
   return queryObject;
}