const prod = {
        BACKEND_API_URL: 'https://slate-backend.teachmatter.com/',
        FRONTEND_URL: 'https://app.teachmatter.com/',
        LESSON_SERVER_URL: 'https://lesson.teachmatter.com/',
        UPLOAD_API_URL: "https://files.teachmatter.com/",
        STREAM_FILE_SERVER : "https://" + "av.teachmatter.com" + ':' + "5005" + '/',
}
const dev = {

        BACKEND_API_URL: 'https://slate-backend.teachmatter.com/',
        FRONTEND_URL: 'https://app.teachmatter.com/',
        LESSON_SERVER_URL: 'https://lesson.teachmatter.com/',
        UPLOAD_API_URL: "https://files.teachmatter.com/",
        STREAM_FILE_SERVER : "https://" + "av.teachmatter.com" + ':' + "5005" + '/',
};
// console.log(process.env.NODE_ENV, 'node environment')

export const config = process.env.NODE_ENV === 'development' ? dev : prod;