// import { RecordRTC, RecordRTCPromisesHandler } from 'recordrtc';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import * as React from "react";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { Button, Dialog, DialogActions, Box, Typography, DialogContent, DialogTitle, Tooltip } from "@mui/material";
import { v4 as uuidv4 } from 'uuid';
import { uploadWhiteboardFiles } from '../whiteboard/src/service/slateService';
import { MAXIMUM_CHUNKS_FOR_RECORDING_IN_ONE_GO, THEME_COLOR, UPLOAD_API_URL } from '../whiteboard/src/config/constants';
import { createWhiteboardAudioBlob, getWhiteboardAudioBlob } from '../services/recordingService';
import NotStartedIcon from '@mui/icons-material/NotStarted';
import { useAtomValue } from 'jotai';
import { isRecordingEnabledAtom } from '../components/jotai/atom/isRecordingEnabledAtom';

// const xhr = new XMLHttpRequest();



function RecordingButtonExternal({ iconColor, recordingTimeRef, whiteBoardID, clientID, isRecordingEnabledProp, accessToken, autoRecord }) {
  const [recordingTime, setRecordingTime] = React.useState(0);
  const [isRecording, setIsRecording] = React.useState(false);
  // const [isRecordingEnabled, setIsRecordingEnabled] = React.useState(isRecordingEnabledProp);
  const [recordingInterval, setRecordingInterval] = React.useState(null);
  // const [recorder, setRecorder] = React.useState(null)
  const [blobBatchID, setBlobBatchID] = React.useState(uuidv4());
  const [chunks, setChunks] = React.useState([]);
  const mediaRecorderRef = React.useRef(null);
  const [recordingConfirmation, setRecordingConfirmation] = React.useState(false)
  const isRecordingEnabled = useAtomValue(isRecordingEnabledAtom)


  const getAudioRecordingBlobsByRoomNameFromDB = async (roomName, clientId) => {

    var blobsArray = []
    var result = [];
    // console.log("room name " + roomName)
    let status, res;
    try {
      res = await getWhiteboardAudioBlob(accessToken, roomName);;
      status = res.status
    }

    catch (err) {
      console.log(err);
      if (err && err.response) {
        status = err.response.status;
      }
    }
    // console.log(index)
    if (status === 200) {
      // console.log(res)
      result = res.data.whiteBoardAudioBlobs
      for (let index = 0; index < result.length; index++) {

        blobsArray.push({
          id: result[index].id,
          roomName: result[index].whiteboard_id,
          blobLink: result[index].recording_file_link,
          duration: result[index].duration,
          blobBatchNumber: result[index].blob_batch_number,
          blobBatchID: result[index].blob_batch_id,
          clientID: result[index].client_id,
          recordingStartTime: result[index].recording_start_time,
          createdAt: result[index].created_at

        })

      }
    }
    else {
      console.log(status)
      // if (status === 400)
      //    alert('No Uploads Yet')
    }


    blobsArray.sort(function (a, b) {
      var momentA = moment(a.createdAt, "YYYY-MM-DDTHH:mm:ss.SSS")
      var momentB = moment(b.createdAt, "YYYY-MM-DDTHH:mm:ss.SSS")
      if (momentA.isBefore(momentB))
        return -1;
      return 1;
    })

    const blobArrayWithSameClientID = blobsArray.filter((obj) => {
      return obj.clientID === clientId
    })
    // console.log("blobsArray is from db", blobArrayWithSameClientID);
    return blobArrayWithSameClientID;

  }



  const moment = extendMoment(Moment);
  var duration = moment.duration(recordingTime, 'seconds');
  var time = duration.hours() + ":" + duration.minutes() + ":" + duration.seconds();


  React.useEffect(() => {

    // console.log("the component is mounting");
    // let audioDbName = "wbdbaudio" + whiteBoardID


    var currentMoment = moment();
    if (recordingTimeRef && recordingTimeRef.current) {
      recordingTimeRef.current.lastMutedTime = currentMoment.toISOString()
    }
    const getRecordingTime = async () => {
      if (whiteBoardID && clientID) {
        var blobsArray = await getAudioRecordingBlobsByRoomNameFromDB(whiteBoardID, clientID, accessToken);
        setRecordingTime(blobsArray.length * 10);

        if (autoRecord) {
          // console.log('brocode', blobsArray)
          // console.log('brocode', recordingTime)
          if (document.getElementById('start-recording-button')) {
            setTimeout(() => {
              document.getElementById('start-recording-button').click()
            }, 1000)
          }
        }
        else {
          // console.log('brocode', recordingTime)
          setRecordingConfirmation(true)
        }
      }
    }
    getRecordingTime();
  }, [whiteBoardID, clientID])



  const startRecording = async () => {
    //   console.log("starting recording")
    let stream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
    setIsRecording(true);
    var start = recordingTime;
    var timer = setInterval(() => {
      start += 1;
      setRecordingTime(start);
    }, 1000);
    setRecordingInterval(timer);
    var startTime = moment().toISOString()

    let options = {
      type: 'audio',
      timeSlice: 10000,
      mimeType: 'audio/wav'
    };
    try {
      mediaRecorderRef.current = new MediaRecorder(stream);
      // console.log('media recorder')
    } catch (e0) {
      // console.log('Unable to create MediaRecorder with options    Object: ', e0);
      try {
        options = { mimeType: 'video/webm,codecs=vp9' };
        mediaRecorderRef.current = new MediaRecorder(stream, options);
      } catch (e1) {
        // console.log('Unable to create MediaRecorder');
        try {
          options = 'video/vp8'; // Chrome 47
          mediaRecorderRef.current = new MediaRecorder(stream, options);
        } catch (e2) {
          alert('MediaRecorder is not supported by this browser');
          return;
        }
      }
    }
    // console.log("now register on data available")
    mediaRecorderRef.current.start(10000);

    mediaRecorderRef.current.ondataavailable = (event) => {
      // console.log('ondataavailable')
      if (event.data && event.data.size > 0) {
        // console.log('pushing data')
        dataAvailableUpload(event.data, startTime)
      }
    };

  }


  async function dataAvailableUpload(blob, startTime) {
    // console.log("data is available for upload")
    //  console.log("time is ", startTime)
    var chunkStartTime = moment(startTime);
    chunkStartTime.add(chunks.length * 10, 'seconds');
    var chunkEndTime = moment(chunkStartTime);
    chunkEndTime.add(10, 'seconds');

    var lastUnmutedTime, lastMutedTime;
    if (recordingTimeRef && recordingTimeRef.current) {
      lastUnmutedTime = recordingTimeRef.current.lastUnmutedTime;
      lastMutedTime = recordingTimeRef.current.lastMutedTime;
    }
    var shouldSendMutedAudio = false;
    if (lastMutedTime && lastUnmutedTime) {
      // console.log("both last muted time and last muted time exist");
      let lastUnmutedTimeMoment = moment(lastUnmutedTime);
      let lastMutedTimeMoment = moment(lastMutedTime);
      let chunkTime = moment.range(chunkStartTime, chunkEndTime);
      if (lastMutedTimeMoment.isBefore(lastUnmutedTimeMoment)) {
        // console.log("the last muted time moment is before the last unmuted time")
        let mutedRange = moment.range(lastMutedTimeMoment, lastUnmutedTimeMoment);
        if (mutedRange.overlaps(chunkTime)) {
          shouldSendMutedAudio = true;
          // console.log("the muted range overlaps with chuck time there setting should send muted audio to true " );
        }
      }
      else {
        // console.log("the last unmuted time is before the last muted time");
        if (chunkTime.contains(lastUnmutedTimeMoment) || chunkTime.contains(lastMutedTimeMoment)) {
          // console.log("the chunk time contains either last unmuted or last muted time");
          shouldSendMutedAudio = true;
        }
        if (lastMutedTimeMoment.isBefore(chunkEndTime)) {
          shouldSendMutedAudio = true;
        }
      }
    }
    else {
      if (lastMutedTime) {
        // console.log("only last muted time exists and this should never happen ");
        let lastMutedTimeMoment = moment(lastMutedTime);
        if (lastMutedTimeMoment.isBefore(chunkEndTime))
          shouldSendMutedAudio = true;
      }
      if (lastUnmutedTime) {
        // console.log("only last unmuted time exists and this should happen only at the start");
        shouldSendMutedAudio = false;
      }
    }


    if (typeof lastUnmutedTime == 'undefined' && typeof lastMutedTime == 'undefined') {
      // console.log("both muted and unmuted times are undefined and this can only happen once or at the start");
      shouldSendMutedAudio = true
    }

    var normalArr = [];
    chunks.push(blob)
    normalArr.push(blob)
    //   console.log("whiteBoardID  is", whiteBoardID)
    // var newblob = new Blob(normalArr, {
    //   type: 'audio/wav'
    // });

    // console.log("should send muted audio" + shouldSendMutedAudio)
    if (chunks.length > MAXIMUM_CHUNKS_FOR_RECORDING_IN_ONE_GO) {
      // console.log('stopping recording as chunks exceeded', MAXIMUM_CHUNKS_FOR_RECORDING_IN_ONE_GO)
      document.getElementById('stop-recording-button').click()
      return;
    }
    // we dont need to upload any audio files from local when audio is muted.
    //Either the muted chuck is the first chunk or in between that we already have
    let storageFileLink
    if (shouldSendMutedAudio) {
      if (chunks.length === 1) {
        // console.log("this is the first chunk and the chunks length is " + chunks.length + "and the should send muted audio is " + shouldSendMutedAudio);
        storageFileLink = UPLOAD_API_URL + "uploads/9eebcf13-7a44-4e2a-a2e3-ddf0c9a989f8/2021-06-28T08_45_59.050Z-mutedAudio-c843c8d3-d1e3-4cb3-8f82-3f8d3435cca0-1";

        await createAudioBlobFunc(whiteBoardID, storageFileLink, blobBatchID, chunks.length, startTime, 10000, clientID, storageFileLink, accessToken)
      }
      else {
        // console.log("this is not the  chunk and the chunks length is " + chunks.length + "and the should send muted audio is " + shouldSendMutedAudio);
        storageFileLink = UPLOAD_API_URL + "uploads/9eebcf13-7a44-4e2a-a2e3-ddf0c9a989f8/2021-06-28T08_45_59.050Z-mutedAudio-c843c8d3-d1e3-4cb3-8f82-3f8d3435cca0-2";
        await createAudioBlobFunc(whiteBoardID, storageFileLink, blobBatchID, chunks.length, startTime, 10000, clientID, storageFileLink, accessToken)
      }
      return;
    }
    // const fileLink = "https://" + awsmobile.aws_user_files_s3_bucket + ".s3.ap-south-1.amazonaws.com/public/";
    const fileName = "whiteBoard_audio_" + whiteBoardID + "_" + startTime + "_" + blobBatchID + "_" + chunks.length;
    // const fileBuffer = await convertBlobToFileBuffer(blob);
    // console.log("uploading the blob now since the audio is unmuted and the chunk length is" + chunks.length);
    let audioStorageFileLink;

    const requestBody = new FormData();
    requestBody.append('file', blob, fileName);
    // imageData.append('file', file);
    let status, res;
    try {
      res = await uploadWhiteboardFiles(accessToken, whiteBoardID, 'audioRecording', requestBody);
      status = res.status
    }

    catch (err) {
      console.log(err);
      status = err.response.status;
    }
    // console.log(index)
    if (status === 200) {
      // alert('file uploaded')
      audioStorageFileLink = UPLOAD_API_URL + "uploads/whiteBoardStorage/" + whiteBoardID + '/audioRecording/' + fileName
    }
    else {
      console.log(status)
    }

    // console.log("the file link in minio " + audioStorageFileLink)
    await createAudioBlobFunc(whiteBoardID, storageFileLink, blobBatchID, chunks.length, startTime, 10000, clientID, audioStorageFileLink, accessToken)

  }


  // async function convertBlobToFileBuffer(blob) {
  //   const arrayBuffer = await blob.arrayBuffer();
  //   const fileBuffer = Buffer.from(arrayBuffer);
  //   return fileBuffer
  // }

  const stopRecording = async () => {
    // console.log("we are stopping the recording now so the chunks will again start from 1 once we starrt the recording");
    setIsRecording(false);
    clearInterval(recordingInterval);
    setBlobBatchID(uuidv4());
    setChunks([]);
    mediaRecorderRef.current.stop();
  }


  async function createAudioBlobFunc(whiteBoardID, blobLink, blobBatchID, blobBatchNumber, recordingStartTime, duration, clientID, minioStorageFileLink) {
    //   console.log("createAudioRecordingBlob props", whiteBoardID, blobLink, blobBatchID, blobBatchNumber, recordingStartTime, duration)


    //Uploading to dashboard server
    const whiteBoardData = {
      "whiteBoardId": whiteBoardID,
      "clientId": clientID,
      "recordingFileLink": minioStorageFileLink,
      "recordingStartTime": recordingStartTime,
      "blobBatchId": blobBatchID,
      "blobBatchNumber": blobBatchNumber,
      "duration": duration
    }

    let status, res;
    try {
      res = await createWhiteboardAudioBlob(accessToken, whiteBoardData);
      status = res.status
    }

    catch (err) {
      console.log(err);
      status = err.response.status;
    }
    // console.log(index)
    if (status === 200) {
      // console.log(res)
    }
    else if (status === 401) {
      console.log(status)
    }
    else {
      console.log(status)
    }



  }



  if (isRecordingEnabled)
    return (
      <>
        <Button
          id='stop-recording-button'
          sx={{
            display: 'none',
          }} onClick={stopRecording}>
        </Button>
        <Dialog
          open={recordingConfirmation}
        // onClose={() => {
        //   setRecordingConfirmation(false)
        // }}
        >
          <DialogTitle sx={{ justifyContent: 'center', textAlign: 'center', color: '#B33A3A' }} >
            Record Session
          </DialogTitle>
          <DialogContent>
            <Typography sx={{ fontSize: "1rem", padding: '0 25px' }}>Do you want to start the recording?</Typography>
          </DialogContent>
          <DialogActions>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Button
                sx={{ width: "50%", color: THEME_COLOR }}
                onClick={async () => {
                  setRecordingConfirmation(false)
                  startRecording()
                }}
              >
                Yes
              </Button>
              <Button
                sx={{ width: "50%", color: THEME_COLOR }}
                onClick={(e) => {
                  setRecordingConfirmation(false)
                }}
              >
                No
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
        {
          !isRecording ?
            <Tooltip title="Start recording">
              <>
                <Button
                  id='start-recording-button'
                  sx={{
                    display: { xs: 'none', sm: 'flex', md: 'flex', lg: 'flex' },
                    color: 'red',
                    position: 'fixed',
                    bottom: '10px',
                    left: '10px',
                    backgroundColor: '#ffffff',
                    padding: '10px',
                    borderRadius: '10px',
                    boxShadow: 1, zIndex: 100,
                    textTransform: 'none',
                    fontSize: "0.85rem",
                    "&:hover": { backgroundColor: "rgb(255,255,255,0.8)" }
                  }} onClick={startRecording}>Recording{" " + time + ' '} <NotStartedIcon /> </Button>
                <Button sx={{
                  display: { xs: 'flex', sm: 'none', md: 'none', lg: 'none' },
                  color: '#ffffff',
                  height: "2rem",
                  paddingBottom: '10px',
                  marginBottom: '10px',
                  textAlign: 'center',
                  justifyContent: 'center',
                  alignItems: 'center'
                }} onClick={startRecording}>REC{" " + time + ' '}  </Button>
              </>
            </Tooltip> :
            <Tooltip title="Stop recording">
              <>
                <Button
                  id='stop-recording-button'
                  sx={{
                    display: { xs: 'none', sm: 'flex', md: 'flex', lg: 'flex' },
                    animationName: '$blinker',
                    animationDuration: '1s',
                    animationTimingFunction: 'linear',
                    animationIterationCount: 'infinite',
                    color: "#ffffff",
                    backgroundColor: "red",
                    position: 'fixed',
                    bottom: '10px',
                    left: '10px',
                    padding: '10px',
                    borderRadius: '10px',
                    boxShadow: 1,
                    zIndex: 100,
                    fontSize: "0.85rem",
                    textTransform: 'none',
                    "&:hover": { backgroundColor: "rgb(255,0,0,0.6)" }
                  }} onClick={stopRecording}>Recording  {" " + time + ' '} <FiberManualRecordIcon />
                </Button>

                <Button
                  id='stop-recording-button'
                  sx={{
                    display: { xs: 'flex', sm: 'none', md: 'none', lg: 'none' },
                    animationName: '$blinker',
                    animationDuration: '1s',
                    animationTimingFunction: 'linear',
                    animationIterationCount: 'infinite',
                    color: "red",
                    height: "2rem",
                    paddingBottom: '10px',
                    marginBottom: '10px',
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }} onClick={stopRecording}>REC{" " + time + ' '}  </Button>
              </>
            </Tooltip>
        }</>)
  return <></>





}

export default RecordingButtonExternal;



