import React, { forwardRef, useImperativeHandle, useState } from "react";
import { IconButton, Box, Button, Popover, Typography, OutlinedInput } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Rating from '@mui/material/Rating';
import { THEME_COLOR, THEME_HOVER_COLOR } from "../whiteboard/src/config/constants";


const FeedbackDialog = forwardRef(({ isTeacher, sendLessonEventViaWebSocket, userName }, ref) => {
    const [feedbackInputValue, setFeedbackInputValue] = useState('');
    const [ratingValue, setRatingValue] = useState(0)
    const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
    const [openConfirmationForFeedback, setOpenConfirmationForFeedback] = useState(false)

    useImperativeHandle(ref, () => ({
        handleOpenFeedbackDialog
    }));

    const handleOpenFeedbackDialog = (value) => {
        setOpenFeedbackDialog(value)
    }


    return <>
        {isTeacher && <>
        {/* <Tooltip title='Ask for feedback'>
            <IconButton
                sx={{ color: THEME_COLOR, position: 'fixed', right: 0, bottom: 0, zIndex: 100 }}
                size="large"
                onClick={(e) => {
                    setOpenConfirmationForFeedback(e.currentTarget)
                }}>
                <FeedbackIcon />
            </IconButton>
        </Tooltip> */}
            <Popover
                open={openConfirmationForFeedback}
                anchorEl={openConfirmationForFeedback}
                onClose={() => setOpenConfirmationForFeedback(false)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', padding: '10px' }}>
                    <Typography variant='body2' sx={{
                        color: THEME_COLOR,
                    }}>Do you want to ask for feedback?</Typography>
                    <Box sx={{
                        paddingTop: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'center',
                    }}>

                        <Button
                            color="secondary"
                            type="submit"
                            variant="contained"
                            sx={{
                                height: '25px',
                                textTransform: 'none',
                                background: THEME_COLOR,
                                '&:hover': {
                                    backgroundColor: THEME_HOVER_COLOR,
                                    boxShadow: 'none',
                                },
                                marginRight: '10px'
                            }}
                            onClick={() => {
                                setOpenConfirmationForFeedback(false)
                            }}
                        >
                            No
                        </Button>
                        <Button
                            color="secondary"
                            type="submit"
                            variant="contained"
                            sx={{
                                height: '25px',
                                textTransform: 'none',
                                background: THEME_COLOR,
                                '&:hover': {
                                    backgroundColor: THEME_HOVER_COLOR,
                                    boxShadow: 'none',
                                },
                            }}
                            onClick={() => {

                                let lessonEvent = {
                                    eventType: 'feedback',
                                    eventData: {
                                        'openFeedbackDialog': true,
                                        'sentBy': userName
                                    }
                                }
                                sendLessonEventViaWebSocket(lessonEvent)
                                setOpenConfirmationForFeedback(false)
                            }}
                        >
                            Yes
                        </Button>
                    </Box>

                </Box>
            </Popover>
        </>}
        <Dialog
            open={openFeedbackDialog}
            onClose={() => {
                setOpenFeedbackDialog(false)
            }}
            fullWidth
        >
            <DialogTitle sx={{ justifyContent: 'center', textAlign: 'center', position: 'relative' }}>
                <Typography sx={{ fontSize: '2.5rem', fontWeight: '600', color: THEME_COLOR }}>
                    Feedback Form
                </Typography>
                <IconButton
                    sx={{ color: 'black', position: 'absolute', right: '5px', top: "5px", zIndex: 10000 }}
                    onClick={() => {
                        setOpenFeedbackDialog(false)
                    }}
                    size="medium">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box sx={{


                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                }}>
                    <Box sx={{
                        paddingTop: '10px 20%', display: 'flex', justifyContent: 'center', width: '100%',
                    }}>
                        <Typography variant='body1' sx={{ fontWeight: '400', color: '#000000' }}>
                            How was the quality of teaching provided by the teacher?
                        </Typography>

                    </Box>
                    <Box sx={{
                        paddingTop: '10px', display: 'flex', justifyContent: 'center', width: '100%', paddingBottom: '10px',
                    }}>
                        <Rating
                            value={ratingValue}
                            onChange={(event, newValue) => {
                                setRatingValue(newValue);
                            }}
                            size="large"
                        />

                    </Box>
                    <Box sx={{
                        paddingTop: '10px', display: 'flex', justifyContent: 'center', width: '100%', paddingBottom: '10px',
                    }}>
                        <OutlinedInput
                            sx={{
                                width: '80%', borderRadius: '10px',
                                "& .MuiOutlinedInput-root": {
                                    '&:hover ': {
                                        borderColor: THEME_COLOR,
                                    },
                                    '&.Mui-focused': {
                                        borderColor: THEME_COLOR,
                                    },
                                }
                            }}
                            id='feedback' type="text" placeholder="Any feedback or comments" value={feedbackInputValue} onChange={(e) => { setFeedbackInputValue(e.target.value) }} name="Feedback" required
                        />

                    </Box>
                    <Box sx={{
                        paddingTop: '10px', display: 'flex', justifyContent: 'center', width: '100%', paddingBottom: '10px'
                    }}>

                        <Button
                            type="submit"
                            variant="contained"
                            sx={{
                                textTransform: 'none',
                                background: THEME_COLOR,
                                '&:hover': {
                                    backgroundColor: THEME_HOVER_COLOR,
                                    boxShadow: 'none',
                                },
                            }}
                            onClick={() => {
                                setOpenFeedbackDialog(false)
                            }}
                        >
                            Send Feedback
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>

    </>;
});

export default FeedbackDialog