

import React, { forwardRef, useImperativeHandle, useState } from "react";
import { IconButton, Box, Grid, Typography, Tooltip } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import Draggable from "react-draggable";
import AssignmentIcon from '@mui/icons-material/Assignment';
import { WHITEBOARD_ICON_COLOR } from "../whiteboard/src/config/constants";

const SlatePad = forwardRef((props, ref) => {
    const [openSlatePad, setOpenSlatePad] = useState(false);

    useImperativeHandle(ref, () => ({
        handleOpenSlatePad,
    }));

    const handleOpenSlatePad = (value) => {
        setOpenSlatePad(value)

    }


    return <Box>
        <Tooltip title='Slate Pad' placement="right">
            <IconButton
                id='slate-pad-button'
                sx={{ position: 'fixed', left: '15px', top: '55%', color: WHITEBOARD_ICON_COLOR, zIndex: 100 }}
                onClick={() => {
                    setOpenSlatePad(true)
                    props.sendLessonEventViaWebSocket({
                        eventType: 'handleSlatePad',
                        eventData: {
                            'openSlatePad': true,
                            'sentBy': props.userName,
                        }
                    })
                }
                }
                size="medium">
                <AssignmentIcon />
            </IconButton>
        </Tooltip>

        {openSlatePad &&
            <Box sx={{
                position: 'fixed',
                left: "80px",
                bottom: "15%",
                boxShadow: "2",
                borderRadius: '10px',
                background: 'white',
                height: { xs: '40%', sm: '50%', md: '75%', lg: '75%' },
                width: { xs: '60%', sm: '45%', md: '40%', lg: '40%' },
                zIndex: 99,
            }}>

                <Grid
                    //  className='handle-Slatepad'
                    container sx={{
                        color: 'black',
                        height: { xs: '7.5%', sm: '5%', md: '5%', lg: '5%' },
                        position: 'sticky',
                        right: '5px',
                        top: "5px",
                        zIndex: 10000,
                        backgroundColor: 'grey',
                        borderRadius: '10px 10px 0 0',
                        // "&:hover": { cursor: "move" }
                    }} justifyContent='right' textAlign='right'>
                    <Typography sx={{ fontSize: { xs: '0.9rem', sm: '1.1rem', md: '1.25rem', lg: '1.25rem' }, color: "#ffffff", fontWeight: 600, width: '80%', textAlign: 'center', justifyContent: 'center', paddingRight: "20%" }}>
                        Slate Pad
                    </Typography>
                </Grid>
                <IconButton
                    sx={{ color: 'black', position: 'absolute', top: 0, right: 0, zIndex: 10001, fontSize: '1rem' }}
                    onClick={() => {
                        // console.log('hey')
                        setOpenSlatePad(false);
                        props.sendLessonEventViaWebSocket({
                            eventType: 'handleSlatePad',
                            eventData: {
                                'openSlatePad': false,
                                'sentBy': props.userName,
                            }
                        })
                    }}
                    size="small">
                    <CloseIcon sx={{ fontSize: '1rem', }} />
                </IconButton>
                <iframe src={"https://server1.slatemates.in:9001/p/" + props.slateRef.current.id + "?showControls=false&showChat=false&showLineNumbers=true&useMonospaceFont=false"}
                    title="Internet Speed"
                    width="100%"
                    height="90%"
                    style={{ border: 'none', padding: '5px' }}
                ></iframe>
                <Grid
                    className='handle-Slatepad'
                    container
                    sx={{
                        color: 'black',
                        height: '2.5%',
                        position: 'sticky',
                        right: '10px',
                        bottom: 0,
                        zIndex: 10000,
                        // "&:hover": { cursor: "move" }
                    }}
                    justifyContent='right'
                    textAlign='right'
                >

                </Grid>
            </Box>
        }
    </Box>
});

export default SlatePad;