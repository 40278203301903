import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {  IconButton, Box, Grid } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import Draggable from "react-draggable";

import { Document, Page } from 'react-pdf';


const PdfViewer = forwardRef((props, ref) => {
    const [openIframeBox, setOpenIframeBox] = useState(false);
    const [mediaUrl, setMediaUrl] = useState('')
    const [positionFromLeft, setPositionFromLeft] = useState('80px')
    const [positionFromTop, setPositionFromTop] = useState('12.5%')
    const [playerWidth, setPlayerWidth] = useState('50vw')
    const [playerHeight, setPlayerHeight] = useState('60px')

    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    useImperativeHandle(ref, () => ({
        handleOpenIframeBox,
        handleMediaUrl,
        handlePositionOfPlayer,
        handleSizeOfPlayer,
        handlePageNumber
    }));

    const handlePositionOfPlayer = (left, top) => {
        setPositionFromLeft(left)
        setPositionFromTop(top)
    }

    const handleSizeOfPlayer = (width, height) => {
        setPlayerHeight(height)
        setPlayerWidth(width)
        // console.log(height, width)
    }

    const handleOpenIframeBox = (value) => {
        setOpenIframeBox(value)
        // console.log(value)
    }


    const handleMediaUrl = (value) => {
        setMediaUrl(value)

    }

    const handlePageNumber = (value) => {
        setPageNumber(value)
    }

    useEffect(() => {

    }, [mediaUrl, openIframeBox])

    return <>

        {openIframeBox && <Draggable><Box sx={{
            position: 'fixed',
            left: positionFromLeft,
            top: positionFromTop,
            boxShadow: "2",
            borderRadius: '10px',
            zIndex: 105,
            background: 'white'
        }}>
            <Box sx={{ position: 'relative', overscrollBehavior: "none", maxHeight: '100%', minHeight: '25px' }} >
                <Grid container sx={{ color: 'black', height: '10px', position: 'sticky', right: 0, top: 0, zIndex: 10000, width: playerWidth }} justifyContent='right' textAlign='right'>
                    <IconButton
                        sx={{ color: 'black', position: 'sticky', zIndex: 10000 }}
                        onClick={() => {
                            setOpenIframeBox(false);
                            props.sendLessonEventViaWebSocket({
                                eventType: 'handlePdfViewer',
                                eventData: {
                                    'openIframeBox': false,
                                    'sentBy': props.userName,
                                    'mediaUrl': '',
                                }
                            })
                        }}
                        size="small">
                        <CloseIcon sx={{ fontSize: '1rem' }} />
                    </IconButton>
                </Grid>
                <Box sx={{
                    // display: maximize ? 'block' : 'none',
                    height: playerHeight,
                    padding: '10px 10px 20px 10px',
                    width: playerWidth
                }}>
                    {/* <iframe
                        height="92.5%"
                        width="100%"
                        style={{ border: 'none', padding: '5px' }}
                        src={mediaUrl}
                    >
                    </iframe> */}
                    {/* <Box sx={{ display: "flex", flexDirection: 'row', paddingTop: "2.5px", borderRadius: '10px', justifyContent: "center", textAlign: 'center', alignItems: 'center' }}>
                        <TextField
                            type="number"
                            value={pageNumber}
                            sx={{
                                input: {
                                    color: "#ffffff",
                                    width: '50px',
                                    height: '2px',
                                    borderColor: '#ffffff !important',
                                    fontSize: '0.9rem',
                                    padding: '10px',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    backgroundColor: 'black',
                                },

                                borderRadius: '5px',
                                width: '50px',
                                height: '2px',
                                color: '#ffffff',
                                padding: 0,
                                marginBottom: '20px',
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'white',

                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'white',
                                    },
                                    '&.Mui-focused fieldset': {
                                        border: "2px solid",
                                        borderColor: 'white',
                                        borderRadius: '5px',
                                    },
                                },
                                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                    'WebkitAppearance': 'none',
                                    margin: 0,
                                },
                                '& input[type="number"]': {
                                    'MozAppearance': 'textfield',
                                },
                            }}
                            onFocus={() => {
                                props.isTextFocused.current = true;
                            }}
                            onBlur={() => {
                                props.isTextFocused.current = false;
                                // console.log(props.pageNumber)
                            }}
                            onChange={(e) => {
                                setPageNumber(e.target.value)

                            }}
                            autoFocus />&nbsp;
                        <span style={{ color: '#9F9EB2', fontSize: '0.9rem', paddingTop: '0.1rem' }}>
                            of
                        </span>
                        &nbsp;
                        <span style={{ color: '#9F9EB2', fontSize: '1rem', fontWeight: '550' }} >
                            {numPages}
                        </span>
                        &nbsp;
                    </Box> */}
                    <Box sx={{ justifyContent: 'center', textAlign: 'center' }}>
                        <button
                            disabled={pageNumber === 1}
                            onClick={() => {
                                setPageNumber(pageNumber - 1)
                                props.sendLessonEventViaWebSocket({
                                    eventType: 'changePdfViewerPage',
                                    eventData: {
                                        'sentBy': props.userName,
                                        'pageNumber': pageNumber - 1
                                    }
                                })
                            }}
                        >
                            Previous
                        </button>
                        <button
                            disabled={pageNumber === numPages}
                            onClick={() => {
                                setPageNumber(pageNumber + 1)
                                props.sendLessonEventViaWebSocket({
                                    eventType: 'changePdfViewerPage',
                                    eventData: {
                                        'sentBy': props.userName,
                                        'pageNumber': pageNumber + 1
                                    }
                                })
                            }}
                        >
                            Next
                        </button>
                    </Box>
                    <Document
                        file={mediaUrl} onLoadSuccess={onDocumentLoadSuccess}>
                        <Page
                            height={playerHeight * 0.8}
                            width={playerWidth * 0.75}
                            pageNumber={pageNumber} />
                    </Document>

                </Box> :


            </Box>
        </Box></Draggable>}

    </>;
});

export default PdfViewer