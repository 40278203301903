import { Box, Button} from '@mui/material';
import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs, } from "react-pdf";
import { SizeMe } from 'react-sizeme';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import CustomLoader from '../CustomLoader/CustomLoader';
import { THEME_COLOR, THEME_HOVER_COLOR } from '../../whiteboard/src/config/constants';

//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
//pdfjs.GlobalWorkerOptions.workerSrc = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.5.136/pdf.min.mjs"
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;



export default function PdfViewerCustom({ pdfFileLink }) {
    // console.log(pdfFileLink)
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);



    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    return (
        <Box sx={{ height: '100%', width: '100%', overflowY: 'auto', paddingTop: { xs: '50px', sm: '50px', md: '50px', lg: '50px' } }}>
            <div style={{ position: "absolute", backgroundColor: '#ffffff', top: '2.5%', left: 0, width: '100%', justifyContent: 'center', zIndex: 10, padding: '10px 0' }}>
                <p style={{ margin: '10px 0' }}>
                    Page  <span style={{ color: THEME_COLOR }}>{pageNumber || (numPages ? 1 : '--')}</span> of {numPages || '--'}
                </p>
                <Button
                    sx={{
                        textTransform: 'none',
                        backgroundColor: THEME_COLOR,
                        color: '#FFFFFF',
                        fontSize: '0.8rem',
                        fontWeight: '500',
                        height: '35px',
                        borderRadius: '5px',
                        '&:hover': {
                            backgroundColor: THEME_HOVER_COLOR,
                            boxShadow: 'none',
                        },
                        '&:disabled': {
                            backgroundColor: '#f8f8f8',
                            boxShadow: 'none',
                        },
                    }}
                    type="button"
                    disabled={pageNumber <= 1}
                    onClick={previousPage}
                >
                    Previous
                </Button>
                <Button
                    sx={{
                        textTransform: 'none',
                        backgroundColor: THEME_COLOR,
                        color: '#FFFFFF',
                        fontSize: '0.8rem',
                        fontWeight: '500',
                        height: '35px',
                        borderRadius: '5px',
                        marginLeft: '10px',
                        '&:hover': {
                            backgroundColor: THEME_HOVER_COLOR,
                            boxShadow: 'none',
                        },
                        '&:disabled': {
                            backgroundColor: '#f8f8f8',
                            boxShadow: 'none',
                        },
                    }}
                    type="button"
                    disabled={pageNumber >= numPages}
                    onClick={nextPage}
                >
                    Next
                </Button>
            </div>
            <div style={{ width: '100%', height: 'auto', justifyContent: 'center', overflow: 'hidden', minHeight: '150px' }}>
                <SizeMe
                    monitorHeight
                    refreshRate={128}
                    refreshMode={"debounce"}
                    render={({ size }) => (
                        <div style={{ position: 'relative' }}>
                            <Document
                                file={pdfFileLink}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <Page
                                    width={size.width}
                                    pageNumber={pageNumber}
                                />

                            </Document>
                            {!numPages && <div style={{ position: 'absolute', top: '50px', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                <CustomLoader />
                            </div>}
                        </div>
                    )}
                />
                {/* Adjust the height and width values as needed */}

            </div>
        </Box>
    );
}