import { useAtom } from "jotai";
import React, { useEffect } from "react";
import { sessionMembersArrayAtom } from "../../../../components/jotai/atom/sessionMembersArrayAtom";
import { Box, Avatar } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { THEME_COLOR } from "../../config/constants";

const DisplayAvatars = ({ profileUrlList, isTeacher }) => {

    const [sessionMembersArray, setSessionMembersArray] = useAtom(sessionMembersArrayAtom);

    const checkIfMemberIsInSession = (sessionMembersArray, email) => {
        // console.log('profile check', sessionMembersArray, email)
        for (let i = 0; i < sessionMembersArray.length; ++i) {
            if (sessionMembersArray[i].emailID === email) {
                // console.log('is online')
                return true
            }
        }
        return false
    }

    const getTabStatus = (sessionMembersArray, email) => {
        for (let i = 0; i < sessionMembersArray.length; ++i) {
            if (sessionMembersArray[i].emailID === email) {
                return sessionMembersArray[i].tabStatus
            }
        }
    }

    useEffect(() => {
        // console.log('sessionMembersArray changed')
    }, [sessionMembersArray])

    return <Box
        sx={{
            display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' },
            flexDirection: 'row',
            justifyContent: "right",
            alignItems: 'right',
            marginRight: '20px',
            marginTop: '10px'
        }}>
        {profileUrlList.map((element, index) => {
            return checkIfMemberIsInSession(sessionMembersArray, element.email) &&
                    <Box key={index} sx={{ display: 'flex', flexDirection: 'row', marginLeft: '-10px', }}>
                        <Avatar
                            sx={{
                                height: 45,
                                width: 45,
                                background: THEME_COLOR,
                                color: '#ffffff',
                                fontSize: '0.9rem',
                                // marginLeft: '10px',
                                border: '3px solid',
                                borderColor: getTabStatus(sessionMembersArray, element.email) === 'online' ? 'green' : (getTabStatus(sessionMembersArray, element.email) === 'offline' ? 'orange' : 'white')
                            }}
                            src={element.url}
                        >
                            {(element.email).substr(0, 2).toUpperCase()}
                        </Avatar>
                        {/* {isTeacher && <>
                            {getTabStatus(sessionMembersArray, element.email) === 'online' && <CircleIcon sx={{ color: 'green', fontSize: "0.7rem" }} />}
                            {getTabStatus(sessionMembersArray, element.email) === 'offline' && <CircleIcon sx={{ color: 'orange', fontSize: "0.7rem" }} />}
                        </>} */}
                    </Box>
        })}
    </Box>
}

export default DisplayAvatars