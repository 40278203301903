import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { IconButton, Box, Grid } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";

// import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import Draggable from "react-draggable";

const IframeBox = forwardRef((props, ref) => {
    const [openIframeBox, setOpenIframeBox] = useState(false);
    const [mediaUrl, setMediaUrl] = useState('')
    const [positionFromLeft, setPositionFromLeft] = useState('80px')
    const [positionFromTop, setPositionFromTop] = useState('12.5%')
    const [playerWidth, setPlayerWidth] = useState('50vw')
    const [playerHeight, setPlayerHeight] = useState('60px')

    useImperativeHandle(ref, () => ({
        handleOpenIframeBox,
        handleMediaUrl,
        handlePositionOfPlayer,
        handleSizeOfPlayer
    }));

    const handlePositionOfPlayer = (left, top) => {
        setPositionFromLeft(left)
        setPositionFromTop(top)
    }

    const handleSizeOfPlayer = (width, height) => {
        setPlayerHeight(height)
        setPlayerWidth(width)
        // console.log(height, width)
    }

    const handleOpenIframeBox = (value) => {
        setOpenIframeBox(value)
        // console.log(value)
    }


    const handleMediaUrl = (value) => {
        setMediaUrl(value)

    }

    useEffect(() => {

    }, [mediaUrl, openIframeBox])

    return <>

        {openIframeBox && <Draggable><Box sx={{
            position: 'fixed',
            left: positionFromLeft,
            top: positionFromTop,
            boxShadow: "2",
            borderRadius: '10px',
            zIndex: 105,
            background: 'white'
        }}>
            <Box sx={{ position: 'relative', overscrollBehavior: "none", maxHeight: '100%', minHeight: '25px' }} >
                <Grid container sx={{ color: 'black', height: '10px', position: 'sticky', right: 0, top: 0, zIndex: 10000, width: playerWidth }} justifyContent='right' textAlign='right'>
                    <IconButton
                        sx={{ color: 'black', position: 'sticky', zIndex: 10000 }}
                        onClick={() => {
                            setOpenIframeBox(false);
                            props.sendLessonEventViaWebSocket({
                                eventType: 'handleIframe',
                                eventData: {
                                    'openIframeBox': false,
                                    'sentBy': props.userName,
                                    'mediaUrl': '',
                                }
                            })
                        }}
                        size="small">
                        <CloseIcon sx={{ fontSize: '1rem' }} />
                    </IconButton>
                </Grid>
                <Box sx={{
                    height: playerHeight,
                    padding: '10px 10px 20px 10px',
                    width: playerWidth
                }}>
                    <iframe
                        title='iframeBox'
                        height="92.5%"
                        width="100%"
                        style={{ border: 'none', padding: '5px' }}
                        src={mediaUrl}
                    >
                    </iframe>

                </Box> :


            </Box>
        </Box></Draggable>}

    </>;
});

export default IframeBox