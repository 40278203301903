import { useAtomValue } from "jotai";
import React from "react";
import { canUserRedoAtom, canUserUndoAtom } from "../components/jotai/atom/UndoRedoButtonAtom";
import { IconButton, Tooltip } from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";

const UndoRedoButton = (props) => {
    const canUserRedo = useAtomValue(canUserRedoAtom)
    const canUserUndo = useAtomValue(canUserUndoAtom)

    return <>
        <Tooltip title="Undo">
            <IconButton
                id='upload-files-button-whiteboard'
                sx={{
                    color: props.iconColor,
                    ":disabled": {
                        color: 'grey'
                    }
                }}
                onClick={() => {
                    props._sketch.current.undo()
                }}
                size="medium"
                disabled={!canUserUndo}
            >
                <UndoIcon />
            </IconButton>
        </Tooltip>
        <Tooltip title="Redo">
            <IconButton
                id='upload-files-button-whiteboard'
                sx={{
                    color: props.iconColor,
                    ":disabled": {
                        color: 'grey'
                    }
                }}
                onClick={() => {
                    props._sketch.current.redo()
                }}
                size="medium"
                disabled={!canUserRedo}
            >
                <RedoIcon />
            </IconButton>
        </Tooltip>
    </>
}

export default UndoRedoButton;