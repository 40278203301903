import { getProfilePicture, getSlates, getUser, listWhiteboardFiles, markSlateMessageAsDelivered, markSlateMessageAsRead } from "../whiteboard/src/service/slateService";
import { capitalizeFirstAlphabet, findObjectById } from "./helperFunctions";

export function setCookie(name, value, exp_days) {
    var d = new Date();
    d.setTime(d.getTime() + (exp_days * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toGMTString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

export async function getCookie(name) {
    var cname = name + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(cname) === 0) {
            return c.substring(cname.length, c.length);
        }
    }
    return null;
}

export function checkTypesOfCanvasObject(type) {
    if (type && (type === 'Path' || type === 'Rect' || type === 'Circle' || type === 'Line')) {
        return true;
    }
    else {
        return false
    }
}

export function rgbToRgba(rgb, alpha) {
    // Extract the numerical values from the RGB string
    const result = rgb.match(/(\d+),\s*(\d+),\s*(\d+)/);
    if (result) {
        const r = result[1];
        const g = result[2];
        const b = result[3];
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
    return null;
}


export const getUserData = async (token) => {

    if (token !== null) {
        let status, res;
        try {
            res = await getUser(token);
            status = res.status
        }
        catch (err) {
            console.log(err)
            // status = err.response.status
        }
        // console.log(res);
        if (status === 200) {

            // console.log(res.data.user)
            return res.data.user
        }
        else {
            // console.log(res)
            return null;
        }

    }
};

export async function getContactList(accessToken) {

    const res = await getSlates(accessToken);
    const user = await getUserData(accessToken)
    const contactList = []
    if (res.status === 200) {
        // console.log(res.data.slates);
        const Slates = res.data.slates;
        for (let i = 0; i < Slates.length; ++i) {
            //checking new contact inside host list

            if (Slates[i].creatorDetails.email !== user.email) {
                contactList.push(Slates[i].creatorDetails)
            }
            for (let j = 0; j < Slates[i].hostDetails.length; ++j) {
                if (Slates[i].hostDetails[j].email !== user.email) {
                    contactList.push(Slates[i].hostDetails[j])
                }
            }
            //checking new contact inside participant list
            for (let k = 0; k < Slates[i].participantDetails.length; ++k) {
                if (Slates[i].participantDetails[k].email !== user.email) {
                    contactList.push(Slates[i].participantDetails[k])
                }
            }

        }
        // console.log(contactList)

    }
    let uniqueContactList = removeDuplicates(contactList)
    return uniqueContactList;
}

export const isDate = function (date) {
    return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
}


export const getTimeIfTodayDateElseGetDateAndTime = (time) => {
    // Get today's date
    let inputDate = new Date(time);
    if (isDate(inputDate)) {
        var todaysDate = new Date();
        // call setHours to take the time out of the comparison
        if ((new Date(time)).setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0)) {
            return 'Today' + " " + convertTo24HourFormatForDate(inputDate)
        }
        else if (getDifferenceOfDaysBetweenDates(((new Date(time)).setHours(0, 0, 0, 0)), todaysDate.setHours(0, 0, 0, 0)) === 1) {
            return 'Yesterday' + " " + convertTo24HourFormatForDate(inputDate)
        }
        else {
            return inputDate.toLocaleDateString() + " " + convertTo24HourFormatForDate(inputDate)
        }
    }
    else {
        return ''
    }
}


export function convertTo24HourFormatForDate(inputDate) {
    // Assuming inputDate is in the format 'yyyy-mm-dd hh:mm:ss'
    var dateObject = new Date(inputDate);

    // Extract hours and minutes
    var hours = dateObject.getHours();
    var minutes = dateObject.getMinutes();

    // Format the result
    var formattedResult = (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes;

    return formattedResult;
}

export const getDifferenceOfDaysBetweenDates = (firstDate, secondDate) => {
    const date1 = new Date(firstDate);
    const date2 = new Date(secondDate);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    // console.log(diffDays + " days");
    return diffDays
}

export function removeDuplicates(arr) {
    return arr.filter((element, index, array) => array.findIndex(subElement => subElement.email === element.email) === index);

}
export function ValidateEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return (true)
    }
    return (false)
}

export function getNameFromEmail(slateRef, userEmail) {
    let displayName = userEmail;
    for (let i = 0; i < slateRef.current.hostDetails.length; ++i) {
        if (slateRef.current.hostDetails[i].email === userEmail) {
            if (slateRef.current.hostDetails[i].firstName && slateRef.current.hostDetails[i].lastName && slateRef.current.hostDetails[i].firstName !== '' && slateRef.current.hostDetails[i].lastName !== '') {
                displayName = capitalizeFirstAlphabet(slateRef.current.hostDetails[i].firstName) + ' ' + capitalizeFirstAlphabet(slateRef.current.hostDetails[i].lastName)
            }
            else {
                displayName = slateRef.current.hostDetails[i].email.substring(0, 2)
            }
        }
    }
    for (let j = 0; j < slateRef.current.participantDetails.length; ++j) {
        if (slateRef.current.participantDetails[j].email === userEmail) {
            if (slateRef.current.participantDetails[j].firstName && slateRef.current.participantDetails[j].lastName && slateRef.current.participantDetails[j].firstName !== '' && slateRef.current.participantDetails[j].lastName !== '') {
                displayName = capitalizeFirstAlphabet(slateRef.current.participantDetails[j].firstName) + ' ' + capitalizeFirstAlphabet(slateRef.current.participantDetails[j].lastName)
            }
            else {
                displayName = slateRef.current.participantDetails[j].email.substring(0, 2)
            }
        }
    }
    if (slateRef.current.creatorDetails.email === userEmail) {
        if (slateRef.current.creatorDetails.firstName && slateRef.current.creatorDetails.firstName !== '' && slateRef.current.creatorDetails.lastName !== '') {
            displayName = capitalizeFirstAlphabet(slateRef.current.creatorDetails.firstName) + ' ' + capitalizeFirstAlphabet(slateRef.current.creatorDetails.lastName)
        }
        else {
            displayName = slateRef.current.creatorDetails.email.substring(0, 2)
        }
    }
    return displayName
}

export function getNameFromId(slate, userId) {
    let displayName = '';
    for (let i = 0; i < slate.hostDetails.length; ++i) {
        if (slate.hostDetails[i].user_id === userId) {
            if (slate.hostDetails[i].firstName && slate.hostDetails[i].lastName && slate.hostDetails[i].firstName !== '' && slate.hostDetails[i].lastName !== '') {
                displayName = capitalizeFirstAlphabet(slate.hostDetails[i].firstName) + ' ' + capitalizeFirstAlphabet(slate.hostDetails[i].lastName)
            }
            else {
                displayName = slate.hostDetails[i].email
            }
        }
    }
    for (let j = 0; j < slate.participantDetails.length; ++j) {
        if (slate.participantDetails[j].user_id === userId) {
            if (slate.participantDetails[j].firstName && slate.participantDetails[j].lastName && slate.participantDetails[j].firstName !== '' && slate.participantDetails[j].lastName !== '') {
                displayName = capitalizeFirstAlphabet(slate.participantDetails[j].firstName) + ' ' + capitalizeFirstAlphabet(slate.participantDetails[j].lastName)
            }
            else {
                displayName = slate.participantDetails[j].email
            }
        }
    }
    if (slate.creatorDetails.user_id === userId) {
        if (slate.creatorDetails.firstName && slate.creatorDetails.lastName && slate.creatorDetails.firstName !== '' && slate.creatorDetails.lastName !== '') {
            displayName = capitalizeFirstAlphabet(slate.creatorDetails.firstName) + ' ' + capitalizeFirstAlphabet(slate.creatorDetails.lastName)
        }
        else {
            displayName = slate.creatorDetails.email
        }
    }
    return displayName
}

export async function getProfileList(slateRef, accessToken) {
    let profileList = [];
    for (let i = 0; i < removeDuplicates(slateRef.current.hostDetails).length; ++i) {
        let hostProfileUrl = await getProfilePictureUrl(accessToken, removeDuplicates(slateRef.current.hostDetails)[i].email)
        profileList.push({
            'url': hostProfileUrl,
            'email': removeDuplicates(slateRef.current.hostDetails)[i].email
        })
    }
    for (let j = 0; j < removeDuplicates(slateRef.current.participantDetails).length; ++j) {
        let participantProfileUrl = await getProfilePictureUrl(accessToken, removeDuplicates(slateRef.current.participantDetails)[j].email)
        profileList.push({
            'url': participantProfileUrl,
            'email': removeDuplicates(slateRef.current.participantDetails)[j].email
        })
    }
    if (!(findObjectById(slateRef.current.hostDetails, slateRef.current.creator_id) || findObjectById(slateRef.current.participantDetails, slateRef.current.creator_id))) {
        let creatorProfileUrl = await getProfilePictureUrl(accessToken, slateRef.current.creatorDetails.email)
        profileList.push({
            'url': creatorProfileUrl,
            'email': slateRef.current.creatorDetails.email
        })
    }
    return profileList
}

export async function getProfilePictureUrl(accessToken, userEmail) {
    // console.log('fetching profile')
    let status, res;
    try {
        res = await getProfilePicture(accessToken, userEmail)
        status = res.status
    }

    catch (err) {
        console.log(err);
        status = err.response.status;
    }
    // console.log(index)
    if (status === 200) {
        // console.log('profile url', res)
        return res.data.picture
    }
    else {
        // console.log(status)
    }
    return ''
}

export function isValidUrl(string) {
    try {
        new URL(string);
        return true;
    } catch (err) {
        return false;
    }
}


export function getYoutubeLinkId(url) {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length == 11) {
        return match[2];
    } else {
        return 'error';
    }
}

const GOOGLE_VENDOR_NAME = 'Google Inc.';

function isOpera() {
    return Boolean(window.opr);
}

function isChromium() {
    return Boolean(window.chrome);
}

function getBrowserName() {
    const userAgent = window.navigator.userAgent;
    const vendor = window.navigator.vendor;
    switch (true) {
        case /Edge|Edg|EdgiOS/.test(userAgent):
            return 'Edge';
        case /OPR|Opera/.test(userAgent) && isOpera():
            return 'Opera';
        case /CriOS/.test(userAgent):
        case /Chrome/.test(userAgent) && vendor === GOOGLE_VENDOR_NAME && isChromium():
            return 'Chrome';
        case /Vivaldi/.test(userAgent):
            return 'Vivaldi';
        case /YaBrowser/.test(userAgent):
            return 'Yandex';
        case /Firefox|FxiOS/.test(userAgent):
            return 'Firefox';
        case /Safari/.test(userAgent):
            return 'Safari';
        case /MSIE|Trident/.test(userAgent):
            return 'Internet Explorer';
        default:
            return 'Unknown';
    }
}

export function isChrome() {
    const name = getBrowserName();
    return name === 'Chrome';
}

export const getWhiteboardFileName = async (accessToken, whiteBoardID) => {
    let folderName = 'JSON'
    let status, res;
    try {
        res = await listWhiteboardFiles(accessToken, whiteBoardID, folderName);
        status = res.status
    }

    catch (err) {
        console.log(err);
    }
    // console.log(index)
    if (status === 200) {
        const result = res.data.files;
        console.log(result)
        if (result.length === 0)
            return null;
        for (let i = 0; i < result.length; ++i) {
            return result[i]
        }
    }
    else {
        return null
    }
}

export const checkAndMarkAsRead = (token, slateId, messageArrayBySlate, user) => {
    if (!(checkIfAllMessagesAreRead(messageArrayBySlate, user))) {
      markSlateMessageAsRead(token, slateId)
    }
  
  }
  
  export const checkIfAllMessagesAreRead = (messageArrayBySlate, user) => {
    // console.log(messageArrayBySlate)
    for (let msg of messageArrayBySlate) {
      if (msg.creator_id !== user.id) {
        if (msg.message_read_user_ids) {
          if (!(msg.message_read_user_ids.includes(user.id))) {
            return false
          }
        }
        else {
          return false
        }
  
      }
    }
    return true
  }
  
  export const checkAndMarkAsDelivered = (token, slateId, messageArrayBySlate, user) => {
    if (!(checkIfAllMessagesAreDelivered(messageArrayBySlate, user))) {
      markSlateMessageAsDelivered(token, slateId)
    }
  
  }
  
  export const checkIfAllMessagesAreDelivered = (messageArrayBySlate, user) => {
    for (let msg of messageArrayBySlate) {
      if (msg.creator_id !== user.id) {
        if (msg.message_delivered_user_ids) {
          if (!(msg.message_delivered_user_ids.includes(user.id))) {
            return false
          }
        }
        else {
          return false
        }
  
      }
    }
    return true
  }