import React from "react"
import { Box, Grid } from "@mui/material"
import CustomLoader from "../CustomLoader/CustomLoader"



const CustomPlaceholder = ({ isChatLoading }) => {

    return <>{isChatLoading &&
        <Grid
            // key={index}
            id={'last-message-inside-list-loader'}
            sx={{
                padding: '10px',
                wordWrap: 'break-word !important',
                display: 'flex',
                marginLeft: "5px",
                marginTop: "5px",
                width: 'calc(100%-20px)',
                justifyContent: 'right',
                textAlign: 'right',
                alignItems: 'end',
                position: 'relative',
                flexDirection: 'column',
                marginBottom: '20px',
            }} >

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    borderRadius: '5px',
                    flexDirection: 'column',
                    backgroundColor: "#ffffff",
                    maxWidth: '80%',
                    minWidth: '95px',
                    boxShadow: 1,
                    padding: '10px 15px 20px',
                    position: 'relative'
                }}
            >
                <Box
                    sx={{
                        borderRadius: '5px',
                        // paddingTop: '2px',
                        // color: element.creator_id === user.id ? '#ffffff' : '#000000',
                        display: "flex",
                        height: 220,
                        width: 220,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CustomLoader />



                </Box>
            </Box>
        </Grid>}
    </>
}

export default CustomPlaceholder