import * as React from "react";
import { Tooltip, IconButton, Box, Popover, Grid } from '@mui/material';
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from '@mui/icons-material/Info';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
const Shortcuts = [
    {
        shortcutKey: 'N',
        functionality: 'Clipping Tool'
    },
    {
        shortcutKey: 'Delete/Backspace',
        functionality: 'Remove Selected'
    },

    {
        shortcutKey: 'U',
        functionality: 'Upload Files'
    },
    {
        shortcutKey: 'P/W',
        functionality: 'Pencil Tool'
    },
    {
        shortcutKey: 'Up Arrow Key / MouseWheel Up',
        functionality: 'Pan Up'
    },
    {
        shortcutKey: 'Down Arrow Key / MouseWheel Down',
        functionality: 'Pan Down'
    },
    {
        shortcutKey: 'Left Arrow Key',
        functionality: 'Pan Left'
    },
    {
        shortcutKey: 'Right Arrow Key ',
        functionality: 'Pan Right'
    },
    {
        shortcutKey: 'I / (Ctrl Key + MouseWheel Up)',
        functionality: 'Zoom In'
    },
    {
        shortcutKey: 'O / (Ctrl Key + MouseWheel Down)',
        functionality: 'Zoom Out'
    },
    {
        shortcutKey: 'H',
        functionality: 'Highlighter Tool'
    },
    {
        shortcutKey: 'E',
        functionality: 'EraserTool'
    },
    {
        shortcutKey: 'S',
        functionality: 'Select Tool'
    },
    {
        shortcutKey: 'T',
        functionality: 'Add Text'
    },
    {
        shortcutKey: 'L',
        functionality: 'Line Tool'
    },
    {
        shortcutKey: 'A',
        functionality: 'Arrow Tool'
    },
    {
        shortcutKey: 'C',
        functionality: 'Circle Tool'
    },
    {
        shortcutKey: 'D',
        functionality: 'Pan Tool'
    },

    {
        shortcutKey: 'Shift+R',
        functionality: 'Set LineColor to Red'
    },
    {
        shortcutKey: 'Shift+B',
        functionality: 'Set LineColor to Blue'
    },
    {
        shortcutKey: 'Shift+P',
        functionality: 'Set LineColor to Purple'
    },
    {
        shortcutKey: 'Shift+Y',
        functionality: 'Set LineColor to Yellow'
    },
    {
        shortcutKey: 'Shift+G',
        functionality: 'Set LineColor to Green'
    },
    {
        shortcutKey: 'Ctrl+M',
        functionality: 'Toggle Mic'
    },

]

const ShortcutGuide = () => {
    const [openShortcutGuide, setOpenShortcutGuide] = useState(false);

    return (<>
        <Tooltip title="Shortcut Guide">
            <IconButton
                sx={{ color: "#000000", zIndex: 100 }}
                onClick={(e) => {
                    setOpenShortcutGuide(e.currentTarget);
                    window.whiteboardScrollDisable = true
                }}
                size="small">
                <InfoIcon sx={{fontSize:'1.25rem'}} />
            </IconButton>

        </Tooltip>
        <Popover
            open={openShortcutGuide}
            anchorEl={openShortcutGuide}
            onClose={() => {
                setOpenShortcutGuide(false)
                window.whiteboardScrollDisable = false;
            }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <Box sx={{ position: 'relative', overscrollBehavior: "none", maxHeight: '60%' }} >
                <Grid container sx={{ color: 'black', height: '15px', position: 'sticky', right: '5px', top: "5px", zIndex: 10000 }} justifyContent='right' textAlign='right'>
                    <IconButton
                        sx={{ color: 'black', position: 'sticky', zIndex: 10000, fontSize: '1rem' }}
                        onClick={() => {
                            setOpenShortcutGuide(false);
                            window.whiteboardScrollDisable = false;
                        }}
                       >
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <Table  >
                    <TableHead>
                        <TableRow>
                            <TableCell>Shortcut Key</TableCell>
                            <TableCell>Functionality</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Shortcuts.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item.shortcutKey}</TableCell>
                                <TableCell>{item.functionality}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            </Box>
        </Popover>

    </>)

}

export default ShortcutGuide;
