import axios from 'axios';
import { BACKEND_API_URL } from '../whiteboard/src/config/constants';

function getUrlWithToken(url, token) {
    return url + 'token=' + token;
}

export async function createWhiteboardAudioBlob(token, requestBody) {
    // console.log(requestBody)
    const url = BACKEND_API_URL + 'whiteBoard/createWhiteBoardAudioBlob?'
    const res = await axios.post(getUrlWithToken(url, token), requestBody);
    // console.log(res)
    return res;

}

export async function getWhiteboardAudioBlob(token, whiteBoardId) {

    const url = BACKEND_API_URL + 'whiteBoard/getWhiteBoardAudioBlobs?'
    const res = await axios.get(getUrlWithToken(url, token) + '&whiteBoardId=' + whiteBoardId);
    // console.log(res)
    return res;


}