import * as React from 'react';
import { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography, Grid, FormControl, Button, IconButton, DialogActions, Popover, Box } from '@mui/material';
import InputNames from '../whiteboard/src/components/EditSlate/InputNames';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material';
import { ValidateEmail } from '../components/utils';
import { FRONTEND_URL, THEME_COLOR, THEME_HOVER_COLOR } from '../whiteboard/src/config/constants';
import CloseIcon from '@mui/icons-material/Close';
import { addParticipantToSlate } from "../whiteboard/src/service/slateService";

const AddParticipants = ({ accessToken, slateRef, openAddParticipantForm, setOpenAddParticipantForm, isTextFocused }) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [hostEmailIDsList, setHostEmailIDsList] = useState([]);
    const [participantEmailIDsList, setParticipantEmailIDsList] = useState([]);
    // const [isUserSignedUp, setIsUserSignedUp] = useState(false)

    const isParticipantEmailIdValid = React.useRef(null);
    const [isParticipantListEmpty, setIsParticipantListEmpty] = useState(false);
    const [openCopiedToClipboard, setOpenCopiedToClipboard] = useState(false);
    const [initialParticipantEmailIDsList, setInitialParticipantEmailIDsList] = useState([]);

    useEffect(() => {
        if (slateRef && slateRef.current) {
            setHostEmailIDsList(createEmailIdListArray(removeDuplicates(slateRef.current.hostDetails)))
            setInitialParticipantEmailIDsList(createEmailIdListArray(removeDuplicates(slateRef.current.participantDetails)))
        }
    }, [slateRef])

    const createEmailIdListArray = (objectArray) => {
        const emailIdArray = []
        objectArray.map((element) => {
            emailIdArray.push(element.email)
        })
        return emailIdArray;
    }

    function removeDuplicates(arr) {
        return arr.filter((element, index, array) => array.findIndex(subElement => subElement.email === element.email) === index);

    }

    const getSessionLink = () => {

        return FRONTEND_URL + 'slate/' + slateRef.current.id + '?forwardLink=' + 'lesson'

    }

    const handleClose = () => {
        setOpenAddParticipantForm(false);
        setTimeout(() => {
            setParticipantEmailIDsList([])
            isParticipantEmailIdValid.current = null
            setIsParticipantListEmpty(false)
        }, 500)

    }




    const handleAddParticipant = async () => {
        const participantInputState = document.getElementById('Participant EmailID').value;
        if (participantInputState !== '') {
            if (!(hostEmailIDsList.includes(participantInputState)) && !(participantEmailIDsList.includes(participantInputState)) && !(initialParticipantEmailIDsList.includes(participantInputState))) {
                if (ValidateEmail(participantInputState)) {
                    participantEmailIDsList.push(participantInputState.toLowerCase())
                    isParticipantEmailIdValid.current = true
                    // setParticipantEmailIDsList(prevState => [...prevState, participantInputState])
                }
                else {
                    isParticipantEmailIdValid.current = false
                }
            }
        }
        else {
            isParticipantEmailIdValid.current = true
        }
        // console.log(participantEmailIDsList)
        // console.log(isParticipantEmailIdValid.current)
        if (participantEmailIDsList.length > 0) {
            const SlateData = {
                "slateId": slateRef.current.id,
                "participantEmailIds": participantEmailIDsList
            }
            if (isParticipantEmailIdValid.current) {
                // setIsLoading(true)
                // console.log(SlateData)
                let status, res;
                try {
                    res = await addParticipantToSlate(accessToken, SlateData);
                    status = res.status
                }

                catch (err) {
                    console.log(err);
                    status = err.response.status;
                }
                // console.log(index)
                if (status === 200) {

                }
                else if (status === 401) {
                    alert("You are not authorized to edit slate")
                }
                else {
                    console.log(status)
                }
                setIsParticipantListEmpty(false)
                handleClose()
            }
            else {
                setIsParticipantListEmpty(false)
            }
        }
        if (participantEmailIDsList.length === 0 && isParticipantEmailIdValid.current) {
            setIsParticipantListEmpty(true)
        }
    }

    return (<>
        <Dialog fullWidth maxWidth="sm" open={openAddParticipantForm} onClose={() => { setOpenAddParticipantForm(false) }} fullScreen={fullScreen} >
            <DialogTitle sx={{ justifyContent: 'center', textAlign: 'center', position: 'relative' }}>
                <Typography sx={{ fontSize: '2.25rem', fontWeight: '600', color: THEME_COLOR }}>
                    Add Participant(s)
                </Typography>

                <IconButton
                    sx={{ color: 'black', position: 'absolute', right: '5px', top: "5px", zIndex: 10000 }}
                    onClick={() => {
                        handleClose()
                    }}
                    size="small">
                    <CloseIcon sx={{ fontSize: '1rem' }} />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container item xs={12} sm={12} md={12} lg={12} justifyContent='center' textAlign='center' sx={{ display: 'flex', flexDirection: 'column', paddingLeft: '40px', marginTop: '5px' }}>
                    <form onSubmit={e => {
                        e.preventDefault()
                        handleAddParticipant()
                    }}>

                        <InputNames
                            otherNamesList={hostEmailIDsList.concat(initialParticipantEmailIDsList)}
                            namesList={participantEmailIDsList}
                            setNamesList={setParticipantEmailIDsList}
                            accessToken={accessToken}
                            placeholderName='Participant EmailID'
                            isTextFocused={isTextFocused}
                        />
                        {isParticipantListEmpty && <Typography variant='body2' sx={{ color: 'red', marginBottom: '15px', paddingRight: "30px" }}>No participant added yet</Typography>}
                        <FormControl sx={{ width: '50%', marginBottom: '15px' }}>
                            <Button
                                type='submit'
                                sx={{
                                    borderRadius: '25px',
                                    width: '85%',
                                    height: '50px',
                                    textTransform: 'none',
                                    backgroundColor: THEME_COLOR,
                                    color: '#FFFFFF',
                                    fontSize: '1rem',
                                    fontWeight: '500',
                                    '&:hover': {
                                        backgroundColor: THEME_HOVER_COLOR,
                                        boxShadow: 'none',
                                    },

                                }}
                            >
                                Add participant(s)
                            </Button>
                        </FormControl>
                    </form>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button sx={{

                    color: THEME_COLOR,
                    textTransform: "none",
                    fontWeight: 500

                }}
                    autoFocus
                    onClick={(event) => {
                        const url = getSessionLink();
                        navigator.clipboard.writeText(url)
                        setOpenCopiedToClipboard(event.currentTarget)
                        setTimeout(() => {
                            setOpenCopiedToClipboard(false)
                        }, 500)
                    }}>
                    Copy Link
                </Button>
            </DialogActions>
        </Dialog>
        <Popover
            open={openCopiedToClipboard}
            anchorEl={openCopiedToClipboard}
            onClose={() => {
                setOpenCopiedToClipboard(false)

            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 0,
            }}
        >
            <Box sx={{ backgroundColor: "#525252" }}>
                <Typography sx={{ fontSize: '0.8rem', color: '#FFFFFF', padding: '5px', fontWeight: 500 }}>
                    Copied!
                </Typography>
            </Box>

        </Popover>
    </>




    )
};

export default AddParticipants;
