import * as React from "react";
import { Tooltip, IconButton, Box } from '@mui/material';
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ScienceIcon from '@mui/icons-material/Science';
import { WHITEBOARD_ICON_COLOR } from "../whiteboard/src/config/constants";

const PeriodicTable = () => {
    const [openPeriodicTable, setOpenPeriodicTable] = useState(false);

    return (<>

        <Tooltip title="Periodic Table" placement="right">
            <IconButton
                id='periodic-table-button'
                sx={{ color: WHITEBOARD_ICON_COLOR, position: 'fixed', top: '45%', left: '15px', zIndex: 100 }}
                onClick={() => {
                    setOpenPeriodicTable(!openPeriodicTable);
                }}
                size="medium">
                <ScienceIcon />
            </IconButton>

        </Tooltip>
        {openPeriodicTable && <Box sx={{
            position: 'fixed', bottom: '100px', left: "50px", top: "20px", height: '80%',
            width: '70%',
            border: "2px solid #686a6e",
            "&:hover": {
                boxShadow: "0 8px 16px 0 rgb(63 46 28 / 7%)",
            },
            zIndex: 98
        }}>

            <IconButton
                sx={{ color: 'black', position: 'absolute', right: '5px', top: "10px", zIndex: 10000 }}
                onClick={() => {
                    setOpenPeriodicTable(false);;
                }}
                size="large">
                <CloseIcon />
            </IconButton>
            <img alt='Periodic_Table' src='https://sciencenotes.org/wp-content/uploads/2017/05/PeriodicTableWallpaper2017.png' height='100%' width='100%'></img>

        </Box>}




    </>)

}

export default PeriodicTable;
