/*eslint no-unused-vars: 0*/

import FabricCanvasTool from "../fabrictool";
import eraser_cursor from '../../../../images/eraser_cursor.png'

class Eraser extends FabricCanvasTool {
  configureCanvas(props) {
    let canvas = this._canvas;
    canvas.defaultCursor = `url(" ${eraser_cursor} "), auto`;
    canvas.hoverCursor = `url(" ${eraser_cursor} "), auto`;
    canvas.isDrawingMode = false;
    canvas.selection = true;
    canvas.forEachObject((o) => {
      // console.log('eraser tool selected', o)
      // console.log('eraser tool selected left and top', o.left, o.top)
      o.eraserLeft = o.left;
      o.eraserTop = o.top;
      if (!o.isPdf)
        o.selectable = o.evented = true;
    });
  }


  doMouseUp(o, clientID) {

    const canvas = this._canvas;
    var activeObjects = canvas.getActiveObjects();
    // console.log(activeObjects)
    for (let obj of activeObjects) {
      // console.log('eraser tool active', obj)
      // const left = obj.left;
      // const top = obj.top;
      // console.log('obj left and top', left, top)
      // console.log('eraser left and top', obj.eraserLeft, obj.eraserTop)

      //currently we dont delete any image on the canvas using the eraser tool
      if (obj.type && obj.type !== "image") {
        obj.set("__removed", true);
        obj.set("removedBy", clientID)
        // console.log(obj)
        obj.set('left', obj.eraserLeft)
        obj.set('top', obj.eraserTop)
        canvas.remove(obj);

      }
    }

    canvas.discardActiveObject();
    canvas.renderAll();

  }
}

export default Eraser;
