import * as React from 'react';
import { useState, useEffect } from 'react';
import { Box, FormControl, IconButton, Typography, Autocomplete, TextField, Select, MenuItem ,createFilterOptions} from '@mui/material';
import { getContactList, ValidateEmail } from '../../../../components/utils';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { addHostToSlate, addParticipantToSlate } from '../../service/slateService';
import { capitalizeFirstAlphabet } from '../../../../components/helperFunctions';
import { THEME_COLOR } from '../../config/constants';

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option.email + option.firstName + option.lastName + (option.firstName + ' ' + option.lastName),
});

const InputEmail = ({ slate, hostList, participantList, setHostList, setParticipantList, placeholderName, accessToken, isTextFocused, setDisplayHostList, setDisplayParticipantList }) => {

    const [name, setName] = useState('')
    const [isEmailIdInvalid, setIsEmailIdInvalid] = useState(false);
    const [isUserAlreadyAdded, setIsUserAlreadyAdded] = useState(false);
    const [contactList, setContactList] = useState([])
    const [memberType, setMemberType] = useState('participant')
    // const [isInputFocused, setIsInputFocused] = useState(false);
    useEffect(() => {
        settingContactList()

    }, [])

    const settingContactList = async () => {
        const listOfContacts = await getContactList(accessToken)
        setContactList(listOfContacts)
    }


    const addParticipant = async () => {
        // console.log(name)
        if (name !== '') {
            if (!(participantList.includes(name)) && !(hostList.includes(name))) {
                // console.log(ValidateEmail(name))
                if (ValidateEmail(name)) {
                    const participantArray = [name]
                    // console.log('hey')
                    setParticipantList(participantList.concat(participantArray))
                    let obj = {
                        'email': name,
                        'firstName': '',
                        'lastName': ''
                    }
                    for (let j = 0; j < contactList.length; ++j) {
                        if (name === contactList[j].email) {
                            obj.firstName = contactList[j].firstName;
                            obj.lastName = contactList[j].lastName
                        }
                    }
                    setDisplayParticipantList(prevValue => [...prevValue, obj])
                    setIsEmailIdInvalid(false)
                    const SlateData = {
                        "slateId": slate.id,
                        "participantEmailIds": participantArray
                    }

                    // setIsLoading(true)
                    // console.log(SlateData)
                    let status, res;
                    try {
                        res = await addParticipantToSlate(accessToken, SlateData);
                        status = res.status
                    }

                    catch (err) {
                        console.log(err);
                        status = err.response.status;
                    }
                    // console.log(index)
                    if (status === 200) {
                        setName('')
                    }
                    else if (status === 401) {
                        alert("You are not authorized to edit slate")
                    }
                    else {
                        console.log(status)
                    }
                }
                else {
                    document.getElementById(placeholderName).focus();
                    setIsEmailIdInvalid(true)
                    setTimeout(() => {
                        setIsEmailIdInvalid(false)
                    }, 1000)
                }
                setIsUserAlreadyAdded(false)
            }
            else {
                setIsUserAlreadyAdded(true)
            }
            // console.log(namesList)
        }
        setName('')
        // console.log(document.getElementById(placeholderName).value)
    }

    const addHost = async () => {
        // console.log(name)
        if (name !== '') {
            if (!(participantList.includes(name)) && !(hostList.includes(name))) {
                // console.log(ValidateEmail(name))
                if (ValidateEmail(name)) {
                    const hostArray = [name]
                    // console.log('hey')
                    setHostList(hostList.concat(hostArray))
                    let obj = {
                        'email': name,
                        'firstName': '',
                        'lastName': ''
                    }
                    for (let j = 0; j < contactList.length; ++j) {
                        if (name === contactList[j].email) {
                            obj.firstName = contactList[j].firstName;
                            obj.lastName = contactList[j].lastName
                        }
                    }
                    setDisplayHostList(prevValue => [...prevValue, obj])
                    setIsEmailIdInvalid(false)
                    const SlateData = {
                        "slateId": slate.id,
                        "hostEmailIds": hostArray
                    }

                    // setIsLoading(true)
                    // console.log(SlateData)
                    let status, res;
                    try {
                        res = await addHostToSlate(accessToken, SlateData);
                        status = res.status
                    }

                    catch (err) {
                        console.log(err);
                        status = err.response.status;
                    }
                    // console.log(index)
                    if (status === 200) {
                        setName('')
                    }
                    else if (status === 401) {
                        alert("You are not authorized to edit slate")
                    }
                    else {
                        console.log(status)
                    }
                }
                else {
                    document.getElementById(placeholderName).focus();
                    setIsEmailIdInvalid(true)
                    setTimeout(() => {
                        setIsEmailIdInvalid(false)
                    }, 1000)
                }
                setIsUserAlreadyAdded(false)
            }
            else {
                setIsUserAlreadyAdded(true)
            }
            // console.log(namesList)
        }
        setName('')
        // console.log(document.getElementById(placeholderName).value)
    }



    const checkKeyPress = (e) => {
        const { key, keyCode } = e;
        // console.log(key, keyCode);
        if (keyCode === 13) {
            if (memberType === 'participant')
                addParticipant()
            else {
                addHost()
            }
        }
    };

    return (<Box >
        <FormControl sx={{ width: '100%', marginBottom: '15px' }} >
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <IconButton
                    onClick={() => {
                        if (memberType === 'participant')
                            addParticipant()
                        else {
                            addHost()
                        }

                    }}
                    sx={{ width: '10%', fontSize: '1rem', height: '55px', color: THEME_COLOR}}
                >
                    <PersonAddIcon sx={{ fontSize: '1.25rem' }} />
                </IconButton>
                <Autocomplete
                    id={placeholderName}
                    options={contactList}
                    freeSolo
                    value={name}
                    // getOptionSelected={(option, value) => option === value}
                    renderOption={(props, option, { selected }) => (
                        <li {...props} key={option.user_id}>
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    '& span': {
                                        color: '#8b949e',
                                    },
                                    fontSize: '0.8rem'
                                }}
                            >
                                {capitalizeFirstAlphabet(option.firstName) + ' ' + capitalizeFirstAlphabet(option.lastName)}
                                <br />
                                <span>{option.email}</span>
                            </Box>

                        </li>

                    )}
                    getOptionLabel={(option) => typeof option === 'string'
                        || option instanceof String ? option : ""}
                    filterOptions={filterOptions}
                    onChange={(event, value) => {
                        // console.log(event)
                        // console.log(value)
                        if (value) {
                            setName(value.email)
                        }
                        else {
                            setName('')
                        }
                    }}
                    sx={{
                        width: '100%', height: '60px',
                        '& .MuiAutocomplete-inputRoot': {
                            borderRadius: '15px',
                            '&.Mui-focused fieldset': {
                                borderColor: `${THEME_COLOR} !important`,
                            },
                        },

                    }}
                    componentsProps={{
                        paper: {
                            sx: {
                                width: '100%',
                                borderRadius: '15px',
                            }
                        }
                    }}
                    renderInput={(params) =>
                        <TextField
                            {...params}

                            sx={{ borderRadius: '15px', width: '100%', height: '60px', }}
                            type="email"
                            pattern=".+@globex\.com"
                            placeholder={placeholderName}
                            value={name}
                            onFocus={() => {
                                isTextFocused.current = true;
                            }}
                            onBlur={() => {
                                isTextFocused.current = false;
                            }}
                            onChange={(e) => setName(e.target.value)}
                            onKeyDown={checkKeyPress}
                        />}
                />
                <FormControl sx={{ width: { xs: '50%', sm: '30%', md: '30%', lg: '30%', xl: '30%' }, marginBottom: '15px', marginLeft: '5px' }}>
                    <Select
                        sx={{
                            borderRadius: '15px',
                            width: '85%',
                            height: '55px',
                            fontSize: '0.55rem',
                            '&.Mui-focused fieldset': {
                                borderColor: `${THEME_COLOR} !important`,

                            },
                            '&.Mui-inputLabel': {
                                color: `${THEME_COLOR} !important`,
                            },

                        }}
                        value={memberType}
                        onChange={(event) => { setMemberType(event.target.value) }}
                    >
                        <MenuItem value='host'>Host</MenuItem>
                        <MenuItem value='participant'>Participant</MenuItem>

                    </Select>
                </FormControl>

            </Box>
            {isEmailIdInvalid && <Typography variant='body2' sx={{ color: 'red', marginTop: '5px', justifyContent: 'left', textAlign: 'left', paddingLeft: '10px' }}>Invalid Email ID</Typography>}
            {isUserAlreadyAdded && <Typography variant='body2' sx={{ color: 'red', marginTop: '5px', justifyContent: 'left', textAlign: 'left', paddingLeft: '10px' }}>User has already been added to Host(s)/Participant(s)</Typography>}

        </FormControl>
    </Box>

    )
};

export default InputEmail;
