import React, { useState } from 'react';
import {

    IconButton,
    Tooltip,

} from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';

import PermissionDrawer from './PermissionDrawer';
import { THEME_COLOR } from '../../config/constants';

const PermissionDrawerButton = ({ sendLessonEventViaWebSocket }) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);


    return (
        <div>
            <Tooltip title="Permissions" arrow>
                <IconButton
                    onClick={() => {
                        setIsDrawerOpen(true)
                    }}
                    sx={{
                        display: { xs: 'none', sm: 'block', md: 'block', lg: 'block' },
                        color: THEME_COLOR,
                        marginRight: '20px',
                        borderRadius: '5px',
                        border: '1.5px solid #D3D3D3',
                        marginTop: { xs: "20px", sm: '10px', md: "10px", lg: '10px', xl: '10px' },
                        marginBottom: { xs: "20px", sm: '10px', md: "10px", lg: '10px', xl: '10px' },
                        width: { xs: '20px', sm: '40px', md: '40px', lg: '60px' }
                    }}
                >
                    <PeopleIcon />
                </IconButton>
            </Tooltip>
            {isDrawerOpen && <PermissionDrawer isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} sendLessonEventViaWebSocket={sendLessonEventViaWebSocket} />}
        </div>
    );
};

export default PermissionDrawerButton;
