// import { RecordRTC, RecordRTCPromisesHandler } from 'recordrtc';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import * as React from "react";
import { Button, Dialog, DialogActions, Box, Typography, DialogContent, DialogTitle, Tooltip, Grid } from "@mui/material";
import { THEME_COLOR } from '../../config/constants';
import CustomLoader from '../../../../components/CustomLoader/CustomLoader';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
// const xhr = new XMLHttpRequest();



function WhiteboardPlayer(props, ref) {
    const [startPlayerPopup, setStartPlayerPopup] = React.useState(false)
    const [isPlayerLoading, setIsPlayerLoading] = React.useState(false)
    const [playerTime, setPlayerTime] = React.useState(0)

    React.useImperativeHandle(ref, () => ({
        setStartPlayerPopup,
        setIsPlayerLoading,
        startPlayerTime
    }))

    const startPlayerTime = () => {
        let start = 0
        setInterval(() => {
            start += 1;
            setPlayerTime(start);
        }, 1000);
    }

    const getTime = (playerTime) => {
        const moment = extendMoment(Moment);
        let duration = moment.duration(playerTime, 'seconds');
        let time = duration.hours().toString().padStart(2, '0') + ":" +
        duration.minutes().toString().padStart(2, '0') + ":" +
        duration.seconds().toString().padStart(2, '0');
        return time
    }

    const memoizedTime = React.useMemo(() => getTime(playerTime), [playerTime]);


    return (
        <>
            {!isPlayerLoading && <Box
                id='start-recording-button'
                sx={{
                    display: 'flex',
                    color: '#ffffff',
                    position: 'fixed',
                    bottom: '10px',
                    left: '10px',
                    backgroundColor: THEME_COLOR,
                    padding: '10px 20px',
                    borderRadius: '10px',
                    boxShadow: 1, zIndex: 100,
                    textTransform: 'none',
                    fontSize: "1rem",
                    // "&:hover": { backgroundColor: "rgb(255,255,255,0.8)" }
                }} >
                {" " + memoizedTime + ' '}

            </Box>}
            {isPlayerLoading &&
                <Grid sx={{
                    position: "absolute", width: "100%", height: "100vh", opacity: "1", top: "0", left: "0",
                    backgroundColor: THEME_COLOR, display: "flex", alignItems: "center", alignContent: "center", justifyContent: "center"
                }}>
                    <Box sx={{ justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img style={{ height: '60px', width: 'auto', marginBottom: '10px' }} src='/loader_title.png' />
                        <Typography sx={{
                            color: '#ffffff',
                            marginLeft: "auto",
                            marginRight: "auto",
                            fontSize: '1.25rem'
                        }}>
                            Loading the player.It may take a while..
                        </Typography>
                        <Box sx={{
                            marginTop: '10px',
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}>
                            <CustomLoader />
                        </Box>
                    </Box>
                </Grid>
            }
            <Dialog
                open={startPlayerPopup}
            // onClose={() => {
            //   setRecordingConfirmation(false)
            // }}
            >
                <DialogTitle sx={{ justifyContent: 'center', textAlign: 'center', color: '#B33A3A' }} >
                    Whiteboard Player
                </DialogTitle>
                <DialogContent>
                    <Typography sx={{ fontSize: "1rem", padding: '0 25px' }}>Do you want to start the player?</Typography>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Button
                            sx={{ width: "100%", color: THEME_COLOR }}
                            onClick={async () => {
                                setStartPlayerPopup(false)
                                props.playEventsInOrder(props.whiteBoardID)
                            }}
                        >
                            Yes
                        </Button>
                        {/* <Button
                            sx={{ width: "50%", color: THEME_COLOR }}
                            onClick={(e) => {
                                setStartPlayerPopup(false)
                            }}
                        >
                            No
                        </Button> */}
                    </Box>
                </DialogActions>
            </Dialog>

        </>)






}

export default React.forwardRef(WhiteboardPlayer);



