import React from 'react';
import { getSession, listWhiteboardFiles } from '../whiteboard/src/service/slateService';
import { THEME_COLOR, UPLOAD_API_URL } from '../whiteboard/src/config/constants';
import PdfHeaderTitle from './PdfHeader';
import { capitalizeFirstAlphabet, convertTimeToLocal } from './helperFunctions';
import { StyleSheet, Text, View, pdf, Document, Page, Image } from '@react-pdf/renderer'
import { getNameFromEmail, getProfilePictureUrl } from './utils';

const styles = StyleSheet.create({
    table: {
        width: '100%',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid #EEE',
        paddingTop: 8,
        paddingBottom: 8,
    },
    header: {
        borderTop: 'none',
    },
    bold: {
        fontWeight: 'bold',
    },
    // So Declarative and unDRY 👌
    row1_heading: {
        width: '33%',
        color: THEME_COLOR,
        justifyContent: 'center',
        textAlign: 'center'
    },
    row2_heading: {
        width: '33%',
        color: THEME_COLOR,
        justifyContent: 'center',
        textAlign: 'center'
    },
    row3_heading: {
        width: '33%',
        color: THEME_COLOR,
        justifyContent: 'center',
        textAlign: 'center'
    },
    row1: {
        width: '33%',
        justifyContent: 'center',
        textAlign: 'center'
    },

    row2: {
        width: '33%',
        justifyContent: 'center',
        textAlign: 'center'
    },
    row3: {
        width: '33%',
        justifyContent: 'center',
        textAlign: 'center'
    },
    insideRow: {
        width: '100%',
        justifyContent: 'center',
        textAlign: 'center'
    },
    titleContainer: {
        marginBottom: 25
    },
    reportTitle: {
        color: '#181357',
        letterSpacing: 3,
        fontSize: 20,
        textAlign: 'center',
        textTransform: 'none',
    }

})

const MyDoc = (imageSources, slateRef, session, displayName, profileUrl) => (
    <Document>
        {slateRef && slateRef.current && <Page size='A4' orientation={"portrait"} pageIndex={1} style={{
            backgroundColor: '#fff',
            fontFamily: 'Helvetica',
            fontSize: 15,
            paddingTop: 30,
            paddingLeft: 50,
            paddingRight: 50,
            lineHeight: 1.5,
            flexDirection: 'column',
        }} >
            <Image
                src='/website_logo.png'
                style={{
                    width: 280,
                    height: 70,
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}
            />
            <PdfHeaderTitle title={slateRef.current.title} />
            <View style={styles.titleContainer}>
                <Text style={styles.reportTitle}><Text style={{ fontSize: 25, letterSpacing: 1, fontWeight: 700 }}>{displayName}'s Notes</Text></Text>
            </View>
            <View style={styles.titleContainer}>
                <Image
                    src={profileUrl}
                    style={{
                        width: 70,
                        height: 70,
                        borderRadius: '50px',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                    }}
                />
            </View>
            <View style={styles.titleContainer}>
                <Text style={styles.reportTitle}>Session start time:<Text style={{ fontSize: 15, letterSpacing: 1, fontWeight: 700 }}> {session && convertTimeToLocal(session.start_time)}</Text></Text>
            </View>
            <View style={styles.table}>
                <View style={[styles.row, styles.bold, styles.header]}>
                    <Text style={styles.row1_heading}>Creator</Text>
                    <Text style={styles.row2_heading}>Host(s)</Text>
                    <Text style={styles.row3_heading}>Participant(s)</Text>
                </View>
                <View style={styles.row} wrap={false}>
                    <Text style={styles.row1}>{
                        slateRef.current.creatorDetails.firstName !== '' && slateRef.current.creatorDetails.lastName !== '' ?
                            capitalizeFirstAlphabet(slateRef.current.creatorDetails.firstName) + " " + capitalizeFirstAlphabet(slateRef.current.creatorDetails.lastName) :
                            slateRef.current.creatorDetails.email
                    }
                    </Text>
                    <Text style={styles.row2}>

                        {
                            slateRef.current.hostDetails &&
                            (slateRef.current.hostDetails.length === 0 ?
                                <Text style={styles.insideRow}>
                                    {
                                        slateRef.current.creatorDetails.firstName !== '' && slateRef.current.creatorDetails.lastName !== '' ?
                                            capitalizeFirstAlphabet(slateRef.current.creatorDetails.firstName) + " " + capitalizeFirstAlphabet(slateRef.current.creatorDetails.lastName) :
                                            slateRef.current.creatorDetails.email
                                    }
                                </Text> :
                                removeDuplicates(slateRef.current.hostDetails).map((slateObj) => {
                                    return (<Text style={styles.insideRow}>
                                        {slateObj.firstName && slateObj.lastName && slateObj.firstName !== '' && slateObj.lastName !== '' ? capitalizeFirstAlphabet(slateObj.firstName) + ' ' + capitalizeFirstAlphabet(slateObj.lastName) : slateObj.email}
                                        {"\n"}
                                    </Text>)

                                }))
                        }
                    </Text>
                    <Text style={styles.row3}>
                        {
                            slateRef.current.participantDetails &&
                            (slateRef.current.participantDetails.length === 0 ?
                                <Text style={styles.insideRow}>
                                    No Participants
                                </Text> :
                                removeDuplicates(slateRef.current.participantDetails).map((slateObj) => {
                                    return (<Text style={styles.insideRow}>
                                        {slateObj.firstName && slateObj.lastName && slateObj.firstName !== '' && slateObj.lastName !== '' ? capitalizeFirstAlphabet(slateObj.firstName) + ' ' + capitalizeFirstAlphabet(slateObj.lastName) : slateObj.email}
                                        {"\n"}
                                    </Text>)

                                }))
                        }
                    </Text>
                </View>
            </View>
        </Page>}
        {
            imageSources.map((src, key) =>
                <Page size={{ height: src.height, width: src.width }} orientation={"portrait"} pageIndex={2} style={{
                    display: "flex", marginTop: "auto", marginBottom: "auto", position: 'relative'
                }} key={key}>
                    <Image src={src.Url}
                        style={{
                            objectFit: "fill",
                            display: "block",
                            height: src.height,
                            width: src.width,
                            marginTop: "auto",
                            marginBottom: "auto",
                            marginLeft: "auto",
                            marginRight: "auto"
                        }}
                    />
                </Page>)
        }

    </Document>
);

function removeDuplicates(arr) {
    return arr.filter((element, index, array) => array.findIndex(subElement => subElement.email === element.email) === index);

}

async function toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = async function () {
            await callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}

const getSessionByID = async (whiteboardID, accessToken) => {
    let token = accessToken;

    let res, status
    try {
        res = await getSession(token, whiteboardID)
        status = res.status
    }
    catch (err) {
        console.log(err)
        status = err.response.status
    }
    if (status === 200) {
        //console.log(res.data)
        return res.data
    }



    // console.log(userData.data.user.id)

}

export default async function downloadItems(folderName, accessToken, whiteBoardID, slateRef, userEmail) {

    const fileLink = UPLOAD_API_URL + "uploads/whiteBoardStorage/" + whiteBoardID + '/' + folderName
    let displayName, profileUrl;

    displayName = getNameFromEmail(slateRef, userEmail)
    profileUrl = await getProfilePictureUrl(accessToken, userEmail)

    var imageSources = [];
    let session = await getSessionByID(whiteBoardID, accessToken)
    let status, res;
    try {
        res = await listWhiteboardFiles(accessToken, whiteBoardID, folderName);
        status = res.status
    }

    catch (err) {
        console.log(err);
        status = err.response.status;
    }
    // console.log(index)
    if (status === 200) {
        const result = res.data.files;
        if (result.length === 0)
            return null;
        for (let i = 0; i < result.length; ++i) {
            var storageFileName = '' + result[i];
            // console.log(result[i].split('_')[0])
            let lastModified = result[i].split('_')[0];
            var storageFileLink = fileLink + storageFileName;
            let urlLinkArray = storageFileName.split('_');
            let width = urlLinkArray[urlLinkArray.length - 2];
            let height = urlLinkArray[urlLinkArray.length - 1];
            // console.log(storageFileLink)
            await toDataURL(storageFileLink, async function (dataUrl) {

                imageSources.push({
                    Url: dataUrl,
                    height: height,
                    width: width,
                    lastModified: lastModified
                });

                if (result.length === imageSources.length) {
                    imageSources.sort((a, b) => new Date(a.lastModified) - new Date(b.lastModified));
                    var doc = MyDoc(imageSources, slateRef, session, displayName, profileUrl);
                    // console.log("blob loading");
                    const blob = await pdf(doc).toBlob();
                    var href = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = href;
                    a.download = convertTimeToLocal(session.start_time) + '_' + displayName + "_pdf";
                    a.click();
                    // console.log("the blob generated", blob)
                }
            })
        }
    }
    else {
        console.log(status)
        if (status === 400) {
            return null
        }
    }
    // console.log(result)


}