import React, { useEffect, useState } from 'react';
import { Box, Hidden, IconButton, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Content from './Content';
import { THEME_COLOR } from '../../whiteboard/src/config/constants';


const SlatesInOnePage = 7;


const ContentList = ({ isSearchPressed, array, setArray, user, isWhiteboard, handleIsPdf, handlePptxDocs, addImage, uploadCanvasFromContentLibrary, setContentBox }) => {
    const [filteredBySearchArray, setFilteredBySearchArray] = useState(array)
    const temporaryFilteredSearchArray = JSON.parse(JSON.stringify(filteredBySearchArray))
    const [pageNumber, setPageNumber] = useState(1)
    const [totalNumberOfPages, setTotalNumberOfPages] = useState(Math.ceil(filteredBySearchArray.length / SlatesInOnePage))
    const [arrayToDisplay, setArrayToDisplay] = useState(temporaryFilteredSearchArray.filter((element, index) => {
        return index < SlatesInOnePage
    }))
    // console.log(arrayToDisplay)

    const changeArrayToDisplay = (pageNumber) => {
        const temporaryArrayToDisplay = []
        // console.log(pageNumber)
        // console.log(filteredBySearchArray)
        for (let i = (pageNumber - 1) * SlatesInOnePage; i < (pageNumber * SlatesInOnePage); ++i) {
            if (i >= filteredBySearchArray.length) {
                break;
            }
            temporaryArrayToDisplay.push(filteredBySearchArray[i])
        }
        setArrayToDisplay([...temporaryArrayToDisplay])
        // console.log(temporaryArrayToDisplay)
    }
    // const getWhiteboardID = () => {
    //     const whiteboardID = v4();
    //     // console.log(whiteboardID)
    //     return whiteboardID
    // }

    useEffect(() => {
        let tempFilteredSearchArray = JSON.parse(JSON.stringify(filteredBySearchArray))
        setPageNumber(1)
        setTotalNumberOfPages(Math.ceil(filteredBySearchArray.length / SlatesInOnePage))
        setArrayToDisplay(tempFilteredSearchArray.filter((element, index) => {
            return index < SlatesInOnePage
        }))
    }, [filteredBySearchArray])

    useEffect(() => {
        setFilteredBySearchArray(array)
    }, [array])

    return (
        <Box sx={{ padding: '15px', backgroundColor: '#f8f8f8', borderRadius: '5px', overflowY: 'auto' }}>
            {filteredBySearchArray.length > 0 ? <>
                {<Box sx={{
                    height: '45px',
                    // backgroundColor: '#ffffff',
                    borderRadius: '10px',
                    display: 'flex',
                    flexDirection: 'row',
                    paddingRight: '20px',
                    justifyContent: 'center',
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    marginBottom: '5px'
                }}>
                    {pageNumber > 1 &&
                        <IconButton size='small' sx={{ marginRight: '5px', color: THEME_COLOR, borderRadius: '10px', backgroundColor: '#ffffff', height: '2rem', width: '2rem' }} onClick={() => {
                            if (pageNumber > 1) {
                                changeArrayToDisplay(pageNumber - 1)
                                setPageNumber(pageNumber - 1)
                            }
                        }}>
                            <ArrowBackIosNewIcon sx={{ fontSize: '0.8rem' }} />
                        </IconButton>
                    }
                    <Typography sx={{ fontSize: '1rem' }}>
                        <IconButton size='small' sx={{ border: "1px solid #e0e0e0", color: THEME_COLOR, borderRadius: '10px', backgroundColor: '#ffffff', height: '2rem', width: '2rem' }}>
                            <span style={{ color: THEME_COLOR, fontSize: '0.8rem', fontWeight: 'bold' }} >
                                {pageNumber}
                            </span>
                        </IconButton>

                        &nbsp;
                        <span style={{ color: '#9F9EB2', paddingTop: "5px" }}>
                            of
                        </span>
                        &nbsp;
                        <span style={{ paddingTop: "5px" }} >
                            {totalNumberOfPages}
                        </span>
                        &nbsp;
                    </Typography>
                    {pageNumber < totalNumberOfPages &&
                        <IconButton size='small' sx={{ marginLeft: '5px', color: THEME_COLOR, borderRadius: '10px', backgroundColor: '#ffffff', height: '2rem', width: '2rem' }} onClick={() => {
                            if (pageNumber < totalNumberOfPages) {
                                changeArrayToDisplay(pageNumber + 1)
                                setPageNumber(pageNumber + 1)
                            }
                        }}>
                            <ArrowForwardIosIcon sx={{ fontSize: '0.8rem' }} />
                        </IconButton>
                    }
                </Box>}
                <Box sx={{
                    // border: '1px solid #e0e0e0',
                    display: 'flex',
                    flexDirection: 'column',
                    paddingleft: '20px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '25px',
                    paddingBottom: '25px',
                    // boxShadow: 2
                }}>


                    {arrayToDisplay.map((content, index) => {
                        return (
                            <Content
                                content={content}
                                index={index}
                                user={user}
                                array={array}
                                setArray={setArray}
                                isWhiteboard={isWhiteboard}
                                handleIsPdf={handleIsPdf}
                                handlePptxDocs={handlePptxDocs}
                                addImage={addImage}
                                uploadCanvasFromContentLibrary={uploadCanvasFromContentLibrary}
                                setContentBox={setContentBox}
                            />
                        )
                    })}

                </Box>
            </> : <>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh', width: '100%', flexDirection: "column" }}>
                    {isSearchPressed && <Typography variant='h6' sx={{ color: THEME_COLOR }}>
                        No content found
                    </Typography>}
                </Box>
            </>}
        </Box>

    );
}


export default ContentList;


