import React, { useEffect, useState } from "react";
import { IconButton, Box, Grid, Tooltip, Button, Typography, TextField } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import { useAtom } from "jotai";
import { openTimerBoxAtom } from "../components/jotai/atom/openTimerBoxAtom";
import { updateSessionState } from "../whiteboard/src/service/slateService";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import { timerIdAtom } from "../components/jotai/atom/timerIdAtom";
import { WHITEBOARD_ICON_COLOR, WHITEBOARD_TOOLBAR_COLOR } from "../whiteboard/src/config/constants";

const Timer = ((props) => {
    const [openTimerBox, setOpenTimerBox] = useAtom(openTimerBoxAtom);
    const [timerId, setTimerId] = useAtom(timerIdAtom);
    const [time, setTime] = useState(0)
    const [totalDuration, setTotalDuration] = useState(1)
    const [timerInterval, setTimerInterval] = useState()
    const [showCloseButton, setShowCloseButton] = useState(false)

    useEffect(() => {

        if (window.lesson && window.lesson.sessionState) {
            if (window.lesson.sessionState.timer) {
                if (window.lesson.sessionState.timer.isActive) {
                    let currentMoment = moment()
                    let startTimeTemp = moment(window.lesson.sessionState.timer.startTime);

                    // Calculate the difference in seconds
                    const differenceInSecondsFromStartTime = currentMoment.diff(startTimeTemp, 'seconds');
                    const differenceInSeconds = window.lesson.sessionState.timer.timerDuration - differenceInSecondsFromStartTime

                    setTime(differenceInSeconds)
                    let start = differenceInSeconds;
                    if (start > 0) {
                        let timer = setInterval(() => {
                            start -= 1;
                            setTime(start);
                            if (start === 0) {
                                clearInterval(timer)
                                if (!props.isTeacher) {
                                    setShowCloseButton(true)
                                }
                            }
                        }, 1000);
                        setTimerInterval(timer);
                    }
                    else {
                        setTime(0)
                        if (!props.isTeacher) {
                            setOpenTimerBox(false)
                        }
                    }
                }
            }
        }

        return () => {
            clearInterval(timerInterval);
            setTime(0);
            setShowCloseButton(false);
        };



    }, [timerId])

    useEffect(() => {

        if (!openTimerBox) {
            clearInterval(timerInterval);
            setTime(0);
            setShowCloseButton(false);
        }

    }, [openTimerBox])

    const startTimer = () => {
        let timeTemp = totalDuration * 60
        if (timeTemp > 0) {
            setTime(timeTemp)
            let start = timeTemp;
            let timer = setInterval(() => {
                start -= 1;
                setTime(start);
                if (start === 0) {
                    clearInterval(timer)
                    if (!props.isTeacher) {
                        setOpenTimerBox(false)
                    }
                }
            }, 1000);
            setTimerInterval(timer);

            let sessionStateData = JSON.parse(JSON.stringify(window.lesson.sessionState))
            let currentMoment = moment()
            let timerId = uuidv4()
            sessionStateData = {
                ...sessionStateData,
                'timer': {
                    id: timerId,
                    startTime: currentMoment,
                    timerDuration: totalDuration * 60,
                    isActive: true
                }
            }
            updateSessionStateandSendEvent(sessionStateData)
        }
    }


    const stopTimer = () => {
        clearInterval(timerInterval);
        setTime(0)
        let sessionStateData = JSON.parse(JSON.stringify(window.lesson.sessionState))
        let currentMoment = moment()
        if (sessionStateData.timer) {
            sessionStateData.timer.endTime = currentMoment
            sessionStateData.timer.isActive = false
        }
        updateSessionStateandSendEvent(sessionStateData)
    }

    const pauseTimer = () => {
        clearInterval(timerInterval);
    }


    const getTimeInMinutesAndSeconds = (time) => {
        if (time < 60) {
            return '00:' + getUnits(time)
        }
        else {
            return getUnits(parseInt(time / 60)) + ':' + getUnits(time % 60)
        }
    }

    const getUnits = (time) => {
        return time > 9 ? time : '0' + time
    }

    const updateSessionStateandSendEvent = async (sessionStateData) => {
        let status, res;
        // console.log(sessionStateData)
        try {
            res = await updateSessionState(window.lesson.accessToken, window.lesson.session.id, JSON.stringify(sessionStateData), window.lesson.slate.id);
            status = res.status
        }
        catch (err) {
            console.log(err);
            status = err.response.status;
        }
        // console.log(index)
        if (status === 200) {
            props.sendLessonEventViaWebSocket({
                eventType: 'sessionStateUpdated',
                eventData: {
                    'sentBy': window.lesson.user.id,
                    'sessionState': sessionStateData
                }
            })
            window.lesson.sessionState = sessionStateData
        }
        else {
            alert("Could not update permissions")
            console.log(status)
        }
    }


    return <>
        {props.isTeacher && <Tooltip title='Timer'>
            <IconButton
                id='timer-button'
                sx={{ position: 'fixed', left: '15px', top: '25%', color: WHITEBOARD_ICON_COLOR, zIndex: 100 }}
                size="medium"
                onClick={(e) => {
                    setOpenTimerBox(true)
                }}>
                <AccessAlarmIcon />
            </IconButton>
        </Tooltip>}
        {openTimerBox && <Box sx={{
            position: 'fixed',
            left: '80px',
            top: '12.5%',
            boxShadow: "2",
            borderRadius: '10px',
            zIndex: 1050,
            // width: 300,
            // height: 300,
            background: 'white'
        }}>
            <Box sx={{ borderRadius: '10px 10px 0 0', position: 'relative', overscrollBehavior: "none", maxHeight: '100%', minHeight: '25px' }} >
                <Grid container sx={{ backgroundColor: WHITEBOARD_TOOLBAR_COLOR, height: '0px', position: 'sticky', right: 0, top: 0, zIndex: 10000, width: '100%' }} justifyContent='right' textAlign='right'>
                    {props.isTeacher ? <IconButton
                        sx={{ color: 'white', position: 'sticky', zIndex: 10000 }}
                        onClick={() => {
                            stopTimer()
                            setOpenTimerBox(false);
                        }}
                        size="small">
                        <CloseIcon sx={{ fontSize: '1rem' }} />
                    </IconButton> : <>
                        {showCloseButton && <IconButton
                            sx={{ color: 'white', position: 'sticky', zIndex: 10000 }}
                            onClick={() => {
                                setOpenTimerBox(false);
                                setShowCloseButton(false);
                            }}
                            size="small">
                            <CloseIcon sx={{ fontSize: '1rem' }} />
                        </IconButton>}
                    </>}
                </Grid>
                <Box sx={{
                    width: '100%',
                    backgroundColor: WHITEBOARD_TOOLBAR_COLOR,
                    borderRadius: '10px 10px 0 0'
                }}>
                    <Typography sx={{
                        height: '100%',
                        width: '100%',
                        textAlign: 'center',
                        justifyContent: 'center',
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        color: 'white'
                    }}>
                        <AccessAlarmIcon sx={{ marginTop: '5px' }} />
                    </Typography>
                </Box>
                <Box sx={{
                    // display: maximize ? 'block' : 'none',
                    height: '100%',
                    padding: '0px 20px 20px',
                    width: '100%'
                }}>
                    <Box sx={{ textAlign: 'center', justifyContent: 'center' }}>
                        <Typography sx={{
                            height: '100%',
                            width: '100%',
                            textAlign: 'center',
                            justifyContent: 'center',
                            fontSize: '2.5rem',
                            fontWeight: 'bold',
                            color: time === 0 ? 'black' : time < 10 ? 'red' : 'black'
                        }}>
                            {getTimeInMinutesAndSeconds(time)}
                        </Typography>

                    </Box>
                    {props.isTeacher && time === 0 && <Box sx={{ textAlign: 'center', justifyContent: 'center', padding: '5px 0 20px', display: 'flex' }}>
                        <Typography sx={{ fontSize: '1rem', fontWeight: 550 }}>
                            Duration :&nbsp;&nbsp;
                        </Typography>
                        <TextField
                            type="number"
                            value={totalDuration}
                            sx={{
                                input: {
                                    color: "#000000",
                                    width: '80px',
                                    height: '10px',
                                    borderColor: '#ffffff !important',
                                    fontSize: '0.9rem',
                                    padding: '10px',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    // backgroundColor: 'black',
                                },

                                borderRadius: '5px',
                                width: '60px',
                                height: '10px',
                                color: '#ffffff',
                                padding: 0,
                                marginBottom: '20px',
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        border: "2px solid",
                                        borderColor: 'black',

                                    },
                                    '&:hover fieldset': {
                                        border: "2px solid",
                                        borderColor: 'black',
                                    },
                                    '&.Mui-focused fieldset': {
                                        border: "2px solid",
                                        borderColor: 'black',
                                        borderRadius: '5px',
                                    },
                                },



                                // '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                //     'WebkitAppearance': 'none',
                                //     margin: 0,
                                // },
                                // '& input[type="number"]': {
                                //     'MozAppearance': 'textfield',
                                // },
                            }}
                            onChange={(e) => {
                                setTotalDuration(e.target.value)
                            }}
                        >

                        </TextField>
                        <Typography sx={{ fontSize: '1rem', fontWeight: 400 }}>
                            &nbsp; min(s)
                        </Typography>
                    </Box>}
                    {props.isTeacher && <Box sx={{ justifyContent: 'center', textAlign: 'center' }}>
                        {time === 0 ? <Button
                            sx={{
                                textTransform: 'none',
                                backgroundColor: WHITEBOARD_TOOLBAR_COLOR,
                                color: '#FFFFFF',
                                borderRadius: '20px',
                                padding: '10px 15px',
                                '&:hover': {
                                    backgroundColor: WHITEBOARD_TOOLBAR_COLOR,
                                    boxShadow: 'none',
                                },
                            }}
                            onClick={() => {
                                startTimer()
                            }}
                            disabled={time > 0}
                        >
                            <PlayCircleOutlineIcon />
                        </Button>
                            :

                            <Button
                                sx={{
                                    textTransform: 'none',
                                    backgroundColor: WHITEBOARD_TOOLBAR_COLOR,
                                    color: '#FFFFFF',
                                    marginLeft: '10px',
                                    borderRadius: '20px',
                                    padding: '10px 15px',
                                    '&:hover': {
                                        backgroundColor: WHITEBOARD_TOOLBAR_COLOR,
                                        boxShadow: 'none',
                                    },
                                }}
                                onClick={() => {
                                    stopTimer()
                                }}
                                disabled={time === 0}
                            >
                                <StopCircleIcon />
                            </Button>}
                        {/* <Button
                            sx={{
                                textTransform: 'none',
                         
                                color: '#FFFFFF',
                                marginLeft: '10px',
                                borderRadius: '10px',
                                padding: '10px 25px',
                                '&:hover': {
                             
                                    boxShadow: 'none',
                                },
                            }}
                            onClick={() => {
                                pauseTimer()
                            }}
                        >
                            Pause
                        </Button> */}


                    </Box>}

                </Box>


            </Box>
        </Box>}

    </>;
});

export default Timer