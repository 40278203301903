import * as React from 'react';

/**
 * @internal
 */
export function useObservableState(observable, startWith) {
    const [state, setState] = React.useState(startWith);
    React.useEffect(() => {
        // observable state doesn't run in SSR
        if (typeof window === 'undefined' || !observable) return;
        const subscription = observable.subscribe(setState);
        return () => subscription.unsubscribe();
    }, [observable]);
    return state;
}
