import React, {
    useEffect, useState,
} from 'react';
import { Button, Grid, Hidden, IconButton, SvgIcon, Typography, styled } from '@mui/material'
import { Box, } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { messageArrayAtom } from '../components/jotai/atom/messageArrayAtom';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import CloseIcon from "@mui/icons-material/Close";
import CustomLoader from '../components/CustomLoader/CustomLoader';
import { capitalizeFirstAlphabet, findNamebyId, findObjectById } from '../components/helperFunctions';
import { getTimeIfTodayDateElseGetDateAndTime, removeDuplicates } from '../components/utils';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import DownloadIcon from '@mui/icons-material/Download';
import './audioPlayerStyles.css';
import { fileDownloadingProgressAtom, isFileDownloadingInChatAtom } from '../components/jotai/atom/isFileDownloadingInChatAtom';
import DownloadFileLoader from '../components/CustomLoader/DownloadFileLoader';
import { CHAT_MESSAGE_BOX_COLOR, THEME_COLOR, THEME_HOVER_COLOR } from '../whiteboard/src/config/constants';
import CustomPlaceholder from '../components/CustomPlaceholder/CustomPlaceholder'
import axios from 'axios';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';

const Root = styled(Grid)((
    {
        theme
    }
) => ({
    width: '100%',
    height: '90%',
    // minHeight: '80%',
    // backgroundColor: theme.palette.background.default,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    backgroundColor: '#f8f8f8',
}));

const colors = ['#084887', '#44AF69', '#F15156', '#4D95EA', '#17138B', '#171717'];

function MessageList({ user, scrollMessagesToBottom, slateID, isChatLoading, slateObj, closeChat, isAudioNoteLoading, handleIsPdf, handlePptxDocs, addImage, uploadCanvasFromContentLibrary, isTeacher }) {

    const [messageList] = useAtom(messageArrayAtom)
    const [slateMembers, setSlateMembers] = useState([])
    const setIsDownloading = useSetAtom(isFileDownloadingInChatAtom)
    const setDownloadProgress = useSetAtom(fileDownloadingProgressAtom)
    // console.log('message list is rerendering', messageList, slateID, slateObj, user)
    useEffect(() => {
        scrollMessagesToBottom();
        // console.log(messageList)
    }, [messageList]);

    useEffect(() => {
        setColorToSlateMembers()

    }, [slateObj])

    useEffect(() => {
        if (document.getElementById('audio-note-loader-block')) {
            document.getElementById('audio-note-loader-block').scrollIntoView(false)
        }
    }, [isAudioNoteLoading])

    useEffect(() => {
        scrollMessagesToBottom();
    }, [isChatLoading]);

    const handleClickForWhiteboard = (fileLink, isImage) => {
        if (isImage) {
            if (addImage) {
                addImage.addImg(fileLink, false)
            }
            return;
        }
        else {
            // let fileType = 'pdf1';
            console.log(fileLink)
            let elementNameArray = (fileLink).split('.');
            let fileType = elementNameArray[elementNameArray.length - 1];
            if (fileType === 'pdf') {
                handleIsPdf(null, fileLink)

            }

            else if (fileType === 'txt') {
                uploadCanvasFromContentLibrary(fileLink)
            }
            else if (fileType === 'mp4' || fileType === 'mov' || fileType === 'webm' || fileType === 'video') {
                if (addImage) {
                    let elementNameArray = (fileLink).split('/')
                    let elementName = elementNameArray[elementNameArray.length - 1]
                    addImage.addAudioVideoTag(fileLink, 'video', elementName)
                }
                //isvideo
            }
            else if (fileType === 'mp3' || fileType === 'wav' || fileType === 'audio') {
                if (addImage) {
                    let elementNameArray = (fileLink).split('/')
                    let elementName = elementNameArray[elementNameArray.length - 1]
                    addImage.addAudioVideoTag(fileLink, 'audio', elementName)
                }
                //isaudio
            }
            else if (fileType === 'pptx') {
                handlePptxDocs(null, 'pptx', fileLink)
            }
            else if (fileType === 'docx') {
                handlePptxDocs(null, 'docx', fileLink)
            }
            else if (fileType === 'xlsx') {
                handlePptxDocs(null, 'xlsx', fileLink)
            }
            else if (fileType === 'ppt') {
                handlePptxDocs(null, 'ppt', fileLink)
            }
            else if (fileType === 'doc') {
                handlePptxDocs(null, 'doc', fileLink)
            }
            else if (fileType === 'xls') {
                handlePptxDocs(null, 'xls', fileLink)
            }
        }


    }

    const setColorToSlateMembers = () => {
        let slateMembersTemp = []
        if (slateObj) {
            if (!(findObjectById(slateObj.hostDetails, (slateObj.creatorDetails).user_id) || findObjectById(slateObj.participantDetails, (slateObj.creatorDetails).user_id))) {
                slateMembersTemp.push(slateObj.creatorDetails)
            }
            for (let i = 0; i < (slateObj.hostDetails).length; ++i) {
                slateMembersTemp.push(slateObj.hostDetails[i])
            }
            for (let i = 0; i < (slateObj.participantDetails).length; ++i) {
                slateMembersTemp.push(slateObj.participantDetails[i])
            }
            let slateMemberTemp2 = removeDuplicates(slateMembersTemp)
            for (let i = 0; i < slateMemberTemp2.length; ++i) {
                if (i < colors.length) {
                    slateMemberTemp2[i].user_color = colors[i]
                }
                else {
                    slateMemberTemp2[i].user_color = '#000000'
                }
            }
            // console.log('slate members are', slateMemberTemp2)
            setSlateMembers(slateMemberTemp2)
        }

    }

    const getColorOfSlateMembers = (userId) => {
        for (let i = 0; i < slateMembers.length; ++i) {
            if (userId === slateMembers[i].user_id) {
                // console.log(slateMembers[i].email, 'colors', slateMembers[i].user_color)
                return slateMembers[i].user_color
            }
        }
        return '#000000'
    }

    const renderTextWithNewlines = (text) => {
        const segments = text.split('$%$%$');
        return segments.map((segment, index) => (
            <React.Fragment key={index}>
                {segment}
                {index < segments.length - 1 && <br />}
            </React.Fragment>
        ));
    };


    const handleFileDownload = async (element) => {
        setIsDownloading(true)
        try {
            const response = await axios.get(element.file_link, {
                responseType: 'blob',
                onDownloadProgress: (progressEvent) => {
                    const total = progressEvent.total;
                    const loaded = progressEvent.loaded;
                    const percentCompleted = Math.floor((loaded / total) * 100);
                    // console.log(percentCompleted)
                    setDownloadProgress(percentCompleted)
                    // setProgress(percentCompleted);
                }
            });

            // Create a link element, set its href to the blob URL, and click it to download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', element.file_name); // or extract the file name from response
            document.body.appendChild(link);
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
            setIsDownloading(false)
            setDownloadProgress(0)
        } catch (error) {
            console.error('Error downloading the file', error);
            setIsDownloading(false)
            setDownloadProgress(0)
        }

    }

    const checkIfDeliveredToAll = (members, element, user) => {
        // console.log(members, 'heyheyehy')
        for (let member of members) {
            if (!(member.email === 'slatemates.ops@gmail.com' || member.user_id === user.id || member.user_id === element.creator_id)) {
                // if (element.id === 'e0ede6f1-3b8e-4758-87c8-9c747e465b6f') {
                //     console.log(!(checkIfDataHasId(element.message_delivered_user_ids, member.user_id)))
                //     console.log(member)
                // }
                if (!(checkIfDataHasId(element.message_delivered_user_ids, member.user_id)))
                    return false;
            }
        }
        return true;
    }

    const checkIfReadByAll = (members, element, user) => {
        // console.log(members, 'heyheyehy')

        for (let member of members) {

            if (!(member.email === 'slatemates.ops@gmail.com' || member.user_id === user.id || member.user_id === element.creator_id)) {
                // if (element.id === 'e0ede6f1-3b8e-4758-87c8-9c747e465b6f') {
                //     console.log(!(checkIfDataHasId(element.message_delivered_user_ids, member.user_id)))
                //     console.log(member)
                // }
                if (!(checkIfDataHasId(element.message_read_user_ids, member.user_id)))
                    return false;
            }

        }
        return true;
    }

    const checkIfDataHasId = (data, id) => {
        if (data) {
            for (let item of data) {
                // console.log(item)
                if (item === id) {
                    return true
                }
            }
            return false
        } else {
            return false
        }

    }

    const processAndRenderText = (text, searchValue) => {
        // Split the text by the special line break marker
        const segments = text.split('$%$%$');

        return segments.map((segment, segmentIndex) => {
            // Split the segment by URLs or the search term
            const parts = segment.split(/(https?:\/\/[^\s]+)/g);

            return (
                <React.Fragment key={segmentIndex}>
                    {parts.map((part, partIndex) => {

                        if (part.match(/^https?:\/\//)) {
                            // Render URLs as clickable links
                            return (
                                <a key={partIndex} href={part} style={{ color: 'blue' }} target="_blank" rel="noopener noreferrer">
                                    {part}
                                </a>
                            );
                        }
                        else {
                            // Return normal text
                            return part;
                        }
                    })}
                    {segmentIndex < segments.length - 1 && <br />} {/* Add a line break after each segment */}
                </React.Fragment>
            );
        });
    };

    return (

        <Root>

            <IconButton
                sx={{
                    display: { xs: 'block', sm: 'block', md: 'block', lg: 'block' },
                    marginRight: '5px', color: 'black', position: 'absolute', right: '5px', top: 0, zIndex: 10000, height: '1.2rem', width: '1.2rem'
                }}
                onClick={closeChat}
            >
                <CloseIcon sx={{ fontSize: '1.25rem' }} />
            </IconButton>
            <Box
                sx={{
                    width: '100%',
                    height: '7.5%',
                    overflowY: 'auto',
                    backgroundColor: '#ffffff',
                    color: 'black',
                    justifyContent: 'center',
                    textAlign: 'center',
                    alignItems: 'center',
                    borderBottom: '1px solid rgb(224, 224, 224)'
                }}>
                <Typography
                    sx={{
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        height: "100%",
                        paddingTop: '2%'
                    }}
                >
                    Chat
                </Typography>
            </Box>
            <DownloadFileLoader />
            {slateID && <Box
                sx={{
                    marginTop: '7,5%',
                    width: '100%',
                    height: '92.5%',
                    overflowY: 'auto',
                    backgroundColor: '#f8f8f8',
                }}>

                {true ?
                    <>
                        {messageList ? messageList.map((element, index) => {
                            return (element.text || element.message_type === 'file' || element.message_type === 'audio' || element.message_type === 'image' || element.message_type === 'session_feedback') &&
                                <Grid
                                    key={index}
                                    id={index === (messageList).length - 1 ? 'last-message-inside-list' : 'element-number' + index}
                                    sx={{
                                        padding: '10px',
                                        wordWrap: 'break-word !important',
                                        display: 'flex',
                                        marginLeft: "5px",
                                        marginTop: "5px",
                                        width: 'calc(100%-20px)',
                                        justifyContent: element.creator_id === user.id ? 'right' : 'left',
                                        textAlign: element.creator_id === user.id ? 'right' : 'left',
                                        alignItems: element.creator_id === user.id ? 'end' : 'start',
                                        position: 'relative',
                                        flexDirection: 'column',
                                        marginBottom: element.message_type === 'file' || element.message_type === 'image' ? '40px' : 0,
                                    }} >

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            borderRadius: '5px',
                                            flexDirection: 'column',
                                            backgroundColor: element.message_type !== 'audio' ? (element.message_type !== 'image' ? (element.creator_id === user.id ? CHAT_MESSAGE_BOX_COLOR : "#ffffff") : '#ffffff') : 'none',
                                            maxWidth: '80%',
                                            minWidth: element.message_type !== 'audio' ? '95px' : { xs: '80%', sm: '50%', md: '80%', lg: '80%' },
                                            boxShadow: element.message_type !== 'audio' ? 1 : 'none',
                                            padding: element.message_type !== 'audio' ? (element.message_type !== 'image' ? '10px 15px 20px' : 0) : 0,
                                            position: 'relative'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                position: 'absolute',
                                                bottom: '2px',
                                                right: '5px',
                                                fontSize: '0.6rem',
                                                justifyContent: 'right',
                                                textAlign: 'right',
                                                color: '#00000',
                                                display: 'flex'
                                            }}>
                                            {getTimeIfTodayDateElseGetDateAndTime(element.created_at)}
                                            {(element.creator_id === user.id || user.email === 'slatemates.ops@gmail.com') && <>
                                                {checkIfReadByAll(slateMembers, element, user) ? <DoneAllIcon sx={{
                                                    fontSize: '0.8rem',
                                                    marginLeft: '5px',
                                                    color: '#1877F2'
                                                }} />
                                                    : checkIfDeliveredToAll(slateMembers, element, user) ? <DoneAllIcon sx={{
                                                        fontSize: '0.8rem',
                                                        marginLeft: '5px'
                                                    }} /> : <DoneIcon sx={{
                                                        fontSize: '0.8rem',
                                                        marginLeft: '5px'
                                                    }} />}
                                            </>}
                                        </Typography>
                                        {element.creator_id === user.id ? <></> : <Typography
                                            sx={{
                                                borderRadius: '5px',
                                                flexWrap: 'wrap',
                                                color: element.creator_id === user.id ? '#ffffff' : getColorOfSlateMembers(element.creator_id),
                                                display: "flex",
                                                // width: { xs: '70px', sm: '100px', md: '120px', lg: '140px', xl: '180px' },
                                                overflow: 'hidden',
                                                maxWidth: '100%',
                                                // minWidth: { xs: "50vw", sm: '30vw', md: '20vw', lg: '15vw' },
                                                wordWrap: "break-word",
                                                justifyContent: 'left',
                                                textAlign: 'left',
                                                alignItems: 'left',
                                                fontSize: { xs: '1rem', sm: '1rem', md: '1rem', lg: '1rem' },
                                                fontWeight: 600,
                                                padding: element.message_type === 'image' ? '5px' : 0
                                            }}
                                        >

                                            {
                                                findObjectById(slateObj.hostDetails, element.creator_id) && <>
                                                    {findNamebyId(slateObj.hostDetails, element.creator_id)}
                                                </>
                                            }
                                            {
                                                findObjectById(slateObj.participantDetails, element.creator_id) && <>
                                                    {findNamebyId(slateObj.participantDetails, element.creator_id)}
                                                </>
                                            }
                                            {
                                                !(findObjectById(slateObj.hostDetails, element.creator_id) || findObjectById(slateObj.participantDetails, element.creator_id)) && <>
                                                    {slateObj.creatorDetails.firstName && slateObj.creatorDetails.lastName && slateObj.creatorDetails.firstName !== '' && slateObj.creatorDetails.lastName !== '' ? capitalizeFirstAlphabet(slateObj.creatorDetails.firstName) + ' ' + capitalizeFirstAlphabet(slateObj.creatorDetails.lastName) : slateObj.creatorDetails.email}
                                                </>
                                            }
                                            &nbsp;

                                        </Typography>}
                                        {element.message_type === 'text' &&
                                            <Typography
                                                sx={{
                                                    borderRadius: '5px',
                                                    paddingTop: '2px',

                                                    color: element.creator_id === user.id ? '#ffffff' : '#000000',
                                                    display: "flex",
                                                    maxWidth: '100%',
                                                    // minWidth: '20%',
                                                    // width: { xs: '70px', sm: '100px', md: '120px', lg: '140px', xl: '180px' },
                                                    overflow: 'hidden',
                                                    flexWrap: 'wrap',
                                                    wordWrap: "break-word",
                                                    justifyContent: 'left',
                                                    textAlign: 'left',
                                                    alignItems: 'left',
                                                    fontSize: { xs: '1rem', sm: '1rem', md: '1rem', lg: '1rem' },
                                                    fontWeight: 400,
                                                    overflowWrap: 'anywhere'
                                                }}
                                            >

                                                {element.text.match(/(https?:\/\/[^\s]+)/g) ? (
                                                    processAndRenderText(element.text)
                                                ) : (
                                                    renderTextWithNewlines(element.text)
                                                )}
                                            </Typography>
                                        }
                                        {element.message_type === 'file' && <>
                                            <a style={{ cursor: "pointer", textDecoration: 'none', maxWidth: '100%', minWidth: '20%' }}
                                                onClick={() => handleFileDownload(element)}>
                                                <Grid
                                                    sx={{
                                                        borderRadius: '5px',
                                                        padding: '5% 0%',

                                                        color: element.creator_id === user.id ? '#ffffff' : '#000000',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        width: '100%',
                                                        // width: { xs: '70px', sm: '100px', md: '120px', lg: '140px', xl: '180px' },
                                                        justifyContent: 'center',
                                                        textAlign: 'center',
                                                        alignItems: 'center',
                                                        fontWeight: 400
                                                    }}
                                                >

                                                    <Typography
                                                        sx={{
                                                            padding: '1.5px 10px 6px',
                                                            display: "inline-block",
                                                            width: '100%',
                                                            // width: { xs: '70px', sm: '100px', md: '120px', lg: '140px', xl: '180px' },
                                                            overflow: 'hidden',
                                                            wordWrap: "break-word",
                                                            justifyContent: 'left',
                                                            textAlign: 'left',
                                                            alignItems: 'left',
                                                            fontSize: { xs: '1rem', sm: '1rem', md: '1rem', lg: '1rem' },
                                                            fontWeight: 400
                                                        }}
                                                    >


                                                        <span style={{ display: 'flex' }}>
                                                            {element.file_name}&nbsp;<CloudDownloadIcon sx={{ fontSize: '1.35rem' }} />
                                                        </span>

                                                        {/* hehehehe how are you broooo ddsd dddddddddddddddddddddddddddd fdddddddddddd */}
                                                        {/* {element.creatorUserId} */}
                                                    </Typography>

                                                </Grid>
                                            </a>
                                        </>
                                        }
                                        {element.message_type === 'audio' &&
                                            <>
                                                {!element.isAudioLoading ? <Box
                                                    sx={{
                                                        borderRadius: '5px',
                                                        paddingTop: '2px',

                                                        // color: element.creator_id === user.id ? '#ffffff' : '#000000',
                                                        display: "flex",
                                                        width: '100%',
                                                        // minWidth: '20%',
                                                        // width: { xs: '70px', sm: '100px', md: '120px', lg: '140px', xl: '180px' },
                                                        overflow: 'hidden',
                                                    }}
                                                >

                                                    <AudioPlayer
                                                        style={{
                                                            borderRadius: "1rem",
                                                            color: "white",
                                                            background: "#ffffff",
                                                            height: '100%',
                                                            width: '100%',
                                                            boxShadow: 'none',
                                                            margin: 0,
                                                        }}
                                                        showJumpControls={false}
                                                        hasDefaultKeyBindings={false}
                                                        src={element.file_link}
                                                        timeFormat={'mm:ss'}
                                                        defaultDuration={element.text ? '0' + Math.floor(parseInt(element.text) / 60) + ':' + (parseInt(element.text) % 60 > 9 ? parseInt(element.text) % 60 : '0' + parseInt(element.text) % 60) : '--:--'}
                                                    />


                                                </Box> :
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexWrap: 'wrap',
                                                            borderRadius: '5px',
                                                            flexDirection: 'column',
                                                            backgroundColor: '#ffffff',
                                                            boxShadow: 'none',
                                                            padding: '10px 15px',
                                                            justifyContent: 'center',
                                                            textAlign: 'center',
                                                            alignItems: 'center',
                                                            padding: '20px 10px'
                                                        }}
                                                    >
                                                        {/* Audio is loading */}
                                                        <CustomLoader />
                                                    </Box>
                                                }
                                            </>
                                        }

                                        {element.message_type === 'image' &&
                                            <Box
                                                sx={{
                                                    borderRadius: '5px',
                                                    // paddingTop: '2px',


                                                    // color: element.creator_id === user.id ? '#ffffff' : '#000000',
                                                    display: "flex",
                                                    width: '100%',
                                                    // minWidth: '20%',
                                                    // width: { xs: '70px', sm: '100px', md: '120px', lg: '140px', xl: '180px' },
                                                    overflow: 'hidden',
                                                    position: 'relative'
                                                }}
                                            >
                                                <IconButton
                                                    sx={{
                                                        position: 'absolute',
                                                        // left: element.creator_id === user.id ? 0 : 'none',
                                                        // right: element.creator_id === user.id ? 'none' : 0,
                                                        right: 0,
                                                        top: 0,
                                                        '&:hover': {
                                                            boxShadow: 1,
                                                            backgroundColor: '#ffffff',
                                                        },
                                                        borderRadius: '5px 0 0 0',
                                                        backgroundColor: '#ffffff',
                                                        color: THEME_COLOR
                                                    }}
                                                    size='small'
                                                    onClick={() => handleFileDownload(element)}
                                                >
                                                    <DownloadIcon />
                                                </IconButton>
                                                <div
                                                    style={{
                                                        height: 250,
                                                        width: 250,
                                                        backgroundColor: '#ffffff',
                                                        borderRadius: '8px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <img
                                                        style={{
                                                            width: 250,
                                                            height: 'auto',        // Automatically adjust height to maintain aspect ratio
                                                            objectFit: 'contain',    // Ensure the image covers the entire container (use 'contain' if you want it to fit inside)

                                                        }} src={element.file_link}
                                                        loading="lazy"
                                                    ></img>
                                                </div>


                                            </Box>
                                        }
                                        {element.message_type === 'session_feedback' &&
                                            <Typography
                                                sx={{
                                                    borderRadius: '5px',
                                                    paddingTop: '2px',

                                                    color: element.creator_id === user.id ? '#ffffff' : '#000000',
                                                    display: "flex",
                                                    maxWidth: '100%',
                                                    // minWidth: '20%',
                                                    // width: { xs: '70px', sm: '100px', md: '120px', lg: '140px', xl: '180px' },
                                                    overflow: 'hidden',
                                                    flexWrap: 'wrap',
                                                    wordWrap: "break-word",
                                                    justifyContent: 'left',
                                                    textAlign: 'left',
                                                    alignItems: 'left',
                                                    fontSize: { xs: '1rem', sm: '1rem', md: '1rem', lg: '1rem' },
                                                    fontWeight: 400,
                                                    overflowWrap: 'anywhere'
                                                }}
                                            >

                                                {element.text.match(/(https?:\/\/[^\s]+)/g) ? (
                                                    processAndRenderText(element.text)
                                                ) : (
                                                    renderTextWithNewlines(element.text)
                                                )}
                                                {/* hehehehe how are you broooo ddsd dddddddddddddddddddddddddddd fdddddddddddd */}
                                                {/* {element.creatorUserId} */}
                                            </Typography>
                                        }
                                        {isTeacher && (element.message_type === 'image' || element.message_type === 'file') && <Box
                                            sx={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                // borderRadius: '5px',
                                                flexDirection: 'column',
                                                width: '100%',
                                                // boxShadow: element.message_type !== 'audio' ? 1 : 'none',
                                                position: 'absolute',
                                                bottom: -32.5,
                                                right: 0
                                            }}
                                        ><Button
                                            sx={{
                                                // marginTop: '10px',
                                                textTransform: 'none',
                                                backgroundColor: THEME_COLOR,
                                                color: '#FFFFFF',
                                                fontSize: '0.8rem',
                                                fontWeight: '500',
                                                height: '35px',
                                                borderRadius: '0 0 5px 5px',
                                                '&:hover': {
                                                    backgroundColor: THEME_HOVER_COLOR,
                                                    boxShadow: 'none',
                                                },
                                            }}
                                            onClick={() => {
                                                if (element.message_type === 'image') {
                                                    handleClickForWhiteboard(element.file_link, true)
                                                }
                                                else {
                                                    handleClickForWhiteboard(element.file_link, false)
                                                }
                                                // handleOpenNewSession()
                                            }}
                                        >
                                                Paste to whiteboard
                                            </Button>


                                        </Box>}
                                    </Box>

                                </Grid>

                        }) : <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            padding: '10px',
                            wordWrap: 'break-word !important',
                            display: 'flex',
                            marginLeft: "5px",
                            marginTop: "5px",
                            width: 'calc(100%-20px)',
                            height: '100%'
                        }} >
                            <Typography variant='h6' sx={{ color: THEME_COLOR }}> Loading Messages ...</Typography>
                            <CustomLoader />
                        </Box>
                        }
                        <CustomPlaceholder isChatLoading={isChatLoading} />
                    </> : <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        padding: '10px',
                        wordWrap: 'break-word !important',
                        display: 'flex',
                        marginLeft: "5px",
                        marginTop: "5px",
                        width: 'calc(100%-20px)',
                        height: '100%'
                    }} >
                        <Typography variant='h6' sx={{ color: THEME_COLOR }}> Uploading file ...</Typography>
                        <CustomLoader />
                    </Box>}


            </Box>}
        </Root>

    );
}

export default MessageList;