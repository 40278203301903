import * as React from 'react';
import { ConnectionQuality, Track } from 'livekit-client';

import {
  QualityUnknownIcon,
  QualityExcellentIcon,
  QualityGoodIcon,
  QualityPoorIcon,
} from './index';

/**
 * @internal
 */

/**
 * @internal
 */
export function getConnectionQualityIcon(quality) {
  switch (quality) {
    case ConnectionQuality.Excellent:
      return <QualityExcellentIcon />;
    case ConnectionQuality.Good:
      return <QualityGoodIcon />;
    case ConnectionQuality.Poor:
      return <QualityPoorIcon />;
    default:
      return <QualityUnknownIcon />;
  }
}