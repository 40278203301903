import React, { useState } from 'react';
import { Box, Paper, InputBase, IconButton, Typography, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { getContentBySearch } from '../../whiteboard/src/service/slateService';
import axios from 'axios';
import { THEME_COLOR } from '../../whiteboard/src/config/constants';

// import Paper from '@mui/material/Paper';
// import InputBase from '@mui/material/InputBase';
// import IconButton from '@mui/material/IconButton';
// import SearchIcon from '@mui/icons-material/Search';
const subjectList = [
    'Maths',
    'English',
    'Science'
]
const gradeList = [
    '1',
    '2',
    '3',
    '4', '5', '6', '7', '8', '9', '10', '11', '12'
]
const boardList = [
    'IB',
    'IGCSE',
    'CBSE'
]


const ContentHeader = ({ array, setArray, isWhiteboard, setIsSearchPressed, isSearchPressed }) => {
    const [searchText, setSearchText] = useState('');
    const [subject, setSubject] = useState('')
    const [grade, setGrade] = useState('')
    const [board, setBoard] = useState('')

    // const handleSubmit = async () => {
    //     let res, status
    //     try {
    //         res = await getContentBySearch(searchText)
    //         status = res.status
    //     }
    //     catch (err) {
    //     }
    //     if (status === 200) {
    //         //      console.log(res.data)
    //         console.log(res)
    //         setArray(res.data.response.docs)
    //     }

    // }

    const handleSubmit = async () => {

        const baseURL = "https://search.teachmatter.com:3002/solr";
        const collectionName = "content_core";
        const queryParameters = {
            board: board,
            subject: subject,
            grade: grade
        };

        // Construct the query part of the URL
        const queryPart = `(board:"${queryParameters.board}" AND subject:"${queryParameters.subject}" AND grade:"${queryParameters.grade}")`;

        // Assemble the full URL
        const fullURL = `${baseURL}/${collectionName}/select?q=${encodeURIComponent(queryPart)}&qf=board+subject+grade&defType=edismax&wt=json`;

        // console.log(fullURL);
        axios.get(fullURL)
            .then(response => {
                // console.log(response.data);
                setArray(response.data.response.docs)
                if (!isSearchPressed) {
                    setIsSearchPressed(true)
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    const checkKeyPress = (e) => {
        const { key, keyCode } = e;

        // console.log(key, keyCode);
        if (keyCode === 13) {
            e.preventDefault()
            handleSubmit()
        }
    };


    return (
        <Box sx={{

            // backgroundColor: '#FFFFFF',
            // border: '1px solid #e0e0e0',
            display: 'flex',
            flexDirection: 'column',
            paddingRight: '15px',
            paddingTop: '2.5px',
            // width: '100wh',
            margin: "0 15px",
            borderRadius: '5px',
            marginBottom: '10px',
        }}>
            <Box sx={{ padding: '20px 0 20px', display: 'flex', justifyContent: 'center', width: '100%', flexDirection: 'row' }}>
                <Typography variant={isWhiteboard ? 'h4' : 'h3'} sx={{ color: '#000000', fontWeight: 600 }}>
                    Content Library
                </Typography>
            </Box>
            <Box sx={{ padding: '20px 0 5px', display: 'flex', justifyContent: 'center', width: '100%', flexDirection: isWhiteboard ? 'column' : { xs: 'column', sm: 'column', md: 'row', lg: 'row' }, alignItems: 'center', columnGap: '10px' }}>


                {/* <Paper
                    component="form"
                    sx={{ borderRadius: '25px', p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%', maxWidth: '350px', marginBottom: '15px' }}
                >
                    <InputBase
                        autoFocus={true}
                        sx={{ borderRadius: '10px', ml: 1, flex: 1, width: '100%', height: '50px' }}
                        placeholder="Search Content"
                        value={searchText}
                        onKeyDown={checkKeyPress}
                        onChange={(e) => {
                            setSearchText(e.target.value)
                        }}
                    />
                    <IconButton
                        type="button"
                        sx={{ p: '10px' }}
                        onClick={handleSubmit}
                        size='small'>
                        <SearchIcon />
                    </IconButton>

                </Paper> */}
                <FormControl sx={{ width: '100%', maxWidth: '250px', marginBottom: '15px' }}>
                    <InputLabel sx={{
                        '&.MuiInputLabel-shrink': {
                            color: THEME_COLOR
                        }
                    }}
                    >
                        Subject
                    </InputLabel>
                    <Select
                        value={subject}
                        onChange={(event) => {
                            setSubject(event.target.value);
                        }}
                        sx={{
                            borderRadius: '25px',
                            width: '100%',
                            height: '60px',
                            '&.Mui-focused fieldset': {
                                borderColor: `${THEME_COLOR} !important`,
                            },
                            '&.Mui-inputLabel': {
                                color: `${THEME_COLOR} !important`,
                            },

                        }}
                        label="Subject"
                    >

                        {subjectList.map((element, index) => {
                            return <MenuItem key={index} value={element}>{element}</MenuItem>
                        })}


                    </Select>
                </FormControl>
                <FormControl sx={{ width: '100%', maxWidth: '250px', marginBottom: '15px' }}>
                    <InputLabel sx={{
                        '&.MuiInputLabel-shrink': {
                            color: THEME_COLOR
                        }
                    }}
                    >
                        Grade
                    </InputLabel>
                    <Select
                        value={grade}
                        onChange={(event) => {
                            setGrade(event.target.value);
                        }}
                        sx={{
                            borderRadius: '25px',
                            width: '100%',
                            height: '60px',
                            '&.Mui-focused fieldset': {
                                borderColor: `${THEME_COLOR} !important`,
                            },
                            '&.Mui-inputLabel': {
                                color: `${THEME_COLOR} !important`,
                            },

                        }}
                        label="Grade"
                    >

                        {gradeList.map((element, index) => {
                            return <MenuItem key={index} value={element}>{element}</MenuItem>
                        })}


                    </Select>
                </FormControl>
                <FormControl sx={{ width: '100%', maxWidth: '250px', marginBottom: '15px' }}>
                    <InputLabel sx={{
                        '&.MuiInputLabel-shrink': {
                            color: THEME_COLOR
                        }
                    }}
                    >
                        Board
                    </InputLabel>
                    <Select
                        value={board}
                        onChange={(event) => {
                            setBoard(event.target.value);
                        }}
                        sx={{
                            borderRadius: '25px',
                            width: '100%',
                            height: '60px',
                            '&.Mui-focused fieldset': {
                                borderColor: `${THEME_COLOR} !important`,
                            },
                            '&.Mui-inputLabel': {
                                color: `${THEME_COLOR} !important`,
                            },

                        }}
                        label="Board"
                    >

                        {boardList.map((element, index) => {
                            return <MenuItem key={index} value={element}>{element}</MenuItem>
                        })}


                    </Select>
                </FormControl>
                <Button sx={{
                    textTransform: 'none',
                    backgroundColor: THEME_COLOR,
                    padding: '10px 20px',
                    color: '#ffffff',
                    marginBottom: '15px',
                    fontWeight: 500,
                    "&:hover": {
                        backgroundColor: THEME_COLOR,
                    }
                }}
                    onClick={handleSubmit}
                >
                    Search <SearchIcon />
                </Button>
            </Box>
        </Box>


    );
}


export default ContentHeader;
