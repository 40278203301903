import { getCookie, rgbToRgba } from "../../../components/utils";
import { config } from "./config";

// export const BACKEND_SERVER = 'server1.slatemates.in';

// export const DASHBOARD_PORT = 5000;

// export const UPLOAD_PORT = 3006;

export const STREAM_FILE_SERVER = config.STREAM_FILE_SERVER;

export const COOKIE_EXPIRATION_DAYS = 365;

export const MAX_ZOOM = 3.0;

export const MIN_ZOOM = 0.01;


export const BACKEND_API_URL = config.BACKEND_API_URL;

export const FRONTEND_URL = config.FRONTEND_URL;

export const LESSON_SERVER_URL = config.LESSON_SERVER_URL;

export const UPLOAD_API_URL = config.UPLOAD_API_URL;

export const THEME_COLOR = 'rgb(77, 149, 234)';

export const THEME_HOVER_COLOR = rgbToRgba(THEME_COLOR, 0.8);

export const CHAT_MESSAGE_BOX_COLOR = rgbToRgba(THEME_COLOR, 0.7);

export const CHIP_COLOR = rgbToRgba(THEME_COLOR, 0.8);

export const THEME_LIGHT_COLOR = rgbToRgba(THEME_COLOR, 0);

export const THEME_OBJECT_BORDER = THEME_COLOR;

export const WHITEBOARD_ICON_COLOR = '#ffffff'

export const WHITEBOARD_TOOLBAR_COLOR = '#27292C'

export const MAXIMUM_CHUNKS_FOR_RECORDING_IN_ONE_GO = 900; //each chunks is of 10 seconds so 900 means 9000secs which means 150 mins

export const MIC_ENABLED_EVENT_NAME='mic:enabled';

export const MIC_DISABLED_EVENT_NAME='mic:disabled';

export const CAMERA_ENABLED_EVENT_NAME='camera:enabled';

export const CAMERA_DISABLED_EVENT_NAME='camera:disabled';

export const SCREENSHARING_ENABLED_EVENT_NAME='screensharing:enabled';

export const SCREENSHARING_DISABLED_EVENT_NAME='screensharing:disabled';

export const WHITEBOARD_EVENTS_LOADED_EVENT_NAME='whiteboardEvents:loaded';

export const PREVIOUS_WHITEBOARD_FILE_LOADED_EVENT_NAME='previousWhiteboardFile:loaded'
