import * as React from 'react';
import { LocalParticipant, Participant } from 'livekit-client';
import { encryptionStatusObservable } from '@livekit/components-core';
import { useEnsureParticipant, useEnsureRoom } from '@livekit/components-react';
import { useObservableState } from './internal/useObservableState';

/**
 * @alpha
 */
export function useIsEncrypted(participant) {
    const p = useEnsureParticipant(participant);
    const room = useEnsureRoom();

    const observer = React.useMemo(() => encryptionStatusObservable(room, p), [room, p]);
    const isEncrypted = useObservableState(
        observer,
        p instanceof LocalParticipant ? p.isE2EEEnabled : p.isEncrypted,
    );
    return isEncrypted;
}
