/* eslint no-unused-vars: 0 */
import FabricCanvasTool from "../fabrictool";
import * as fabric from 'fabric'
import pan_icon from '../../../../images/pan_icon.png'

class Pan extends FabricCanvasTool {
  configureCanvas(props) {
    let canvas = this._canvas;
    canvas.isDrawingMode = canvas.selection = false;
    canvas.forEachObject((o) => (o.selectable = o.evented = false));
    canvas.defaultCursor = `url(${pan_icon}), auto`;
    canvas.hoverCursor = `url(${pan_icon}), auto`;
  }

  doMouseDown(o) {
    let canvas = this._canvas;
    this.isDown = true;
    let pointer = canvas.getPointer(o.e);
    let currentZoom = canvas.getZoom();
    this.startX = pointer.x * currentZoom;
    this.startY = pointer.y * currentZoom;
  }

  doMouseMove(o) {
    if (!this.isDown) return;
    let canvas = this._canvas;
    let pointer = canvas.getPointer(o.e);
    let currentZoom = canvas.getZoom();

    canvas.relativePan({
      x: pointer.x * currentZoom - this.startX,
      y: pointer.y * currentZoom - this.startY,
    });
    canvas.renderAll();
  }

  doMouseUp(o) {
    this.isDown = false;
  }
}

export default Pan;

// /* eslint no-unused-vars: 0 */
// import FabricCanvasTool from "../fabrictool";
// import * as fabric from 'fabric'
// import pan_icon from '../../../../images/pan_icon.png'

// class Pan extends FabricCanvasTool {
//   configureCanvas(props) {
//     let canvas = this._canvas;
//     canvas.isDrawingMode = canvas.selection = false;
//     canvas.forEachObject((o) => (o.selectable = o.evented = false));
//     canvas.defaultCursor = `url(${pan_icon}), auto`;
//     canvas.hoverCursor = `url(${pan_icon}), auto`;
//   }

//   doMouseDown(o) {
//     let canvas = this._canvas;
//     this.isDown = true;
//     // let pointer = canvas.getPointer(o.e);
//     console.log(o.e)
//     o.e.preventDefault();
//     // get the mouse cursor position at startup:
//     this.startX = o.e.clientX;
//     this.startY = o.e.clientY;
//   }

//   doMouseMove(o) {
//     if (!this.isDown) return;
//     let canvas = this._canvas;
//     // let pointer = canvas.getPointer(o.e);
//     console.log(o.e.clientX - this.startX)
//     console.log(o.e.clientY - this.startY)
//     canvas.relativePan({
//       x: o.e.clientX - this.startX,
//       y: o.e.clientY - this.startY,
//     });
//     canvas.renderAll();
//   }

//   doMouseUp(o) {
//     this.isDown = false;
//   }
// }

// export default Pan;

