import { useSetAtom } from 'jotai';
import { useRef, useCallback } from 'react';
import { canUserRedoAtom, canUserUndoAtom } from '../../../components/jotai/atom/UndoRedoButtonAtom';

const useHistory = (undoLimit = 10, debug = false) => {
  const undoListRef = useRef([]);
  const redoListRef = useRef([]);
  const setCanUserUndo = useSetAtom(canUserUndoAtom)
  const setCanUserRedo = useSetAtom(canUserRedoAtom)

  // const print = useCallback(() => {
  //   if (debug) {
  //     console.log(
  //       undoListRef.current,
  //       redoListRef.current.slice(0).reverse()
  //     );
  //   }
  // }, [debug]);

  const keep = useCallback((obj) => {
    undoListRef.current = [...undoListRef.current, obj];
    if (undoListRef.current.length > undoLimit) {
      undoListRef.current.shift();
    }
    // console.log(undoListRef.current)
    if (undoListRef.current.length === 0) {
      setCanUserUndo(false)
    }
    else {
      setCanUserUndo(true)
    }
    // print();
  }, []);

  const undo = useCallback(() => {
    const undoList = [...undoListRef.current];
    const undoObj = undoList.pop();

    if (undoObj !== undefined) {
      redoListRef.current = [...redoListRef.current, undoObj];
      if (redoListRef.current.length > undoLimit) {
        redoListRef.current.shift();
      }
      undoListRef.current = undoList;
      // print();
    }
    if (undoListRef.current.length === 0) {
      setCanUserUndo(false)
    }
    else {
      setCanUserUndo(true)
    }
    if (redoListRef.current.length === 0) {
      setCanUserRedo(false)
    }
    else {
      setCanUserRedo(true)
    }
    return undoObj;
  }, [undoLimit]);

  const redo = useCallback(() => {
    const redoList = [...redoListRef.current];
    const redoObj = redoList.pop();

    if (redoObj !== undefined) {
      undoListRef.current = [...undoListRef.current, redoObj];
      if (undoListRef.current.length > undoLimit) {
        undoListRef.current.shift();
      }
      redoListRef.current = redoList;
      // print();
    }
    if (undoListRef.current.length === 0) {
      setCanUserUndo(false)
    }
    else {
      setCanUserUndo(true)
    }
    if (redoListRef.current.length === 0) {
      setCanUserRedo(false)
    }
    else {
      setCanUserRedo(true)
    }

    return redoObj;
  }, []);

  const canUndo = useCallback(() => {
    return undoListRef.current.length > 0;
  }, []);

  const canRedo = useCallback(() => {
    return redoListRef.current.length > 0;
  }, []);

  const clear = useCallback(() => {
    undoListRef.current = [];
    redoListRef.current = [];
    setCanUserRedo(false);
    setCanUserUndo(false);
    // print();
  }, []);

  return {
    keep,
    undo,
    redo,
    canUndo,
    canRedo,
    clear,
    getUndoLimit: () => undoLimit,
    getUndoList: () => undoListRef.current,
    getRedoList: () => redoListRef.current
  };
};

export default useHistory;
