import React, {
    useEffect,
    useRef,
    useState
} from 'react';
import { Grid, styled } from '@mui/material'
import { Avatar, Box, Divider, IconButton, Input, Paper, Button, Tooltip } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import { useSetAtom } from 'jotai';
import { messageArrayAtom } from '../components/jotai/atom/messageArrayAtom';
import { sendChatMessage, uploadFile } from '../whiteboard/src/service/slateService';
import { THEME_COLOR, UPLOAD_API_URL } from '../whiteboard/src/config/constants';
import MicIcon from '@mui/icons-material/Mic';
import { v4 as uuidv4 } from 'uuid';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import moment from "moment";

var chunks = []

const Root = styled('div')((
    {
        theme
    }
) => ({
    width: '100%',
    height: '10%',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    maxHeight: '15%',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    position: 'absolute',
    bottom: 0,

}));


function MessageInput({ user, slateID, setIsChatLoading, accessToken, isTextFocused, setIsAudioNoteLoading }) {
    // const [inputValue, setinputValue] = useState('')
    // const inputValueRef = useRef('')
    const setMessageArray = useSetAtom(messageArrayAtom);
    const [recordingTime, setRecordingTime] = React.useState(0);
    const [recordingInterval, setRecordingInterval] = React.useState(null);
    const [isRecording, setIsRecording] = React.useState(false);
    const mediaRecorderRef =useRef(null);
    const textFieldRef = useRef(null)

    useEffect(() => {
        if (recordingTime > 300) {
            stopRecording()
        }
    }, [recordingTime])


    useEffect(() => {
        const inputElement = textFieldRef.current;

        if (inputElement) {
            console.log('adding paste lis')
            inputElement.addEventListener('paste', handlePaste);
        }

        // Clean up event listeners on component unmount
        return () => {
            if (inputElement) {
                inputElement.removeEventListener('paste', handlePaste);
            }
        };
    }, [textFieldRef.current, slateID])

    // const handleChange = (e) => {
    //     console.log(e.target.value)
    //     inputValueRef.current += e.target.value
    // }

    const handlePaste = async (event) => {
        console.log('trying paste')
        if (event.clipboardData.files.length === 0) {
            return;
        }
        setIsChatLoading(true)
        var items = (event.clipboardData || event.originalEvent.clipboardData).items;

        console.log(JSON.stringify(items)); // will give you the mime types
        // find pasted image among pasted items
        let blob = null;
        for (var i = 0; i < items.length; i++) {
            if (items[i].type.indexOf("image") === 0) {
                blob = items[i].getAsFile();
            }
        }
        console.log(" items: ", JSON.stringify(items));
        const file = blob;

        let photoId = uuidv4()
        const uploadFileName = 'image_' + photoId + '.png';

        console.log("file name " + uploadFileName)
        const imageData = new FormData();
        let fileType = 'image'
        imageData.append('file', file, uploadFileName);
        let status, res;
        try {
            res = await uploadFile(accessToken, imageData);
            status = res.status
        }

        catch (err) {
            console.log(err);
            status = err.response.status;
        }
        // console.log(index)
        if (status === 200) {
            let fileLink = UPLOAD_API_URL + 'uploads/' + user.id + '/' + uploadFileName
            setIsChatLoading(false)
            let messageId = uuidv4()
            handleMessageSend(messageId, fileType, uploadFileName, fileLink)
        }
        else {
            console.log(status)
        }
        // Handle the paste event as needed
    };


    const handleMessageSend = async (messageId, messageType, fileName, fileLink, totalAudioDuration) => {
        let inputValue = document.getElementById('input-for-messaging-in-chat').value;
        // console.log(inputValueRef)
        // console.log(inputValueRef.current)
        // console.log(inputValueRef.current.value)
        // if (inputValueRef && inputValueRef.current) {
        //     inputValue = inputValueRef.current.value
        // }
        if ((inputValue !== '') || messageType === 'file' || messageType === 'audio' || messageType === 'image') {
            let token = accessToken
            let textValue = messageType === 'text' ? inputValue : (messageType === 'audio' ? totalAudioDuration + '' : '')
            let status, res;
            try {
                res = sendChatMessage(token, slateID, textValue, fileName, messageType, fileLink, messageId)
                status = res.status
            }
            catch (err) {
                // console.log(err)
                status = err.response.status
            }
            if (status === 200) {

                // console.log(messageObject)
                // console.log(messageArray)

            }
            let currentMoment = moment()
            if (messageType !== 'audio') {
                const messageObject = {
                    text: textValue,
                    creator_id: user.id,
                    message_type: messageType,
                    slate_id: slateID,
                    file_name: fileName,
                    file_link: fileLink,
                    created_at: currentMoment.toISOString(),
                    id: messageId
                }
                EditMessageArray(messageObject)
            }

            if (messageType === 'text') {
                document.getElementById('input-for-messaging-in-chat').value = ''
                // setinputValue('')
            }

        }
    }

    const EditMessageArray = (data) => {
        setMessageArray(prevValue =>
            [...prevValue, data]
        );
    }

    const EditSpecificMessageinMessageArray = (data) => {
        setMessageArray(prevValue => {
            return prevValue.map((element) => element.id !== data.id ? element : data);
        }
        );
    }

    const checkKeyPress = (e) => {
        const { key, keyCode } = e;
        // console.log(key, keyCode);
        if (keyCode === 13) {
            let messageId = uuidv4()
            handleMessageSend(messageId, 'text', null, null)
        }
    };

    const handleUploadFile = async (event) => {
        setIsChatLoading(true)
        const file = event.target.files[0];
        const isImage = file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/webp'
        const filePathArray = event.target.value.split("\\");
        // console.log("file path array " + filePathArray)
        const uploadFileName = (filePathArray[filePathArray.length - 1]).replace('+', '_').replace('&', '_');
        // console.log("file name " + uploadFileName)
        let fileType = 'file'
        if (isImage) {
            fileType = 'image'
        }
        const imageData = new FormData();
        imageData.append('file', file, uploadFileName);
        let status, res;
        try {
            res = await uploadFile(accessToken, imageData);
            status = res.status
        }

        catch (err) {
            console.log(err);
            status = err.response.status;
        }
        // console.log(index)
        if (status === 200) {
            let fileLink = UPLOAD_API_URL + 'uploads/' + user.id + '/' + uploadFileName
            setIsChatLoading(false)
            let messageId = uuidv4()
            handleMessageSend(messageId, fileType, uploadFileName, fileLink)
        }
        else {
            console.log(status)
        }
    }

    const startRecording = async () => {
        //   console.log("starting recording")
        chunks = []
        let stream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
        setIsRecording(true);
        var start = 0;
        var timer = setInterval(() => {
            start += 1;
            setRecordingTime(start);
        }, 1000);
        setRecordingInterval(timer);


        let options = {
            type: 'audio',
            timeSlice: 10000,
            mimeType: 'audio/wav'
        };
        try {
            mediaRecorderRef.current = new MediaRecorder(stream);
            // console.log('media recorder')
        } catch (e0) {
            // console.log('Unable to create MediaRecorder with options    Object: ', e0);
            try {
                options = { mimeType: 'video/webm,codecs=vp9' };
                mediaRecorderRef.current = new MediaRecorder(stream, options);
            } catch (e1) {
                // console.log('Unable to create MediaRecorder');
                try {
                    options = 'video/vp8'; // Chrome 47
                    mediaRecorderRef.current = new MediaRecorder(stream, options);
                } catch (e2) {
                    alert('MediaRecorder is not supported by this browser');
                    return;
                }
            }
        }

        mediaRecorderRef.current.ondataavailable = (event) => {
            // console.log('ondataavailable')
            if (event.data && event.data.size > 0) {
                // console.log('pushing data')
                chunks.push(event.data)
            }
        };
        // console.log("now register on data available")
        mediaRecorderRef.current.start(100);


    }
    const stopRecording = async () => {
        // console.log("we are stopping the recording now so the chunks will again start from 1 once we starrt the recording");
        setIsRecording(false);
        setIsAudioNoteLoading(true)
        clearInterval(recordingInterval);
        let totalAudioDuration = recordingTime
        setRecordingTime(0)
        let audioFileId = uuidv4()
        const uploadFileName = 'audio_note_' + slateID + '_' + audioFileId + '.wav';
        // console.log("file name " + uploadFileName)
        // const audioUrl = URL.createObjectURL(voiceNoteData);
        // const audio = new Audio(audioUrl);
        // const voiceNoteDataObj = new FormData();
        // console.log(chunks)
        if (chunks.length > 1) {
            const blobObj = new Blob(chunks, { type: "audio/mp3" });
            // console.log(blobObj)
            const voiceNoteDataObj = new File([blobObj], uploadFileName, { type: "audio/mp3" });
            const audioData = new FormData();
            audioData.append('file', voiceNoteDataObj, uploadFileName);
            // const url = window.URL.createObjectURL(blobObj);
            // const a = document.createElement('a');
            // a.style.display = 'none';
            // a.href = url;
            // a.download = 'yoyo';
            // document.body.appendChild(a);
            // a.click();

            let messageId = uuidv4()
            let currentMoment = moment()
            let messageObject = {
                text: totalAudioDuration + '',
                creator_id: user.id,
                message_type: 'audio',
                slate_id: slateID,
                file_name: uploadFileName,
                file_link: '',
                created_at: currentMoment.toISOString(),
                id: messageId,
                isAudioLoading: true
            }
            EditMessageArray(messageObject)

            let status, res;
            try {
                res = await uploadFile(accessToken, audioData);
                status = res.status
            }

            catch (err) {
                console.log(err);
                status = err.response.status;
            }
            // console.log(index)
            if (status === 200) {
                let fileLink = UPLOAD_API_URL + 'uploads/' + user.id + '/' + uploadFileName
                // setIsChatLoading(false)
                // console.log(fileLink)
                // handleMessageSend('audio', uploadFileName, fileLink)
                let messageObject1 = {
                    text: totalAudioDuration + '',
                    creator_id: user.id,
                    message_type: 'audio',
                    slate_id: slateID,
                    file_name: uploadFileName,
                    file_link: fileLink.replace('wav', 'mp3'),
                    created_at: currentMoment.toISOString(),
                    id: messageId,
                    isAudioLoading: false
                }
                EditSpecificMessageinMessageArray(messageObject1)
                handleMessageSend(messageId, 'audio', uploadFileName, fileLink, totalAudioDuration)
            }
            else {
                console.log(status)
            }
            // handleMessageSend('audio', uploadFileName, audio)
        }
        mediaRecorderRef.current.stop();
    }


    return (
        <Root>
            <Paper variant="outlined" component={Box} flexGrow={1} p={1}>
                <Input
                    ref={textFieldRef}
                    sx={{ width: '80%' }}
                    id='input-for-messaging-in-chat'
                    // value={inputValueRef.current}
                    // ref={inputValueRef}
                    disableUnderline
                    fullWidth
                    autoFocus
                    onKeyDown={checkKeyPress}
                    onFocus={() => {
                        isTextFocused.current = true;
                    }}
                    onBlur={() => {
                        isTextFocused.current = false;
                    }}
                    // onChange={handleChange}
                    placeholder="Leave a message"
                    disabled={!slateID}
                />
            </Paper>
            {!isRecording ? <>
                <Tooltip title="Start recording">
                    <>
                        <IconButton sx={{
                            display: 'flex',
                            backgroundColor: 'green',
                            color: '#ffffff',
                            padding: '10px',
                            // borderRadius: '10px',
                            // boxShadow: 1, zIndex: 100,
                            textTransform: 'none',
                            fontSize: "0.85rem",
                            marginLeft: '10px',
                            "&:hover": { backgroundColor: "green" }
                        }}
                            onClick={startRecording}
                            disabled={!slateID}
                            size="small"
                        ><MicIcon /> </IconButton>
                    </>
                </Tooltip>
                <Tooltip title="Attach file">
                    <span>
                        <IconButton
                            edge="end"
                            onClick={() => document.getElementById("file-to-upload-to-chat").click()}
                            disabled={!slateID}
                            size="small"
                        >
                            <AttachFileIcon />
                        </IconButton>
                    </span>
                </Tooltip>
                <input
                    type="file"
                    id="file-to-upload-to-chat"
                    hidden
                    onChange={handleUploadFile}
                />
                {/* <Tooltip title="Camera">
                    <span>
                        <IconButton
                            edge="end"
                            onClick={() => {
                                setOpenCameraDialog(true)
                                // document.getElementById("camera-file-to-upload-to-chat").click()
                            }}
                            disabled={!slateID}
                            size="small"
                        >
                            <CameraAltIcon />
                        </IconButton>
                    </span>
                </Tooltip> */}
                {/* <CustomWebcam openCameraDialog={openCameraDialog} setOpenCameraDialog={setOpenCameraDialog} uploadCameraPhoto={uploadCameraPhoto} /> */}
                {/* <input
                hidden
                id="camera-file-to-upload-to-chat"
                type="file"
                accept="image/*"
                onChange={handleUploadFile}
                capture="environment"

            /> */}
                <Tooltip title="Send">
                    <span>
                        <IconButton
                            sx={{ color: THEME_COLOR, marginLeft: '5px' }}
                            // disabled={!body || disabled}
                            onMouseDown={() => {
                                let messageId = uuidv4()
                                handleMessageSend(messageId, 'text', null, null)
                            }}
                            disabled={!slateID}
                            size="small"
                        >
                            <SendIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            </> :
                <Tooltip title="Stop recording">
                    <>
                        {/* <IconButton
                            sx={{
                                display: 'flex',
                                animationName: '$blinker',
                                animationDuration: '1s',
                                animationTimingFunction: 'linear',
                                animationIterationCount: 'infinite',
                                color: "#ffffff",
                                backgroundColor: "red",
                                // padding: '10px',
                                // borderRadius: '10px',
                                // boxShadow: 1,
                                // zIndex: 100,
                                fontSize: "0.85rem",
                                textTransform: 'none',
                                marginLeft: '10px',
                                "&:hover": { backgroundColor: "rgb(255,0,0,0.6)" }
                            }}
                            onClick={stopRecording}
                            disabled={!slateID}
                            size="small"
                        >
                            <MicIcon />
                        </IconButton> */}
                        <Button
                            sx={{
                                display: 'flex',
                                animationName: '$blinker',
                                animationDuration: '1s',
                                animationTimingFunction: 'linear',
                                animationIterationCount: 'infinite',
                                color: "#ffffff",
                                backgroundColor: "red",
                                padding: '7px 6px',
                                // borderRadius: '10px',
                                // boxShadow: 1,
                                // zIndex: 100,
                                fontSize: { xs: "0.75rem", sm: "0.75rem", md: "0.85rem", lg: "0.85rem" },
                                textTransform: 'none',
                                marginLeft: '10px',
                                marginRight: '10px',
                                "&:hover": { backgroundColor: "rgb(255,0,0,0.6)" }
                            }}
                            onClick={stopRecording}
                            disabled={!slateID}
                        >
                            {" " + recordingTime + ' '}&nbsp;<PauseCircleOutlineIcon />
                        </Button>
                    </>
                </Tooltip>
            }

        </Root>
    );
}

export default MessageInput;