import React, { useState, useEffect } from 'react';
import {
    Drawer,
    Tabs,
    Tab,
    IconButton,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    ListItemText,
    Checkbox,
    Button,
    Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getNameFromId } from '../../../../components/utils';
import VideocamIcon from '@mui/icons-material/Videocam';
import PeopleIcon from '@mui/icons-material/People';
import CreateIcon from '@mui/icons-material/Create';
import { updateSessionState } from '../../service/slateService';
import { THEME_COLOR } from '../../config/constants';

const PermissionDrawer = ({ isDrawerOpen, setIsDrawerOpen, sendLessonEventViaWebSocket }) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [showParticipants, setShowParticipants] = useState(false);
    const [permissionType, setUserPermissionType] = useState("")
    const [sessionStateData, setSessionStateData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    // const [participantsList, setParticipantslist] = useState([])


    useEffect(() => {
        // console.log(window.sessionState)
        let sessionStateTemp = JSON.parse(JSON.stringify(window.lesson.sessionState))
        // console.log(sessionStateTemp)
        setSessionStateData(sessionStateTemp)
        // console.log(sessionStateData,)
        setIsLoading(true)
    }, [])


    useEffect(() => {

    }, [sessionStateData])


    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleOptionClick = (permission) => {
        // Show participants when clicking on the options
        setUserPermissionType(permission)
        setShowParticipants(true);
    };

    const handleBackClick = () => {
        // Go back to options screen
        setShowParticipants(false);
    };

    // Replace with actual participant data

    const handlePropertyChange = (participant, permissionName, value) => {

        if (permissionName === 'cameraEnabled') {
            // console.log('found ya ')
            participant.permissions.cameraEnabled = value
        }
        else if (permissionName === 'micEnabled') {
            participant.permissions.micEnabled = value
        }
        else if (permissionName === 'toolBarEnabled') {
            participant.permissions.toolBarEnabled = value
        }
        else if (permissionName === 'panEnabled') {
            participant.permissions.panEnabled = value
        }
        else if (permissionName === 'zoomEnabled') {
            participant.permissions.zoomEnabled = value
        }

        // for (let i = 0; i < sessionStateData.participants.length; ++i) {
        //     if (sessionStateData.participants[i].id === participant.id) {
        //         if (propertyType === 'cameraEnabled') {
        //             console.log('found ya ')
        //             sessionStateData.participants[i].cameraEnabled = value
        //         }
        //         else if (propertyType === 'micEnabled') {
        //             sessionStateData.participants[i].micEnabled = value
        //         }
        //     }
        // }
    }

    const handleMainButtonChange = (participantList, permissionName, value) => {
        // console.log(participantList, permissionName, value)
        for (let i = 0; i < participantList.length; ++i) {
            if (permissionName === 'cameraEnabled') {
                // console.log('main button', value)
                participantList[i].permissions.cameraEnabled = value
            }
            else if (permissionName === 'micEnabled') {
                participantList[i].permissions.micEnabled = value
            }
            else if (permissionName === 'toolBarEnabled') {
                participantList[i].permissions.toolBarEnabled = value
            }
            else if (permissionName === 'panEnabled') {
                participantList[i].permissions.panEnabled = value
            }
            else if (permissionName === 'zoomEnabled') {
                participantList[i].permissions.zoomEnabled = value
            }

        }
    }


    const getInitialValue = (participant, permissionName) => {
        if (permissionName === 'cameraEnabled') {
            return participant.permissions.cameraEnabled
        }
        else if (permissionName === 'micEnabled') {
            return participant.permissions.micEnabled
        }
        else if (permissionName === 'toolBarEnabled') {
            return participant.permissions.toolBarEnabled
        }
        else if (permissionName === 'panEnabled') {
            return participant.permissions.panEnabled
        }
        else if (permissionName === 'zoomEnabled') {
            return participant.permissions.zoomEnabled
        }
    }

    const getInitialValueMain = (participantList, permissionName) => {
        let counter = 0
        for (let i = 0; i < participantList.length; ++i) {
            // console.log('lolol', participantList[i].permissions[permissionName])
            if (participantList[i].permissions[permissionName])
                counter++
        }
        if (counter === participantList.length) {
            return true
        } else {
            return false
        }

        // if (permissionName === 'cameraEnabled') {

        //     return participant.permissions.cameraEnabled
        // }
        // else if (permissionName === 'micEnabled') {
        //     return participant.permissions.micEnabled
        // }
    }

    return (
        <div>
            {isLoading && <Drawer
                sx={{
                    width: { xs: '100%', sm: '100%', md: '100%', lg: 400, xl: 400 },
                    flexShrink: 0,
                    zIndex: 1500,
                    '& .MuiDrawer-paper': {
                        width: { xs: '100%', sm: '100%', md: '100%', lg: 400, xl: 400 },
                        boxSizing: 'border-box',
                    },
                }}
                anchor="right"
                open={isDrawerOpen}
                onClose={() => {
                    setIsDrawerOpen(false)
                }}
            >
                <IconButton
                    sx={{ color: '#000000', position: 'absolute', right: '5px', top: '5px', zIndex: 10000 }}
                    onClick={() => {
                        setIsDrawerOpen(false);
                        setShowParticipants(false); // Close participants screen when closing drawer
                    }}
                    size="small"
                >
                    <CloseIcon sx={{ fontSize: '1.25rem' }} />
                </IconButton>
                {showParticipants ?
                    <div>
                        <IconButton
                            onClick={handleBackClick}
                            sx={{ color: '#000000', position: 'absolute', left: '5px', top: '6.5px', zIndex: 10000 }}
                            size="small"
                        >
                            <ArrowBackIcon sx={{ fontSize: '1.35rem' }} />
                        </IconButton>
                        <Typography
                            sx={{
                                textAlign: 'left',
                                paddingLeft: '60px',
                                marginTop: '10px',
                                color: '#000000',
                                fontSize: '1rem',
                                fontWeight: 600,
                                paddingBottom: '5px',
                                borderBottom: '1px solid #e0e0e0'
                            }}>
                            {permissionType === 'cameraEnabled' && 'Enable Camera'}
                            {permissionType === 'micEnabled' && 'Enable Microphone'}
                            {permissionType === 'panEnabled' && 'Enable Pan'}
                            {permissionType === 'toolBarEnabled' && 'Enable Toolbar'}
                            {permissionType === 'zoomEnabled' && 'Enable Zoom'}
                        </Typography>
                        <List sx={{ marginTop: '20px' }}>
                            {/* <ListItem >

                                <ListItemText >
                                    Select All
                                </ListItemText>
                                <Checkbox
                                        sx={{ color: `${THEME_COLOR} !important`}}
                                    // checked={true}
                                    defaultChecked={getInitialValueMain(sessionStateData.participants, permissionType)}
                                    onChange={(e) => {
                                        console.log(e.target.checked)
                                        handleMainButtonChange(sessionStateData.participants, permissionType, e.target.checked)
                                    }}
                                />
                            </ListItem> */}
                            {sessionStateData.participants.map((participant, index) => (
                                <ListItem key={index}>

                                    <ListItemText >
                                        {getNameFromId(window.lesson.slate, participant.id)}
                                    </ListItemText>
                                    <Checkbox
                                        sx={{ color: `${THEME_COLOR} !important` }}
                                        // checked={true}
                                        defaultChecked={getInitialValue(participant, permissionType)}
                                        onChange={(e) => {
                                            // console.log(e.target.checked)
                                            // console.log(participant, permissionType)
                                            handlePropertyChange(participant, permissionType, e.target.checked)
                                        }}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </div>
                    :
                    <div>
                        <Typography sx={{
                            textAlign: 'left',
                            paddingLeft: '20px',
                            marginTop: '10px',
                            color: '#000000',
                            fontSize: '1rem',
                            fontWeight: 600,
                            display: 'flex'
                        }}>
                            <PeopleIcon sx={{ fontSize: '1.35rem' }} />&nbsp; Students
                        </Typography>
                        <Box sx={{ width: '100%', paddingLeft: '20px', paddingTop: "5px", paddingBottom: '5px', borderBottom: '1px solid #e0e0e0', borderTop: '1px solid #e0e0e0', marginTop: '10px' }}>
                            <Typography sx={{
                                textAlign: 'left',

                                color: THEME_COLOR,
                                fontSize: '0.9rem',
                                fontWeight: 500,
                                display: 'inline-block',
                                borderBottom: `3px solid ${THEME_COLOR}`
                            }}>
                                Permissions
                            </Typography>
                        </Box>

                        <Typography sx={{ textAlign: 'left', paddingLeft: '20px', marginTop: '20px', color: '#828282', fontSize: '1rem', fontWeight: 500 }}>
                            Allow Students To ...
                        </Typography>

                        <Accordion
                            sx={{ boxShadow: 'none', marginTop: '20px', }}
                            defaultExpanded>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                sx={{ backgroundColor: '#f8f8f8', display: 'flex' }}

                            >
                                <VideocamIcon sx={{ fontSize: '1.45rem', marginRight: '10px', marginLeft: '5px' }} />
                                <Typography sx={{ color: '#000000', fontSize: '1rem', fontWeight: 500 }}>Video & Audio Controls</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List>
                                    {/* Video Option */}
                                    <ListItem>

                                        <ListItemText primary="Video" />
                                        <Checkbox
                                            sx={{ color: `${THEME_COLOR} !important` }}
                                            defaultChecked={getInitialValueMain(sessionStateData.participants, 'cameraEnabled')}
                                            onChange={(e) => {
                                                // console.log(e.target.checked)
                                                handleMainButtonChange(sessionStateData.participants, 'cameraEnabled', e.target.checked)
                                            }}
                                        />
                                        <IconButton onClick={() => {
                                            handleOptionClick('cameraEnabled')
                                        }}>
                                            <ArrowForwardIosIcon sx={{ fontSize: '1.1rem', color: 'black' }} />
                                        </IconButton>

                                    </ListItem>
                                    {/* Microphone Option */}
                                    <ListItem>

                                        <ListItemText primary="Microphone" />
                                        <Checkbox
                                            sx={{ color: `${THEME_COLOR} !important` }}
                                            defaultChecked={getInitialValueMain(sessionStateData.participants, 'micEnabled')}
                                            onChange={(e) => {
                                                // console.log(e.target.checked)
                                                handleMainButtonChange(sessionStateData.participants, 'micEnabled', e.target.checked)
                                            }}
                                        />
                                        <IconButton onClick={() => {
                                            handleOptionClick('micEnabled')
                                        }}>
                                            <ArrowForwardIosIcon sx={{ fontSize: '1.1rem', color: 'black' }} />
                                        </IconButton>

                                    </ListItem>
                                </List>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion
                            sx={{ boxShadow: 'none', marginTop: '20px', }}
                            defaultExpanded>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                sx={{ backgroundColor: '#f8f8f8', display: 'flex' }}

                            >
                                <CreateIcon sx={{ fontSize: '1.45rem', marginRight: '10px', marginLeft: '5px' }} />
                                <Typography sx={{ color: '#000000', fontSize: '1rem', fontWeight: 500 }}>Whiteboard Controls</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List>
                                    {/* Video Option */}
                                    <ListItem>

                                        <ListItemText primary="Pan" />
                                        <Checkbox
                                            sx={{ color: `${THEME_COLOR} !important` }}
                                            defaultChecked={getInitialValueMain(sessionStateData.participants, 'panEnabled')}
                                            onChange={(e) => {
                                                // console.log(e.target.checked)
                                                handleMainButtonChange(sessionStateData.participants, 'panEnabled', e.target.checked)
                                            }}
                                        />
                                        <IconButton onClick={() => {
                                            handleOptionClick('panEnabled')
                                        }}>
                                            <ArrowForwardIosIcon sx={{ fontSize: '1.1rem', color: 'black' }} />
                                        </IconButton>

                                    </ListItem>
                                    {/* Microphone Option */}
                                    <ListItem>

                                        <ListItemText primary="Toolbar" />
                                        <Checkbox
                                            sx={{ color: `${THEME_COLOR} !important` }}
                                            defaultChecked={getInitialValueMain(sessionStateData.participants, 'toolBarEnabled')}
                                            onChange={(e) => {
                                                // console.log(e.target.checked)
                                                handleMainButtonChange(sessionStateData.participants, 'toolBarEnabled', e.target.checked)
                                            }}
                                        />
                                        <IconButton onClick={() => {
                                            handleOptionClick('toolBarEnabled')
                                        }}>
                                            <ArrowForwardIosIcon sx={{ fontSize: '1.1rem', color: 'black' }} />
                                        </IconButton>

                                    </ListItem>

                                    <ListItem>

                                        <ListItemText primary="Zoom" />
                                        <Checkbox
                                            sx={{ color: `${THEME_COLOR} !important` }}
                                            defaultChecked={getInitialValueMain(sessionStateData.participants, 'zoomEnabled')}
                                            onChange={(e) => {
                                                // console.log(e.target.checked)
                                                handleMainButtonChange(sessionStateData.participants, 'zoomEnabled', e.target.checked)
                                            }}
                                        />
                                        <IconButton onClick={() => {
                                            handleOptionClick('zoomEnabled')
                                        }}>
                                            <ArrowForwardIosIcon sx={{ fontSize: '1.1rem', color: 'black' }} />
                                        </IconButton>

                                    </ListItem>
                                </List>
                            </AccordionDetails>
                        </Accordion>
                        {/* Participants List */}
                    </div>}
                <div style={{ position: 'absolute', bottom: '10px', width: '100%', textAlign: 'center' }}>
                    {/* <Button variant="contained"
                        onClick={() => {
                            console.log(sessionStateData)
                            let sessionStateTemp = JSON.parse(JSON.stringify(window.lesson.sessionState))
                            console.log(sessionStateTemp)
                            setSessionStateData(sessionStateTemp)
                        }}
                        sx={{
                            textTransform: 'none',
                            fontSize: '0.9rem',
                            marginRight: '10px',
                            color: 'black',
                            backgroundColor: '#f2f2f2',
                            border: '1px solid #e0e0e0',
                            '&:hover': {
                                color: 'black',
                                backgroundColor: '#f2f2f2',
                                border: '1px solid #e0e0e0',
                            }
                        }}
                    >
                        Cancel Changes
                    </Button> */}
                    <Button
                        variant="contained"
                        onClick={async () => {
                            // console.log(sessionStateData)

                            let status, res;
                            try {
                                res = await updateSessionState(window.lesson.accessToken, window.lesson.session.id, JSON.stringify(sessionStateData), window.lesson.slate.id);
                                status = res.status
                            }
                            catch (err) {
                                console.log(err);
                                status = err.response.status;
                            }
                            // console.log(index)
                            if (status === 200) {
                                sendLessonEventViaWebSocket({
                                    eventType: 'sessionStateUpdated',
                                    eventData: {
                                        'sentBy': window.lesson.user.id,
                                        'sessionState': sessionStateData
                                    }
                                })
                                window.lesson.sessionState = sessionStateData
                                setIsDrawerOpen(false)
                            }
                            else {
                                alert("Could not update permissions")
                                // console.log(status)
                            }
                        }}
                        sx={{
                            textTransform: 'none',
                            fontSize: '0.9rem',
                            backgroundColor: THEME_COLOR,
                            '&:hover': {
                                backgroundColor: THEME_COLOR,
                            }
                        }}
                    >
                        Update Changes
                    </Button>
                </div>
            </Drawer>}
        </div>
    );
};

export default PermissionDrawer;
