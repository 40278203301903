import React, { useEffect, useState } from "react";
import { Avatar, } from '@mui/material';
import { capitalizeFirstAlphabet } from "../../../../components/helperFunctions";
import { getProfilePicture } from "../../service/slateService";
import { THEME_COLOR } from "../../config/constants";


const CreateAvatar = ({ data, accessToken }) => {
    const [profileUrl, setProfileUrl] = useState('')
    useEffect(() => {
        // console.log(element)
        initialize()

    }, [])
    // useEffect(() => {
    //     // console.log(element)
    //     initialize()

    // }, [slateMembersArray])

    const initialize = async () => {
        const tempProfile = await getProfilePictureUrl(accessToken)
        setProfileUrl(tempProfile)
    }

    const getProfilePictureUrl = async (accessToken) => {
        // console.log('fetching profile')
        let status, res;
        try {
            res = await getProfilePicture(accessToken, data.email)
            status = res.status
        }

        catch (err) {
            console.log(err);
            status = err.response.status;
        }
        // console.log(index)
        if (status === 200) {
            // console.log('profile url', res)
            return res.data.picture
        }
        else {
            // console.log(status)
        }
        return '';
    }

    return <>
        <Avatar
            sx={{
                height: '2rem',
                width: '2rem',
                backgroundColor: THEME_COLOR,
                fontSize: "0.9rem",
                fontWeight: "bolder",
                marginRight: '10px'
            }}
            src={profileUrl}
        >{data.firstName && data.lastName && data.firstName !== '' && data.lastName !== '' ? (capitalizeFirstAlphabet(data.firstName).substring(0, 1) + capitalizeFirstAlphabet(data.lastName).substring(0, 1)) : capitalizeFirstAlphabet(data.email).substring(0, 2)}</Avatar>
    </>;
};

export default CreateAvatar