import React, { useEffect, useState } from "react";
import { Box, LinearProgress } from '@mui/material';
import io from "socket.io-client";
import { Document, Page } from 'react-pdf';
import { useRef } from "react";
import { useLocation } from "react-router-dom";
import { getQueryObject } from "../utils/common";

const PdfViewer = (() => {
    const [mediaUrl, setMediaUrl] = useState('')

    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const socketRef = useRef(null);
    const { search } = useLocation();
    const [roomName, setRoomName] = useState("");
    const [clientID, setClientID] = useState(null);
    const [accessToken, setAccessToken] = useState(null);
    const [objectID, setObjectID] = useState(null);
    const [height, setHeight] = useState()
    const [width, setWidth] = useState()

    useEffect(() => {
        const query = getQueryObject(search);
        var {
            roomName,
            clientID,
            accessToken,
            objectID,
            pdfUrl
        } = query;

        setClientID(clientID);
        setRoomName(roomName);
        setAccessToken(accessToken);
        setObjectID(objectID)
        setMediaUrl(pdfUrl)
        // setAudioVideoPlatform(audioVideoPlatform);
    }, [search])

    useEffect(() => {
        if (roomName && accessToken && objectID && mediaUrl && clientID) {
            initialiseSocket(socketRef, roomName, accessToken, objectID, mediaUrl, clientID);
        }

    }, [roomName, accessToken, objectID, mediaUrl, clientID])

    // useEffect(() => {

    // }, [mediaUrl])
    useEffect(() => {
        if (objectID) {
            let elmnt = window.parent.document.getElementById(objectID)
            // console.log(objectID, elmnt)
            if (elmnt) {
                setHeight(parseInt((elmnt.style.height).substring(0, (elmnt.style.height).length - 2)))
                setWidth(parseInt((elmnt.style.width).substring(0, (elmnt.style.width).length - 2)))
            }
        }

    }, [objectID])

    function initialiseSocket(socketRef, roomName, accessToken, objectID, mediaUrl, clientID) {

        // console.log(accessToken)
        socketRef.current = io.connect("https://websocket.teachmatter.com",
            {
                transports: ["websocket"],
                withCredentials: false,
                extraHeaders: {
                    'my-custom-header': 'abcd'
                },
                auth: {
                    token: accessToken
                }
            }
        );
        socketRef.current.on("connect", () => {
            // console.log("connected to 3003 server " + socketRef.current.id)
            // console.log("connection time" + Date().toLocaleString());
            //roomname for pdf_viewer roomname
            socketRef.current.emit("joinSession", 'pdf_viewer_' + objectID)


        });

        socketRef.current.on("disconnect", () => {
            // console.log("Disconnected now from 3003");
        });


        socketRef.current.on('lessonEvent', (lessonEvent) => {
            // console.log("lesson event" + JSON.stringify(lessonEvent))

            if (lessonEvent.eventType === 'changePdfViewerPage') {
                let changePdfViewerPageEventData = lessonEvent.eventData
                // console.log(changePdfViewerPageEventData)
                // console.log(changePdfViewerPageEventData.sentBy !== userName)
                if (changePdfViewerPageEventData.sentBy !== clientID) {
                    if (changePdfViewerPageEventData.objectID === objectID) {
                        handlePageNumber(changePdfViewerPageEventData.pageNumber)
                    }
                }
            }


        })



    }
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }


    const sendLessonEventViaWebSocket = (lessonEvent, objectID) => {
        // console.log("trying to send data " + JSON.stringify(lessonEvent))
        // console.log('pdf_viewer_' + objectID)
        socketRef.current.emit("lessonEvent", 'pdf_viewer_' + objectID, lessonEvent)
        // console.log("emitted event")
    }



    const handlePageNumber = (value) => {
        setPageNumber(value)
    }

    window.changePdfViewerDimension = (viewerHeight, viewerWidth) => {
        setHeight(viewerHeight)
        setWidth(viewerWidth)
    }

    return <Box sx={{ height: '100%', width: '100%' }}>
        <Box
            sx={{
                // display: maximize ? 'block' : 'none',
                height: '100%',
                width: '100%'
            }}>
            {/* <Box sx={{ display: "flex", flexDirection: 'row', paddingTop: "2.5px", borderRadius: '10px', justifyContent: "center", textAlign: 'center', alignItems: 'center' }}>
                        <TextField
                            type="number"
                            value={pageNumber}
                            sx={{
                                input: {
                                    color: "#ffffff",
                                    width: '50px',
                                    height: '2px',
                                    borderColor: '#ffffff !important',
                                    fontSize: '0.9rem',
                                    padding: '10px',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    backgroundColor: 'black',
                                },

                                borderRadius: '5px',
                                width: '50px',
                                height: '2px',
                                color: '#ffffff',
                                padding: 0,
                                marginBottom: '20px',
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'white',

                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'white',
                                    },
                                    '&.Mui-focused fieldset': {
                                        border: "2px solid",
                                        borderColor: 'white',
                                        borderRadius: '5px',
                                    },
                                },
                                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                    'WebkitAppearance': 'none',
                                    margin: 0,
                                },
                                '& input[type="number"]': {
                                    'MozAppearance': 'textfield',
                                },
                            }}
                            onFocus={() => {
                                props.isTextFocused.current = true;
                            }}
                            onBlur={() => {
                                props.isTextFocused.current = false;
                                // console.log(props.pageNumber)
                            }}
                            onChange={(e) => {
                                setPageNumber(e.target.value)

                            }}
                            autoFocus />&nbsp;
                        <span style={{ color: '#9F9EB2', fontSize: '0.9rem', paddingTop: '0.1rem' }}>
                            of
                        </span>
                        &nbsp;
                        <span style={{ color: '#9F9EB2', fontSize: '1rem', fontWeight: '550' }} >
                            {numPages}
                        </span>
                        &nbsp;
                    </Box> */}
            <Box sx={{ justifyContent: 'center', textAlign: 'center' }}>
                <button
                    disabled={pageNumber === 1}
                    onClick={() => {
                        setPageNumber(pageNumber - 1)
                        sendLessonEventViaWebSocket({
                            eventType: 'changePdfViewerPage',
                            eventData: {
                                'sentBy': clientID,
                                'pageNumber': pageNumber - 1,
                                'objectID': objectID
                            }
                        }, objectID)
                    }}
                >
                    Previous
                </button>
                <button
                    disabled={pageNumber === numPages}
                    onClick={() => {
                        setPageNumber(pageNumber + 1)
                        sendLessonEventViaWebSocket({
                            eventType: 'changePdfViewerPage',
                            eventData: {
                                'sentBy': clientID,
                                'pageNumber': pageNumber + 1,
                                'objectID': objectID
                            }
                        }, objectID)
                    }}
                >
                    Next
                </button>
            </Box>
            <Box
                sx={{ justifyContent: 'center', textAlign: 'center', height: '80%', width: '100%' }}>
                <Document
                    loading={<LinearProgress />}
                    style={{ textAlign: "center", height: '50% !important', width: '50% !important' }}
                    file={mediaUrl}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    <Page
                        loading={<LinearProgress />}
                        width={width}
                        height={height}
                        scale={0.95}
                        pageNumber={pageNumber}
                        renderAnnotationLayer={false}
                    />
                </Document>
            </Box>
            {/* <Button
                id={objectID + '-resize-button'}
                sx={{ display: 'none' }}
                onClick={() => {
                    let elmnt = window.parent.document.getElementById(objectID)
                    // console.log(objectID, elmnt)
                    setHeight(parseInt((elmnt.style.height).substring(0, (elmnt.style.height).length - 2)))
                    setWidth(parseInt((elmnt.style.width).substring(0, (elmnt.style.width).length - 2)))
                }}
            >

            </Button> */}
        </Box>
    </Box>;
});

export default PdfViewer