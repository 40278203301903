import { forwardRef, useImperativeHandle } from 'react';
import { useState } from "react";
import Box from "@mui/material/Box";
import { Typography } from '@mui/material';

const MousePointer = forwardRef((props, ref) => {

  const [peerMap, setPeerMap] = useState(new Map());


  useImperativeHandle(ref, () => ({
    setMouseCoords(xCoord, yCoord, peerID, canvasWidth, canvasHeight, color, name, pencilWidth) {
      // console.log("got data from peer" + xCoord, yCoord, peerID, canvasWidth, canvasHeight, color, pencilWidth);
      setPeerMap(new Map(peerMap.set(peerID, { x: xCoord * canvasWidth, y: yCoord * canvasHeight, color: color, name: name, pencilWidth: parseInt(pencilWidth) })));
    }
    ,
    handleDeletePeerMap

  }));

  const handleDeletePeerMap = (peerID) => {
    // console.log('peer id is', peerID)
    // console.log('peer id is', peerMap)
    // Get a copy of the current peerMap state
    // const peerData = peerMap.get(peerID);
    const updatedPeerMap = new Map(peerMap);
    updatedPeerMap.delete(peerID)

    setPeerMap(updatedPeerMap);
    // console.log('peer id is', peerMap)
    // setPeerMap((prevState) => {
    //   return prevState.filter((obj) => obj.peerID !== id)
    // });
  }

  return (

    //zIndex increased so that the mouse pointers are visible when something is drawn on the whiteboard canvas such as background color

    [...peerMap.keys()].map(peerID => (
      <Box sx={{
        zIndex: "1",
        position: "fixed",
        top: peerMap.get(peerID).y + "px",
        left: peerMap.get(peerID).x + "px",
        display: 'flex',
        flexDirection: 'row'

      }}>
        <Box sx={{
          height: peerMap.get(peerID).pencilWidth == 9 ? "16px" : peerMap.get(peerID).pencilWidth == 6 ? "12px" : "8px",
          width: peerMap.get(peerID).pencilWidth == 9 ? "16px" : peerMap.get(peerID).pencilWidth == 6 ? "12px" : "8px",
          backgroundColor: peerMap.get(peerID).color,
          borderRadius: "50%",
          boxShadow: "0 0 0 2px rgb(220,220,220) "
        }}>
        </Box>
        {peerMap.get(peerID).name && <Box sx={{ backgroundColor: peerMap.get(peerID).color, borderRadius: "20px", marginLeft: '5px', padding: '2px 5px' }}>
          <Typography sx={{ fontSize: '0.75rem', color: peerMap.get(peerID).color == '#ffffff' ? '#000000' : '#ffffff' }}>
            {peerMap.get(peerID).name}
          </Typography>
        </Box>}
      </Box>))

  )

});
export default MousePointer;